import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";
import Style from "./VehicleAndMachinery.module.css"
import FormHeadings from "../PNMAssets/FormHeadings";
import { useRecoilState } from "recoil";
import { siteId } from "../../../atoms";

const DistributionBox = () => {
  const webApi = new WebApimanager();
  const [formData, setFormData] = useState({
    siteName: "",
    type: "",
    assetCode: "",
    equipmentType: "Distribution Board",
    transitionDate: "",
    subCode: "",
    scopeOfWorkDetails: "",
    location: "",
    tower: "",
    floor: "",
    flat: "",
    room: "",
    remark: "",
    // problemReportedOn: "",
    // breakDownDate: "",
    // breakDownTime: "",
    // repairOkDate: "",
    // repairOkTime: "",
    // repairStatus: "",
    // kmOrHrReading: null,
    // observation: "",
    // actionTaken: "",
    // remark: "",
    // repeatedProblem: "",
    // fuelPumpTypeSetting: "",
    // gasOrOilCondition: "",
    // leakages: "",
    // engineStatus: "",
    // tyreStatus: "",
    // radiatorStatus: "",
    // batteryStatus: "",
    // mechanic: "",
    // documentAttached: "",
  });

  const [errors, setErrors] = useState({});
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [id, setId] = useState();
  const [site, setSite] = useRecoilState(siteId);
  const [sites, setDatasites] = useState([]);

  const handleSiteChange = (e) => {
    const { name, value } = e.target;

    setSite(value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // const newValue = name === "kmOrHrReading" ? parseInt(value, 10) : value;
    let newFormData = {
      ...formData,
      [name]: value,
    };
    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find(
        (asset) => asset.assetCode === value.split("/")[0]
      );
      if (assCode) {
        setId(assCode._id)
        newFormData = {
          ...newFormData,

          ["subCode"]: assCode.subCode,
        };
      }
    }
    setFormData(newFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
    });
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      // setFormData((prev) => ({ ...prev, assetCode: id }));
      console.log(formData);
      const response = await webApi.post("api/dailyLogReport/db", {...formData,["assetCode"]:id});
      console.log(response);
      window.location.reload();
    }
  };
  console.log({...formData,["assetCode"]:id},"idcheck")

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      const response = await webApi.get(`api/pnms/assetCode`);
      setAssetCodeData(response.data.data.AssetCodes);
    };

    // const fetchSiteData = async () => {
    //   const response = await webApi.get(`api/sites/AllSites`);
    //   setNameData(response.data.sites);
    // };

    // fetchSiteData();
    fetchAssetCodeData();
  }, []);
  useEffect(() => {
 
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
      
          if (!site) {
            const defaultSiteId = response.data.sites[0]._id;
            setSite(defaultSiteId); 
            setFormData((prev) => ({ ...prev, siteName: defaultSiteId })); 
          } else {
            setFormData((prev) => ({ ...prev, siteName: site })); 
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);

  return (
    // <div className={styles.VMmainDiv}>
    //   <div className={styles.VMFormcont}>
    //     <h3 className={styles.VMheading} style={{ paddingBottom: "32px" }}>
    //       Daily Log Report For Distribution Box
    //     </h3>
    //     <div style={{ display: "flex" }}>
    //       <form onSubmit={handleSubmit}>
    //         <div>
    //           <div className={styles.FormItemsMain}>
    //             <FormHeadings text={"Site Name:"} />
    //             <div className="relative">
    //               <select
    //                 className={st.pdropDownSelect}
    //                 name="siteName"
    //                 value={formData.siteName}
    //                 onChange={handleSiteChange}
    //                 style={{
    //                   marginLeft: "18px",
    //                   width: "836px",
    //                   paddingLeft: "20px",
    //                 }}
    //               >
    //                 <option value="" hidden>
    //                   Select a site
    //                 </option>
    //                 {sites &&
    //                   Array.isArray(sites) &&
    //                   sites.map((item) => (
    //                     <option value={item._id} key={item._id}>
    //                       {item.siteName}
    //                     </option>
    //                   ))}
    //               </select>
    //               <p className=" text-danger absolute left-[2%]">
    //                 {errors.siteName}
    //               </p>
    //             </div>
    //           </div>
    //           <div className={styles.formSecondryFields}>
    //             <div>
    //               <div
    //                 className={styles.FormItemsMain}
    //                 style={{
    //                   display: "flex",
    //                   alignItems: "center",
    //                   whiteSpace: "nowrap",
    //                   justifyContent: "space-between",
    //                 }}
    //               >
                    
    //                 <FormHeadings text={"Transition ID:"} />
    //                 <input
    //                   type="text"
    //                   className={st.pdropDownSelect}
    //                   name="transitionID"
    //                   readOnly
    //                 />
    //               </div>

    //               <div
    //                 className={styles.FormItemsMain}
    //                 style={{
    //                   display: "flex",
    //                   alignItems: "center",
    //                   whiteSpace: "nowrap",
    //                 }}
    //               >
    //                 <FormHeadings text={"Equipment Type:"} />
    //                 <div className="relative">
    //                   <input
    //                     className={st.pdropDownSelect}
    //                     name="equipmentType"
    //                     value={formData.equipmentType}
    //                     style={{
    //                       paddingLeft: "5px",
    //                       marginLeft: "8px",
    //                       paddingLeft: "20px",
    //                     }}
    //                     readOnly
    //                   />
    //                   <p className="text-danger absolute left-[2%]">
    //                     {errors.equipmentType}
    //                   </p>
    //                 </div>
    //               </div>
    //               <div
    //                 className={styles.FormItemsMain}
    //                 style={{
    //                   display: "flex",
    //                   alignItems: "center",
    //                   whiteSpace: "nowrap",
    //                   justifyContent: "space-between",
    //                 }}
    //               >
    //                 <FormHeadings text={"Type:"} />
    //                 <div className="relative">
    //                   <select
    //                     className={st.pdropDownSelect}
    //                     name="type"
    //                     value={formData.type}
    //                     onChange={handleChange}
    //                     style={{
    //                       paddingLeft: "5px",
    //                       marginLeft: "8px",
    //                       paddingLeft: "20px",
    //                     }}
    //                   >
    //                     <option value="" hidden>
    //                       select Type
    //                     </option>
    //                     <option value="Own">Own</option>
    //                     <option value="Hire">Hire</option>
    //                   </select>
    //                   <p className="text-danger absolute left-[2%]">
    //                     {errors.type}
    //                   </p>
    //                 </div>
    //               </div>
    //             </div>

    //             <div>
    //               <div>
    //                 <div className={`${styles.FormItemsMain} justify-between`}>
    //                   <FormHeadings text={"Transition Date:"} />
    //                   <div>
    //                     <input
    //                       type="date"
    //                       className={st.pdropDownSelect}
    //                       style={{ paddingLeft: "20px" }}
    //                       name="transitionDate"
    //                       value={formData.transitionDate}
    //                       onChange={handleChange}
    //                       placeholder="---------"
    //                     />
    //                     <p className="text-danger absolute">
    //                       {errors.transitionDate}
    //                     </p>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="flex mt-6 justify-between ">
    //                 <FormHeadings text={"Asset code/Name:"} />
    //                 <div>
    //                   <select
    //                     className={st.pdropDownSelect}
    //                     name="assetCode"
    //                     value={formData.assetCode}
    //                     onChange={handleChange}
    //                     style={{
    //                       display: "flex",
    //                       alignItems: "center",
    //                       whiteSpace: "nowrap",
    //                       justifyContent: "space-between",
    //                     }}
    //                   >
    //                     <option value="" hidden>
    //                       Select name
    //                     </option>
    //                     {assetCodeData.length &&
    //                       assetCodeData
    //                         .filter(
    //                           (item) => item.type === "Distribution Board"
    //                         )
    //                         .map((item) => (
    //                           <option
    //                             value={item.assetCode + "/" + item.name}
    //                             key={item._id}
    //                           >
    //                             {item.assetCode + "/" + item.name}
    //                           </option>
    //                         ))}
    //                   </select>
    //                   <p className="text-danger absolute">{errors.assetCode}</p>
    //                 </div>
    //               </div>
    //               <div
    //                 className={styles.FormItemsMain}
    //                 style={{
    //                   display: "flex",
    //                   alignItems: "center",
    //                   whiteSpace: "nowrap",
    //                   justifyContent: "space-between",
    //                 }}
    //               >
    //                 <FormHeadings text={"Sub code:"} />
    //                 <div>
    //                   <input
    //                     className={st.pdropDownSelect}
    //                     type="text"
    //                     placeholder="Enter SubCode"
    //                     name="subCode"
    //                     onChange={handleChange}
    //                     value={formData.subCode}
    //                   />
    //                   <p className="text-danger absolute">{errors.subCode}</p>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className={styles.formSeperator}></div>
    //           <div className={styles.FormItemsMain}>
    //             <FormHeadings text={"Scope of work Details:"} />
    //             <div>
    //               <input
    //                 type="text"
    //                 placeholder="Enter Scope of work"
    //                 className={styles.dropDownSelect}
    //                 name="scopeOfWorkDetails"
    //                 value={formData.scopeOfWorkDetails}
    //                 style={{ width: "792px", paddingLeft: "20px" }}
    //                 onChange={handleChange}
    //               />
    //               <p className="text-danger absolute">
    //                 {errors.scopeOfWorkDetails}
    //               </p>
    //             </div>
    //           </div>

    //           <h3
    //             className={styles.VMheading}
    //             style={{
    //               paddingBottom: "32px",
    //               marginTop: "50px",
    //             }}
    //           >
    //             Work Area Details:
    //           </h3>

    //           <div className={`${styles.querySetailsDiv} w-full gap-4`}>
    //             <div className="flex-col w-[33%] ">
    //               <div className="flex justify-between mb-10 w-full`">
    //                 <FormHeadings text={"Location:"} />
    //                 <div>
    //                   <input
    //                     name="location"
    //                     value={formData.location}
    //                     onChange={handleChange}
    //                     style={{
    //                       paddingLeft: "20px",
    //                       width: "240px",
    //                       backgroundColor: "#f3f3f3",
    //                       height: "30px",
    //                       fontSize: "12px",
    //                       fontFamily: "Poppins",
    //                       border: "1px solid rgba(151, 151, 151, 0.25)",
    //                     }}
    //                     type="text"
    //                     placeholder="Enter Location"
    //                   />
    //                   <p className="text-danger absolute">{errors.location}</p>
    //                 </div>
    //               </div>
    //               <div className="flex justify-between mb-10 w-full">
    //                 <FormHeadings text={"Flat:"} />
    //                 <div>
    //                   <input
    //                     name="flat"
    //                     value={formData.flat}
    //                     onChange={handleChange}
    //                     style={{
    //                       paddingLeft: "20px",
    //                       width: "240px",
    //                       backgroundColor: "#f3f3f3",
    //                       height: "30px",
    //                       fontSize: "12px",
    //                       fontFamily: "Poppins",
    //                       border: "1px solid rgba(151, 151, 151, 0.25)",
    //                     }}
    //                     type="text"
    //                     placeholder="Enter"
    //                   />
    //                   <p className="text-danger absolute">{errors.flat}</p>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="flex-col w-[33%]">
    //               <div className="flex justify-between mb-10 w-full">
    //                 <FormHeadings text={"Tower:"} />
    //                 <div>
    //                   <input
    //                     name="tower"
    //                     value={formData.tower}
    //                     onChange={handleChange}
    //                     style={{
    //                       paddingLeft: "20px",
    //                       width: "240px",
    //                       backgroundColor: "#f3f3f3",
    //                       height: "30px",
    //                       fontSize: "12px",
    //                       fontFamily: "Poppins",
    //                       border: "1px solid rgba(151, 151, 151, 0.25)",
    //                     }}
    //                     type="text"
    //                     placeholder="Enter Location"
    //                   />
    //                   <p className="text-danger absolute">{errors.tower}</p>
    //                 </div>
    //               </div>
    //               <div className="flex justify-between mb-10 w-full">
    //                 <FormHeadings text={"Room:"} />
    //                 <div>
    //                   <input
    //                     name="room"
    //                     value={formData.room}
    //                     onChange={handleChange}
    //                     style={{
    //                       paddingLeft: "20px",
    //                       width: "240px",
    //                       backgroundColor: "#f3f3f3",
    //                       height: "30px",
    //                       fontSize: "12px",
    //                       fontFamily: "Poppins",
    //                       border: "1px solid rgba(151, 151, 151, 0.25)",
    //                     }}
    //                     type="text"
    //                     placeholder="Enter"
    //                   />
    //                   <p className="text-danger absolute">{errors.room}</p>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="flex-col w-[33%]">
    //               <div className="flex justify-between mb-10 w-full  ">
    //                 <FormHeadings text={"Floor:"} />
    //                 <div>
    //                   <input
    //                     name="floor"
    //                     value={formData.floor}
    //                     onChange={handleChange}
    //                     style={{
    //                       paddingLeft: "20px",
    //                       width: "240px",
    //                       backgroundColor: "#f3f3f3",
    //                       height: "30px",
    //                       fontSize: "12px",
    //                       fontFamily: "Poppins",
    //                       border: "1px solid rgba(151, 151, 151, 0.25)",
    //                     }}
    //                     type="text"
    //                     placeholder="Enter Location"
    //                   />
    //                   <p className="text-danger absolute">{errors.floor}</p>
    //                 </div>
    //               </div>
    //               <div className="flex justify-between mb-10 w-full">
    //                 <FormHeadings text={"Remark:"} />
    //                 <div>
    //                   <input
    //                     name="remark"
    //                     value={formData.remark}
    //                     onChange={handleChange}
    //                     style={{
    //                       paddingLeft: "20px",
    //                       width: "240px",
    //                       backgroundColor: "#f3f3f3",
    //                       height: "30px",
    //                       fontSize: "12px",
    //                       fontFamily: "Poppins",
    //                       border: "1px solid rgba(151, 151, 151, 0.25)",
    //                     }}
    //                     type="text"
    //                     placeholder="Enter"
    //                   />
    //                   <p className="text-danger absolute">{errors.remark}</p>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div style={{ paddingTop: "32px" }}>
    //           <button type="submit" className={styles.submitBtn}>
    //             SUBMIT
    //           </button>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    // </div>
    <>
      <div className={Style.VMMainContainer}>
          <h3 className={Style.VMHeading}>
            Daily Log Report For Power Tools
          </h3>
          <form onSubmit={handleSubmit} className={Style.VMFormContainer}>
            <div className={Style.VMSiteContainer}>
              <div className={Style.VMSiteHeading}>
                <FormHeadings text={"Site Name:"} />
              </div>
              <div className={Style.VMSite}>
                <select
                  className={Style.VMSiteInputField}
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleSiteChange}
                  
                >
                  <option value="" hidden>
                    Select a site
                  </option>
                  {sites &&
                    Array.isArray(sites) &&
                    sites.map((item) => (
                      <option value={item._id} key={item._id}>
                        {item.siteName}
                      </option>
                    ))}
                </select>
                <p className="text-danger absolute">{errors.siteName}</p>
              </div>
            </div>
            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition ID:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                      <input type="text" readOnly className={Style.VMTopInputField} />
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition Date:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                            type="date"
                            className={Style.VMTopInputField}
                            name="transitionDate"
                            value={formData.transitionDate}
                            onChange={handleChange}
                            placeholder="---------"
                          />
                          <p className="text-danger absolute">
                            {errors.transitionDate}
                          </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Equipment Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          name="equipmentType"
                          value={formData.equipmentType}
                          readOnly
                        />
                        <p className="text-danger absolute ">
                          {errors.equipmentType}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Asset code/Name:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="assetCode"
                          value={formData.assetCode}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            Select name
                          </option>
                          {assetCodeData.length &&
                            assetCodeData
                              .filter(
                                (item) =>
                                  item.type === "Distribution Board"
                              )
                              .map((item) => (
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                        </select>
                        <p className="text-danger absolute">
                          {errors.assetCode}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="Own">Own</option>
                          <option value="Hire">Hire</option>
                        </select>
                        <p className="text-danger absolute ">
                          {errors.type}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"SubCode:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          type="text"
                          placeholder="Enter SubCode"
                          name="subCode"
                          onChange={handleChange}
                          value={formData.subCode}
                        />
                        <p className="text-danger absolute">{errors.subCode}</p>
                    </div>
                  </div>
                </div>
              </div>
              
                
            </div>
            <div className={Style.VMFormSeperator}></div>

            <div className={Style.VMWorkDetails}>
              <div className={Style.VMWorkDetailsHeading}>
                <FormHeadings text={"Scope of work Details:"} />
              </div>
              <div className={Style.VMWorkDetailsInputCont}>
                <input
                  type="text"
                  placeholder="Enter Scope of work"
                  className={Style.VMWorkDetailsInputField}
                  name="scopeOfWorkDetails"
                  value={formData.scopeOfWorkDetails}
                  onChange={handleChange}
                />
                <p className="text-danger absolute">
                  {errors.scopeOfWorkDetails}
                </p>
              </div>

            </div>

            <h3 className={Style.VMHeading} style={{marginBlock:"30px"}}>
            Work Area Details
          </h3>

          
           
           

          <div className={Style.DBMidFormCont}>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Location:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter Location"
                          type="text"
                          className={Style.VMMidInput}
                          name="location"
                          value={formData.location}
                          onChange={handleChange}
                          
                        />
                        <p className="text-danger absolute  ">
                          {errors.location}
                        </p>
                    </div>
                </div>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Tower:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter Tower"
                          type="text"
                          className={Style.VMMidInput}
                          name="tower"
                          value={formData.tower}
                          onChange={handleChange}
                          
                        />
                        <p className="text-danger absolute  ">
                          {errors.tower}
                        </p>
                    </div>
                </div>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Floor:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter Floor"
                          type="text"
                          className={Style.VMMidInput}
                          name="floor"
                          value={formData.floor}
                          onChange={handleChange}
                          
                        />
                        <p className="text-danger absolute  ">
                          {errors.floor}
                        </p>
                    </div>
                </div>
          </div>
            
          <div className={Style.DBMidFormCont}>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Flat:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter flat"
                          type="text"
                          className={Style.VMMidInput}
                          name="flat"
                          value={formData.flat}
                          onChange={handleChange}
                          
                        />
                        <p className="text-danger absolute  ">
                          {errors.flat}
                        </p>
                    </div>
                </div>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Room:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter Room"
                          type="text"
                          className={Style.VMMidInput}
                          name="room"
                          value={formData.room}
                          onChange={handleChange}
                          
                        />
                        <p className="text-danger absolute  ">
                          {errors.room}
                        </p>
                    </div>
                </div>
                <div className={Style.DBMidFormContent}>
                    <div className={Style.VMMidFormInputHeading}>
                      <FormHeadings text={"Remark:"} />
                    </div>
                    <div className={Style.DBMidFormInputContainer}>
                    <input
                          placeholder="Enter Remark"
                          type="text"
                          className={Style.VMMidInput}
                          name="remark"
                          value={formData.remark}
                          onChange={handleChange}
                          
                        />
                        <p className="text-danger absolute  ">
                          {errors.remark}
                        </p>
                    </div>
                </div>
          </div>

          <div className={Style.VMBtnContainer}>
            <button >SUBMIT</button>
          </div>

          </form>
    </div>

    </>
  );
};

export default DistributionBox;
