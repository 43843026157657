

import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import styles from "./Form.module.css";
import Add from "../../../Images/Add.svg";
import Sub from "../../../Images/subtration.svg";

const FolderFile = (props) => {
  const { open, handleDialogClose, site, Filter, existingData } = props;
  let webApi = new WebApimanager();
  
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <div className={styles.entireContainer}>
        <div className={styles.leftsideContainer}>
          {/* leftUpperTable */}
          <div>
            <table>
              <tr>
                <th>FormNo</th>
                <th>ChecklistType</th>
              </tr>
              <tbody>
                <tr>
                  <td>MEP/CL-B/001</td>
                  <td>status</td>
                </tr>
              </tbody>
            </table>
          </div>
            {/* leftDownTable */}
          <div>
          <table>
              <tr>
                <th>FormNo</th>
                <th>ChecklistType</th>
              </tr>
              <tbody>
                <tr>
                  <td>MEP/CL-B/001</td>
                  <td>status</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.rightsideContainer}></div>
      </div>
    </>
  );
};

export default FolderFile;
