import React, { useState } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import styles from "./Design.module.css";
import Bin from "../../../Images/bin.svg";
import Add from "../../../Images/Add.svg"
import Sub from "../../../Images/subtration.svg"

const AddDescriptions = (props) => {
  const { open, handleDialogClose, site, Filter, existingData } = props;
  let webApi = new WebApimanager();
  const [apiProcessing, setApiProcessing] = useState({loader: false,message: "Loading...",});
  const departmentData = {
    Management: {
      roles: [
        "Company Managing Director",
        "Director",
        "Technical President",
        "Senior Vice President",
        "Vice President",
        "Assistant Vice President",
      ],
    },
    "Design Consultant": {
      roles: [
        "Archiectural",
        "Structural",
        "MEP",
        "Facade",
        "Landscape and Lighting",
        "Vertical Transportation",
        "Traffic and Parking",
      ],

    },

    Execution: {
      roles: [
        "Deputy Project Manager",
        "Assistant Project Manager",
        "Assistant Manager",
        "Sr.Project Engineer",
        "Project Engineer",
        "Jr.Project Engineer",
        "Senior Engineer",
        "Engineer",
        "Junior Engineer",
        "GTE",
      ],
  
    },
    Planning: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],

    },
    SiteManagement: {
      role: [
        "General Manager",
        "Assistant General Manager",
        "Senior Project Manager",
        "Project Manager",
      ],

    },
    QualitySurveyorBilling: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],

    },
    MEP: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Supervisors",
      ],

    },
    EHS: {
      roles: [
        "Head",
        "Manager",
        "Assistant Manager",
        "Safety Incharge",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Steve Ward",
        "Male Nurse",
      ],
  
    },
    CRMTECH: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],

    },
    Store: {
      roles: [
        "Head",
        "Store Manager",
        "Assistant Store Manager",
        "Store Officer",
        "Store Incharge",
        "Sr.Store Keeper",
        "Store Keeper/ Executive",
        "Sr.Store Asst",
        "Store Asst",
        "Trainee Store",
      ],
   
    },
    Survey: {
      roles: ["Sr.Engineer", "Engineer", "Jr.Engineer", "GTE", "Helper"],
    },
    Accounts: {
      roles: [
        "Head",
        "Manager",
        "Sr.Accountant",
        "Accountant",
        "Jr.Accountant",
      ],
   
    },
    Admin: {
      roles: [
        "Head",
        "Project Admin",
        "Ass Admin",
        "Time Keeper",
        "Colony Admin",
      ],
    },
  };
  const [descriptions, setDescriptions] = useState([{ description: "" }]);
  const [dropdownRows, setDropdownRows] = useState([{ department: "", role: "", availableRoles: [] }]);
  const [errorMessage, setErrorMessage] = useState({});
  const [errors,setErrors] =useState({});
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [availableRoles, setAvailableRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  
  const handleDepartmentChange = (index, event) => {
    const selectedDepartment = event.target.value;
    const updatedRows = [...dropdownRows];
    updatedRows[index].department = selectedDepartment;
    updatedRows[index].availableRoles = departmentData[selectedDepartment]?.roles || [];
    updatedRows[index].role = ''; // Reset role when department changes
    setDropdownRows(updatedRows);
  };

  
  const handleRoleChange = (index, event) => {
    const selectedRole = event.target.value;
    const updatedRows = [...dropdownRows];
    updatedRows[index].role = selectedRole;
    setDropdownRows(updatedRows);
  };
  const handleAddClick = () => {
    setDropdownRows([
      ...dropdownRows,
      { department: '', role: '', availableRoles: [] }, 
    ]);
    setDescriptions([...descriptions, { description: "" }]);
  };
 
  const handleRemoveDropdownRow = (index) => {
    const updatedRows = dropdownRows.filter((_, rowIndex) => rowIndex !== index);
    setDropdownRows(updatedRows);
  };
  const handleClose = () => {
    handleDialogClose();
    setDropdownRows([
      ...dropdownRows,
      { department: '', role: '', availableRoles: [] }, 
    ]);


  };

  const handleAddRow = () => {
    setDescriptions([...descriptions, { description: "" }]);
  };

  const handleRemoveRow = (index) => {
    const updatedDescriptions = descriptions.filter((_, i) => i !== index);
    setDescriptions(updatedDescriptions);
  };

  const handleDescriptionChange = (index, value) => {
    const updatedDescriptions = descriptions.map((item, i) =>
      i === index ? { description: value } : item
    );
    setDescriptions(updatedDescriptions);
  };
  const validateForm = () => {
    let isValid = true;
    const errors = {};
  
    // Example validation for descriptions
    descriptions.forEach((item, index) => {
      if (!item.description) {
        errors[`description${index}`] =`Description ${index+1} is required`;
        isValid = false;
      }
    });
  
    // Example validation for dropdown rows (departments/roles)
    dropdownRows.forEach((row, index) => {
      if (!row.department) {
        errors[`department${index}`] = `Please select a department ${index+1}`;
        isValid = false;
      }
      if (!row.role) {
        errors[`role${index}`] = `Please select a role ${index+1}`;
        isValid = false;
      }
    });
  
    setErrors(errors); // Set errors for displaying validation messages
    return isValid;
  };
  
  const handleFormSubmit = () => {
    if (validateForm()) {
      setApiProcessing({ loader: true, message: "Submitting..." });
      
      webApi
        .post('/your-api-endpoint')
        .then(response => {
          setApiProcessing({ loader: false, message: "" });
          // Handle success, close dialog, etc.
        })
        .catch(error => {
          setApiProcessing({ loader: false, message: "" });
          setErrorMessage("An error occurred");
          // Handle error
        });
    }
  };

  const handleFormSave = () => {
    if (validateForm()) {
      setApiProcessing({ loader: true, message: "Submitting..." });
      
      webApi
        .post('/your-api-endpoint')
        .then(response => {
          setApiProcessing({ loader: false, message: "" });
          // Handle success, close dialog, etc.
        })
        .catch(error => {
          setApiProcessing({ loader: false, message: "" });
          setErrorMessage("An error occurred");
          // Handle error
        });
    }
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: "50px",
          }}
        >
          <p
            style={{ margin: "5px 480px", textAlign: "center", color: "white" }}
          >
            Add Descriptions
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "500px" }}
        >
          <div className={styles.overall}>
            <div>
              <p className={styles.heading}>Ranjith Buildcon Pvt. Ltd.</p>
            </div>
            <div className={styles.second}>
              <div className={styles.grid}>
                <p>ActivityName</p>
                <p>:</p>
                <p>ActivityName</p>

                <p>ActivityName</p>
                <p>:</p>
                <p>ActivityName</p>

                <p>ActivityName</p>
                <p>:</p>
                <p>ActivityName</p>

                <p>ActivityName</p>
                <p>:</p>
                <p>ActivityName</p>
              </div>
            </div>

            <div>
              <table>
                <thead>
                  <tr className={styles.tableHead}>
                    <th>S.No</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {descriptions.map((item, index) => (
                    <tr key={index} className={styles.tableBody}>
                      <td>{index + 1}</td>
                      <td>
                        <div className={styles.addflex}>
                          <input
                            type="text"
                            placeholder="Enter the description"
                            value={item.description}
                            onChange={(e) =>
                              handleDescriptionChange(index, e.target.value)
                            }
                          />
                  
                          <img
                            src={Bin}
                            loading="lazy"
                            className={styles.bin}
                            onClick={() => handleRemoveRow(index)}
                            alt="Delete"
                          />
                        </div>
                        {errors[`description${index}`] && (
                        <p className={styles.error}>{errors[`description${index}`]}</p>
                      )}
                      </td>
                   
                    </tr>
                    
                  ))}
                </tbody>
              </table>
              <button className={styles.addMoreButton} onClick={handleAddRow}>
                Add One More
              </button>
            </div>
            <div>
              <div className={styles.assginIncharge}>
                <p style={{marginTop:'20px'}}>Assigning In charge</p>
                <img
                src={Add}
                loading="lazy"
                alt="loading"
                style={{marginLeft:'20px'}}
                onClick={handleAddClick}
                />
              </div>
              {dropdownRows.map((row, index) => (
                <div className={styles.DeptContainer}>
                  <div className={styles.InputContainer}>
                    <label >Department {index+1}:</label>
                    <div className={styles.InputFieldContainer}>
                    <select
                      value={row.department}
                      onChange={(e) => handleDepartmentChange(index, e)}
                      className={styles.InputField}
                    >
                      <option value="">Select Department</option>
                      {Object.keys(departmentData).map((dept) => (
                        <option key={dept} value={dept}>
                          {dept}
                        </option>
                      ))}
                    </select>
                    {errors[`department${index}`] && (
                          <p className={styles.error} style={{position:"absolute"}}>{errors[`department${index}`]}</p>
                        )}
                    </div>
                  </div>
                  <div className={styles.InputContainer} style={{justifyContent:"flex-end"}}>
                  <label >Role {index+1}:</label>
                    <div className={styles.InputFieldContainer}>
                    <select
                      value={row.role}
                      onChange={(e) => handleRoleChange(index, e)}
                      className={styles.InputField}
                    >
                      <option value="">Select Role</option>
                        {row.availableRoles.map((role, roleIndex) => (
                          <option key={roleIndex} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                      {errors[`role${index}`] && (
                            <p className={styles.error} style={{position:"absolute"}}>{errors[`role${index}`]}</p>
                          )}
                    </div>
                  </div>

                </div>
              
//         <div className={styles.deptrole} key={index}>
//           <label style={{marginTop:'10px'}}>Department {index+1}:</label>
          
//           <select
//             value={row.department}
//             onChange={(e) => handleDepartmentChange(index, e)}
//             className={styles.inchargedropDown}
//           >
//             <option value="">Select Department</option>
//             {Object.keys(departmentData).map((dept) => (
//               <option key={dept} value={dept}>
//                 {dept}
//               </option>
//             ))}
//           </select>
//           {errors[`department${index}`] && (
//                 <p className={styles.error} style={{position:"absolute",bottom:"0px"}}>{errors[`department${index}`]}</p>
//               )}
         
//           <label style={{ marginTop: '10px' }}>Role{index+1}:</label>
//           <select
//             value={row.role}
//             onChange={(e) => handleRoleChange(index, e)}
//             className={styles.inchargedropDown}
//           >
//             <option value="">Select Role</option>
//             {row.availableRoles.map((role, roleIndex) => (
//               <option key={roleIndex} value={role}>
//                 {role}
//               </option>
//             ))}
//           </select>
//           {errors[`role${index}`] && (
//                 <p className={styles.error}>{errors[`role${index}`]}</p>
//               )}
         
// {index > 0 &&
//           <img
//             src={Sub} 
//             alt="loading"
//             style={{  cursor: 'pointer' }}
//             onClick={() => handleRemoveDropdownRow(index)} 
//           />
// }

             
//         </div>



    
      ))}
      </div>
 <div className={styles.buttons}>
  <button className={styles.requestingButton} onClick={handleFormSubmit}>REQUESTING APPROVAL</button>
 <button
              onClick={handleFormSave}
              className={styles.submitButton}
            >
              Save
            </button>
 </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddDescriptions;
