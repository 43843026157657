import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { WebApimanager } from "../../../WebApiManager";
import Style from "../DailyLogReport/VehicleAndMachinery.module.css"
import FormHeadings from "../PNMAssets/FormHeadings";
import { siteId } from "../../../atoms";
import { useRecoilState } from "recoil";

const MiscellaneousReport = () => {
  const webApi = new WebApimanager();
  const [formData, setFormData] = useState({
    siteName: "",
    makeName: "",
    selectRateType: "",
    rateCharge: "",
    equipmentType: "",
    model: "",
    vendor: "",
    mobileNo: "",
    vehicleNo: "",
    transitionDate: "",
    assetCode: "",
    scopeOfWorkDetails: "",
    remarks: "",
    location: "",
    startDate: "",
    endDate: "",
    differenceDate: "",
    startTime: "",
    endTime: "",
    differenceTime: "",
    debitNote: "" || "No",
    employeeType: "",
    employeeId: "",
    debitAmount: "",
    debitReason: "",
  });

  const [errors, setErrors] = useState({});
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [sites, setDatasites] = useState([]);
  const [id, setId] = useState();
  const [equipment, setEquipment] = useState("");

  function calculateDateDifference(startDate, endDate) {
    const [startYear, startMonth, startDay] = startDate.split("-").map(Number);
    const [endYear, endMonth, endDay] = endDate.split("-").map(Number);
    console.log(startYear, endYear);
    console.log(startMonth, endMonth);
    console.log(startDay, endDay);

    const start = new Date(startYear, startMonth - 1, startDay);
    const end = new Date(endYear, endMonth - 1, endDay);

    if (end >= start) {
      const diffTime = end.getTime() - start.getTime();
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return Math.round(diffDays);
    } else {
      return -1;
    }
  }

  const handleSiteChange = (e) => {
    const { name, value } = e.target;

    setSite(e.target.value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // const newValue = name === "kmOrHrReading" ? parseInt(value, 10) : value;
    let newFormData = {
      ...formData,
      [name]: value,
    };
    if (name === "equipmentType" && value) {
      setEquipment(value);
    }
    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find(
        (asset) => asset.assetCode === value.split("/")[0]
      );
      if (assCode) {
        setId(assCode._id);
        newFormData = {
          ...newFormData,
          ["subCode"]: assCode.subCode,
          ["equipmentType"]: assCode.type,
        };
      }
    }
    setFormData(newFormData);

    if (name === "startDate" || name === "endDate") {
      if (newFormData.startDate !== "" && newFormData.endDate !== "") {
        const diff = calculateDateDifference(
          newFormData.startDate,
          newFormData.endDate
        );
        setFormData({ ...newFormData, differenceDate: diff });
        if (diff < 0) {
          errors.differenceDate = "start date should be less than end Date";
          setFormData({
            ...newFormData,
            differenceDate: "",
          });
        } else {
          setFormData({ ...newFormData, differenceDate: diff });
          errors.differenceDate = "";
        }
      } else {
        setFormData({ ...newFormData, differenceDate: "" });
      }
    }
    if (name === "startTime" || name === "endTime") {
      if (newFormData.startTime && newFormData.endTime) {
        const start = new Date(`1970-01-01T${newFormData.startTime}`);
        const end = new Date(`1970-01-01T${newFormData.endTime}`);
        let difference = (end.getTime() - start.getTime()) / 1000; // Difference in seconds

        const hours = Math.floor(difference / 3600);
        difference = difference % 3600;
        const minutes = Math.floor(difference / 60);

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedDifference = `${formattedHours}:${formattedMinutes}`;
        if (start >= end) {
          errors.differenceTime = "Start Time should be less than End Date";
          setFormData({
            ...newFormData,
            differenceTime: "",
          });
        } else {
          errors.differenceTime = "";
        }
        setFormData({
          ...newFormData,
          differenceTime: formattedDifference,
        });
      } else {
        setFormData({
          ...newFormData,
          differenceTime: "",
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};

    const requiredFields = [
      "siteName",
      "makeName",
      "selectRateType",
      "rateCharge",
      "equipmentType",
      "assetCode",
      "startDate",
      "endDate",
      "differenceDate",
      "startTime",
      "endTime",
      "differenceTime",
      "model",
      "vehicleNo",
      "mobileNo",
      "vendor",
      "transitionDate",
      "scopeOfWorkDetails",
      "location",
      "remarks",
    ];
    const mobileRegex = /^[6-9]\d{9}$/
    requiredFields.forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
      if (key === "mobileNo" && formData[key]){
        if(!mobileRegex.test(formData[key])){
          formErrors[key]="Enter a valid Mob No"
        }
      }
    });

    if (formData.debitNote === "Yes") {
      const conditionalDebitNoteFields = [
        "employeeType",
        "employeeId",
        "debitAmount",
        "debitReason",
      ];
      conditionalDebitNoteFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    }

    console.log("submitted", formData);

    if (Object.keys(formErrors).length) {
      console.log("Form errors:", formErrors);
      setErrors(formErrors);
    } else {
      console.log(formData);
      const tempFormData = {
        siteName: site,
        type: formData.type,
        assetCode: id,
        equipmentType: formData.equipmentType,

        startDate: formData.startDate,
        endDate: formData.endDate,
        differenceDate: formData.differenceDate,
        startTime: formData.startTime,
        endTime: formData.endTime,
        differenceTime: formData.differenceTime,

        transitionDate: formData.transitionDate,
        scopeOfWorkDetails: formData.scopeOfWorkDetails,
        location: formData.location,
        remarks: formData.remarks,
        debitAmount: Number(formData.debitAmount),
        debitReason: formData.debitReason,
        model: formData.model,
        vendor: formData.vendor,
        mobileNo: formData.mobileNo,
        phoneNo: formData.phoneNo,
        debitNote: formData.debitNote || "No",
        employeeType: formData.employeeType,
        debitAmount: Number(formData.debitAmount),
        debitReason: formData.debitReason,
        employeeId: formData.employeeId,
      };

      console.log("temp", tempFormData);
      console.log("formData", formData);
      const response = await webApi.post(
        "api/pnms/miscellaneous",
        tempFormData
      );
      console.log(response);
      // window.location.reload();
    }
  };

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      const response = await webApi.get(`api/pnms/assetCode`);
      setAssetCodeData(response.data.data.AssetCodes);
    };

    fetchAssetCodeData();
  }, []);

  useEffect(() => {
 
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
      
          if (!site) {
            const defaultSiteId = response.data.sites[0]._id;
            setSite(defaultSiteId); 
            setFormData((prev) => ({ ...prev, siteName: defaultSiteId })); 
          } else {
            setFormData((prev) => ({ ...prev, siteName: site })); 
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);

  return (
    
    <>
    <div className={Style.VMMainContainer}>
          <h3 className={Style.VMHeading}>
          Miscellaneous Report
          </h3>
          <form onSubmit={handleSubmit} className={Style.VMFormContainer}>
            <div className={Style.VMSiteContainer}>
              <div className={Style.VMSiteHeading}>
                <FormHeadings text={"Site Name:"} />
              </div>
              <div className={Style.VMSite}>
                <select
                  className={Style.VMSiteInputField}
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleSiteChange}
                  
                >
                  <option value="" hidden>
                    Select a site
                  </option>
                  {sites &&
                    Array.isArray(sites) &&
                    sites.map((item) => (
                      <option value={item._id} key={item._id}>
                        {item.siteName}
                      </option>
                    ))}
                </select>
                <p className="text-danger absolute">{errors.siteName}</p>
              </div>
            </div>
            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition ID:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                      <input type="text" readOnly className={Style.VMTopInputField} />
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition Date:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                            type="date"
                            className={Style.VMTopInputField}
                            name="transitionDate"
                            value={formData.transitionDate}
                            onChange={handleChange}
                            placeholder="---------"
                          />
                          <p className="text-danger absolute">
                            {errors.transitionDate}
                          </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Equipment Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="equipmentType"
                          value={formData.equipmentType}
                          onChange={handleChange}
                        >
                          <option value="" hidden>
                            Select Equipment Type
                          </option>
                          <option value="Vehicle">Vehicle</option>
                          <option value="Machinery">Machinery</option>
                          <option value="Power Tools">Power Tools</option>
                          <option value="Distribution Board">
                            Distribution Board
                          </option>
                          <option value="Batching Point">Batching Point</option>
                        </select>
                        <p className="text-danger absolute ">
                          {errors.equipmentType}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Asset code/Name:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="assetCode"
                          value={formData.assetCode}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            Select name
                          </option>
                          {assetCodeData.length &&
                            assetCodeData
                              .filter((item) => item.type.includes(equipment))
                              .map((item) => (
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                        </select>
                        <p className="text-danger absolute">
                          {errors.assetCode}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Make  Name:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          name="makeName"
                          value={formData.makeName}
                          onChange={handleChange}
                          placeholder="Enter Name"
                          
                        />
                        <p className="text-danger absolute ">
                          {errors.makeName}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Model:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          type="text"
                          placeholder="Enter Model"
                          name="model"
                          onChange={handleChange}
                          value={formData.model}
                        />
                        <p className="text-danger absolute">{errors.model}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Vendor:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          name="vendor"
                          value={formData.vendor}
                          onChange={handleChange}
                          placeholder="Enter Name"
                          
                        />
                        <p className="text-danger absolute ">
                          {errors.vendor}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Mobile No:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          type="number"
                          placeholder="Enter Mobile No"
                          name="mobileNo"
                          onChange={handleChange}
                          value={formData.mobileNo}
                        />
                        <p className="text-danger absolute">{errors.mobileNo}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer} >
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Rate:"} />

                    </div>
                    <div className={Style.VMTopInputCont} style={{display:"flex",position:"relative"}}>
                      <select
                          name="selectRateType"
                          value={formData.selectRateType}
                          onChange={handleChange}
                          className={!formData.selectRateType?Style.VMTopInputField:Style.MRTopInputField

                          }
                        >
                          <option value="" hidden>
                            Select Date/Hr
                          </option>
                          <option value="Week">Week</option>
                          <option value="Day">Day</option>
                          <option value="Hour">Hour</option>
                        </select>
                        {formData.selectRateType && <input
                          type="number"
                          className={Style.MRRate}
                          placeholder="Enter Number"
                          name="rateCharge"
                          value={formData.rateCharge}
                          onChange={handleChange}
                        />}
                        {!formData.selectRateType && (
                          <p className="text-danger absolute top-[100%]">
                            {errors.selectRateType}
                          </p>
                        )}
                        {formData.selectRateType && !formData.rateCharge && (
                          <p className="text-danger absolute top-[100%]">
                            {errors.rateCharge}
                          </p>
                        )}
                      
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Vehicle No:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          type="number"
                          placeholder="Enter Number"
                          name="vehicleNo"
                          onChange={handleChange}
                          value={formData.vehicleNo}
                        />
                        <p className="text-danger absolute">{errors.vehicleNo}</p>
                    </div>
                  </div>
                </div>
              </div>
              
                
            </div>
            <div className={Style.VMFormSeperator}></div>
            <h3 className={Style.VMHeading} style={{marginTop:"30px"}}>
          Details
          </h3>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>Date:</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Start:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="date"
                              className={Style.VMMidInput}
                              name="startDate"
                              value={formData.startDate}
                              onChange={handleChange}
                            />
                            <p className="text-danger absolute ">
                              {errors.startDate}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"End:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="date"
                            className={Style.VMMidInput}
                            name="endDate"
                            value={formData.endDate}
                            onChange={handleChange}
                            
                          />
                          <p className="text-danger absolute  ">
                            {errors.endDate}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="number"
                            className={Style.VMMidInput}
                            name="differenceDate"
                            value={formData.differenceDate}
                            onChange={handleChange}
                            
                          />
                          <p className="text-danger absolute  ">
                            {errors.differenceDate}
                          </p>
                      </div>
                </div>
            </div>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>Clock:</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"In:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="time"
                              className={Style.VMMidInput}
                              name="startTime"
                              value={formData.startTime}
                              onChange={handleChange}
                            />
                            <p className="text-danger absolute  ">
                              {errors.startTime}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Out:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="time"
                            className={Style.VMMidInput}
                            name="endTime"
                            value={formData.endTime}
                            onChange={handleChange}
                            
                          />
                          <p className="text-danger absolute ">
                            {errors.endTime}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="text"
                            className={Style.VMMidInput}
                            name="differenceTime"
                            value={formData.differenceTime}
                            onChange={handleChange}
                            
                          />
                          <p className="text-danger absolute  ">
                            {errors.differenceTime}
                          </p>
                      </div>
                </div>
            </div>

            <div className={Style.VMFormSeperator}></div>
            <div className={Style.VMTopFormMainContainer}>
              
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Debit Note:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                            className={Style.VMTopInputField}
                            name="debitNote"
                            value={formData.debitNote || "No"}
                            onChange={handleChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                          <p className="text-danger absolute ">
                            {errors.debitNote}
                          </p>
                    </div>
                  </div>
                </div>
                
              </div>
              
              {formData.debitNote === "Yes" && <>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  name="employeeType"
                                  value={formData.employeeType}
                                  placeholder="Enter employee Type"
                                  onChange={handleChange}
                                />

                                <p className="text-danger absolute">
                                  {errors.employeeType}
                                </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee ID:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  name="employeeId"
                                  placeholder="Enter Employee Id"
                                  value={formData.employeeId}
                                  onChange={handleChange}
                                />

                                <p className="text-danger absolute">
                                  {errors.employeeId}
                                </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Debit Reason:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  placeholder="Enter Reason"
                                  name="debitReason"
                                  value={formData.debitReason}
                                  onChange={handleChange}
                                />
                                <p className="text-danger absolute">
                                  {errors.debitReason}
                                </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Debit Amount:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                    type="number"
                                    className={Style.VMTopInputField}
                                    name="debitAmount"
                                    placeholder="Enter amount"
                                    value={formData.debitAmount}
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger absolute">
                                    {errors.debitAmount}
                                  </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>}
                
            </div>
            <div className={Style.VMFormSeperator}></div>

            <div className={Style.VMWorkDetails}>
              <div className={Style.VMWorkDetailsHeading}>
                <FormHeadings text={"Scope of work Details:"} />
              </div>
              <div className={Style.VMWorkDetailsInputCont}>
                <input
                  type="text"
                  placeholder="Enter Scope of work"
                  className={Style.VMWorkDetailsInputField}
                  name="scopeOfWorkDetails"
                  value={formData.scopeOfWorkDetails}
                  onChange={handleChange}
                />
                <p className="text-danger absolute">
                  {errors.scopeOfWorkDetails}
                </p>
              </div>

            </div>
            <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Location:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                          type="text"
                          className={Style.VMTopInputField}
                          name="location"
                          placeholder="Enter Work Location"
                          value={formData.location}
                          onChange={handleChange}
                        />
                        <p className="text-danger absolute ">
                          {errors.location}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Remarks:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                        <input
                            type="text"
                            className={Style.VMTopInputField}
                            name="remarks"
                            placeholder="Enter Remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                          />
                          <p className="text-danger absolute">{errors.remarks}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMBtnContainer}>
                  <button >SUBMIT</button>
                </div>

          </form>
    </div>
      
    </>
  );
};

export default MiscellaneousReport;
