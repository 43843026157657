import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import "./Dialog.module.css";
import * as Icon from "react-bootstrap-icons";
import { WebApimanager } from "../../WebApiManager";
import { Loader } from "../../Widgets/notificationFeedbacks";
import PermissionToggle from "./ToggleSwitch";
import ToggleSwitch from "./ToggleSwitch";
import GoogleMapWithSearch from "./GoogleMapWithSearch";
import { siteId } from "../../atoms";
import { useRecoilState } from "recoil";
import LocationSearch from "./LocationSearch";
const AddSiteDialog = (props) => {
  const { open, close, id, companyName: initialCompanyName } = props;
  const webApi = new WebApimanager();
  const [site, setSite] = useRecoilState(siteId);
  const [formData, setFormData] = useState({
    companyName: "",
    siteName: "",
    siteAddress: "",
    country: "",
    cityState: "",
    pinCode: "",
    featureDetails: "",
    drawing: false,
    pm: false,
    qaqc: false,
    ehs: false,
    qs: false,
    planner: false,
    hr: false,
    user: false,
    store: false,
    admin: false,
    location: false,
    authentication: false,
    latitude: "",
    longitude: "",
    radius: "",
  });
  // const indiaStateCityData = {
  //   "Andhra Pradesh": ["Visakhapatnam", "Vijayawada", "Guntur", "Tirupati"],
  //   "Arunachal Pradesh": ["Itanagar", "Tawang", "Bomdila"],
  //   "Assam": ["Guwahati", "Dispur", "Silchar"],
  //   "Bihar": ["Patna", "Gaya", "Bhagalpur"],
  //   "Chhattisgarh": ["Raipur", "Bilaspur", "Korba"],
  //   "Goa": ["Panaji", "Margao", "Vasco da Gama"],
  //   "Gujarat": ["Ahmedabad", "Surat", "Vadodara"],
  //   "Haryana": ["Chandigarh", "Faridabad", "Gurugram"],
  //   "Himachal Pradesh": ["Shimla", "Dharamshala", "Manali"],
  //   "Jharkhand": ["Ranchi", "Jamshedpur", "Dhanbad"],
  //   "Karnataka": ["Bengaluru", "Mysuru", "Hubli"],
  //   "Kerala": ["Thiruvananthapuram", "Kochi", "Kozhikode"],
  //   "Madhya Pradesh": ["Bhopal", "Indore", "Gwalior"],
  //   "Maharashtra": ["Mumbai", "Pune", "Nagpur", "Aurangabad"],
  //   "Manipur": ["Imphal", "Churachandpur", "Thoubal"],
  //   "Meghalaya": ["Shillong", "Tura", "Jowai"],
  //   "Mizoram": ["Aizawl", "Lunglei", "Champhai"],
  //   "Nagaland": ["Kohima", "Dimapur"],
  //   "Odisha": ["Bhubaneswar", "Cuttack", "Rourkela"],
  //   "Punjab": ["Chandigarh", "Amritsar", "Jalandhar"],
  //   "Rajasthan": ["Jaipur", "Udaipur", "Jodhpur"],
  //   "Sikkim": ["Gangtok", "Namchi"],
  //   "Tamil Nadu": ["Chennai", "Coimbatore", "Madurai"],
  //   "Telangana": ["Hyderabad", "Warangal", "Karimnagar"],
  //   "Tripura": ["Agartala", "Udaipur", "Dharmanagar"],
  //   "Uttar Pradesh": ["Lucknow", "Kanpur", "Agra"],
  //   "Uttarakhand": ["Dehradun", "Haridwar", "Nainital"],
  //   "West Bengal": ["Kolkata", "Siliguri", "Asansol"],
  // };

  const indiaStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  useEffect(() => {
    // Update formData when companyName changes
    if (initialCompanyName !== undefined) {
      setFormData((prevData) => ({
        ...prevData,
        companyName: initialCompanyName,
      }));
    }
  }, [initialCompanyName]);
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const validate = () => {
    const newErrors = {};
    const latitudeRegex = /^-?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
    const longitudeRegex = /^-?(1[0-7]\d(\.\d+)?|[1-9]?\d(\.\d+)?|180(\.0+)?)$/;
    const namesRegex = /^[a-zA-Z]+$/;

    if (!formData.companyName)
      newErrors.companyName = "Company Name is required";
    if (formData.companyName && !namesRegex.test(formData.companyName))
      newErrors.companyName = "Company Name should be alphabets";
    if (!formData.siteName) newErrors.siteName = "Site Name is required";
    if (formData.siteName && !namesRegex.test(formData.siteName))
      newErrors.siteName = "site Name should be alphabets";
    if (!formData.siteAddress)
      newErrors.siteAddress = "Site Address is required";
    if (!formData.country) newErrors.country = "Country is required";
    if (!formData.cityState) newErrors.cityState = "City/State is required";
    if (!formData.pinCode || formData.pinCode.length !== 6)
      newErrors.pinCode = "Valid PinCode is required";
    if (!formData.featureDetails)
      newErrors.featureDetails = "Feature Details is required";
    if (!formData.latitude || !latitudeRegex.test(formData.latitude)) {
      newErrors.latitude = "Valid Latitude is required";
    }
    if (!formData.longitude || !longitudeRegex.test(formData.longitude)) {
      newErrors.longitude = "Valid Longitude is required";
    }
    if (!formData.radius) newErrors.radius = "Radius is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";
    const lettersOnlyRegex = /^[A-Za-z\s]*$/; // Regular expression to allow only letters and spaces

    switch (name) {
      case "companyName":
      case "siteName":
        if (value.trim()) {
          if (!lettersOnlyRegex.test(value)) {
            errorMessage = `${name
              .replace(/([A-Z])/g, " $1")
              .trim()} should contain letters only.`;
          }
        } else {
          errorMessage = `Please enter ${name
            .replace(/([A-Z])/g, " $1")
            .trim()}.`;
        }
        break;
      case "pinCode":
        const isValidPinCode = /^\d{6}$/.test(value);
        errorMessage = value.trim()
          ? isValidPinCode
            ? ""
            : "PinCode must be exactly 6 digits."
          : "Please enter a PinCode.";
        break;
      case "latitude":
        const isValidLatitude = /^-?([1-8]?\d(\.\d+)?|90(\.0+)?)$/.test(value);
        errorMessage = value.trim()
          ? isValidLatitude
            ? ""
            : "Please enter a valid Latitude."
          : "Please enter a Latitude.";
        break;
      case "longitude":
        const isValidLongitude =
          /^-?(1[0-7]\d(\.\d+)?|[1-9]?\d(\.\d+)?|180(\.0+)?)$/;
        errorMessage = value.trim()
          ? isValidLongitude
            ? ""
            : "Please enter a valid Longitude."
          : "Please enter a Longitude.";
        break;
    }

    //Handle latitude, longitude, and radius separately
    if (name === "latitude" || name === "longitude" || name === "radius") {
      const parsedValue = value === "" ? "" : parseFloat(value);

      if (isNaN(parsedValue)) {
        errorMessage = `Please enter a valid ${name}`;
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: parsedValue,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   let errorMessage = "";

  //   const lettersOnlyRegex = /^[A-Za-z\s]*$/; // Allow only letters and spaces
  //   const pinCodeRegex = /^\d{6}$/; // PinCode must be exactly 6 digits

  //   switch (name) {
  //     case "companyName":
  //     case "siteName":
  //       if (value.trim()) {
  //         if (!lettersOnlyRegex.test(value)) {
  //           errorMessage = `${name
  //             .replace(/([A-Z])/g, " $1")
  //             .trim()} should contain letters only.`;
  //         }
  //       } else {
  //         errorMessage = `Please enter ${name
  //           .replace(/([A-Z])/g, " $1")
  //           .trim()}.`;
  //       }
  //       break;
  //     case "pinCode":
  //       errorMessage = value.trim()
  //         ? pinCodeRegex.test(value)
  //           ? ""
  //           : "PinCode must be exactly 6 digits."
  //         : "Please enter a PinCode.";
  //       break;
  //     case "latitude":
  //     case "longitude":
  //     case "radius":
  //       const parsedValue = value === "" ? "" : parseFloat(value);
  //       if (isNaN(parsedValue) && value !== "") {
  //         errorMessage = `Please enter a valid ${name}.`;
  //       }
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         [name]: value === "" ? "" : parsedValue,
  //       }));
  //       break;
  //     default:
  //       setFormData((prevData) => ({
  //         ...prevData,
  //         [name]: value,
  //       }));
  //       break;
  //   }

  //   // Update the error state
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: errorMessage,
  //   }));
  // };

  const handleToggle = (key) => {
    setFormData((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevents the page from reloading
    if (validate()) {
      setApiProcessing({ loader: true, message: "Loading..." });
      try {
        const response = await webApi.post("api/sites/PostSite", {
          siteName: formData.siteName,
          companyId: id,
          siteAddress: formData.siteAddress,
          cityOrState: formData.cityState,
          country: formData.country,
          pinCode: formData.pinCode,
          fetureDetails: formData.featureDetails,
          enableModules: {
            drawings: formData.drawing,
            pAndM: formData.pm,
            qaAndQc: formData.qaqc,
            ehs: formData.ehs,
            qs: formData.qs,
            planner: formData.planner,
            hr: formData.hr,
            user: formData.user,
            store: formData.store,
            admin: formData.admin,
          },
          permissions: {
            location: formData.location,
            authentication: formData.authentication,
          },
          siteLocationDetails: {
            latitude: formData.latitude,
            longitude: formData.longitude,
            radius: formData.radius,
          },
        });

        if (response.status === 201) {
          // const id = response.data.data.sites._id;
          setApiProcessing({ loader: false, message: "" });
          close();
          window.location.reload();
        } else {
          setErrorMessage("Validation error occurred.");
        }
      } catch (error) {
        setErrorMessage("Failed to submit data.");
      } finally {
        setApiProcessing({ loader: false, message: "" });
      }
    }
  };

  const handleClose = () => {
    close();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: " #4D4D4D",
          margin: "0px",
        }}
      >
        <p style={{ margin: "5px 500px", textAlign: "center", color: "white" }}>
          Add Site
        </p>
        <RxCross1
          size={20}
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            marginRight: "20px",
            color: "white",
          }}
          onClick={handleClose}
        />
      </div>
      <DialogContent
        style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
      >
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <form onSubmit={handleSubmit}>
          <div
            style={{
              marginRight: "40px",
              marginLeft: "40px",
              marginTop: "20px",
            }}
          >
            <div>
              <h3>Site Details</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginTop: "20px",
                  paddingBottom: "40px",
                  borderBottom: "1px dotted lightgray",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "80px",
                    }}
                  >
                    <label>
                      Company Name<a style={{ color: "red" }}>*</a>
                    </label>
                    <input
                      type="text"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      style={{ backgroundColor: "rgb(243, 243, 243)" }}
                      readOnly
                    />
                    {errors.companyName && (
                      <p style={{ color: "red" }}>{errors.companyName}</p>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>
                      Site Name<a style={{ color: "red" }}>*</a>
                    </label>
                    <input
                      type="text"
                      name="siteName"
                      placeholder="Enter Site Name"
                      value={formData.siteName}
                      onChange={handleChange}
                      style={{ backgroundColor: "rgb(243, 243, 243)" }}
                    />
                    {errors.siteName && (
                      <p style={{ color: "red" }}>{errors.siteName}</p>
                    )}
                  </div>
                </div>
                <label style={{ marginTop: "20px" }}>
                  Site Address<a style={{ color: "red" }}>*</a>
                </label>
                <textarea
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    width: "93%",
                    backgroundColor: "rgb(243, 243, 243)",
                    border: "1px  solid rgba(201, 197, 197, 0.986) !important",
                  }}
                  name="siteAddress"
                  placeholder="Enter Your Site Address"
                  value={formData.siteAddress}
                  onChange={handleChange}
                />
                {errors.siteAddress && (
                  <p style={{ color: "red" }}>{errors.siteAddress}</p>
                )}

                <div className="flex mt-4">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "80px",
                    }}
                  >
                    <label>
                      Country<a style={{ color: "red" }}>*</a>
                    </label>
                    <select
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      style={{ backgroundColor: "rgb(243, 243, 243)" }}
                    >
                      <option value="">Select Country</option>
                      <option value="India">India</option>
                      {/* Add more countries as needed */}
                    </select>
                    {errors.country && (
                      <p style={{ color: "red" }}>{errors.country}</p>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <label>
                      City / State<a style={{ color: "red" }}>*</a>
                    </label>
                    <select
                      name="cityState"
                      value={formData.cityState}
                      onChange={handleChange}
                      style={{ backgroundColor: "rgb(243, 243, 243)" }}
                    >
                      {formData.country !== "" && (
                        <>
                          <option value="">Select City / State</option>
                          {/* <option value="">Select State</option> */}
                          {indiaStates.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                        </>
                      )}
                    </select>
                    {errors.cityState && (
                      <p style={{ color: "red" }}>{errors.cityState}</p>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: "80px",
                    }}
                  >
                    <label>
                      PinCode<a style={{ color: "red" }}>*</a>
                    </label>
                    <input
                      type="number"
                      name="pinCode"
                      placeholder="Enter Your PinCode"
                      maxLength={6}
                      value={formData.pinCode}
                      onChange={handleChange}
                      style={{
                        "-moz-appearance": "textfield",
                        backgroundColor: "rgb(243, 243, 243)",
                      }}
                    />
                    {errors.pinCode && (
                      <p style={{ color: "red" }}>{errors.pinCode}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h3>Enable Modules</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  paddingBottom: "40px",
                  borderBottom: "1px dotted lightgray",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "30px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <ToggleSwitch
                    label="Drawing"
                    isChecked={formData.drawing}
                    onToggle={() => handleToggle("drawing")}
                  />
                  <ToggleSwitch
                    label="P & M"
                    isChecked={formData.pm}
                    onToggle={() => handleToggle("pm")}
                  />
                  <ToggleSwitch
                    label="QA/QC"
                    isChecked={formData.qaqc}
                    onToggle={() => handleToggle("qaqc")}
                  />
                  <ToggleSwitch
                    label="EHS"
                    isChecked={formData.ehs}
                    onToggle={() => handleToggle("ehs")}
                  />
                  <ToggleSwitch
                    label="QS"
                    isChecked={formData.qs}
                    onToggle={() => handleToggle("qs")}
                  />
                  <ToggleSwitch
                    label="PLANNER"
                    isChecked={formData.planner}
                    onToggle={() => handleToggle("planner")}
                  />
                  <ToggleSwitch
                    label="HR"
                    isChecked={formData.hr}
                    onToggle={() => handleToggle("hr")}
                  />
                  <ToggleSwitch
                    label="USER"
                    isChecked={formData.user}
                    onToggle={() => handleToggle("user")}
                  />
                  <ToggleSwitch
                    label="STORE"
                    isChecked={formData.store}
                    onToggle={() => handleToggle("store")}
                  />
                  <ToggleSwitch
                    label="ADMIN"
                    isChecked={formData.admin}
                    onToggle={() => handleToggle("admin")}
                  />
                  <ToggleSwitch
                    label="Dashboard"
                    isChecked={formData.dashboard}
                    onToggle={() => handleToggle("dashboard")}
                  />
                  <ToggleSwitch
                    label="WorkStatus"
                    isChecked={formData.workStatus}
                    onToggle={() => handleToggle("workStatus")}
                  />
                  <ToggleSwitch
                    label="Checklist"
                    isChecked={formData.checklist}
                    onToggle={() => handleToggle("checklist")}
                  />
                  <ToggleSwitch
                    label="Sites"
                    isChecked={formData.sites}
                    onToggle={() => handleToggle("sites")}
                  />
                  <ToggleSwitch
                    label="Space"
                    isChecked={formData.space}
                    onToggle={() => handleToggle("space")}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5">
              <h3>Feature Details</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  paddingBottom: "40px",
                  borderBottom: "1px dotted lightgray",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <label>
                      How many levels of checklist do you have for an activity
                      in QC?
                    </label>
                    <select
                      name="featureDetails"
                      value={formData.featureDetails}
                      onChange={handleChange}
                      style={{ backgroundColor: "rgb(243, 243, 243)" }}
                    >
                      <option value="">Select a Number</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                    {errors.featureDetails && (
                      <p style={{ color: "red" }}>{errors.featureDetails}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "20px" }}>
              <h3>Permissions</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  paddingBottom: "40px",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "30px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <ToggleSwitch
                    label="Locations"
                    isChecked={formData.location}
                    onToggle={() => handleToggle("location")}
                    style={{
                      backgroundColor: "transparent !important",
                      border: "none !important",
                      boxShadow: "none !important",
                    }}
                  />
                  <ToggleSwitch
                    label="Authentication"
                    isChecked={formData.authentication}
                    onToggle={() => handleToggle("authentication")}
                    style={{
                      backgroundColor: "transparent !important",
                      border: "none !important",
                      boxShadow: "none !important",
                    }}
                  />
                </div>
              </div>
            </div>

            <div style={{ marginTop: "20px" }}>
              <h3>Site Location Details</h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "20px",
                  paddingBottom: "40px",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "30px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>
                      Latitude<a style={{ color: "red" }}>*</a>
                    </label>
                    <input
                      type="number"
                      name="latitude"
                      placeholder="Enter the value of latitude"
                      value={formData.latitude}
                      onChange={handleChange}
                      style={{
                        width: "330px",
                        backgroundColor: "rgb(243, 243, 243)",
                      }}
                    />
                    {errors.latitude && (
                      <p style={{ color: "red" }}>{errors.latitude}</p>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>
                      Longitude<a style={{ color: "red" }}>*</a>
                    </label>
                    <input
                      type="number"
                      name="longitude"
                      placeholder="Enter the value of Longitude"
                      value={formData.longitude}
                      onChange={handleChange}
                      style={{
                        width: "330px",
                        backgroundColor: "rgb(243, 243, 243)",
                      }}
                    />
                    {errors.longitude && (
                      <p style={{ color: "red" }}>{errors.longitude}</p>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <label>
                      Radius(m)<a style={{ color: "red" }}>*</a>
                    </label>
                    <input
                      type="number"
                      name="radius"
                      placeholder="Enter the value of Radius"
                      value={formData.radius}
                      onChange={handleChange}
                      style={{
                        width: "330px",
                        backgroundColor: "rgb(243, 243, 243)",
                      }}
                    />
                    {errors.radius && (
                      <p style={{ color: "red" }}>{errors.radius}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <GoogleMapWithSearch/> */}

            {/* <LocationSearch /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "45px",
              }}
            >
              <button
                type="submit"
                style={{
                  width: "175px",
                  height: "50px",
                  marginLeft: "50px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid var(--primary-color)",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default AddSiteDialog;
