import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import "./DateTimeModal.css";

const DateTimeModal = ({ show, handleClose }) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const handleSave = () => {
    console.log("Saved Date:", date, "Saved Time:", time);
    handleClose();
  };

  const toggleCalendar = () => {
    setShowCalendar((prev) => !prev);
  };

  const handleDateSelect = (selectedDate) => {
    setDate(selectedDate);
    setShowCalendar(false);
  };

  const changeMonth = (direction) => {
    if (direction === -1) {
      if (currentMonth === 0) {
        setCurrentMonth(11);
        setCurrentYear(currentYear - 1);
      } else {
        setCurrentMonth(currentMonth - 1);
      }
    } else {
      if (currentMonth === 11) {
        setCurrentMonth(0);
        setCurrentYear(currentYear + 1);
      } else {
        setCurrentMonth(currentMonth + 1);
      }
    }
  };

  const renderCalendar = () => {
    const monthMap = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];

    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    const days = [];

    const today = new Date();
    const currentDay = today.getDate();
    const currentMonthIndex = today.getMonth();
    const currentYearValue = today.getFullYear();

    // Add empty slots for days from the previous month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`prev-${i}`} className="date out-of-scope" />);
    }

    // Add days for the current month
    for (let day = 1; day <= daysInMonth; day++) {
      const isToday =
        currentDay === day &&
        currentMonthIndex === currentMonth &&
        currentYearValue === currentYear;

      days.push(
        <div
          key={day}
          className={`date ${isToday ? "today" : ""}`}
          onClick={() =>
            handleDateSelect(
              `${currentYear}-${String(currentMonth + 1).padStart(
                2,
                "0"
              )}-${String(day).padStart(2, "0")}`
            )
          }
        >
          {day}
        </div>
      );
    }

    // Create rows of 7 days
    const rows = [];
    for (let i = 0; i < days.length; i += 7) {
      rows.push(
        <div key={i} className="calendar-row">
          {days.slice(i, i + 7)}
        </div>
      );
    }

    return (
      <div className="calendar">
        <div className="headline">
          <span className="click-left" onClick={() => changeMonth(-1)}>
            {"<"}
          </span>
          <span className="month">{monthMap[currentMonth]}</span>
          <span className="divider">&nbsp;&nbsp;•&nbsp;&nbsp;</span>
          <span className="year">{currentYear}</span>
          <span className="click-right" onClick={() => changeMonth(1)}>
            {">"}
          </span>
        </div>
        <div className="weekdays">
          {["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].map((day) => (
            <div key={day} className="day">
              {day}
            </div>
          ))}
        </div>
        <div className="calendar-body">{rows}</div>
      </div>
    );
  };

  return (
    <Modal show={show} onHide={handleClose} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>Select Date and Time</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mb-3">
          <div className="col-md-5">
            <label htmlFor="date">
              <p className="mb-0">
                Date <span style={{ color: "red" }}>*</span>
              </p>
            </label>
            <input
              type="text"
              id="date"
              value={date}
              readOnly
              placeholder="Select a date"
              style={{ marginBottom: "10px", width: "100%" }}
            />
          </div>
          <div className="col-md-5">
            <label htmlFor="time" className="form-label">
              <p className="mb-0">
                Time <span style={{ color: "red" }}>*</span>
              </p>
            </label>
            <input
              type="time"
              id="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              style={{ marginBottom: "10px", width: "100%" }}
            />
          </div>
          <div className="col-sm-2">
            <div className="input-group">
              <span
                className="input-group-text"
                style={{ cursor: "pointer", marginTop: "40px" }}
                onClick={toggleCalendar}
              >
                <FaCalendarAlt />
              </span>
            </div>
          </div>
        </div>

        {showCalendar && (
          <div
            style={{
              position: "relative",
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              padding: "10px",
              maxWidth: "100%",
            }}
          >
            {renderCalendar()}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="custom-footer">
        <Button variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DateTimeModal;
