import React from "react";
import { CiStar } from "react-icons/ci";
import * as Icon from "react-bootstrap-icons";
import { MdAccessTime } from "react-icons/md";

const DraftNotifications = () => {
  return (
    <>
      <table className="table-auto w-full mt-4">
        <thead>
          <tr>
            <th>Name</th>
            <th>Message</th>
            <th>Time & Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>firstName</td>
            <td>message</td>
            <td>date</td>

            <td style={{ display: "flex", gap: "15px" }}>
              <CiStar size={20} style={{ cursor: "pointer" }} />
              <MdAccessTime size={20} style={{ cursor: "pointer" }} />
              <Icon.Trash
                size={20}
                //   onClick={() =>
                //     deleteNotifications(recivedNotification)
                //   }
                style={{ cursor: "pointer" }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DraftNotifications;
