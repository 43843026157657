import React, { useState, useEffect, useRef } from "react";
import { WebApimanager } from "../../WebApiManager";
import { siteId } from "../../atoms";
import { useRecoilState } from "recoil";
import SelectSite from "../../Utilities/SelectSite";
import TableWrapper from "../../Utilities/TableWrapper";
import * as Icon from "react-bootstrap-icons";
import "./Notifications.css";

import EHS from "./NotificationSVG/EHS.svg";
import QS from "./NotificationSVG/QS.svg";
import Store from "./NotificationSVG/Store.svg";
import Admin from "./NotificationSVG/Admin.svg";
import QC from "./NotificationSVG/QC.svg";
import Settings from "./NotificationSVG/Settings.svg";
import LogIn from "./NotificationSVG/LogIn.svg";
import Drawing from "./NotificationSVG/Drawing.svg";
import User from "./NotificationSVG/User.svg";
import PNM from "./NotificationSVG/PNM.svg";
import Issue from "./NotificationSVG/Issue.svg";
import Task from "./NotificationSVG/Task.svg";
import Planner from "./NotificationSVG/Planner.svg";
import Info from "./NotificationSVG/Info.svg";

const SystemGenerated = () => {
  const webApi = new WebApimanager();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [site, setSite] = useRecoilState(siteId);
  const [readNotifications, setReadNotifications] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [uniqueStatuses, setUniqueStatuses] = useState([]);

  const departmentIcons = {
    EHS: EHS,
    Store: Store,
    Admin: Admin,
    QC: QC,
    Settings: Settings,
    LogIn: LogIn,
    Drawing: Drawing,
    User: User,
    PNM: PNM,
    Issue: Issue,
    Task: Task,
    Planner: Planner,
    Info: Info,
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setLoading(true);
    webApi
      .get("api/notification")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data.notifications)) {
          setNotifications(response.data.data.notifications);

          const statuses = new Set(
            response.data.data.notifications.map((n) => n.status)
          );
          setUniqueStatuses(["All", ...Array.from(statuses)]);
        } else {
          setError("Unexpected data format");
        }
      })
      .catch((error) => {
        setError("There was an error fetching the data", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div style={{ marginTop: "100px" }}>Error: {error}</div>;

  const handleMouseEnter = (id, isRead) => {
    if (!isRead && !readNotifications.has(id)) {
      webApi
        .put(`api/notification/read/${id}`)
        .then(() => {
          setReadNotifications((prev) => new Set(prev).add(id));
          setNotifications((prevNotifications) =>
            prevNotifications.map((notification) =>
              notification._id === id
                ? { ...notification, isRead: true }
                : notification
            )
          );
        })
        .catch((error) => {
          console.error("There was an error updating the notification!", error);
        });
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredNotifications = notifications.filter((notification) => {
    const createdDate = new Date(notification.createdDate).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }
    );
    const createdTime = new Date(notification.createdDate)
      .toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace("am", "AM")
      .replace("pm", "PM");

    return (
      (notification.message
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
        notification.subject
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        notification.status
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        createdDate.includes(searchQuery) ||
        createdTime.includes(searchQuery)) &&
      (selectedStatus === "All" || notification.status === selectedStatus)
    );
  });

  const reversedNotifications = [...filteredNotifications].reverse();

  const handleDropdownToggle = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setShowDropdown(false);
  };

  return (
    <div>
      {site === "" ? (
        <SelectSite />
      ) : (
        <div className="m-[60px]">
          <TableWrapper
            children={
              <>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "5px",
                    marginRight: "5px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{ color: "var(--primary-color)", fontSize: "20px" }}
                  >
                    Notification
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div style={{ position: "relative", width: "250px" }}>
                      <input
                        type="text"
                        placeholder="Search Notifications"
                        onChange={handleSearchChange}
                        value={searchQuery}
                        style={{
                          paddingLeft: "20px",
                          borderRadius: "6px",
                          border: "1px solid var(--primary-color)",
                          width: "100%",
                          height: "40px",
                        }}
                      />
                      <Icon.Search
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "#ACACAC",
                        }}
                      />
                    </div>

                    <Icon.FunnelFill
                      size={20}
                      style={{
                        width: "40px",
                        height: "40px",
                        padding: "5px",
                        border: "1px solid #ACACAC",
                        borderRadius: "6px",
                        cursor: "pointer",
                        color: "#4D4D4D",
                      }}
                      onClick={handleDropdownToggle}
                    />
                    {showDropdown && (
                      <div ref={dropdownRef} style={{ position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            top: "45px",
                            right: "0",
                            borderRadius: "6px",
                            padding: "15px 10px",
                            zIndex: 1000,
                            width: "150px",
                            background: "white",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {uniqueStatuses.map((status) => (
                            <button
                              key={status}
                              onClick={() => handleStatusChange(status)}
                              style={{
                                display: "block",
                                width: "100%",
                                border: "none",
                                marginBottom: "5px",
                                background: "white",
                                textAlign: "left",
                                cursor: "pointer",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                color:
                                  selectedStatus === status
                                    ? "var(--primary-color)"
                                    : "#303030",
                              }}
                            >
                              {status}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="table-container">
                  <table className="custom-table">
                    <thead className="custom-thead tableHeaderTitle">
                      <tr className="custom-row">
                        <td className="custom-cell">Activity</td>
                        <td className="custom-cell">Status</td>
                        <td className="custom-cell">Time & Date</td>
                      </tr>
                    </thead>
                  </table>
                  <div class="custom-tbody-container">
                    <table class="custom-table">
                      <tbody className="custom-tbody tableBodyData">
                        {reversedNotifications.map((notification, index) => (
                          <tr
                            key={notification._id}
                            className="custom-row"
                            onMouseEnter={() =>
                              handleMouseEnter(
                                notification._id,
                                notification.isRead
                              )
                            }
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                index % 2 === 0 ? "#F3F6F8" : "#FDF8F4",
                              marginBottom: "20px",
                            }}
                          >
                            <td
                              className="custom-cell"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ marginRight: "8px" }}>
                                {departmentIcons[notification.department] ? (
                                  <img
                                    alt="not found"
                                    src={
                                      departmentIcons[notification.department]
                                    }
                                    style={{ width: "30px", height: "30px" }}
                                  />
                                ) : (
                                  "Icons display"
                                )}
                              </div>
                              <span>
                                {notification.message} {notification.subject}
                              </span>
                            </td>

                            <td
                              className="custom-cell"
                              style={{ paddingLeft: "27px" }}
                            >
                              {notification.status}
                            </td>
                            <td
                              className="custom-cell"
                              style={{ paddingLeft: "27px" }}
                            >
                              {new Date(
                                notification.createdDate
                              ).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                              })}
                              &nbsp;&nbsp;
                              {new Date(notification.createdDate)
                                .toLocaleTimeString("en-GB", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                })
                                .replace("am", "AM")
                                .replace("pm", "PM")}{" "}
                              &nbsp;&nbsp; &nbsp;&nbsp;
                              {!notification.isRead && (
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: "#e47331",
                                  }}
                                ></span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            }
          />
        </div>
      )}
    </div>
  );
};

export default SystemGenerated;
