import React, { useState, useEffect } from "react";
import "../users.css";
import { WebApimanager } from "../../../WebApiManager";
import { RxCross2 } from "react-icons/rx";

const ProfileModal = ({ show, onHide, user, refetchUserData }) => {
  const departmentData = {
    Management: {
      roles: [
        "Company Managing Director",
        "Director",
        "Technical President",
        "Senior Vice President",
        "Vice President",
        "Assistant Vice President",
      ],
      workModels: [""],
    },
    "Design Consultant": {
      roles: [
        "Archiectural",
        "Structural",
        "MEP",
        "Facade",
        "Landscape and Lighting",
        "Vertical Transportation",
        "Traffic and Parking",
      ],
      workModels: [""],
    },
    Execution: {
      roles: [
        "Deputy Project Manager",
        "Assistant Project Manager",
        "Assistant Manager",
        "Sr.Project Engineer",
        "Project Engineer",
        "Jr.Project Engineer",
        "Senior Engineer",
        "Engineer",
        "Junior Engineer",
        "GTE",
      ],
      workModels: ["Structural", "Finishes"],
    },
    Planning: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: [""],
    },
    SiteManagement: {
      roles: [
        "General Manager",
        "Assistant General Manager",
        "Senior Project Manager",
        "Project Manager",
      ],
      workModels: [""],
    },
    QualitySurveyorBilling: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: ["BBS", "Billing", "Contractor", "QS"],
    },
    MEP: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Supervisors",
      ],
      workModels: ["Electrical", "Plumbing", "Fire Fighting", "Ele & HVAC"],
    },
    EHS: {
      roles: [
        "Head",
        "Manager",
        "Assistant Manager",
        "Safety Incharge",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Steve Ward",
        "Male Nurse",
      ],
      workModels: [""],
    },
    CRMTECH: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: [""],
    },
    Store: {
      roles: [
        "Head",
        "Store Manager",
        "Assistant Store Manager",
        "Store Officer",
        "Store Incharge",
        "Sr.Store Keeper",
        "Store Keeper/ Executive",
        "Sr.Store Asst",
        "Store Asst",
        "Trainee Store",
      ],
      workModels: [""],
    },
    Survey: {
      roles: ["Sr.Engineer", "Engineer", "Jr.Engineer", "GTE", "Helper"],
    },
    Accounts: {
      roles: [
        "Head",
        "Manager",
        "Sr.Accountant",
        "Accountant",
        "Jr.Accountant",
      ],
      workModels: [""],
    },
    Admin: {
      roles: [
        "Head",
        "Project Admin",
        "Ass Admin",
        "Time Keeper",
        "Colony Admin",
      ],
      workModels: [""],
    },
  };

  const webApi = new WebApimanager();
  const [roles, setRoles] = useState([]);
  const [workModels, setWorkModels] = useState([]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    contactNumber: "",
    email: "",
    bloodGroup: "",
    emergencyContact: "",
    employeeId: "",
    site: "",
    role: "",
    department: "",
    workModel: "",
  });

  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        contactNumber: user.contactNumber || "",
        email: user.email || "",
        bloodGroup: user.bloodGroup || "",
        emergencyContact: user.emergencyContact || "",
        reportingEmpIdName: user.reportingEmpIdName || "",
        employeeId: user.empId || "",
        site: user.site || "",
        role: user.role || "",
        department: user.department || "",
        workModel: user.workModel || "",
      });
    }
  }, [user]);

  useEffect(() => {
    console.log("user  formData", formData);
  }, [formData]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    console.log(`Field ID: ${id}, Value: ${value}`);

    setFormData((prev) => ({ ...prev, [id]: value }));

    if (id === "department") {
      const selectedDepartment = departmentData[value];
      if (selectedDepartment) {
        setRoles(selectedDepartment.roles || []);
        setWorkModels(selectedDepartment.workModels || []);
      } else {
        setRoles([]);
        setWorkModels([]);
      }
    }
  };

  const [errors, setError] = useState({});

  const validationForm = () => {
    const errors = {};

    if (formData.contactNumber && formData.contactNumber.length !== 10) {
      errors.contactNumber = "Contact number must be 10 digits";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validationForm()) return;

    try {
      const response = await webApi.put(`api/users/${user._id}`, formData);
      if (response.status === 200) {
        refetchUserData();
        onHide();
      } else {
        console.log("Failed to update profile.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <>
      <div className={`modal ${show ? "d-block" : "d-none"}`} tabIndex="-1">
        <div
          className="modal-dialog"
          style={{ maxWidth: "70%", margin: "40px auto", padding: "15px 50px" }}
        >
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                position: "relative",
                height: "50px",
                backgroundColor: "#4D4D4D",
              }}
            >
              <p
                className="modal-title"
                style={{
                  textAlign: "center",
                  width: "100%",
                  margin: "0",
                  color: "var(--neturals-background-neturals-base-white, #FFF)",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "500",
                }}
              >
                Update Profile
              </p>
              <button
                type="button"
                onClick={onHide}
                style={{
                  position: "absolute",
                  right: "45px",
                  top: "13px",
                  color: "white",
                }}
              >
                <RxCross2 size={25} />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="firstName" className="lableProgileModal">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="Enter first name"
                        readOnly
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="contactNumber"
                        className="lableProgileModal"
                      >
                        Contact Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        placeholder="Enter contact number"
                        style={{ padding: "10px" }}
                      />
                      {errors.contactNumber && (
                        <div className="text-danger">
                          {errors.contactNumber}
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="bloodGroup" className="lableProgileModal">
                        Blood Group
                      </label>
                      <select
                        id="bloodGroup"
                        className="form-control"
                        value={formData.bloodGroup}
                        onChange={handleChange}
                      >
                        <option value="">Select Blood Group</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="lastName" className="lableProgileModal">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Enter last name"
                        readOnly
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="lableProgileModal">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter email address"
                        style={{ padding: "10px" }}
                      />
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="emergencyContact"
                        className="lableProgileModal"
                      >
                        Emergency Contact Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="emergencyContact"
                        value={formData.emergencyContact}
                        onChange={handleChange}
                        placeholder="Enter emergency contact number"
                        style={{ padding: "10px" }}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="mb-3">
                    <label
                      htmlFor="reportingEmpIdName"
                      className="lableProgileModal"
                    >
                      Reporting Employee ID / Name
                    </label>
                    <input
                      type="text"
                      id="reportingEmpIdName"
                      value={formData.reportingEmpIdName}
                      onChange={handleChange}
                      placeholder="Enter reportingEmpIdName"
                      className="form-control"
                      style={{ width: "100%", marginTop: "20px" }}
                    />
                  </div>
                </div>

                <div>
                  <div className="mb-3">
                    <label htmlFor="employeeId" className="lableProgileModal">
                      Employee ID
                    </label>
                    <input
                      type="text"
                      id="employeeId"
                      value={formData.employeeId}
                      onChange={handleChange}
                      placeholder="Enter Employee ID"
                      className="form-control"
                      style={{ width: "100%", marginTop: "20px" }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="site" className="lableProgileModal">
                        Site
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="site"
                        value={formData.site}
                        onChange={handleChange}
                        placeholder="Enter site"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="role" className="lableProgileModal">
                        Role
                      </label>
                      <select
                        id="role"
                        className="form-control"
                        value={formData.role}
                        onChange={handleChange}
                      >
                        <option value="">Select Role</option>
                        {roles.map((role, index) => (
                          <option key={index} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="department" className="lableProgileModal">
                        Department
                      </label>
                      <select
                        id="department"
                        className="form-control"
                        value={formData.department}
                        onChange={handleChange}
                      >
                        <option value="">Select Department</option>
                        {Object.keys(departmentData).map(
                          (department, index) => (
                            <option key={index} value={department}>
                              {department}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="workModel" className="lableProgileModal">
                        Work Model
                      </label>
                      <select
                        id="workModel"
                        className="form-control"
                        value={formData.workModel}
                        onChange={handleChange}
                      >
                        <option value="">Select Work Model</option>
                        {workModels.map((workModel, index) => (
                          <option key={index} value={workModel}>
                            {workModel}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <button type="submit" className="profileModalSave">
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileModal;
