import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import AddDrawingDialog from "./Components/AddDrawingDialog";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId } from "../../../atoms";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { RxCross1 } from "react-icons/rx";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import ReceivedTable from "./ReceivedTable";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import TableWrapper from "../../../Utilities/TableWrapper";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import { getRegisterData, processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import { useNavigate } from "react-router-dom";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";

const ApprovedTable = () => {
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];

  let webApi = new WebApimanager();

  const [open, setOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [error, setError] = useState({});
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [registerList, setRegisterList] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [editData, setEditData] = useState({
    drawingId: "",
    drawingNumber: "",
    expectedDate: "",
    requestedDate: todayDate(),
    remarks: "",
    drawingFileName: null,
    revision: [],
    natureofrequestedInfo: "",
  });

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  const [currentView, setCurrentView] = useState("upload");

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  const [site, setSite] = useRecoilState(siteId);
  const [selectedRevision, setSelectedRevision] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // const handleSiteChange = (e) => {
  //   setSiteId(e.target.value);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    fetchData();
  };

  const handleEditOpen = (drawingId) => {
    const drawingItem = drawingList.find((item) => item._id === drawingId);
    if (drawingItem) {
      const combinedRevisions = drawingItem.acceptedRORevisions.flatMap(
        (rev) => rev.revision
      );

      setEditData({
        drawingId: drawingId,
        drawingNumber: drawingItem.drawingNo,
        expectedDate: "", // Consider initializing this with a valid date if needed
        requestedDate: todayDate(),
        remarks: "",
        drawingFileName: null,
        revision: combinedRevisions, // Ensure this is set as an array
        natureofrequestedInfo: "", // Ensure this key is initialized if used elsewhere
      });

      setEditDialogOpen(true);
    }
  };

  const handleRevisionSelection = (event) => {
    setSelectedRevision(event.target.value);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditData({
      drawingId: "",
      drawingNumber: "",
      expectedDate: "",
      remarks: "",
      drawingFileName: null,
      revision: [],
      natureofrequestedInfo: [],
    });
  };
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    if (drawingItem.acceptedRORevisions.length > 0) {
      setSelectedTab(drawingItem.acceptedRORevisions.length - 1);
    }
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const fetchInData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setDrawingList(registerInfo);
      setInitialDrawingList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    }
  };

  const fetchData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setDrawingList(response.registers);
        setInitialDrawingList(response.registers);
      }
    });
  };

  useEffect(() => {
    fetchInData();
  }, []);

  useEffect(() => {
    setReloadData(true);
  }, [drawingList]);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setEditData({
        ...editData,
        [name]: files[0], // Assuming you are only uploading one file
      });
    } else {
      setEditData({
        ...editData,
        [name]: value,
      });
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    let formErrors = {};
    if (!editData.drawingNumber)
      formErrors.drawingNumber = "Drawing Number is required";
    if (!editData.expectedDate)
      formErrors.expectedDate = "Expected Date is required";
    if (!editData.remarks) formErrors.remarks = "Remarks are required";
    if (!editData.drawingFileName) {
      formErrors.drawingFileName = "Drawing File is required";
    }
    if (!editData.natureofrequestedInfo.length)
      formErrors.natureofrequestedInfo = "This field is required";
    // if (!selectedRevision) formErrors.revision = 'Please select a revision.';

    setError(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }
    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });

    try {
      const editFormData = {
        drawingId: editData.drawingId,
        drawingNo: editData.drawingNumber,
        siteId: site,
        requestedDate: todayDate(),
        expectedDate: editData.expectedDate,
        remarks: editData.remarks,
        revision: selectedRevision,
        natureOfRequestedInformation: editData.natureofrequestedInfo,
      };

      console.log("Submitting edit data:", editFormData);

      const response = await webApi.post(
        "api/roToSiteLevelRequested/postRequest",
        editFormData
      );
      if (response.status === 200) {
        const responseData = response.data;

        if (responseData.status === "error") {
          // Handle error from response data
          setErrorMessage(responseData.message);
          console.log("Response data:", responseData.message);
          setApiProcessing({ loader: false, message: "" });
          return; // Stop further processing
        }

        const id = responseData.data._id;

        if (editData.drawingFileName) {
          const drawingFileName = new FormData();
          drawingFileName.append("drawingFileName", editData.drawingFileName);
          const uploadResponse = await webApi.imagePut(
            `api/roToSiteLevelRequested/${id}`,
            drawingFileName
          );
          console.log("Upload response:", uploadResponse);
        }

        setApiProcessing({ loader: false, message: "" });
        handleEditClose();
        fetchData();
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
          console.log("Response data:", response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };

  const handleDownload = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      //TODO: need to check this path correct or not ( not working )
      const response = await webApi.getImage(
        `api/architectureToRoRequested/${id}/?revisionsRo=${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "opening...",
    });

    try {
      // Make a single request to the API
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/revisionsRo/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "view", navigate); // Process file for downloading
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const listItems = [
    { id: "A - Missing Information", name: "A - Missing Information" },
    { id: "B - Clarification", name: "B - Clarification" },
    { id: "C - Additional Information", name: "C - Additional Information" },
    { id: "D - Uncoordinated Drawings", name: "D - Uncoordinated Drawings" },
  ];

  // Function to get the name from id
  const getNameFromId = (id) => {
    const item = listItems.find((item) => item.id === id);
    return item ? item.name : "";
  };

  const [expandedRevision, setExpandedRevision] = useState(null);

  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);

    if (!searchInput) {
      // If search input is empty, reset the drawingList to its original state
      fetchData(); // This fetches all drawings again
      setSearchActive(false); // Set searchActive to false to allow periodic fetches
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        const drawingNumber = drawingItem?.drawingNo.toLowerCase() || "";
        const drawingName = drawingItem.drawingTitle?.toLowerCase() || "";
        const consultant = `${drawingItem.designDrawingConsultant?.role}`;
        const category = drawingItem.category?.category?.toLowerCase() || "";
        const acceptedSiteSubmissionDate =
          drawingItem.acceptedSiteSubmissionDate
            ? new Date(drawingItem.acceptedSiteSubmissionDate)
                .toLocaleDateString()
                .toLowerCase()
            : "";
        const dueDays = calculateDueDays(
          drawingItem.acceptedSiteSubmissionDate
        ).toString();
        const dueStatus =
          dueDays >= 0
            ? `${dueDays} days`
            : `${Math.abs(dueDays)} days overdue`;
        const revisions = drawingItem.acceptedRORevisions
          .map((revisionItem) => revisionItem.revision.toLowerCase())
          .join(" ");
        return (
          drawingNumber.includes(searchValue) ||
          drawingName.includes(searchValue) ||
          consultant.includes(searchValue) ||
          category.includes(searchValue) ||
          acceptedSiteSubmissionDate.includes(searchValue) ||
          dueStatus.includes(searchValue) ||
          dueDays.includes(searchValue) ||
          revisions.includes(searchValue)
        );
      });
      setDrawingList(filteredDrawings);
    }
  };
  const calculateDueDays = (
    acceptedSiteSubmissionDate,
    softCopySubmittedDate = null
  ) => {
    if (!acceptedSiteSubmissionDate) return "-";

    const dateToCompare = softCopySubmittedDate
      ? new Date(softCopySubmittedDate)
      : new Date();
    const acceptedDate = new Date(acceptedSiteSubmissionDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // convert time difference to days

    return differenceInDays;
  };
  const isDateComplete = (date) => {
    // Assuming "complete" means the date is not null or undefined and is a valid date
    return (
      date !== null && date !== undefined && !isNaN(new Date(date).getTime())
    );
  };

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <h3 style={{ color: 'var(--primary-color)',fontSize: '20px' }}>Drawing Lists</h3> */}
              <div style={{ display: "flex" }}>
                <Tabs
                  value={currentView}
                  onChange={handleTabViewChange}
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "var(--primary-color)", // Custom color for active tab indicator
                    },
                    "& .MuiTab-root": {
                      textTransform: "none", // Prevents text from being transformed to uppercase
                      color: "#131313", // Text color for all tabs
                      borderBottom: "none",
                      fontWeight: "bold",
                    },
                    "& .Mui-selected": {
                      color: "#131313 !important", // Text color for selected tab
                    },
                    "& .MuiTab-root:not(.Mui-selected)": {
                      color: "#131313", // Text color for non-selected tabs
                    },
                  }}
                >
                  <Tab label="Upload" value="upload" />
                  <Tab label="Received" value="received" />
                </Tabs>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    position: "relative",
                    width: "250px",
                    paddingLeft: "20px",
                    height: "50px",
                  }}
                >
                  <input
                    placeholder="Search Drawing Lists"
                    onChange={searchResult}
                    style={{
                      paddingLeft: "20px",
                      border: "1px solid var(--primary-color)",
                      width: "250px",
                      height: "40px",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "40%",
                      transform: "translateY(-50%)",
                      color:'#ACACAC'
                    }}
                  />
                </div>
                <Button
                  style={{
                    height: "40px",
                    marginLeft: "40px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "var(--primary-color)",
                    border: "1px solid var(--primary-color)",
                    borderRadius:'0px'
                  }}
                  onClick={handleClickOpen}
                >
                  <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} />{" "}
                  Add New
                </Button>
              </div>
            </div>
            <div>
              {currentView === "upload" && (
                <DrawingTableWrapper
                  tableHead={
                    <tr>
                      <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                      <th style={{ width: "10%" }}>Drawing No</th>
                      <th style={{ width: "10%" }}>Design Consultant</th>
                      <th style={{ width: "10%" }}>Drawing category</th>
                      <th style={{ width: "10%" }}>Drawing title</th>
                      <th style={{ width: "13%" }}>
                        Accepted Site Submission Date
                      </th>
                      <th style={{ width: "13%" }}>Final Submission Date</th>
                      <th style={{ width: "13%" }}>Due/Overdue</th>
                      <th style={{ width: "10%" }}>Revision</th>
                      <th style={{ width: "10%" }}>Action</th>
                    </tr>
                  }
                  tableBody={
                    <tbody>
                      <Loader
                        open={apiProcessing.loader}
                        message={apiProcessing.message}
                      />
                      {drawingList &&
                        [
                          ...drawingList
                            .filter(
                              (item) => item.acceptedRORevisions.length > 0
                            ) // Items with revisions
                            .sort((a, b) => {
                              const consultantA = a.designDrawingConsultant?.role
                                ? a.designDrawingConsultant?.role.toLowerCase()
                                : "";
                              const consultantB = b.designDrawingConsultant?.role
                                ? b.designDrawingConsultant?.role.toLowerCase()
                                : "";
                              return consultantA.localeCompare(consultantB); // Sort alphabetically
                            }),
                        ].map((drawingItem, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0
                                  ? "var(--table-oddrow-color)"
                                  : "var(--table-evenrow-color)",
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{drawingItem?.drawingNo || ""}</td>
                            <td>
                              {drawingItem.designDrawingConsultant?.role || ""}
                            </td>
                            <td>{drawingItem.category?.category || ""}</td>
                            <td>{drawingItem.drawingTitle}</td>
                            <td>
                              {formatDate(
                                drawingItem?.acceptedSiteSubmissionDate || ""
                              )}
                            </td>
                            <td>
                              {formatDate(
                                drawingItem.acceptedRORevisions[0]
                                  ?.softCopySubmittedDate
                              ) || "N/A"}
                            </td>
                            <td>
                              <span
                                style={{
                                  color:
                                    new Date(
                                      drawingItem.softCopySubmittedDate
                                    ) < new Date()
                                      ? "black"
                                      : calculateDueDays(
                                          drawingItem.acceptedSiteSubmissionDate,
                                          drawingItem.acceptedRORevisions[0]
                                            ?.softCopySubmittedDate
                                        ) >= 0
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {calculateDueDays(
                                  drawingItem.acceptedSiteSubmissionDate,
                                  drawingItem.acceptedRORevisions[0]
                                    ?.softCopySubmittedDate
                                ) === "-"
                                  ? "-"
                                  : calculateDueDays(
                                      drawingItem.acceptedSiteSubmissionDate,
                                      drawingItem.acceptedRORevisions[0]
                                        ?.softCopySubmittedDate
                                    ) >= 0
                                  ? `${calculateDueDays(
                                      drawingItem.acceptedSiteSubmissionDate,
                                      drawingItem.acceptedRORevisions[0]
                                        ?.softCopySubmittedDate
                                    )} days due`
                                  : `${Math.abs(
                                      calculateDueDays(
                                        drawingItem.acceptedSiteSubmissionDate,
                                        drawingItem.acceptedRORevisions[0]
                                          ?.softCopySubmittedDate
                                      )
                                    )} days overdue`}
                              </span>
                            </td>
                            {/* <td>
{drawingItem.acceptedRORevisions.length > 0 ? (
<div>{drawingItem.acceptedRORevisions.map((revisionItem) => `${revisionItem.revision}` || 'NA').join(', ')}</div>
) : (
<div>-</div>
)}
</td> */}
                            <td>
                              {drawingItem.acceptedRORevisions.length > 0 ? (
                                <div>
                                  {drawingItem.acceptedRORevisions
                                    .slice(-4) // Show only the latest four revisions
                                    .map(
                                      (revisionItem, idx) =>
                                        `${revisionItem.revision}` || "NA"
                                    )
                                    .join(", ")}
                                </div>
                              ) : (
                                <div>-</div>
                              )}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <Icon.Pen
                                size={20}
                                style={{
                                  marginRight: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEditOpen(drawingItem._id)}
                              />
                              <Icon.EyeFill
                                size={20}
                                style={{
                                  marginRight: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleViewOpen(drawingItem)}
                              />
                              <Icon.Download
                                size={20}
                                style={{ marginLeft: "5px", cursor: "pointer" }}
                                onClick={() => {
                                  const latestRevision =
                                    drawingItem.acceptedRORevisions.length > 0
                                      ? drawingItem.acceptedRORevisions[
                                          drawingItem.acceptedRORevisions
                                            .length - 1
                                        ].revision
                                      : null;
                                  handleDownload(
                                    drawingItem._id,
                                    latestRevision
                                  );
                                }}
                              />
                              {/* <Icon.Trash size={20} style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleTrash(drawingItem._id)}/> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  }
                />
              )}
              {currentView === "received" && (
                <ReceivedTable
                  selectedFilter={""}
                  searchValue={searchValue}
                  searchActive={searchActive}
                  setSearchValue={setSearchValue}
                  setSearchActive={setSearchActive}
                  reloadData={reloadData}
                  setReloadData={setReloadData}
                  filterCondition="approved"
                />
              )}
            </div>
          </>
        }
      />
      <AddDrawingDialog
        handleDialogClose={handleDialogClose}
        open={open}
        site={site}
      />
      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{ style: { borderRadius : 10 ,fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height:'50px'
          }}
        >
          <p
            style={{ margin: "5px 250px", textAlign: "center", color: "white" }}
          >
            RFI
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleEditClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <form onSubmit={handleEditSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <div style={{ display: "flex", gap: "15px" }}>
                  <div style={{ flex: 1 }}>
                    <label
                      htmlFor="drawingNumber"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Drawing Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="drawingNumber"
                      placeholder="Enter your Drawing Number"
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                      value={editData.drawingNumber}
                      onChange={handleInputChange}
                      readOnly
                    />
                    {error.drawingNumber && (
                      <div className="text-danger">{error.drawingNumber}</div>
                    )}
                  </div>
                  <div style={{ flex: 1 }}>
                    <label
                      htmlFor="requestedDate"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Requested Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      name="expectedDate"
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                      value={editData.requestedDate}
                      onChange={handleInputChange}
                      readOnly
                    />
                    {error.requestedDate && (
                      <div className="text-danger">{error.requestedDate}</div>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex", gap: "15px" }}>
                  <div style={{ flex: 1 }}>
                    <label
                      htmlFor="expectedDate"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Expected Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      name="expectedDate"
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                      value={editData.expectedDate}
                      onChange={handleInputChange}
                      min={tomorrowDateString}
                    />
                    {error.expectedDate && (
                      <div className="text-danger">{error.expectedDate}</div>
                    )}
                  </div>
                  <div style={{ flex: 1 }}>
                    <label
                      htmlFor="revision"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Revisions
                      {/* <span style={{ color: 'red' }}>*</span> */}
                    </label>
                    <SearchDropdownIdVal
                      placeholder={"Select Revision"}
                      backgroundColor={"var(--table-evenrow-color)"}
                      // Check if `editData.revision` is an array and get the latest revision
                      options={
                        Array.isArray(editData.revision) &&
                        editData.revision.length > 0
                          ? [
                              {
                                name: editData.revision.slice(-1)[0], // Get the latest revision
                                value: editData.revision.slice(-1)[0], // Use the latest revision for value
                              },
                            ]
                          : []
                      }
                      onOptionSelect={(option) =>
                        handleRevisionSelection({
                          target: {
                            name: "selectedRevision",
                            value: option.value,
                          },
                        })
                      }
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                    />

                    {/* <select
    id="revision"
    name="selectedRevision"
    onChange={handleRevisionSelection}
    value={selectedRevision}
    className="form-control fs-12"
    style={{ width: '100%' }}
  >
    <option value="">Select Revision</option>
    {(Array.isArray(editData.revision) ? editData.revision : []).map((rev, index) => (
      <option key={index} value={rev.id || rev}>{rev.name || rev}</option> // Adjust as needed
    ))}
  </select> */}

                    {error.revision && (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        {error.revision}
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex", gap: "15px" }}>
                  <div style={{ flex: 1 }}>
                    {/* <label htmlFor="natureofrequestedInfo" className="fs-10 fw-bold text-start py-2">
Nature of Requested Information<span style={{ color: 'red' }}>*</span>
    </label> */}
                    <MultiSelectSearch
                      mandatory={true}
                      heading={"Nature of Requested Information"}
                      listItems={listItems}
                      selectedIds={editData.natureofrequestedInfo || []} // Ensure this is an array
                      setSelectedIds={(ids) =>
                        setEditData({ ...editData, natureofrequestedInfo: ids })
                      }
                      handleSelectedPropertyOption={(data) => {
                        if (editData.natureofrequestedInfo.includes(data.id)) {
                          setEditData({
                            ...editData,
                            natureofrequestedInfo:
                              editData.natureofrequestedInfo.filter(
                                (id) => id !== data.id
                              ),
                          });
                        } else {
                          setEditData({
                            ...editData,
                            natureofrequestedInfo: [
                              ...editData.natureofrequestedInfo,
                              data.id,
                            ],
                          });
                        }
                      }}
                      clearAll={() =>
                        setEditData({ ...editData, natureofrequestedInfo: [] })
                      }
                      addAll={() =>
                        setEditData({
                          ...editData,
                          natureofrequestedInfo: listItems.map(
                            (item) => item.id
                          ),
                        })
                      }
                    />

                    {/* Display selected fields */}
                    <div style={{ marginTop: "20px" }}>
                      <p className="fs-10 fw-bold text-start py-2">
                        Selected Fields:
                      </p>
                      <ul>
                        {editData.natureofrequestedInfo.length > 0 ? (
                          editData.natureofrequestedInfo.map((id) => (
                            <li key={id}>{getNameFromId(id)}</li>
                          ))
                        ) : (
                          <li>No fields selected</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>

                <div style={{ display: "flex", gap: "15px" }}>
                  <div style={{ flex: 1 }}>
                    <label
                      htmlFor="remarks"
                      className="fs-10 fw-bold text-start py-2"
                    >
                      Request Reason <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      name="remarks"
                      placeholder="Enter your Reason"
                      className="form-control fs-12"
                      style={{ width: "100%" }}
                      // rows="3"
                      value={editData.remarks}
                      onChange={handleInputChange}
                    />
                    {error.remarks && (
                      <div className="text-danger">{error.remarks}</div>
                    )}
                  </div>

                  {/* <div style={{ flex: 1 }}>

    <MultiSelectSearch 
  mandatory={true}
  heading={"Nature of Requested Information"}
  listItems={[
    { id: 'A - Missing Information', name: 'A - Missing Information' },
    { id: 'B - Clarification', name: 'B - Clarification' },
    { id: 'C - Additional Information', name: 'C - Additional Information' },
    { id: 'D - Uncoordinated Drawings', name: 'D - Uncoordinated Drawings' },
  ]}
  selectedIds={editData.natureofrequestedInfo || []}
  setSelectedIds={(ids) => setEditData({ ...editData, natureofrequestedInfo: ids })}
  handleSelectedPropertyOption={(data) => {
    if (editData.natureofrequestedInfo.includes(data.id)) {
      setEditData({
        ...editData,
        natureofrequestedInfo: editData.natureofrequestedInfo.filter(id => id !== data.id),
      });
    } else {
      setEditData({
        ...editData,
        natureofrequestedInfo: [...editData.natureofrequestedInfo, data.id],
      });
    }
  }}
  clearAll={() => setEditData({ ...editData, natureofrequestedInfo: [] })}
  addAll={() => setEditData({ ...editData, natureofrequestedInfo: ['A - Missing Information', 'B - Clarification', 'C - Additional Information', 'D - Uncoordinated Drawings'] })}
/>
  </div> */}
                </div>
                <div>
                  <label
                    htmlFor="drawingFileName"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Upload Your File <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg, .png, .dwg"
                    id="drawingFileName"
                    name="drawingFileName"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        // Check if the file type is one of the accepted types
                        const validExtensions = [
                          ".pdf",
                          ".jpg",
                          ".jpeg",
                          ".png",
                          ".dwg",
                        ];
                        const fileExtension = file.name
                          .slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)
                          .toLowerCase();

                        if (validExtensions.includes(`.${fileExtension}`)) {
                          handleInputChange(e);
                        } else {
                          alert("Please select a valid file type.");
                          e.target.value = null; // Reset the file input if the file is not valid
                        }
                      }
                    }}
                  />

                  {error.drawingFileName && (
                    <div className="text-danger">{error.drawingFileName}</div>
                  )}
                </div>
                {errorMessage && (
                  <div style={{ color: "red", fontSize: "15px" }}>
                    {errorMessage}
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <button
                    type="submit"
                    style={{
                      background: "blue",
                      color: "white",
                      width: "100px",
                      height: "35px",
                      border: "none",
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </>
        </DialogContent>
      </Dialog>
      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 20,
            fontFamily: "Poppins",
            background: "#FFF",
           
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
             height:'50px'
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {singleDrawingData?.acceptedRORevisions?.length > 0 ? (
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="transparent" // Hides the default indicator
                textColor="inherit" // Ensures text color is handled manually
                style={{ backgroundColor: "transparent" }} // Ensures background is transparent
              >
                {singleDrawingData.acceptedRORevisions.map(
                  (revisionItem, index) => (
                    <Tab
                      key={index}
                      label={`${revisionItem.revision}`}
                      style={{
                        backgroundColor:
                          singleDrawingData.acceptedRORevisions.length === 1 ||
                          selectedTab === index
                            ? "var(--primary-color)"
                            : "transparent",
                        color:
                          singleDrawingData.acceptedRORevisions.length === 1 ||
                          selectedTab === index
                            ? "white"
                            : "var(--primary-color)",
                        width: "150px",
                        height: "35px",
                        cursor: "pointer",
                        borderRight:
                          index <
                          singleDrawingData.acceptedRORevisions.length - 1
                            ? "1px solid var(--primary-color)"
                            : "none",
                        // borderRadius: index === 0 && !isSingleRevision ? '5px 0 0 5px' : (index === singleDrawingData.acceptedRORevisions.length - 1 && !isSingleRevision ? '0 5px 5px 0' : 'none'),
                      }}
                    />
                  )
                )}
              </Tabs>
            ) : (
              <div>No Revisions</div>
            )}
          </div>
        </div>
        {singleDrawingData?.acceptedRORevisions?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedTab ===
                  singleDrawingData.acceptedRORevisions.length - 1 ? (
                    <div style={{ color: "green" }}>Good For Construction</div>
                  ) : (
                    <div style={{ color: "red" }}>Superseeded</div>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "80px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                `${singleDrawingData?.designDrawingConsultant?.role}`
              </div>

              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>

              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>

              <div>Scheduled submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedSiteSubmissionDate
                  ? new Date(singleDrawingData.acceptedSiteSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedRORevisions[selectedTab] && (
                    <>
                      <div>Actual Final Submission Date</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedRORevisions[selectedTab]
                          .softCopySubmittedDate
                          ? formatDate(
                              singleDrawingData.acceptedRORevisions[selectedTab]
                                .softCopySubmittedDate
                            )
                          : "No Submitted Date"}
                      </div>
                    </>
                  )}
                </>
              )}

              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedRORevisions[selectedTab] && (
                    <>
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedRORevisions[selectedTab]
                          .revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}
              <div>Due / Overdue</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                <span
                  style={{
                    color:
                      singleDrawingData &&
                      singleDrawingData.acceptedRORevisions &&
                      singleDrawingData.acceptedRORevisions.length > 0
                        ? calculateDueDays(
                            singleDrawingData.acceptedSiteSubmissionDate,
                            singleDrawingData.acceptedRORevisions[0]
                              ?.softCopySubmittedDate
                          ) >= 0
                          ? "green"
                          : "red"
                        : "black",
                  }}
                >
                  {singleDrawingData &&
                  singleDrawingData.acceptedRORevisions &&
                  singleDrawingData.acceptedRORevisions.length > 0
                    ? calculateDueDays(
                        singleDrawingData.acceptedSiteSubmissionDate,
                        singleDrawingData.acceptedRORevisions[0]
                          ?.softCopySubmittedDate
                      ) === "-"
                      ? "NA"
                      : calculateDueDays(
                          singleDrawingData.acceptedSiteSubmissionDate,
                          singleDrawingData.acceptedRORevisions[0]
                            ?.softCopySubmittedDate
                        ) >= 0
                      ? `${calculateDueDays(
                          singleDrawingData.acceptedSiteSubmissionDate,
                          singleDrawingData.acceptedRORevisions[0]
                            ?.softCopySubmittedDate
                        )} days due`
                      : `${Math.abs(
                          calculateDueDays(
                            singleDrawingData.acceptedSiteSubmissionDate,
                            singleDrawingData.acceptedRORevisions[0]
                              ?.softCopySubmittedDate
                          )
                        )} days overdue`
                    : "-"}
                </span>
              </div>

              {singleDrawingData?.acceptedRORevisions?.length > 0 &&
                singleDrawingData?.acceptedRORevisions[selectedTab]
                  ?.changes && (
                  <>
                    <div>Note For Revision</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData.acceptedRORevisions[selectedTab]
                        .changes || "NA"}
                    </div>
                  </>
                )}

              {singleDrawingData?.acceptedRORevisions?.length > 0 &&
                singleDrawingData?.acceptedRORevisions[selectedTab]
                  ?.issuesInRevision?.length > 0 && (
                  <>
                    <div>Issues In Revision</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData.acceptedRORevisions[
                        selectedTab
                      ].issuesInRevision.join(", ") || "NA"}
                    </div>
                  </>
                )}
              {singleDrawingData?.acceptedRORevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedRORevisions[selectedTab] && (
                    <>
                      <div>Drawing File</div>
                      <div>:</div>
                      <div style={{ display: "flex", marginLeft: "-120px" }}>
                        <Icon.Image
                          size={20}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              singleDrawingData._id,
                              singleDrawingData.acceptedRORevisions[selectedTab]
                                .revision
                            )
                          }
                        />
                        <Icon.Download
                          size={20}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownload(
                              singleDrawingData._id,
                              singleDrawingData.acceptedRORevisions[selectedTab]
                                .revision
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ApprovedTable;
