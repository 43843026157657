import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { siteId } from "../../../atoms";
import { useRecoilState } from "recoil";
import { Button } from "react-bootstrap";

const SelectionRegister = (props) => {
  let webApi = new WebApimanager();
  const [open, setOpen] = useState(false);
  const [existingCategories, setExistingCategories] = useState([]);
  const [existingConsultants, setExistingConsultants] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);

  const [formData, setFormData] = useState({
    selectionType: "",
    categories: [],
    consultants: [],
    designConsultant: "",
    DrawingCategory: [],
  });

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [site, setSite] = useRecoilState(siteId);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData({
      selectionType: "",
      categories: [],
      consultants: [],
      designConsultant: "",
      DrawingCategory: [],
    });
    setErrors({});
    setOpen(false);
    props.reloadPage();
  };

  const handleChange = (e, index, type) => {
    const { name, value } = e.target;
    const newValue = value.toUpperCase();
    console.log(`name: ${name}, value: ${value}`);
    if (index !== undefined) {
      const updatedFields = [...formData[type]];
      updatedFields[index].name = value;
      setFormData((prevData) => ({
        ...prevData,
        [type]: updatedFields,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    let valid = true;
    const newErrors = {};

    if (!formData.categories.every((category) => category.name.trim() !== "")) {
      newErrors.categories = "All category fields must be filled";
      valid = false;
    }

    if (!valid) {
      setErrors(newErrors);
      return;
    }
    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });

    let submissionData = {
      selectionType: "category",
    };

    submissionData.categories = formData.categories.map((item) => ({
      category: item.name,
    }));

    console.log("Form submitted:", submissionData);
    try {
      const response = await webApi.post(
        "api/architectureToRoSelectionRegister/create",
        submissionData
      );
      console.log(response);
      if (response.status === 201) {
        handleClose();
        setApiProcessing({ loader: false, message: "" });
        getAll();
      } else if (response.status === 400 || response.status === 200) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message.error || "An error occurred.");
        } else {
          setErrorMessage("An error occurred with the request.");
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });

      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(
          error.response.data.message.error || "An error occurred."
        );
      } else {
        return;
      }
    }
  };

  const handleAddField = (type) =>
    setFormData((prevData) => ({
      ...prevData,
      [type]: [...prevData[type], { name: "" }],
    }));

  const handleRemoveField = (index, type) =>
    setFormData((prevData) => ({
      ...prevData,
      [type]: prevData[type].filter((_, i) => i !== index),
    }));

  const onDelete = (id, type) => {
    const apiEndpoint =
      type === "Category"
        ? `api/category/delete/${id}`
        : `api/designDrawingConsultant/delete/${id}`;

    webApi
      .delete(apiEndpoint)
      .then((response) => {
        console.log(`${type} deleted successfully:`, response.data);
        getAll();
      })
      .catch((error) => {
        console.error(`Error deleting ${type.toLowerCase()}:`, error);
      });
  };
  const getAll = () => {
    webApi.get("api/category/getAll").then((data) => {
      if (data) {
        setExistingCategories(data.data.data.categories);
        setFilteredCategories(data.data.data.categories);
      }
    });

    webApi
      .get(`api/designDrawingConsultant/getAll?siteId=${site}`)
      .then((data) => {
        if (data) {
          setExistingConsultants(data.data.data.consultants);
        }
      });
  };

  useEffect(() => {
    getAll();
  }, []);

  // Search function
  const searchResult = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchInput(searchTerm);

    // Filter categories based on search input
    const filtered = existingCategories.filter((category) =>
      category.category.toLowerCase().includes(searchTerm)
    );
    setFilteredCategories(filtered);
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            margin: "15px",
          }}
        >
          <div
            style={{
              margin: "10px",
              width: "50%",
              borderRadius: 5,
            }}
          >
            <div
              style={{
                border: "1px solid #97979740",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  backgroundColor: " #4D4D4D",
                  color: "white",
                }}
              >
                <p style={{ padding: "5px" }}>View Category </p>
              </div>
              <div
                style={{
                  maxHeight: "270px",
                  overflowY: "auto",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "15px",
                  }}
                >
                  <p></p>
                  <input
                    placeholder="Search"
                    onChange={searchResult}
                    style={{
                      borderRadius: "6px",
                      width: "201px",
                      height: "40px",
                      padding: "10px",
                      borderRadius: "5px ",
                      border: "1px solid var(--primary-color)",
                    }}
                  />
                </div>
                {filteredCategories && filteredCategories.length > 0 ? (
                  filteredCategories.map((category, index) => (
                    <li
                      key={category._id}
                      style={{
                        display: "flex",
                        borderTop: index > 0 ? "1px dotted #000" : "none",
                        borderBottom:
                          index === filteredCategories.length - 1
                            ? "1px dotted #000"
                            : "none",
                        padding: "15px 5px",
                      }}
                    >
                      <p
                        style={{
                          width: "500px",
                          margin: 0,
                          textAlign: "start",
                          marginLeft: "5px",
                          fontSize: "16px",
                        }}
                      >
                        {category.category}
                      </p>
                      <Icon.TrashFill
                        size={20}
                        style={{ cursor: "pointer", color: "#FF0808" }}
                        onClick={() => onDelete(category._id, "Category")}
                      />
                    </li>
                  ))
                ) : (
                  <p>No categories found</p>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              margin: "10px",
              width: "50%",
              borderRadius: 5,
            }}
          >
            <div
              style={{
                border: "1px solid #97979740",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  backgroundColor: " #4D4D4D",
                  color: "white",
                }}
              >
                <p style={{ padding: "5px" }}>Add Category</p>
              </div>
              <div
                style={{
                  maxHeight: "270px",
                  overflowY: "auto",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                  padding: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "15px",
                  }}
                >
                  <p></p>
                  <Button
                    style={{
                      height: "40px",
                      marginLeft: "40px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "var(--primary-color)",
                      border: "1px solid var(--primary-color)",
                    }}
                    onClick={() => handleAddField("categories")}
                  >
                    <Icon.PlusCircle
                      size={20}
                      style={{
                        marginRight: "5px",
                        color: "var(--primary-color)",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        padding: "1px",
                      }}
                    />{" "}
                    Add
                  </Button>
                </div>
                {formData.categories.map((item, index) => (
                  <>
                    <div
                      key={index}
                      style={{
                        alignItems: "center",
                        padding: "8px 15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <label
                        style={{
                          marginRight: "5px",
                          width: "50px",
                          textAlign: "center",
                          display: "block",
                          padding: "0.375rem 0.75rem",
                          fontSize: "1rem",
                          lineHeight: "2",
                          color: "#495057",
                          backgroundColor: "#fff",
                          border: "1px solid #979797",
                          borderRadius: "0.25rem",
                        }}
                      >
                        {index + 1}.
                      </label>
                      <input
                        type="text"
                        name={`category${index}`}
                        value={item.name}
                        placeholder={`Enter the Category ${index + 1}`}
                        onChange={(e) => handleChange(e, index, "categories")}
                        style={{
                          textTransform: "uppercase",
                          width: "400px",
                          padding: "15px",
                          display: "block",
                          fontSize: "1rem",
                          lineHeight: "1.5",
                          color: "#495057",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #979797",
                          borderRadius: "0.25rem",
                          transition:
                            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                          boxShadow: "none",
                          outline: "none",
                        }}
                      />

                      {errors.categories && (
                        <span style={{ color: "red" }}>
                          {errors.categories}
                        </span>
                      )}

                      <Icon.DashCircleFill
                        size={20}
                        onClick={() => handleRemoveField(index, "categories")}
                        style={{
                          cursor: "pointer",
                          color: "red",
                          marginLeft: "8px",
                        }}
                      />
                    </div>
                  </>
                ))}
                {formData.categories.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      style={{
                        marginLeft: "50px",
                        marginTop: "8px",
                        padding: "5px",
                        border: "1px solid var(--primary-color)",
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                        width: "100px",
                      }}
                      onClick={handleSubmit}
                    >
                      SUBMIT
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {errorMessage && (
          <div style={{ textAlign: "center", color: "red" }}>
            {errorMessage}
          </div>
        )}
      </div>
    </>
  );
};

export default SelectionRegister;
