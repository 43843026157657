import logo from "../../Images/TMLOGO.png";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo, profileImageUrl, siteId } from "../../atoms";
import NavbarTile from "./NavbarTile";
import AdminNavbarTile from "./AdminNavbarTile";
import { WebApimanager } from "../../WebApiManager";
import { useNavigate } from "react-router-dom";
import Notification from "../../Images/notification.svg";
import Notificationactive from "../../Images/notification.svg";
import User from "../../Images/User.svg";
import SystemNotificationActiveIcon from "../../Images/SystemNotificationActiveIcon.svg";

const Navbar = () => {
  let webApi = new WebApimanager();
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [profileImage, setProfileImage] = useRecoilState(profileImageUrl);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await webApi.get("api/notification");
        if (response.data && Array.isArray(response.data.data.notifications)) {
          const unreadNotifications = response.data.data.notifications.filter(
            (notification) => !notification.isRead
          );
          setUnreadCount(unreadNotifications.length);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchNotifications();
  }, []);

  useEffect(() => {
    const pageName = location.pathname.substring(1);
    setActiveItem(pageName);
  }, [location.pathname]);

  const handleItemClick = (itemName) => {
    setActiveItem(itemName === activeItem ? null : itemName);
  };

  const handleProfileImageClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleNotificationClick = () => {
    navigate("/systemnotifications");
  };

  const handleLogout = () => {
    setTimeout(() => {
      localStorage.removeItem("recoil-persist:userInfo");
      navigate("/");
    }, 100);
  };

  useEffect(() => {
    console.log("User Info State:", currentUserInfo);
    const pageName = location.pathname.substring(1);
    setActiveItem(pageName);
  }, [location.pathname]);

  // Combine all permitted modules across sites
  const combinedModules = currentUserInfo.permittedSites.reduce((acc, site) => {
    Object.keys(site.enableModules).forEach((module) => {
      if (site.enableModules[module] && !acc.includes(module)) {
        acc.push(module);
      }
    });
    return acc;
  }, []);

  const navbarItems = [
    { title: "Dashboard", path: "dashboard" },
    { title: "WorkStatus", path: "tracker" },
    { title: "Checklist", path: "checklist" },
    {
      title: "Drawing",
      path: "drawing",
      condition: combinedModules.includes("drawings"),
    },
    { title: "Companies", path: "sites" },
    {
      title: "Space",
      path: "space",
      condition: combinedModules.includes("space"),
    },
    {
      title: "Users",
      path: "users",
    },
    { title: "Mails", path: "notifications" },
    { title: "Chats", path: "chats" },
  ];

  const adminNavbarItems = [
    {
      title: "Planner",
      path: "planner",
      condition: combinedModules.includes("planner"),
    },
    { title: "P&M", path: "pnm", condition: combinedModules.includes("pAndM") },
    { title: "HR", path: "hr", condition: combinedModules.includes("hr") },
    { title: "EHS", path: "ehs", condition: combinedModules.includes("ehs") },
    { title: "QS", path: "qs", condition: combinedModules.includes("qs") },
    {
      title: "QA/QC",
      path: "QA/QC",
      condition: combinedModules.includes("qaAndQc"),
    },
    {
      title: "Admin",
      path: "Admin",
      condition: combinedModules.includes("admin"),
    },
    {
      title: "Store",
      path: "Store",
      condition: combinedModules.includes("store"),
    },
  ];

  return (
    <>
      <nav
        style={{
          background: "#4D4D4D",
          position: "fixed",
          zIndex: "10",
          width: "100%",
          height: "50px",
          padding: "12px",
          display: "flex",
          justifyContent: "end",
          gap: "30px",
          paddingLeft: "60px",
          paddingRight: "60px",
        }}
      >
        {adminNavbarItems.map(({ title, path, condition }) =>
          condition === undefined || condition ? (
            <AdminNavbarTile
              key={title}
              title={title}
              path={path}
              activeItem={activeItem}
              handleItemClick={handleItemClick}
            />
          ) : null
        )}
      </nav>
      <nav
        className="flex justify-between items-center"
        style={{
          background: "white",
          position: "fixed",
          zIndex: "10",
          width: "100%",
          top: "50px",
          padding: "6px 60px",
        }}
      >
        <a
          href="tracker"
          id="tracker"
          className="flex gap-2 items-center no-underline focus:outline-none"
        >
          <img
            src={logo}
            className="h-12 object-cover max-w-18 max-h-12"
            alt="logo"
          />
        </a>

        <div
          id="nav-menu"
          className="lg:flex gap-[40px] no-underline focus:outline-none"
        >
          {navbarItems.map(({ title, path, condition }) =>
            condition === undefined || condition ? (
              <NavbarTile
                key={title}
                title={title}
                path={path}
                activeItem={activeItem}
                handleItemClick={handleItemClick}
              />
            ) : null
          )}

          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <div style={{ position: "relative" }}>
              <img
                alt="notification"
                src={
                  activeItem === "systemnotifications"
                    ? SystemNotificationActiveIcon
                    : Notification
                }
                onClick={handleNotificationClick}
              />
              {unreadCount > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-10px",
                    background: "var(--primary-color)",
                    color: "white",
                    borderRadius: "50%",
                    width: "18px",
                    height: "18px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                  }}
                >
                  {unreadCount}
                </div>
              )}
            </div>
            <div
              className="relative"
              style={{ position: "relative" }}
              onClick={handleProfileImageClick}
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
              activeItem={activeItem}
            >
              <img src={profileImage || User} alt="Profile" />

              {showDropdown && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "-80px",
                    backgroundColor: "#fff",
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    padding: "4px",
                    width: "120px",
                  }}
                >
                  <div
                    style={{ padding: "8px", cursor: "pointer" }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#f5f5f5")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = "")
                    }
                  >
                    <a href="/profile">Edit Profile</a>
                  </div>
                  <div
                    style={{ padding: "8px", cursor: "pointer" }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor = "#f5f5f5")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor = "")
                    }
                    onClick={handleLogout}
                  >
                    Logout
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
