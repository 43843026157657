import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Nav, Tab } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import BreakdownReport from "./BreakdownReport";
import DailyLogReport from "./DailyLogReport/DailyLogReport";
import Reports from "./Reports/Reports";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { registerAtom, siteId } from "../../atoms";
import { useRecoilState } from "recoil";
import Footer from "../Footer/Footer";
import LogReportStatus from "./Reports/LogReportStatus";
import MiscellaneousReport from "./Reports/MiscellaneousReport";
import AssetCodeCreation from "./AssetCodeCreation";
import AddnewPnm from "./AddnewPnm/AddnewPnm";
import Options from "./Options/Options";
import SitesDropdown from "../../Utilities/SitesDropdown";
import { WebApimanager } from "../../WebApiManager";

const CustomTabs = ({ onTabChange }) => {
  let webApi = new WebApimanager();
  const [register, setRegister] = useRecoilState(registerAtom);
  const [sites, setDatasites] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);

    // Show dropdown if Options tab is clicked
  };
  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    setRegister([]);
    // window.location.reload();
  };
  useEffect(() => {
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        console.log("API response:", response);
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
          if (site === "") {
            setSite(response.data.sites[0]._id);
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "var(--primary-color)", // Custom color for active tab indicator
            },
            "& .MuiTab-root": {
              textTransform: "none", // Prevents text from being transformed to uppercase
              color: "#131313", // Text color for all tabs
              borderBottom: "none",
              fontWeight: "bold",
            },
            "& .Mui-selected": {
              color: "#131313 !important", // Text color for selected tab
            },
            "& .MuiTab-root:not(.Mui-selected)": {
              color: "#131313", // Text color for non-selected tabs
            },
          }}
        >
          <Tab label="Daily Log Report" />
          <Tab label="Breakdown Report" />
          <Tab label="Miscellaneous" />
          <Tab label=" Reports" />
          <Tab label={<div style={{ position: "relative" }}>Options</div>} />
        </Tabs>
        {/* {dropdownVisible && (
          <ul
            className="dropdown-menu show"
            style={{
              position: "absolute",
              top: "20%", // Position below the tab label
              right: "20%", // Align to the right edge of the tab container
              zIndex: 1000, // Ensure dropdown is above other elements
              marginTop: "10px",
              padding: "0",
              borderRadius: "0.25rem",
              boxShadow: "0 0.125rem 0.25rem rgba(0,0,0,0.075)",
            }}
            aria-labelledby="dropdownMenuButton"
          >
            <li>
              <a className="dropdown-item text-center " href="#">
                <AssetCodeCreation />
              </a>
            </li>
            <li>
              <a className="dropdown-item text-center" href="#">
                <AddnewPnm />
              </a>
            </li>
            <li>
              <a className="dropdown-item text-center" href="#">
                Transfer
              </a>
            </li>
          </ul>
        )} */}
        <div className="absolute right-[100px] w-[220px] h-10">
          <SitesDropdown
            siteId={site}
            handleSiteChange={handleSiteChange}
            sites={sites}
            defaultValue={"Select Site"}
          />
        </div>
      </div>
    
    
      <TabPanel value={value} index={0}>
        <DailyLogReport />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BreakdownReport />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MiscellaneousReport />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Reports />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Options />
      </TabPanel>
    </>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {value === index && (
        <Box p={3} style={{ textAlign: "center" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default CustomTabs;

{
  /* <>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', marginLeft: '200px' }}>
          <Tab.Container id="tabs-example" defaultActiveKey="first">
            <Nav
              variant="pills"
              className="justify-content-center"
              style={{ padding: '10px', borderRadius: '10px', backgroundColor: 'white' }}
            >
              <Nav.Item style={{ width: '200px' }}>
                <Nav.Link eventKey="first" className="d-flex justify-content-center" style={{ textTransform: 'none', color: '#131313', fontWeight: 'bold' }}>
                  Daily Log Report
                </Nav.Link>
              </Nav.Item>
              <Nav.Item style={{ width: '200px' }}>
                <Nav.Link eventKey="second" className="d-flex justify-content-center" style={{ textTransform: 'none', color: '#131313', fontWeight: 'bold' }}>
                  Breakdown Report
                </Nav.Link>
              </Nav.Item>
              <Nav.Item style={{ width: '200px' }}>
                <Nav.Link eventKey="third" className="d-flex justify-content-center" style={{ textTransform: 'none', color: '#131313', fontWeight: 'bold' }}>
                  Miscellaneous
                </Nav.Link>
              </Nav.Item>
              <Nav.Item style={{ width: '200px' }}>
                <Nav.Link eventKey="fourth" className="d-flex justify-content-center" style={{ textTransform: 'none', color: '#131313', fontWeight: 'bold' }}>
                  Reports
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Tab.Container>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', fontFamily: 'Poppins' }}>
        <Tab.Container id="tabs-example" defaultActiveKey="first">
          <Tab.Content style={{ width: '90%' }}>
            <Tab.Pane eventKey="first">
              <div>
                <DailyLogReport />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <BreakdownReport />
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              Miscellaneous
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
              <div>
                <Reports />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <Footer height={site !== '' ? '10px' : 'auto'} />
      </>
    </>
  );
}

export default CustomTabs;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      {value === index && (
        <Box p={3} style={{ textAlign: 'center' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
} */
}

// return (
//   <>
//       <div style = {{marginTop: '30px', }}>
//           <Tab.Container id="tabs-example" defaultActiveKey="first">
//               <div style = {{display: 'flex', justifyContent: 'center'}}>
//                   <div>
//                       <Nav variant="pills" className="justify-content-center" style = {{ padding: '10px', borderRadius: '10px', backgroundColor: 'white'}} >
//                           <Nav.Item style = {{width: '200px'}} >
//                           <Nav.Link  eventKey="first"  className="d-flex justify-content-center">Daily Log Report</Nav.Link>
//                           </Nav.Item>
//                           <Nav.Item style = {{width: '200px'}} >
//                           <Nav.Link  eventKey="second"  className="d-flex justify-content-center">Breakdown Report</Nav.Link>
//                           </Nav.Item>
//                           <Nav.Item style = {{width: '200px'}} >
//                           <Nav.Link  eventKey="third"  className="d-flex justify-content-center">Miscellaneous</Nav.Link>
//                           </Nav.Item>
//                           <Nav.Item style = {{width: '200px'}} >
//                           <Nav.Link  eventKey="fourth"  className="d-flex justify-content-center">Reports</Nav.Link>
//                           </Nav.Item>
//                       </Nav>
//                   </div>
//               </div>
//               <div style = {{display: 'flex', justifyContent: 'center', fontFamily: 'Poppins'}}>
//                   <Tab.Content style = {{width: '90%'}}>
//                   <Tab.Pane eventKey="first">
//                     <div>
//                     <DailyLogReport />

//                   </div>
//                   </Tab.Pane>
//                   <Tab.Pane eventKey="second">

//                     <BreakdownReport />
//                   </Tab.Pane>
//                   <Tab.Pane eventKey="third">
//                      Miscellaneous
//                   </Tab.Pane>
//                   <Tab.Pane eventKey="fourth">
//                   <div>
//                     <Reports />

//                   </div>
//                   </Tab.Pane>
//                 </Tab.Content>
//             </div>
//           </Tab.Container>
//         </div>
//   </>
// );
// };

// export default Tabs;
