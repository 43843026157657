import React, { useState, useEffect } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { useRecoilState } from "recoil";
import { WebApimanager } from "../../WebApiManager";
import SelectSite from "../../Utilities/SelectSite";
import { siteId, userInfo } from "../../atoms";
import Employee from "./Employee";
import Profile from "./Profile";
import OrganisationChart from "./OrganisationChart";

const Users = () => {
  let webApi = new WebApimanager();
  const [value, setValue] = useState(0);
  const [site, setSite] = useRecoilState(siteId);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);

  const [tabEnableModules, setTabEnableModules] = useState({
    employee: true,
    profile: true,
    organizationChart: true,
  });

  useEffect(() => {
    console.log(currentUserInfo, "current info for User Module page");
    if (currentUserInfo && currentUserInfo.permittedSites) {
      const enabledModules = currentUserInfo.permittedSites.map(
        (site) => site.enableModules
      );
      setTabEnableModules({
        employee: enabledModules.some((module) => module.userDetails.employee),
        profile: enabledModules.some((module) => module.userDetails.profile),
        organizationChart: enabledModules.some(
          (module) => module.userDetails.organizationChart
        ),
      });
      console.log("Enabled Tabs: ", tabEnableModules);
    }
  }, [currentUserInfo]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
      <div></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          marginTop: "5px",
          backgroundColor: "#F3F3F3",
        }}
      >
        <Box
          sx={{
            border: "1px solid var(--primary-color)",
            width: "100%",
            maxWidth: "720px",
          }}
        >
          <Tabs
            id="controlled-tab-example"
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none", // Hide default indicator
              },
              "& .MuiTab-root": {
                textTransform: "none",
                borderBottom: "none",
                borderLeft: "1px solid var(--primary-color)",
                minWidth: "240px",
                fontWeight: "bold",
                color: "var(--primary-color)",
                "&:first-of-type": {
                  borderLeft: "none",
                },
                "&:last-of-type": {
                  borderRight: "none",
                },
              },
              "& .Mui-selected": {
                backgroundColor: "var(--primary-color)",
                color: "white ! important",
                borderBottom: "1px solid var(--primary-color)",
              },
              "& .MuiTab-root:not(.Mui-selected)": {
                color: "var(--primary-color)",
              },
            }}
          >
            {tabEnableModules.employee && <Tab label="Employee" />}
            {tabEnableModules.profile && <Tab label="Profile" />}
            {tabEnableModules.organizationChart && (
              <Tab label="Organisation Chart" />
            )}
          </Tabs>
        </Box>
      </div>

      {tabEnableModules.employee && (
        <TabPanel value={value} index={0}>
          <Employee />
        </TabPanel>
      )}
      {tabEnableModules.profile && (
        <TabPanel value={value} index={1}>
          <Profile />
        </TabPanel>
      )}
      {tabEnableModules.organizationChart && (
        <TabPanel value={value} index={2}>
          <OrganisationChart />
        </TabPanel>
      )}
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F3F3F3",
      }}
    >
      {value === index && (
        <Box p={3} style={{ textAlign: "center", width: "100%" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default Users;
