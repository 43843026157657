import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";

const AddFileDialog = (props) => {
  const { open, close, id, onDataRefetch } = props;
  const webApi = new WebApimanager();

  const [formData, setFormData] = useState({
    fileName: "",
    file: null,
  });
  const [errors, setErrors] = useState({
    fileName: "",
    file: "",
  });
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, [name]: files[0] });
      setErrors({ ...errors, file: "" });
    } else {
      setFormData({ ...formData, [name]: value });
      setErrors({ ...errors, fileName: "" });
    }
  };
  const validateForm = () => {
    let valid = true;
    let fileNameError = "";
    let fileError = "";

    if (!formData.fileName.trim()) {
      fileNameError = "File name is required.";
      valid = false;
    }

    if (!formData.file) {
      fileError = "File is required.";
      valid = false;
    }

    setErrors({ fileName: fileNameError, file: fileError });
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setApiProcessing({ loader: true, message: "Submiting..." });

      try {
        const fileName = { fileName: formData.fileName };
        const response = await webApi.put(
          `api/isCode/fileName/${id}`,
          fileName
        );
        if (formData.file) {
          const uploadFile = new FormData();
          uploadFile.append("uploadFile", formData.file);

          await webApi.imagePut(`api/isCode/file/${id}`, uploadFile);
        }

        setApiProcessing({ loader: false, message: "" });
        onDataRefetch();
        console.log("Triggering data refetch...");
        close();

        // Trigger the parent to refetch data
      } catch (error) {
        console.error("Error submitting form:", error);
        setApiProcessing({ loader: false, message: "" });
        const errorMsg =
          error.response?.data?.message ||
          "Failed to submit the form. Please try again later.";
        setErrorMessage(errorMsg);
      }
    }
  };

  const handleClose = () => {
    setFormData({
      fileName: "",
      file: null,
    });
    setErrors({
      fileName: "",
      file: "",
    });
    setErrorMessage("");
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#4D4D4D",
          margin: "0px",
        }}
      >
        <p style={{ margin: "5px 230px", textAlign: "center", color: "white" }}>
          Add File
        </p>
        <RxCross1
          size={20}
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            marginRight: "20px",
            color: "white",
          }}
          onClick={handleClose}
        />
      </div>
      <DialogContent
        style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
      >
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <form onSubmit={handleSubmit}>
          <div
            style={{ margin: "30px", display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="fileName" className="fs-10 fw-bold text-start py-2">
              Enter Your File Name <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              name="fileName"
              value={formData.fileName}
              placeholder="Enter file name here"
              className="form-control fs-12"
              style={{ width: "100%", marginTop: "10px" }}
              onChange={handleChange}
            />
            {errors.fileName && (
              <p style={{ color: "red" }}>{errors.fileName}</p>
            )}

            <label
              htmlFor="file"
              className="fs-10 fw-bold text-start py-2"
              style={{ marginTop: "10px" }}
            >
              Upload Your File <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="file"
              id="file"
              name="file"
              accept=".pdf"
              onChange={handleChange}
              className="form-control fs-12"
              style={{ width: "100%", marginTop: "10px", height: "100%" }}
            />
            {errors.file && <p style={{ color: "red" }}>{errors.file}</p>}
          </div>
          {errorMessage && (
            <p
              severity="error"
              onClose={() => setErrorMessage("")}
              style={{ textAlign: "center" }}
            >
              {errorMessage}
            </p>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "45px",
            }}
          >
            <button
              type="submit"
              style={{
                width: "175px",
                height: "50px",
                marginLeft: "50px",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid var(--primary-color)",
                backgroundColor: "var(--primary-color)",
                color: "white",
              }}
            >
              UPLOAD
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddFileDialog;
