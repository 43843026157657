import React, { useState, useEffect } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import ApprovedTable from "./ApprovedTable";
import RequestedTable from "./RequestedTable";
import PendingTable from "./PendingTable";
import RegisterTable from "./RegisterTable";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { architectureTab, siteId, userInfo } from "../../../atoms";
import SelectSite from "../../../Utilities/SelectSite";
import Analysis from "../Components/Analysis";

const ArchitectureToRo = () => {
  let webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(architectureTab);
  const [site, setSite] = useRecoilState(siteId);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [tabEnableModules, setTabEnableModules] = useState({
    drawing: false,
    rfi: false,
    pending: false,
    register: false,
    analysis: false,
  });

  useEffect(() => {
    if (currentUserInfo?.permittedSites) {
      const enabledTabs = [];

      currentUserInfo.permittedSites.forEach((site) => {
        const architectureToRoDetails =
          site.enableModules.drawingDetails?.architectureToRoDetails;

        if (architectureToRoDetails) {
          if (architectureToRoDetails.drawing) {
            enabledTabs.push("Drawing");
          }
          if (architectureToRoDetails.rfi) {
            enabledTabs.push("RFI");
          }
          if (architectureToRoDetails.pending) {
            enabledTabs.push("Pending");
          }
          if (architectureToRoDetails.register) {
            enabledTabs.push("Register");
          }
          if (architectureToRoDetails.analysis) {
            enabledTabs.push("Analysis");
          }

          console.log("Enabled Tabs:", enabledTabs.join(", "));
          setTabEnableModules({
            drawing: architectureToRoDetails.drawing || false,
            rfi: architectureToRoDetails.rfi || false,
            pending: architectureToRoDetails.pending || false,
            register: architectureToRoDetails.register || false,
            analysis: architectureToRoDetails.analysis || false,
          });
        }
      });
    }
  }, [currentUserInfo]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          marginTop: "5px",
        }}
      >
        <Box
          sx={{
            border: "1px solid var(--primary-color)",
            width: "100%",
            maxWidth: "750px",
          }}
        >
          <Tabs
            id="controlled-tab-example"
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none",
              },
              "& .MuiTab-root": {
                textTransform: "none",
                borderBottom: "none",
                borderLeft: "1px solid var(--primary-color)",
                minWidth: "150px",
                fontWeight: "bold",
                color: "var(--primary-color)",
                "&:first-of-type": {
                  borderLeft: "none",
                },
                "&:last-of-type": {
                  borderRight: "none",
                },
              },
              "& .Mui-selected": {
                backgroundColor: "var(--primary-color)",
                color: "white",
                borderBottom: "1px solid var(--primary-color)",
              },
              "& .MuiTab-root:not(.Mui-selected)": {
                color: "var(--primary-color)",
              },
            }}
          >
            {tabEnableModules.drawing && <Tab label="Drawing" />}
            {tabEnableModules.rfi && <Tab label="RFI" />}
            {tabEnableModules.pending && <Tab label="Pending" />}
            {tabEnableModules.register && <Tab label="Register" />}
            {tabEnableModules.analysis && <Tab label="Analysis" />}
          </Tabs>
        </Box>
      </div>

      {tabEnableModules.drawing && (
        <TabPanel value={value} index={0}>
          <ApprovedTable />
        </TabPanel>
      )}
      {tabEnableModules.rfi && (
        <TabPanel value={value} index={1}>
          <RequestedTable />
        </TabPanel>
      )}
      {tabEnableModules.pending && (
        <TabPanel value={value} index={2}>
          <PendingTable />
        </TabPanel>
      )}
      {tabEnableModules.register && (
        <TabPanel value={value} index={3}>
          <RegisterTable />
        </TabPanel>
      )}
      {tabEnableModules.analysis && (
        <TabPanel value={value} index={4}>
          <Analysis type={"architectToRo"} />
        </TabPanel>
      )}
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {value === index && (
        <Box p={3} style={{ textAlign: "center", width: "100%" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default ArchitectureToRo;
