import React from 'react'
import './ModalStyle.css'
import TickImg from "./TickImg.png"

const ModalPopup = ({setShowModal}) => {
  return (
    <div className="modal-overlay" >
    <div className="modal-content" >
        <div className="modal-Img">
            <img className="TickImg" src={TickImg} alt="tickMark" />
        </div>
      <h3>Request Submitted Successfully</h3>
      
    </div>
  </div>
  )
}

export default ModalPopup