import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { registerAtom, siteId } from "../../../atoms";
import SelectSite from "../../../Utilities/SelectSite";
import "bootstrap/dist/css/bootstrap.min.css";
import TableWrapper from "../../../Utilities/TableWrapper";
import { updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";

const RegisterTable = () => {
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };
  const [drawingList, setDrawingList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [site, setSite] = useRecoilState(siteId);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);

  const fetchInData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setDrawingList(registerInfo);
      setInitialDrawingList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    }
  };

  const fetchData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setDrawingList(response.registers);
        setInitialDrawingList(response.registers);
      }
    });
  };
  useEffect(() => {
    if (!searchActive) {
      fetchInData();
    }
  }, [searchActive]);

  useEffect(() => {
    if (searchValue === "") {
      fetchData();
      setSearchActive(false);
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        if (drawingItem) {
          const drawingNumber = drawingItem.drawingNo
            ? drawingItem.drawingNo.toLowerCase()
            : "";
          const drawingName = drawingItem.drawingTitle
            ? drawingItem.drawingTitle.toLowerCase()
            : "";
          const consultant = drawingItem.designDrawingConsultant
            ? `${drawingItem.designDrawingConsultant.role.toLowerCase()}`
            : "";
          const category =
            drawingItem.category && drawingItem.category.category
              ? drawingItem.category.category.toLowerCase()
              : "";

          const acceptedSiteSubmissionDate =
            drawingItem.acceptedSiteSubmissionDate
              ? new Date(drawingItem.acceptedSiteSubmissionDate).toDateString()
              : "";

          return (
            drawingNumber.includes(searchValue) ||
            drawingName.includes(searchValue) ||
            consultant.includes(searchValue) ||
            category.includes(searchValue) ||
            acceptedSiteSubmissionDate.includes(searchValue)
          );
        } else {
          return false;
        }
      });
      setDrawingList(filteredDrawings);
    }
  }, [searchValue]);
  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase();
    setSearchValue(searchInput);
    setSearchActive(true);
  };

  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#131313",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                Drawing Register
              </p>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      position: "relative",
                      width: "250px",
                      paddingLeft: "5px",
                      height: "50px",
                    }}
                  >
                    <input
                      placeholder="Search Drawings"
                      onChange={searchResult}
                      value={searchValue}
                      style={{
                        paddingLeft: "20px",
              
                        border: "1px solid var(--primary-color)",
                        width: "250px",
                        height: "40px",
                      }}
                    />
                    <Icon.Search
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "40%",
                        transform: "translateY(-50%)",
                        color:'#ACACAC'
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <DrawingTableWrapper
              tableHead={
                <tr>
                  <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                  <th style={{ width: "15%" }}>Drawing No</th>
                  <th style={{ width: "20%" }}>Design consultant </th>
                  <th style={{ width: "20%" }}>Drawing Category</th>
                  <th style={{ width: "15%" }}>Drawing Title</th>
                  <th style={{ width: "20%" }}>
                    Accepted site submission date
                  </th>
                </tr>
              }
              tableBody={
                <tbody>
                  {drawingList
                    .filter((item) => item && item.designDrawingConsultant)
                    .sort((a, b) => {
                      const consultantA = a.designDrawingConsultant.role
                        ? a.designDrawingConsultant.role.toLowerCase()
                        : "";
                      const consultantB = b.designDrawingConsultant.role
                        ? b.designDrawingConsultant.role.toLowerCase()
                        : "";
                      return consultantA.localeCompare(consultantB);
                    })
                    .map((drawingItem, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0
                              ? "var(--table-oddrow-color)"
                              : "var(--table-evenrow-color)",
                        }}
                      >
                        <td>{index + 1}</td>
                        <td>{drawingItem?.drawingNo || "na"}</td>
                        <td>{drawingItem.designDrawingConsultant.role}</td>
                        <td>{drawingItem.category?.category || ""}</td>
                        <td>{drawingItem.drawingTitle}</td>

                        <td>
                          {formatDate(
                            drawingItem?.acceptedROSubmissionDate || ""
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              }

              // tableBody={
              //   <tbody style={{ textAlign: "center" }}>
              //     {drawingList
              //       .filter((item) => item && item.designDrawingConsultant)
              //       .sort((a, b) => {
              //         const consultantA = a.designDrawingConsultant?.role
              //           ? a.designDrawingConsultant?.role.toLowerCase()
              //           : "";
              //         const consultantB = b.designDrawingConsultant?.role
              //           ? b.designDrawingConsultant?.role.toLowerCase()
              //           : "";
              //         return consultantA.localeCompare(consultantB); // Sort alphabetically
              //       })
              //       .map((drawingItem, index) => (
              //         <tr
              //           key={index}
              //           style={{
              //             backgroundColor:
              //               index % 2 === 0
              //                 ? "var(--table-oddrow-color)"
              //                 : "var(--table-evenrow-color)",
              //           }}
              //         >
              //           <td>{index + 1}</td>
              //           <td>{drawingItem?.drawingNo || ""}</td>
              //           <td>
              //             {drawingItem.designDrawingConsultant?.role || ""}
              //           </td>
              //           <td>{drawingItem.category?.category || ""}</td>
              //           <td>{drawingItem?.drawingTitle || ""}</td>

              //           <td>
              //             {formatDate(
              //               drawingItem?.acceptedROSubmissionDate || ""
              //             )}
              //           </td>
              //           {/* <td>{formatDate(drawingItem.acceptedSiteSubmissionDate)}</td>
              // <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
              // {(drawingItem.acceptedArchitectRevisions.length === 0 && drawingItem.acceptedRORevisions.length === 0) ? (
              // <>
              //   <Icon.Pen size={20} style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => handleEdit(drawingItem)} /> */}
              //           {/* <Icon.Trash size={20} style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => handleTrashOpen(drawingItem._id)} /> */}
              //           {/* </>
              // ):(
              //   <div>No Action</div>
              // )}
              // </td> */}
              //         </tr>
              //       ))}
              //   </tbody>
              // }
            />
          </>
        }
      />
    </>
  );
};

export default RegisterTable;
