import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { useRecoilState } from "recoil";
import { Button } from "react-bootstrap";
import {
  userInfo,
  siteId,
  registerAtom,
  atororegisterTab,
} from "../../../atoms";
import { Tabs, Tab, Typography, Box } from "@mui/material";
// import Filter from "../../../Images/Filter.svg";
import SelectSite from "../../Utilities/SelectSite";
import TableWrapper from "../../../Utilities/TableWrapper";
import { WebApimanager } from "../../../WebApiManager";
import "./Approval.css";
import ApprovalTable from "./ApprovalTable";
import Eye from "../../../Images/Eye.svg"
import Pen from "../../../Images/Pen.svg"

const Approval = () => {
  let webApi = new WebApimanager();

  const [site, setSite] = useRecoilState(siteId);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewopen, setviewOpen] = useState(false);
  const [descriptionopen, setDescriptionOpen] = useState(false);

  const [formData, setFormData] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [currentView, setCurrentView] = useState("requestingForm");
  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
    setSearchValue("");
    setSearchActive(false);
  };

  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <Tabs
                  value={currentView}
                  onChange={handleTabViewChange}
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "var(--primary-color)",
                    },
                    "& .MuiTab-root": {
                      textTransform: "none",
                      color: "#131313",
                      borderBottom: "none",
                      fontWeight: "bold",
                    },
                    "& .Mui-selected": {
                      color: "#131313 !important",
                    },
                    "& .MuiTab-root:not(.Mui-selected)": {
                      color: "#131313",
                    },
                  }}
                >
                  <Tab label="Requesting Forms" value="requestingForm" />
                  <Tab label="Pending" value="pending" />
                  <Tab label="Redo" value="redo" />
                  <Tab label="Rejected" value="rejected" />
                  <Tab label="Approved" value="approved" />
                </Tabs>
              </div>
              <div>
                {currentView === "requestingForm" && (
                  <>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          position: "relative",
                          width: "250px",
                          paddingLeft: "5px",
                          height: "50px",
                        }}
                      >
                        <input
                          placeholder="Search Forms"
                          // onChange={searchResult}
                          value={searchValue}
                          style={{
                            paddingLeft: "20px",
                            border: "1px solid var(--primary-color)",
                            width: "250px",
                            height: "40px",
                          }}
                        />
                        <Icon.Search
                          style={{
                            position: "absolute",
                            right: "15px",
                            top: "40%",
                            transform: "translateY(-50%)",
                            color: "#ACACAC",
                          }}
                        />
                      </div>

                      <img
                        alt="filter"
                        src={`data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACUCAMAAAAj+tKkAAAAVFBMVEX///8AAAA2Njbl5eWnp6ekpKQKCgqrq6vq6uo6OjpUVFSysrIODg4FBQWhoaFeXl5CQkJMTEx8fHyXl5clJSXS0tLb29tZWVkgICDy8vKKiorHx8daSN1+AAACG0lEQVR4nO3ciW6DMBAE0GkgQC6SHun5//9ZSKomTcDYeO1dVTNf8DSrNUZCYPVgOitoC6aCSlvgToW1NsGdNVptgjstmoW2wZVFA2y0Ea5s0KXWVoynxinlThsynF2JnxjtsMZv6q025j7bK183ZW3OfUr8ibkp17iJsQ7LW5+xDu/6O3VoZlO2A/2ZmvKIz8yUB+drqMPR/owInT79TRnbDzMdTvqUhR4+VaGXT1Ho6euEKu+ilbdPqcMAn8ZbwC7Ip9BhoC+7MNiX95ky/fxQ7nCWL99pE3K+qHQ425dJGOHLMeX5883UYaQvdYex/SXvUMAHLI370t0cQu8H40nU4VLK1wkTbEol6EuyKWLzPUd8yqL99RHuULi/PqIdivd3Eoptiux+XCI25QTzPUdoyon6ExMm9IkIk/riNyXVflwSuSnJ9uOSqCkn7y9SmMUXIczkm7sp6ffjSmi6v5nCrL4Zwsy+YGF2X9im5NyPK6Hp/vp4f/bV6vi8P/vqP8BSSeELLAgkkEACCSSQQAIJJJBAAgkkkEACCSSQQAIJJJBAAgkkkEACCSSQQAIJJJBAAgkkkMD/AmwejQPxZB3o+eM9PSD21oHNwTgQhU+HmkDg+cU4EMfJMSsDuxIn/gqiDsTR/UjRB6JxHtkGgN2Z7SjRBBCvb8aBQPtuHIjjyO3GDBAfn4O/jLADBL6GTm1LQBQDB84hBvgN8ZgurodbxKkAAAAASUVORK5CYII=`}
                        style={{
                          width: "40px",
                          height: "40px",
                          marginLeft: "10px",
                          padding: "5px",
                          border: "1px solid var(--primary-color)",
                          cursor: "pointer",
                        }}
                      />
                      <Button
                        style={{
                          height: "40px",
                          marginLeft: "5px",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "var(--primary-color)",
                          border: "1px solid var(--primary-color)",
                          borderRadius: "0px",
                        }}
                      >
                        <Icon.PlusCircle
                          size={20}
                          style={{ marginRight: "5px" }}
                        />{" "}
                        Add New
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>

            {currentView === "requestingForm" && (
              <ApprovalTable
                ApprovalTableHead={
                  <tr className="HeaderTableRow">
                    <th className="ApprovalTableTableHead" style={{width:"4%"}}>S.No</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}}>Form Name</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Activity Name</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Revisions</th>
                    <th className="ApprovalTableTableHead"  style={{width:"11%"}}>Requested By</th>
                    <th className="ApprovalTableTableHead"  style={{width:"12%"}}>Requested Date</th>
                    <th className="ApprovalTableTableHead" style={{width:"7%"}} >Status</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Done By</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Done Date</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Actions</th>
                  </tr>
                }
                ApprovalTableBody={
                  <tr className="ValueTableRow">
                    <td className="ApprovalTableTableData" style={{width:"4%"}}>001</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}}>CL-001</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >Gypsum Wok</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >1</td>
                    <td className="ApprovalTableTableData"  style={{width:"11%"}}>Ranjit Baba</td>
                    <td className="ApprovalTableTableData"  style={{width:"12%"}}>1/11/2022</td>
                    <td className="ApprovalTableTableData" style={{width:"7%"}} >Pending</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >Bhanu Baba</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >2/2/2022</td>
                    <td className="ApprovalTableTableData" style={{width:"11%",display:"flex",gap:"8px",justifyContent:"center"}}  >
                      <img src={Pen} alt="Pen" style={{cursor:"pointer"}}/>
                      <img src={Eye} alt="Eye"  style={{cursor:"pointer"}}/>
                    </td>
                  </tr>
                }
              />
            )}
             {currentView === "pending" && (
              <ApprovalTable
                ApprovalTableHead={
                  <tr className="HeaderTableRow">
                    <th className="ApprovalTableTableHead" style={{width:"4%"}}>S.No</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}}>Form Name</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Activity Name</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Revisions</th>
                    <th className="ApprovalTableTableHead"  style={{width:"11%"}}>Requested By</th>
                    <th className="ApprovalTableTableHead"  style={{width:"12%"}}>Requested Date</th>
                    <th className="ApprovalTableTableHead" style={{width:"7%"}} >Status</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Done By</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Done Date</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Actions</th>
                  </tr>
                }
                ApprovalTableBody={
                  <tr className="ValueTableRow">
                    <td className="ApprovalTableTableData" style={{width:"4%"}}>001</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}}>CL-001</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >Bricks Wok</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >1</td>
                    <td className="ApprovalTableTableData"  style={{width:"11%"}}>Ranjit Baba</td>
                    <td className="ApprovalTableTableData"  style={{width:"12%"}}>1/11/2022</td>
                    <td className="ApprovalTableTableData" style={{width:"7%"}} >Pending</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >Bhanu Baba</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >2/2/2022</td>
                    <td className="ApprovalTableTableData" style={{width:"11%",display:"flex",gap:"8px",justifyContent:"center"}}  >
                      <img src={Pen} alt="Pen" style={{cursor:"pointer"}}/>
                      <img src={Eye} alt="Eye"  style={{cursor:"pointer"}}/>
                    </td>
                  </tr>
                }
              />
            )}
             {currentView === "redo" && (
              <ApprovalTable
                ApprovalTableHead={
                  <tr className="HeaderTableRow">
                    <th className="ApprovalTableTableHead" style={{width:"4%"}}>S.No</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}}>Form Name</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Activity Name</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Revisions</th>
                    <th className="ApprovalTableTableHead"  style={{width:"11%"}}>Requested By</th>
                    <th className="ApprovalTableTableHead"  style={{width:"12%"}}>Requested Date</th>
                    <th className="ApprovalTableTableHead" style={{width:"7%"}} >Status</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Done By</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Done Date</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Actions</th>
                  </tr>
                }
                ApprovalTableBody={
                  <tr className="ValueTableRow">
                    <td className="ApprovalTableTableData" style={{width:"4%"}}>001</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}}>CL-001</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >Paint Wok</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >1</td>
                    <td className="ApprovalTableTableData"  style={{width:"11%"}}>Ranjit Baba</td>
                    <td className="ApprovalTableTableData"  style={{width:"12%"}}>1/11/2022</td>
                    <td className="ApprovalTableTableData" style={{width:"7%"}} >Pending</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >Bhanu Baba</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >2/2/2022</td>
                    <td className="ApprovalTableTableData" style={{width:"11%",display:"flex",gap:"8px",justifyContent:"center"}}  >
                      <img src={Pen} alt="Pen" style={{cursor:"pointer"}}/>
                      <img src={Eye} alt="Eye"  style={{cursor:"pointer"}}/>
                    </td>
                  </tr>
                }
              />
            )}
            {currentView === "rejected" && (
              <ApprovalTable
                ApprovalTableHead={
                  <tr className="HeaderTableRow">
                    <th className="ApprovalTableTableHead" style={{width:"4%"}}>S.No</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}}>Form Name</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Activity Name</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Revisions</th>
                    <th className="ApprovalTableTableHead"  style={{width:"11%"}}>Requested By</th>
                    <th className="ApprovalTableTableHead"  style={{width:"12%"}}>Requested Date</th>
                    <th className="ApprovalTableTableHead" style={{width:"7%"}} >Status</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Done By</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Done Date</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Actions</th>
                  </tr>
                }
                ApprovalTableBody={
                  <tr className="ValueTableRow">
                    <td className="ApprovalTableTableData" style={{width:"4%"}}>001</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}}>CL-001</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >Plaster Work</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >1</td>
                    <td className="ApprovalTableTableData"  style={{width:"11%"}}>Ranjit Baba</td>
                    <td className="ApprovalTableTableData"  style={{width:"12%"}}>1/11/2022</td>
                    <td className="ApprovalTableTableData" style={{width:"7%"}} >Pending</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >Bhanu Baba</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >2/2/2022</td>
                    <td className="ApprovalTableTableData" style={{width:"11%",display:"flex",gap:"8px",justifyContent:"center"}}  >
                      <img src={Pen} alt="Pen" style={{cursor:"pointer"}}/>
                      <img src={Eye} alt="Eye"  style={{cursor:"pointer"}}/>
                    </td>
                  </tr>
                }
              />
            )}
            {currentView === "approved" && (
              <ApprovalTable
                ApprovalTableHead={
                  <tr className="HeaderTableRow">
                    <th className="ApprovalTableTableHead" style={{width:"4%"}}>S.No</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}}>Form Name</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Activity Name</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Revisions</th>
                    <th className="ApprovalTableTableHead"  style={{width:"11%"}}>Requested By</th>
                    <th className="ApprovalTableTableHead"  style={{width:"12%"}}>Requested Date</th>
                    <th className="ApprovalTableTableHead" style={{width:"7%"}} >Status</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Done By</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Done Date</th>
                    <th className="ApprovalTableTableHead" style={{width:"11%"}} >Actions</th>
                  </tr>
                }
                ApprovalTableBody={
                  <tr className="ValueTableRow">
                    <td className="ApprovalTableTableData" style={{width:"4%"}}>001</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}}>CL-001</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >Tiles Work</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >1</td>
                    <td className="ApprovalTableTableData"  style={{width:"11%"}}>Ranjit Baba</td>
                    <td className="ApprovalTableTableData"  style={{width:"12%"}}>1/11/2022</td>
                    <td className="ApprovalTableTableData" style={{width:"7%"}} >Pending</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >Bhanu Baba</td>
                    <td className="ApprovalTableTableData" style={{width:"11%"}} >2/2/2022</td>
                    <td className="ApprovalTableTableData" style={{width:"11%",display:"flex",gap:"8px",justifyContent:"center"}}  >
                      <img src={Pen} alt="Pen" style={{cursor:"pointer"}}/>
                      <img src={Eye} alt="Eye"  style={{cursor:"pointer"}}/>
                    </td>
                  </tr>
                }
              />
            )}
          </>
        }
      />
    </>
  );
};

export default Approval;
