import React from "react";
import FloorDetails from "../FloorDetails";
import { Display } from "react-bootstrap-icons";

const ApartmentTowerDetails = (props) => {
  const { formData, handleChange, errors } = props;

  return formData.towers.map((tower, index) => (
    <div key={index}>
      <h3 style={{ marginTop: "20px" }}>Enter Details for Tower {index + 1}</h3>
      <div
        style={{
          borderBottom: "1px dotted lightgray",
          display: "flex",
          marginTop: "4px",
          paddingBottom: "40px",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
            }}
          >
            <label>
              Number of Floors<a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="number"
              min="1"
              max="100"
              name="AnoOfFloors"
              placeholder="Enter Number of Floors"
              value={tower.AnoOfFloors}
              onChange={(e) => handleChange(e, null, null, index)}
              style={{marginTop:'15px'}}
            />
            {errors[`AnoOfFloors-${index}`] && (
              <p style={{ color: "red" }}>{errors[`AnoOfFloors-${index}`]}</p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop:'20px'
            }}
          >
            <label>
              Number of Poures for a Floor
              <a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="number"
              min="1"
              max="100"
              name="AnoOfPouresFloor"
              placeholder="Enter Number Of Poures for a Floor"
              value={tower.AnoOfPouresFloor}
              onChange={(e) => handleChange(e, null, null, index)}
              style={{marginTop:'15px'}}
            />
            {errors[`AnoOfPouresFloor-${index}`] && (
              <p style={{ color: "red" }}>
                {errors[`AnoOfPouresFloor-${index}`]}
              </p>
            )}
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
            }}
          >
            <label>
              Number of Basements<a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="number"
              min="1"
              max="100"
              name="AnoOfBasements"
              placeholder="Enter Number of Basements"
              value={tower.AnoOfBasement}
              onChange={(e) => handleChange(e, null, null, index)}
              style={{marginTop:'15px'}}
            />
            {errors[`AnoOfBasement-${index}`] && (
              <p style={{ color: "red" }}>{errors[`AnoOfBasement-${index}`]}</p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop:'20px'
            }}
          >
            <label>
              Number of Poures for a Basement
              <a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="number"
              min="1"
              max="100"
              name="AnoOfPouresBasement"
              placeholder="Enter Number Of Poures for a basement"
              value={tower.AnoOfPouresBasement}
              onChange={(e) => handleChange(e, null, null, index)}
              style={{marginTop:'15px'}}
            />
            {errors[`AnoOfPouresBasement-${index}`] && (
              <p style={{ color: "red" }}>
                {errors[`AnoOfPouresBasement-${index}`]}
              </p>
            )}
          </div>
        </div>
      </div>

      {tower.AnoOfFloors > 0 && (
        <FloorDetails
          floors={tower.floors}
          errors={errors}
          handleChange={handleChange}
          ventureType={formData.ventureType}
          towerIndex={index}
        />
      )}
    </div>
  ));
};

export default ApartmentTowerDetails;
