import React, { useState, useEffect } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import ApprovedTable from "./ApprovedTable";
import RequestedTable from "./RequestedTable";
import PendingTable from "./PendingTable";
import RegisterTable from "./RegisterTable";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { roTab, siteId, userInfo } from "../../../atoms";
import SelectSite from "../../../Utilities/SelectSite";
import Analysis from "../Components/Analysis";

const ROtoSiteLevel = () => {
  let webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(roTab);
  const [site, setSite] = useRecoilState(siteId);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [tabEnableModules, setTabEnableModules] = useState({
    drawing: false,
    rfi: false,
    pending: false,
    register: false,
    analysis: false,
  });

  useEffect(() => {
    if (currentUserInfo?.permittedSites) {
      const enabledTabs = [];

      currentUserInfo.permittedSites.forEach((site) => {
        const roToSiteDetails =
          site.enableModules.drawingDetails?.roToSiteDetails;

        if (roToSiteDetails) {
          if (roToSiteDetails.drawing) {
            enabledTabs.push("Drawing");
          }
          if (roToSiteDetails.rfi) {
            enabledTabs.push("RFI");
          }
          if (roToSiteDetails.pending) {
            enabledTabs.push("Pending");
          }
          if (roToSiteDetails.register) {
            enabledTabs.push("Register");
          }
          if (roToSiteDetails.analysis) {
            enabledTabs.push("Analysis");
          }
          console.log(
            "Enabled Tabs for roToSiteDetails:",
            enabledTabs.join(", ")
          );

          setTabEnableModules({
            drawing: roToSiteDetails.drawing || false,
            rfi: roToSiteDetails.rfi || false,
            pending: roToSiteDetails.pending || false,
            register: roToSiteDetails.register || false,
            analysis: roToSiteDetails.analysis || false,
          });
        }
      });
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (site === "") {
    return <SelectSite />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          marginTop: "5px",
        }}
      >
        <Box
          sx={{
            border: "1px solid var(--primary-color)",
            width: "100%",
            maxWidth: "750px", // Adjust max-width as needed
          }}
        >
          <Tabs
            id="controlled-tab-example"
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none", // Hide default indicator
              },
              "& .MuiTab-root": {
                textTransform: "none",
                borderBottom: "none",
                borderLeft: "1px solid var(--primary-color)",
                minWidth: "150px",
                fontWeight: "bold",
                color: "var(--primary-color)",
                "&:first-of-type": {
                  borderLeft: "none",
                },
                "&:last-of-type": {
                  borderRight: "none",
                },
              },
              "& .Mui-selected": {
                backgroundColor: "var(--primary-color)", // Background color for selected tab
                color: "white",
                borderBottom: "1px solid var(--primary-color)", // Maintain border at the bottom of selected tab
              },
              "& .MuiTab-root:not(.Mui-selected)": {
                color: "var(--primary-color)", // Text color for non-selected tabs
              },
            }}
          >
            {tabEnableModules.drawing && <Tab label="Drawing" />}
            {tabEnableModules.rfi && <Tab label="RFI" />}
            {tabEnableModules.pending && <Tab label="Pending" />}
            {tabEnableModules.register && <Tab label="Register" />}
            {tabEnableModules.analysis && <Tab label="Analysis" />}
          </Tabs>
        </Box>
      </div>

      {tabEnableModules.drawing && (
        <TabPanel value={value} index={0}>
          <ApprovedTable />
        </TabPanel>
      )}
      {tabEnableModules.rfi && (
        <TabPanel value={value} index={1}>
          <RequestedTable />
        </TabPanel>
      )}
      {tabEnableModules.pending && (
        <TabPanel value={value} index={2}>
          <PendingTable />
        </TabPanel>
      )}
      {tabEnableModules.register && (
        <TabPanel value={value} index={3}>
          <RegisterTable />
        </TabPanel>
      )}

      {tabEnableModules.analysis && (
        <TabPanel value={value} index={4}>
          <Analysis type={"RoToSite"} />
        </TabPanel>
      )}
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {value === index && (
        <Box p={3} style={{ textAlign: "center" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default ROtoSiteLevel;
