import React from "react";
import { CiStar } from "react-icons/ci";
import * as Icon from "react-bootstrap-icons";

const TrashNotifications = () => {
  return (
    <>
      <table className="table-auto w-full mt-4">
        <thead>
          <tr>
            <th>Name</th>
            <th>Message</th>
            <th>Time & Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Draft</td>
            <td>message</td>
            <td>date</td>

            <td>
              <Icon.Trash
                size={20}
                //   onClick={() => deleteNotifications(recivedNotification)}
                style={{ cursor: "pointer" }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default TrashNotifications;
