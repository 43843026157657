import React, { useState } from "react";
import { Dialog, DialogContent, Stepper, StepLabel, Step } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../WebApiManager";
import { Loader } from "../../Widgets/notificationFeedbacks";

import "./Dialog.module.css";
import { siteId } from "../../atoms";
import { useRecoilState } from "recoil";
import "./sites.css";

const AddCompanyDialog = (props) => {
  const { open, close } = props;
  const webApi = new WebApimanager();
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const indiaStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [company, setCompany] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [formData, setFormData] = useState({
    companyName: "",
    ownerName: "",
    phoneNumber: "",
    email: "",
    gstNo: "",
    panNo: "",
    name: "",
    designation: "",
    mailId: "",
    cphoneNumber: "",
    officeAddress: "",
    country: "",
    cityState: "",
    pinCode: "",
    industry: "",
    websiteURL: "",
    description: "",
    GSTNO: null,
    CPAN: null,
    CTAN: null,
    agreementDocument: null,
  });
  const [errors, setErrors] = useState({});

 
  const validateField = (name, value) => {
    let errorMessage = "";
  
    switch (name) {
      case "companyName":
      case "ownerName":
      case "name":
        const isValidName = /^[A-Za-z\s]+$/.test(value);
        errorMessage = value.trim()
          ? isValidName
            ? ""
            : `${name.replace(/([A-Z])/g, " $1").trim()} should contain letters only.`
          : `Please enter ${name.replace(/([A-Z])/g, " $1").trim()}.`;
        break;
      case "email":
      case "mailId":
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        errorMessage = value.trim()
          ? isValidEmail
            ? ""
            : "Please enter a valid email address."
          : "Please enter an email address.";
        break;
      case "phoneNumber":
      case "cphoneNumber":
        const isValidPhoneNumber = /^\d{10}$/.test(value);
        errorMessage = value.trim()
          ? isValidPhoneNumber
            ? ""
            : "Phone number must be exactly 10 digits."
          : "Please enter a phone number.";
        break;
      case "pinCode":
        const isValidPinCode = /^\d{6}$/.test(value);
        errorMessage = value.trim()
          ? isValidPinCode
            ? ""
            : "PinCode must be exactly 6 digits."
          : "Please enter a PinCode.";
        break;
      case "gstNo":
        const isValidGSTNo = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(value);
        errorMessage = value.trim()
          ? isValidGSTNo
            ? ""
            : "Please enter a valid GST Number."
          : "Please enter a GST Number.";
        break;
      case "panNo":
        const isValidPANNo = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value);
        errorMessage = value.trim()
          ? isValidPANNo
            ? ""
            : "Please enter a valid PAN Number."
          : "Please enter a PAN Number.";
        break;
      case "websiteURL":
        const isValidWebsiteURL = /^(https?:\/\/)?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,})(\/[a-zA-Z0-9\-\.]*)?$/.test(value);
        errorMessage = value.trim()
          ? isValidWebsiteURL
            ? ""
            : "Please enter a valid website URL."
          : "Please enter a website URL.";
        break;
      case "designation":
      case "officeAddress":
      case "country":
      case "cityState":
      case "industry":
      case "description":
        errorMessage = value.trim()
          ? ""
          : `Please enter ${name.replace(/([A-Z])/g, " $1").trim()}.`;
        break;
      default:
        break;
    }
  
    return errorMessage;
  };
  const validateFields = () => {
    const newErrors = {};
  
    if (activeStep === 0) {
      Object.keys(formData).forEach((key) => {
        const errorMessage = validateField(key, formData[key]);
        if (errorMessage) {
          newErrors[key] = errorMessage;
        }
      });
    } else if (activeStep === 1) {
      if (!formData.GSTNO) newErrors.GSTNO = "Please upload GST No File.";
      if (!formData.CPAN) newErrors.CPAN = "Please upload Company PAN File.";
      if (!formData.CTAN) newErrors.CTAN = "Please upload Company TAN No File.";
      if (!formData.agreementDocument) newErrors.agreementDocument = "Please upload Agreement Document.";
    }
  
    console.log("Form Data:", formData);
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    const errorMessage = validateField(name, value);
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    let errorMessage = "";
    if (files.length > 0) {
      const file = files[0];

      if (file.type === "application/pdf") {
        setFormData((prevValues) => ({
          ...prevValues,
          [name]: file,
        }));
      } else {
        errorMessage = `Please upload a PDF file.`;
      }
    } else {
      errorMessage = `Please upload ${
        name.charAt(0).toUpperCase() + name.slice(1)
      }.`;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));



  };

  const handleNext = () => {
    const isValid = validateFields(); 
   
    if (!isValid) {
      return; 
    }

    if (activeStep === 2) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1); 
    }
  };
  
  const handleBack = () => {
    setIsBackButtonClicked(true);
    validateFields();
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    setApiProcessing({
      loader: true,
      message: "Submitting data...",
    });

    try {
      const response = await webApi.post("api/company", {
        companyDetails: {
          companyName: formData.companyName,
          ownerName: formData.ownerName,
          companyMailingaddress: formData.email,
          phoneNo: formData.phoneNumber,
          gstNo: formData.gstNo,
          panNo: formData.panNo,
        },
        personalInfo: {
          name: formData.name,
          designation: formData.designation,
          emailId: formData.mailId,
          phNo: formData.cphoneNumber,
        },
        companyAddress: {
          officeAddress: formData.officeAddress,
          country: formData.country,
          cityOrState: formData.cityState,
          pinCode: formData.pinCode,
          industry: formData.industry,
          webSiteUrl: formData.websiteURL,
          description: formData.description,
        },
      });

      console.log("responseData", response);

      if (response.status === 201 && response.data.data.company._id) {
        const id = response.data.data.company._id;
        console.log("Company ID:", id);

        const documentData = {
          gstNo: formData.GSTNO,
          companyPanNo: formData.CPAN,
          companyTanNo: formData.CTAN,
          agreementDocument: formData.agreementDocument,
        };

        const uploadResponses = await Promise.all(
          Object.entries(documentData).map(([key, value]) => {
            return webApi.imagePut(`api/company/documents/${id}`, {
              [key]: value,
            });
          })
        );

        const allUploadsSuccessful = uploadResponses.every(
          (uploadResponse) => uploadResponse.status === 200
        );

        if (allUploadsSuccessful) {
          console.log("All documents uploaded successfully.");
          handleClose();
          window.location.reload();
        } else {
          setErrorMessage("Failed to upload some files. Please try again.");
          console.log("Upload error:", uploadResponses);
        }
      } else if (response.status === 200) {
        const responseData = response.data;
        setErrorMessage(responseData.message || "Validation error occurred.");
        console.log("Validation error:", responseData.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
        console.log("Unexpected response:", response);
      }
    } catch (error) {
      if (error.response) {
        const errorResponse = error.response.data;
        setErrorMessage(errorResponse.message || "Error occurred.");
        console.log("Error:", errorResponse.message);
      } else {
        setErrorMessage("Failed to submit data.");
        console.error("Submission error:", error.message);
      }
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleClose = () => {
    close();
    setFormData({});
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: " #4D4D4D",
          margin: "0px",
        }}
      >
        <p style={{ margin: "5px 500px", textAlign: "center", color: "white" }}>
          Add Company
        </p>
        <RxCross1
          size={20}
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            marginRight: "10px",
            color: "white",
          }}
          onClick={handleClose}
        />
      </div>

      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{
          borderBottom: "1px dotted lightgray",
          paddingBottom: "20px",
          margin: "0 80px",
          marginTop: "16px",
          marginBottom: "16px",
        }}
      >
        <Step style={{ fontSize: "40px", minWidth: "80px" }}>
          <StepLabel
            StepIconComponent={() => (
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  border: `2px solid #4C4C4C`,
                  display: "flex",
                  justifyContent: "center",
                  border: `2px solid ${
                    activeStep === 0 || activeStep === 1 ? "#4C4C4C" : "#979797"
                  }`,
                  alignItems: "center",
                  fontSize: "18px",
                  color: activeStep === 0 ? "#fff" : "#4C4C4C",
                  backgroundColor: activeStep === 0 ? "#4C4C4C" : "transparent",
                }}
              >
                1
              </div>
            )}
          >
            Company Details
          </StepLabel>
        </Step>
        <Step style={{ fontSize: "40px", minWidth: "80px" }}>
          <StepLabel
            StepIconComponent={() => (
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  border: `2px solid #4C4C4C`,
                  display: "flex",
                  justifyContent: "center",
                  border: `2px solid ${activeStep < 2 ? "#4C4C4C" : "#979797"}`,
                  alignItems: "center",
                  fontSize: "18px",
                  color: activeStep === 1 ? "#fff" : "#4C4C4C",
                  backgroundColor: activeStep === 1 ? "#4C4C4C" : "transparent",
                }}
              >
                2
              </div>
            )}
          >
            Company Documents
          </StepLabel>
        </Step>
        <Step style={{ fontSize: "40px", minWidth: "80px" }}>
          <StepLabel
            StepIconComponent={() => (
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  border: `2px solid #4C4C4C`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "18px",
                  color: activeStep === 2 ? "#fff" : "#4C4C4C",
                  backgroundColor: activeStep === 2 ? "#4C4C4C" : "transparent",
                }}
              >
                3
              </div>
            )}
          >
            Confirm Details
          </StepLabel>
        </Step>
      </Stepper>
      <DialogContent
        style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
      >
        <Loader open={apiProcessing.loader} message={apiProcessing.message} />
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            justifyItems: "center",
            margin: "0 80px",
          }}
        >
          {activeStep === 0 ? (
            <>
              <div>
                <div>
                  <h3>Company Details</h3>
                  <div
                    style={{
                      borderBottom: "1px dotted lightgray",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                      paddingBottom: "40px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                        }}
                      >
                        <label>
                          Company Name<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="companyName"
                          placeholder="Enter Company Name"
                          value={formData.companyName}
                          onChange={handleChange}
                          style={{ textTransform: "uppercase",backgroundColor:"#f3f3f3" }}
                        />
                        {errors.companyName && (
                          <p style={{ color: "red" }}>{errors.companyName}</p>
                        )}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label>
                          Owner Name<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="ownerName"
                          placeholder="Enter Owner Name"
                          value={formData.ownerName}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.ownerName && (
                          <p style={{ color: "red" }}>{errors.ownerName}</p>
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "16px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                        }}
                      >
                        <label>
                          Company Mailing Address
                          <a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Enter Company Email"
                          value={formData.email}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.email && (
                          <p style={{ color: "red" }}>{errors.email}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label>
                          Phone Number<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="number"
                          name="phoneNumber"
                          placeholder="Enter Phone Number"
                          maxLength={10}
                          value={formData.phoneNumber}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.phoneNumber && (
                          <p style={{ color: "red" }}>{errors.phoneNumber}</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "16px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                        }}
                      >
                        <label>
                          GST No.<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="gstNo"
                          placeholder="Enter GST No"
                          value={formData.gstNo ? formData.gstNo.toUpperCase() : ''}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.gstNo && (
                          <p style={{ color: "red" }}>{errors.gstNo}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <label>
                          PAN No<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="panNo"
                          placeholder="Enter PAN No"
                   
                          value={formData.panNo ? formData.panNo.toUpperCase() : ''}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.panNo && (
                          <p style={{ color: "red" }}>{errors.panNo}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <h3>Contact Person Details</h3>
                  <div
                    style={{
                      borderBottom: "1px dotted lightgray",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                      paddingBottom: "40px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                        }}
                      >
                        <label>Name</label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Enter Your Name"
                          value={formData.name}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.name && (
                          <p style={{ color: "red" }}>{errors.name}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label>
                          Designation<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="designation"
                          placeholder="Enter Your Designation"
                          value={formData.designation}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.designation && (
                          <p style={{ color: "red" }}>{errors.designation}</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "16px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                        }}
                      >
                        <label>
                          Mail id<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="email"
                          name="mailId"
                          placeholder="Enter Your Mail Id"
                          value={formData.mailId}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.mailId && (
                          <p style={{ color: "red" }}>{errors.mailId}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label>
                          Phone Number<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="number"
                          name="cphoneNumber"
                          maxLength={10}
                          placeholder="Enter Your Contact Phone Number"
                          value={formData.cphoneNumber}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.cphoneNumber && (
                          <p style={{ color: "red" }}>{errors.cphoneNumber}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <h3>Address</h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "20px",
                      paddingBottom: "40px",
                    }}
                  >
                    <label style={{ textAlign: "left" }}>
                      {" "}
                      Office Address<a style={{ color: "red" }}>*</a>
                    </label>
                    <textarea
                      type="text"
                      name="officeAddress"
                      placeholder="Enter Your Office Address"
                      value={formData.officeAddress}
                      onChange={handleChange}
                      style={{ width: "100%" ,backgroundColor:"#f3f3f3"}}
                    />
                    {errors.officeAddress && (
                      <p style={{ color: "red" }}>{errors.officeAddress}</p>
                    )}
                    <div style={{ display: "flex", marginTop: "16px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                        }}
                      >
                        <label>
                          Country<a style={{ color: "red" }}>*</a>
                        </label>
                        <select
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        >
                          <option value="">Select Country</option>
                          <option valie="India">India</option>
                        </select>
                        {errors.country && (
                          <p style={{ color: "red" }}>{errors.country}</p>
                        )}
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label>
                          City / State<a style={{ color: "red" }}>*</a>
                        </label>
                        <select
                          name="cityState"
                          value={formData.cityState}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        >
                          {formData.country !== "" &&(
                            <>
                          <option value="">Select City / State</option>
                          {indiaStates.map((state) => (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          ))}
                          </>
                        )}
                        </select>
                        {errors.cityState && (
                          <p style={{ color: "red" }}>{errors.cityState}</p>
                        )}
                      </div>
                    </div>
                    <div style={{ display: "flex", marginTop: "16px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                        }}
                      >
                        <label>
                          PinCode<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="number"
                          name="pinCode"
                          placeholder="Enter Your PinCode"
                          maxLength={6}
                          value={formData.pinCode}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.pinCode && (
                          <p style={{ color: "red" }}>{errors.pinCode}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label>
                          Industry<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="industry"
                          placeholder="Enter Your Industry Type"
                          value={formData.industry}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.industry && (
                          <p style={{ color: "red" }}>{errors.industry}</p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "16px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginRight: "80px",
                        }}
                      >
                        <label>
                          Website URL<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="url"
                          name="websiteURL"
                          placeholder="Enter Your Website URL"
                          value={formData.websiteURL}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.websiteURL && (
                          <p style={{ color: "red" }}>{errors.websiteURL}</p>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {" "}
                        <label>
                          Description<a style={{ color: "red" }}>*</a>
                        </label>
                        <input
                          type="text"
                          name="description"
                          placeholder="Enter Your Description"
                          value={formData.description}
                          onChange={handleChange}
                          style={{backgroundColor:"#f3f3f3"}}
                        />
                        {errors.description && (
                          <p style={{ color: "red" }}>{errors.description}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : activeStep === 1 ? (
            <>
              <section
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  columnGap: "200px",
                  rowGap: "6px",
                  width: "83.333333%",
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              >
                <div>
                  <label
                    htmlFor="GSTNO"
                    style={{
                      textAlign: "start",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  >
                    GST No :<a style={{ color: "red" }}>*</a>
                  </label>
                  <br />
                  <input
                    type="file"
                    id="GSTNO"
                    name="GSTNO"
                    accept=".pdf"
                    className="form-control"
                    style={{
                      width: "300px",
                      fontSize: "12px",
                      backgroundColor:"#f3f3f3"
                    }}
                    onChange={handleFileChange}
                  />
                  {errors.GSTNO && (
                    <p style={{ color: "red" }}>{errors.GSTNO}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="CPAN"
                    style={{
                      textAlign: "start",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  >
                    Company PAN Number :<a style={{ color: "red" }}>*</a>
                  </label>
                  <br />
                  <input
                    type="file"
                    id="CPAN"
                    name="CPAN"
                    accept=".pdf"
                    className="form-control "
                    style={{
                      width: "300px",
                      fontSize: "12px",
                       backgroundColor:"#f3f3f3"
                    }}
                    onChange={handleFileChange}
                  />
                  {errors.CPAN && <p style={{ color: "red" }}>{errors.CPAN}</p>}
                </div>
                <div>
                  <label
                    htmlFor="CTAN"
                    style={{
                      textAlign: "start",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  >
                    Company TAN Number :<a style={{ color: "red" }}>*</a>
                  </label>{" "}
                  <br />
                  <input
                    type="file"
                    id="CTAN"
                    name="CTAN"
                    accept=".pdf"
                    className="form-control "
                    style={{
                      width: "300px",
                      fontSize: "12px",
                    }}
                    onChange={handleFileChange}
                  />
                  {errors.CTAN && <p style={{ color: "red" }}>{errors.CTAN}</p>}
                </div>
                <div>
                  <label
                    htmlFor="agreementDocument"
                    style={{
                      textAlign: "start",
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }}
                  >
                    Agreement Document :<a style={{ color: "red" }}>*</a>
                  </label>
                  <br />
                  <input
                    type="file"
                    id="agreementDocument"
                    name="agreementDocument"
                    accept=".pdf"
                    className="form-control "
                    style={{
                      width: "300px",
                      fontSize: "12px",
                       backgroundColor:"#f3f3f3"
                    }}
                    onChange={handleFileChange}
                  />
                  {errors.agreementDocument && (
                    <p style={{ color: "red" }}>{errors.agreementDocument}</p>
                  )}
                </div>
                {formData.GSTNO !== null &&
                  formData.CPAN !== null &&
                  formData.CTAN !== null &&
                  formData.agreementDocument !== null &&
                  isBackButtonClicked && (
                    <p style={{ color: "gray", fontSize: "12px" }}>
                      "Note: Files have already been added. If you want to
                      change the files, you can upload new ones."
                    </p>
                  )}
              </section>
            </>
          ) : activeStep === 2 ? (
            <>
              <div style={{ marginLeft: "10px", marginRight: "80px" }}>
                <div
                  style={{
                    borderBottom: "1px dotted lightgray",
                    paddingBottom: "32px",
                    marginBottom: "16px",
                  }}
                >
                  <h3>Company Details</h3>
                  <div style={{ marginTop: "16px" }}>
                    {[
                      { label: "Company Name", value: formData.companyName },
                      { label: "Owner Name", value: formData.ownerName },
                      {
                        label: "Company Mailing Address",
                        value: formData.email,
                      },
                      { label: "Phone Number", value: formData.phoneNumber },
                      { label: "GST No", value: formData.gstNo },
                      { label: "PAN No", value: formData.panNo },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: "600", flex: "1" }}>
                          {field.label}
                        </span>
                        <span style={{ margin: "0 8px" }}>:</span>
                        <span style={{ flex: "2" }}>{field.value}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    borderBottom: "1px dotted lightgray",
                    paddingBottom: "32px",
                    marginBottom: "16px",
                  }}
                >
                  <h3>Contact Person Details</h3>
                  <div style={{ marginTop: "16px" }}>
                    {[
                      { label: "Name", value: formData.name },
                      { label: "Designation", value: formData.designation },
                      { label: "Mail ID", value: formData.mailId },
                      { label: "Phone Number", value: formData.cphoneNumber },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: "600", flex: "1" }}>
                          {field.label}
                        </span>
                        <span style={{ margin: "0 8px" }}>:</span>
                        <span style={{ flex: "2" }}>{field.value}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    borderBottom: "1px dotted lightgray",
                    paddingBottom: "32px",
                    marginBottom: "16px",
                  }}
                >
                  <h3>Address</h3>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "16px",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontWeight: "600", flex: "1" }}>
                      Office Address
                    </span>
                    <span style={{ margin: "0 8px" }}>:</span>
                    <span style={{ flex: "2" }}>{formData.officeAddress}</span>
                  </div>
                  <div style={{ marginTop: "16px" }}>
                    {[
                      { label: "Country", value: formData.country },
                      { label: "City / State", value: formData.cityState },
                      { label: "PinCode", value: formData.pinCode },
                      { label: "Industry", value: formData.industry },
                      { label: "Website URL", value: formData.websiteURL },
                      { label: "Description", value: formData.description },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: "600", flex: "1" }}>
                          {field.label}
                        </span>
                        <span style={{ margin: "0 8px" }}>:</span>
                        <span style={{ flex: "2" }}>{field.value}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div style={{ paddingBottom: "32px", marginBottom: "16px" }}>
                  <h3>Company Documents</h3>
                  <div style={{ marginTop: "16px" }}>
                    {[
                      {
                        label: "GST No",
                        value: formData.GSTNO
                          ? formData.GSTNO.name
                          : "Not Uploaded",
                      },
                      {
                        label: "Company PAN Number",
                        value: formData.CPAN
                          ? formData.CPAN.name
                          : "Not Uploaded",
                      },
                      {
                        label: "Company TAN Number",
                        value: formData.CTAN
                          ? formData.CTAN.name
                          : "Not Uploaded",
                      },
                      {
                        label: "Agreement Document",
                        value: formData.agreementDocument
                          ? formData.agreementDocument.name
                          : "Not Uploaded",
                      },
                    ].map((field, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontWeight: "600", flex: "1" }}>
                          {field.label}
                        </span>
                        <span style={{ margin: "0 8px" }}>:</span>
                        <span style={{ flex: "2" }}>{field.value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "32px",
            }}
          >
            <button
              type="button"
              disabled={activeStep === 0}
              onClick={handleBack}
              style={{
                backgroundColor: "#f97316",
                color: "white",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                borderRadius: "0.25rem",
                cursor: "pointer",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#c2410c")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#f97316")
              }
            >
              Back
            </button>
            <button
              type="button"
              onClick={handleNext}
              style={{
                backgroundColor: "#f97316",
                color: "white",
                paddingTop: "8px",
                paddingBottom: "8px",
                paddingLeft: "16px",
                paddingRight: "16px",
                borderRadius: "0.25rem",
                cursor: "pointer",
              }}
            >
              {activeStep === 2 ? "Finish" : "Next"}
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default AddCompanyDialog;
