import React, { useState, useEffect } from "react";
import ToggleSubModuleSwitch from "./ToggleSubModuleSwitch";
import SubModuleModal from "../Users/SubModuleModal";

const getPageType = (label) => {
  if (label === "Drawing") {
    return "drawingDetails";
  } else if (label === "USER") {
    return "userDetails";
  } else {
    return "";
  }
};

const ToggleSwitchWithModules = ({
  label,
  isChecked,
  selectedSiteId,
  onToggle,
  selectedUser,
  setSelectedUser,
  user,
}) => {
  const drawingPages = {
    "Architecture To Ro": "architectureToRo",
    "Ro To Sitelevel": "roToSite",
    "Site To Sitelevel": "siteToSite",
  };

  const userPages = {
    employee: "employee",
    profile: "profile",
    organizationChart: "organizationChart",
  };

  const pageType = getPageType(label);

  const modules = selectedUser?.modules?.[pageType] || {};

  const [showSubModuleModal, SetShowSubModuleModal] = useState(false);
  const [selectedSubModule, setSelectedSubModule] = useState(null);

  const handleIconClick = (subModuleKey) => {
    setSelectedSubModule(subModuleKey);
    SetShowSubModuleModal(true);
  };

  const handleCloseSubModal = () => {
    SetShowSubModuleModal(false);
    setSelectedSubModule(null);
  };

  useEffect(()=>{
    console.log(selectedUser)
  },[selectedUser])

  return (
    <div
      className="toggle-switch-with-modules"
      style={{ backgroundColor: "transparent", border: "none" }}
    >
      <div className="row">
        <div className="col-sm-6">
          <span>{label}</span>
        </div>
        <div
          className="col-sm-6"
          style={{ marginLeft: "150px", marginTop: "-25px" }}
        >
          <label className="switch">
            <input type="checkbox" checked={isChecked} onChange={onToggle} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>

      {pageType && Object.keys(modules).length > 0 && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            {Object.entries(
              pageType === "drawingDetails" ? drawingPages : userPages
            ).map(([title, value]) => (
              <ToggleSubModuleSwitch
                key={value}
                isChecked={modules[value] || false}
                label={title}
                id={value}
                onToggle={() => {
                  setSelectedUser((prevData) => ({
                    ...prevData,
                    modules: {
                      ...prevData.modules,
                      [pageType]: {
                        ...prevData.modules[pageType],
                        [value]: !prevData.modules[pageType][value],
                      },
                    },
                  }));
                }}
                onIconClick={() => handleIconClick(value)}
                style={{ backgroundColor: "transparent", border: "none" }}
              />
            ))}
          </div>
        </div>
      )}

      {showSubModuleModal && (
        <SubModuleModal
          selectedSiteId={selectedSiteId}
          show={showSubModuleModal}
          onHide={handleCloseSubModal}
          subModuleKey={selectedSubModule}
          pageType={pageType}
          user={user}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}
    </div>
  );
};

export default ToggleSwitchWithModules;
