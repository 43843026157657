import React from "react";

const DrawingTableWrapper = (props) => {
  const { tableBody, tableHead } = props;

  return (
    <div style={{ maxHeight: "300px" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          tableLayout: "fixed",
        }}
      >
        <thead
          style={{
            fontSize: "12px",
            backgroundColor: "#4a4a4a",
            color: "#fff",
          }}
        >
          {tableHead}
        </thead>
      </table>
      <div style={{ maxHeight: "280px", overflowY: "auto" }}>
        <table
          style={{
            width: "100%",
            borderCollapse: "seperate",
            tableLayout: "fixed",
            borderSpacing: "0 10px",
          }}
        >
          {tableBody}
        </table>
      </div>
    </div>
  );
};

export default DrawingTableWrapper;
