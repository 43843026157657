import React, { useState, useEffect } from "react";
import ToggleSwitch from "../Sites/ToggleSwitch";
import "./users.css";
import { WebApimanager } from "../../WebApiManager";

const subModuleDetails = {
  architectureToRoDetails: {
    analysis: "Analysis",
    drawing: "Drawing",
    pending: "Pending",
    register: "Register",
    rfi: "RFI",
  },
  roToSiteDetails: {
    analysis: "Analysis",
    drawing: "Drawing",
    pending: "Pending",
    register: "Register",
    rfi: "RFI",
  },
  siteToSiteDetails: {
    drawing: "Drawing",
    pending: "Pending",
  },
  employee: {},
  profile: {},
  organizationChart: {},
};

const SubModuleModal = ({
  show,
  onHide,
  subModuleKey,
  pageType,
  selectedSiteId,
  selectedUser,
  setSelectedUser,
  user,
}) => {
  const [modulesState, setModulesState] = useState(selectedUser?.modules || {}); // Initialize with selectedUser's modules

  const webApi = new WebApimanager();
  const userId = user?._id;
  const subKey = subModuleKey + "Details";

  useEffect(() => {
    console.log(`Loaded subModuleKey: ${subModuleKey}`);
  }, [subModuleKey]);

  // Handle toggle switches for the modules
  const handleToggle = (detailKey, nestedKey = null) => {
    setModulesState((prevState) => {
      const updatedModules = { ...prevState };

      // Update the modules in state
      updatedModules[pageType] = {
        ...updatedModules[pageType],
        [subKey]: {
          ...(updatedModules[pageType][subKey] || {}), // Fallback to an empty object if it doesn't exist
          [detailKey]: modulesState[pageType][subKey]?.hasOwnProperty(detailKey)
            ? !modulesState[pageType][subKey][detailKey]
            : true,
        },
      };

      return updatedModules;
    });
  };

  // Save the updated modules data
  const handleSave = async () => {
    try {
      const response = await webApi.put(`api/users/updateDetails/${userId}`, {
        modules: modulesState,
        siteId: selectedSiteId,
      });
      console.log("Modules updated successfully:", response);
      setSelectedUser((prevUser) => ({
        ...prevUser,
        modules: modulesState,
      }));
      onHide();
    } catch (error) {
      console.error("Error updating modules:", error);
    }
  };

  const details = subModuleDetails[subKey];
  const modules = modulesState[pageType] || {};

  return (
    show && (
      <div className="modal-container">
        <div className="modal-content submoduleContent">
          <div className="modal-header useModuleModal">
            <p>{`${subModuleKey} Details`}</p>
            <span className="close-btn toggleBtn" onClick={onHide}>
              &times;
            </span>
          </div>

          <div className="modal-body modalBody">
            {details ? (
              Object.entries(details).map(([detailKey, detailLabel], index) => (
                <div
                  key={detailKey}
                  className={`toggle-wrapper toggle-${index}`}
                >
                  <ToggleSwitch
                    label={detailLabel}
                    isChecked={modules[subKey][detailKey]}
                    onToggle={() => handleToggle(detailKey)}
                  />
                </div>
              ))
            ) : (
              <p>No details available for this submodule.</p>
            )}
          </div>

          <div className="modal-footer FooterBtnUser">
            <button className="cancleBtnUsers" onClick={onHide}>
              CANCEL
            </button>
            <button className="saveBtnUsers" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default SubModuleModal;
