import React from "react";
import { AiOutlinePaperClip } from "react-icons/ai";

const TextReviewTile = (props) => {
  return (
    <>
      <span hidden={props.hidden} className={"break-words " + props.className}>
        <p
          className={
            "text-gray-700 text-sm font-semibold " + props.labelClassName
          }
        >
          {props.title || "-"}
        </p>
        <p className={"text-neutral-400 " + props.dataClassName}>
          {props.body || "-"}
        </p>
      </span>
    </>
  );
};

export default TextReviewTile;

export const FileReviewTile = (props) => {
  return (
    <>
      <span hidden={props.hidden}>
        <p className="text-gray-700 text-sm font-semibold">
          {props.title || "-"}
        </p>
        <p className="text-neutral-400 bg-neutral-100 p-2 flex rounded-lg w-fit">
          <AiOutlinePaperClip className="w-5 h-6 mr-1" />
          {props.body || "-"}
        </p>
      </span>
    </>
  );
};

export const MultiFileReviewTile = (props) => {
  return (
    <>
      <span hidden={props.hidden}>
        <p className="text-gray-700 text-sm font-semibold">
          {props.title || "-"}
        </p>
        <p className="text-neutral-400 bg-neutral-100 p-2 flex rounded-lg w-fit">
          <AiOutlinePaperClip className="w-5 h-6 mr-1" />
          {props.body.map((file) => file.name).join(", ") || "-"}
        </p>
      </span>
    </>
  );
};

export const DetailsTile = (props) => {
  const containerClasses = `${props.className} grid grid-cols-2 gap-4`;
  const labelClasses = `${props.labelClassName} `;
  const dataClasses = `${props.dataClassName} `;

  return (
    <div hidden={props.hidden} className={containerClasses}>
      <span className={labelClasses}>{props.label}</span>
      <span className={dataClasses}>{props.data}</span>
    </div>
  );
};

export const DetailsTileTwoCol = (props) => {
  return (
    <>
      <div className={props.className + " grid grid-cols-2 items-center gap-8"}>
        <span className={props.labelClassName}>{props.label}</span>
        <span className={props.dataClassName}>{props.data}</span>
      </div>
    </>
  );
};
