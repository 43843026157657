import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dialog, Stepper, StepLabel, Step, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { WebApimanager } from "../../../WebApiManager";
import * as Icon from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import SearchableDropdown from "../../../Widgets/SearchableDropdown";
import StepConnector from "@mui/material/StepConnector";
import styles from "./AddnewPnm.module.css"
import FormHeadings from "../PNMAssets/FormHeadings";
const AddnewPnm = ({ newPnMStyle }) => {
  let webApi = new WebApimanager();
  const getDaysCountOptions = (daysType) => {
    let options = [];

    if (daysType === "Week") {
      options = Array.from({ length: 54 }, (_, i) => i + 1);
    } else if (daysType === "Month") {
      options = Array.from({ length: 12 }, (_, i) => i + 1);
    } else if (daysType === "Days") {
      options = Array.from({ length: 31 }, (_, i) => i + 1);
    } else if (daysType === "Years") {
      options = Array.from({ length: 5 }, (_, i) => i + 1);
    }

    return options;
  };

  const [open, setOpen] = useState(false);
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [filteredNames, setFilteredNames] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [documentsData, setDocumentsData] = useState([]);
  const [docErrors,setDocErrors] = useState(Array(documentsData.length).fill(''))
  const [errorMessage,setErrorMessage] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({loader: false,message: "Loading...",});
  const [formData, setFormData] = useState({
    type: "",
    fuelRnot: "",
    assetCode:"",
    name:"",
    subCode:"",
    equipmentType:"",
    assetCode: "",
    makename: "",
    modelname: "",
    services:"",
    servicePeriodType: "",
    serviceHours: "",
    serviceDays: "",
    serviceMonths: "",
    serviceWeeks:"",
    kilometers: "",
    inspections: "",
    inspectionsPeriodType:"",
    inspectionHours: "",
    inspectionDays: "",
    inspectionMonths: "",
    inspectionWeeks:"",
    recycletimeperiod: "",
    engineRmotor: "",
    selectNo: "",
    
    OWN: "",
    oldPreviousServiceDate: "",
    oldPreviousPurchasePrice: "",
    oldPurchaseDate: "",
    oldinsuranceY: false,
    daysTypeoldinsurance: "",
    daysCountoldinsurance: "",
    oldwarrantyY: false,
    daysTypeoldwarranty: "",
    daysCountoldwarranty: "",
    oldguaranteeY: false,
    daysTypeoldguarantee: "",
    daysCountoldguarantee: "",
    newpurchaseDate: "",
    newpurchasePrice: "",
    newinsuranceY: false,
    daysTypenewinsurance: "",
    daysCountnewinsurance: "",
    newwarrantyY: false,
    daysTypenewwarranty: "",
    daysCountnewwarranty: "",
    newguaranteeY: false,
    daysTypenewguarantee: "",
    daysCountnewguarantee: "",
    oldrNew:"",
    hireVendorName:"",
    hireVendorNumber:"",
    hirePreviousServiceDate:"",
    daysTypeHireHowManyDays:"",
    daysCountHireHowManyDays:"",
    hireCharges:"",
    hirePrice:"",
    hireInsurance: false,
    daysTypeHireInsurance:"", 
    daysCountHireInsurance:"",
    hireGuarantee:false,
    daysTypeHireGuarantee:"",
    daysCountHireGuarantee:"",
    document:documentsData,
    hireWarranty:false,
    daysTypeHireWarranty:"",
    daysCountHireWarranty:"",

    queries: [],
    
  });

  // 
  useEffect(()=>{
    setFormData((prev)=>({...prev,document:documentsData}))
   
  },[documentsData])
  

  const [inputFields, setInputFields] = useState([{ name: "" }]);
  const [id,setId] = useState('')
  const [equipment, setEquipment] = useState('')
  const handleAddFields = () => {
    setInputFields([...inputFields, { name: "" }]);
  };
 
  // const changeHandler = (e, index) => {
  //   const { name, value, type, files } = e.target;

  //   console.log(`Handling change for index ${index}:`, {
  //     name,
  //     value,
  //     type,
  //     files,
  //   });
   
    
  //     // Handle input changes for document number and dates
  //     setDocumentsData((prevData) => {
  //       const newData = [...prevData];
  //       newData[index] = {
  //         ...newData[index],
  //         [name]: type === 'file' ? files[0] : value, // Save file for file input
  //       };
  //       return newData;
  //     });
   
  //   // setDocumentsData((prevData) => {
  //   //   const newData = [...prevData];

  //   //   if (type === "file") {
  //   //     // Handle file input separately if needed
  //   //     newData[index] = {
  //   //       ...newData[index],
  //   //       [name]: files[0] || null, // Store the file object or null if no file is selected
  //   //     };
  //   //   } else {
  //   //     newData[index] = {
  //   //       ...newData[index],
  //   //       [name]: value,
  //   //     };
  //   //   }

  //   //   return newData;
  //   // });
  // };
  const changeHandler = (e, index) => {

    const { name, value, type, files } = e.target;
console.log(name,value,"changeHandler")
    const newDocumentsData = [...documentsData];
    const documentName = newDocumentsData[index]?.documentName || filteredAssets.find((asset) => asset._id === id)?.documents[index]?.documentName || "";
    if (type === "file") {
      e.stopPropagation()
      newDocumentsData[index] = {
        ...newDocumentsData[index],
        file: files[0]  
        
      };
    } else {

      newDocumentsData[index] = {
        ...newDocumentsData[index],
        [name.split('_')[0]]: value,
        documentName: documentName 
      };
    }
  
    setDocumentsData(newDocumentsData);
  };
  
  // useEffect(() => {
  //   if (formData.assetCode) {
  //     const asset = filteredAssets.find(
  //       (asset) => asset._id === formData.assetCode
  //     );
  //     if (asset) {
  //       const initialDocumentsData = asset.documents.map(() => ({}));
  //       setDocumentsData(initialDocumentsData);
  //     }
  //   }
  // }, [formData.assetCode, filteredAssets]);
  useEffect(() => {
    if (formData.assetCode) {
      const asset = filteredAssets.find(
        (asset) => asset._id === formData.assetCode
      );
      
      if (asset) {
        const initialDocumentsData = asset.documents.map((doc) => ({
          documentName: doc.documentName,  // Include documentName here
          documentNo: "",                   // Initialize empty fields
          regDate: "",
          expDate: "",
          file: null,                      // Initialize file as null
        }));
        
        setDocumentsData(initialDocumentsData);
      }
      console.log(documentsData,"doc data");
    }
  }, [formData.assetCode, filteredAssets]);
  
  const currentAsset = filteredAssets.find(
    (asset) => asset._id === formData.assetCode
  );
  const documents = currentAsset?.documents || [];

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setInputFields(values);
    setFormData((prev) => ({
      ...prev,
      queries: values,
    }));
  };

  const [errors, setErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setShowForm(false);
      setFormData({});
    }, 2000);
    window.location.reload();
  };

  const validateDocuments = () => {
    let isValid = true;
    const newErrors = {};
  
    documentsData.forEach((doc, index) => {
      const formErrors = {};
  
      // Validate document number
      if (!doc.documentNo || doc.documentNo.trim() === "") {
        formErrors.documentNo = "Document Number is required";
        isValid = false;
      }
  
      // Validate registration date
      if (!doc.regDate) {
        formErrors.regDate = "Registration Date is required";
        isValid = false;
      }
  
      // Validate expiry date
      if (!doc.expDate) {
        formErrors.expDate = "Expiry Date is required";
        isValid = false;
      }
  
      // Validate file upload
      if (!doc.file) {
        formErrors.file = "File is required";
        isValid = false;
      }
  
      // Store errors for the specific document
      if (Object.keys(formErrors).length > 0) {
        newErrors[index] = formErrors;
      }
    });
  
    setDocErrors(newErrors);
    return isValid;
  };

  const validateForm1 = () =>{
    let formErrors = {};
    const nameRegex =/^[a-zA-Z]+(?: [a-zA-Z]+)*$/
    const requiredFields=[
      "type",
      "equipmentType",
      "assetCode",
      "fuelRnot",
      "makename",
      "modelname",
      "services",
      "inspections",
      "engineRmotor",
      "selectNo",

    ]
    requiredFields.forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
      if(formData[key] && key === "makename"){
        if(!nameRegex.test(formData[key])){
          formErrors[key] = "model name should be alphabets"
        } 
      }
    });
    if(formData.services==="Yes"){
      if(!formData.servicePeriodType){
        formErrors["servicePeriodType"]="servicePeriodType is required"
      } else {
        if(formData.servicePeriodType === "Weeks" && !formData.serviceWeeks)
          formErrors["serviceWeeks"] ="service Weeks is required"

        else if(formData.servicePeriodType === "Months" && !formData.serviceMonths)
          formErrors["serviceMonths"] ="service Months is required"

        else if(formData.servicePeriodType === "Hours" && !formData.serviceHours)
          formErrors["serviceHours"] ="service Hours is required"

        else if(formData.servicePeriodType === "Days" && !formData.serviceDays)
          formErrors["serviceDays"] ="service Days is required"

        else if((formData.servicePeriodType === "Kilometers" || formData.servicePeriodType === "Both Months & Kilometers") && !formData.kilometers)
          formErrors["kilometers"] ="kilometer is required"
        
      }
    }

    if(formData.inspections==="Yes"){
      if(!formData.inspectionsPeriodType){
        formErrors["inspectionsPeriodType"]="inspectionsPeriodType is required"
      } else {
        if(formData.inspectionsPeriodType === "Weeks" && !formData.inspectionWeeks)
          formErrors["inspectionWeeks"] ="inspection Weeks is required"

        else if(formData.inspectionsPeriodType === "Month" && !formData.inspectionMonths)
          formErrors["inspectionMonths"] ="inspection Months is required"

        else if(formData.inspectionsPeriodType === "Hours" && !formData.inspectionHours)
          formErrors["inspectionHours"] ="inspection Hours is required"

        else if(formData.inspectionsPeriodType === "Days" && !formData.inspectionDays)
          formErrors["inspectionDays"] ="inspection Days is required"

       
      }
    }
    if(formData.type === "own"){
      if(!formData.oldrNew){

        formErrors["oldrNew"]="oldRnew is Required"
      } else {
        if(formData.oldrNew ==="New" ){
          const newRequiredFields=[
            "newpurchaseDate",
            "newpurchasePrice",
          ]
          newRequiredFields.forEach((key) => {
            if (!formData[key]) {
              formErrors[key] = `${key} is required`;
            }
          });

          if(formData.newinsuranceY){
            if(!formData.daysTypenewinsurance){
              formErrors["daysTypenewinsurance"]="daysTypenewinsurance is required"
            } else {
              if(!formData.daysCountnewinsurance){
                formErrors["daysCountnewinsurance"]="daysCountnewinsurance is required"
              }
            }
          }
          if(formData.newguaranteeY){
            if(!formData.daysTypenewguarantee){
              formErrors["daysTypenewguarantee"]="daysTypenewguarantee is required"
            } else {
              if(!formData.daysCountnewguarantee){
                formErrors["daysCountnewguarantee"]="daysCountnewguarantee is required"
              }
            }
          }
          if(formData.newwarrantyY){
            if(!formData.daysTypenewwarranty){
              formErrors["daysTypenewwarranty"]="daysTypenewwarranty is required"
            } else {
              if(!formData.daysCountnewwarranty){
                formErrors["daysCountnewwarranty"]="daysCountnewwarranty is required"
              }
            }
          }

        } else {
          const oldRequiredFields =[
            "oldPreviousServiceDate",
            "oldPreviousPurchasePrice",
            "oldPurchaseDate",
          ]
          oldRequiredFields.forEach((key) => {
            if (!formData[key]) {
              formErrors[key] = `${key} is required`;
            }
          });
          if(formData.oldinsuranceY){
            if(!formData.daysTypeoldinsurance){
              formErrors["daysTypeoldinsurance"]="daysTypeoldinsurance is required"
            } else {
              if(!formData.daysCountoldinsurance){
                formErrors["daysCountoldinsurance"]="daysCountoldinsurance is required"
              }
            }
          }
          if(formData.oldguaranteeY){
            if(!formData.daysTypeoldguarantee){
              formErrors["daysTypeoldguarantee"]="daysTypeoldguarantee is required"
            } else {
              if(!formData.daysCountoldguarantee){
                formErrors["daysCountoldguarantee"]="daysCountoldguarantee is required"
              }
            }
          }
          if(formData.oldwarrantyY){
            if(!formData.daysTypeoldwarranty){
              formErrors["daysTypeoldwarranty"]="daysTypeoldwarranty is required"
            } else {
              if(!formData.daysCountoldwarranty){
                formErrors["daysCountoldwarranty"]="daysCountoldwarranty is required"
              }
            }
          }

        }
      }
    } else {
      const hireRequiredFields =[
        "hireVendorName",
        "hireVendorNumber",
        "hirePreviousServiceDate",
        "hireCharges",
        "daysTypeHireHowManyDays",
    
      ]
      hireRequiredFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
        if(formData[key] && key === "hireVendorName"){
          if(!nameRegex.test(formData[key])){
            formErrors[key] = "Vendor name should be alphabets"
          } 
        }
      });

      if(formData.hireCharges && !formData.hirePrice){

        formErrors["hirePrice"]="hire Price is required"
      }
      if(formData.daysTypeHireHowManyDays && !formData.daysCountHireHowManyDays){
        formErrors["daysCountHireHowManyDays"]="daysCountHireHowManyDays is required"
      }

      if(formData.hireInsurance){
        if(!formData.daysTypeHireInsurance){
          formErrors["daysTypeHireInsurance"]="daysTypeHireInsurance is required"
        } else {
          if(!formData.daysCountHireInsurance){
            formErrors["daysCountHireInsurance"]="daysCountHireInsurance is required"
          }
        }
      }
      if(formData.hireGuarantee){
        if(!formData.daysTypeHireGuarantee){
          formErrors["daysTypeHireGuarantee"]="daysTypeHireGuarantee is required"
        } else {
          if(!formData.daysCountHireGuarantee){
            formErrors["daysCountHireGuarantee"]="daysCountHireGuarantee is required"
          }
        }
      }
      if(formData.hireWarranty){
        if(!formData.daysTypeHireWarranty){
          formErrors["daysTypeHireWarranty"]="daysTypeHireWarranty is required"
        } else {
          if(!formData.daysCountHireWarranty){
            formErrors["daysCountHireWarranty"]="daysCountHireWarranty is required"
          }
        }
      }

    }

    
    console.log(formErrors,"formErrors")
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  }
 
  const handleChange = (e) => {
    setErrors({});
    const { name, value, type, checked, files } = e.target;
    console.log("name:=>",name)
    console.log("checked:=>",value)

    // console.log(e.target.checked,"check")
    let newValue = value;
    if (type === "checkbox") {
      newValue = checked;
    }
    if (type === "file") {
      newValue = files[0];
    }

    // Additional logic for kilometers validation and filtered assets update
    if (name === "kilometers" && (value === "" || parseFloat(value) >= 0)) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
    }

    // Update filtered assets and names
    updateFilteredAssetsAndNames(name, newValue);

    // Set selected type
    if (name === "type") {
      setSelectedType(newValue);
    }
    if(name === "equipmentType"){
      setEquipment(value)
    }

   

    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find(
        (doc, ind) => doc.assetCode === value.split("/")[0]
      );
      if (assCode) {
        setId(assCode._id);
        
        

        setFormData((prevData) => ({
              ...prevData,
              [name]: value,
              ["subCode"]:assCode.subCode,
              ["name"]:value.split("/")[1],
              ["equipmentType"]:assCode.type
            }));
      }
    }

  };

  

  const updateFilteredAssetsAndNames = (fieldName, fieldValue) => {
    let updatedFilteredAssets = [...assetCodeData];
    let updatedFilteredNames = [...assetCodeData];

    if (fieldName === "type") {
      updatedFilteredAssets = assetCodeData.filter(
        (item) => item.type === fieldValue
      );
      updatedFilteredNames = assetCodeData.filter(
        (item) => item.type === fieldValue
      );
    }

    if (fieldName === "assetCode") {
      updatedFilteredNames = assetCodeData.filter(
        (item) => item.assetCode === fieldValue
      );
    }

    if (fieldName === "name") {
      updatedFilteredAssets = assetCodeData.filter(
        (item) => item.name === fieldValue
      );
    }

    setFilteredAssets(updatedFilteredAssets);
    setFilteredNames(updatedFilteredNames);
  };

 
 

  

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      const dilt=filteredAssets.find((asset)=>asset._id === id)
      
      console.log(dilt,"filt")
      
      setDocumentsData(
        Array.from(
          {
            length:
              filteredAssets.find((asset) => asset._id === id)
                ?.documents.length || 0,
          },
          () => ({
            documentNo: "",
            documentName: "",
            regDate: "",
            expDate: "",
            file: null,
          })
        )
      );
    }
    if (activeStep === 1) {
      setFormData((prev) => ({ ...prev, document: documentsData }));
      const filtDoc = filteredAssets
                        .find((asset) => asset._id === id)
      if(filtDoc.documents[0]!==''){
      if(!validateDocuments() ) return
      }
    }
 
    if (activeStep === 2) {
      
      handleSubmit();
    } else {
      if(activeStep === 0){
        if(!validateForm1()) return 
      }
      // else if(activeStep === 1){
      //   if(!validateForm2()) return
      // }
      setActiveStep((prevStep) => prevStep + 1);
      console.log(formData,"formDatastep0")
    }
  };

  useEffect(() => {
    console.log(formData.document, "documents");
  }, []);

  // const handleSubmit =async () => {
  //   const payload = {
  //     type: formData.type,
  //     assetCode: id,
  //     equipmentType:formData.equipmentType,
  //     fuelRnot: formData.fuelRnot,
  //     makeName: formData.makename,
  //     modelName: formData.modelname,
  //     engineRmotor: formData.engineRmotor,
  //     selectNo: formData.selectNo,
  //     type: formData.type,
  //     services: formData.services,
  //     ...formData.services ==="Yes" && {
  //     ...(formData.servicePeriodType === "hours" && { hours: formData.serviceHours }),
  //     ...(formData.servicePeriodType === "days" && { days: formData.serviceDays }),
  //     ...(formData.servicePeriodType === "months" && { months: formData.serviceMonths }),
  //     ...(formData.servicePeriodType === "weeks" && {weeks:formData.serviceWeeks}),
  //     ...(formData.servicePeriodType === "kilometers" && {
  //       kilometers: formData.kilometers,
  //     }),
  //     ...(formData.servicePeriodType === "Both Months&Kilometers" && {
  //       months: formData.months,
  //       kilometers: formData.kilometers,
  //     })},
  //     inspections: formData.inspections,
  //     ...(formData.inspections === "Yes" && {
  //       ...(formData.inspectionsPeriodType === "hours" && { hours: formData.inspectionHours }),
  //       ...(formData.inspectionsPeriodType === "days" && { days: formData.inspectionDays }),
  //       ...(formData.inspectionsPeriodType === "months" && { months: formData.inspectionMonths }),
  //     }),
      
  //     ...(formData.type === "hire" && {
  //       hireVendorName: formData.hireVendorName,
  //       hireVendorNumber:formData.hireVendorNumber,
  //       hirePreviousServiceDate: formData.hirePreviousServiceDate,
  //       ...(formData.hireInsurance && {
  //         hireInsurance:{
  //           daysType:formData.daysTypeHireInsurance,
  //           daysCount:formData.daysCountHireInsurance
  //         }
  //       }),
  //       ...(formData.hireGuarantee && {
  //         hireGuarantee:{
  //           daysType:formData.daysTypeHireGuarantee,
  //           daysCount:formData.daysCountHireGuarantee

  //         }
  //       }),
  //       ...(formData.hireWarranty && {
  //         hireWarranty:{
  //           daysType:formData.daysTypeHireWarranty,
  //           daysCount:formData.daysCountHireWarranty


  //         }
  //       }),
  //       daysTypeHireHowManyDays: formData.daysTypeHireHowManyDays,
  //       daysCountHireHowManyDays: formData.daysCountHireHowManyDays,
  //       hireCharges: formData.hireCharges,
  //       hirePrice: formData.hirePrice,
       
  //     }),
  //     own:formData.oldrNew,
  //     ...(formData.type === "own" && {
  //       ...(formData.oldrNew === "Old" && {
  //         oldPreviousServiceDate: formData.oldPreviousServiceDate,
  //         oldPreviousPurchasePrice: formData.oldPreviousPurchasePrice,
  //         oldPurchaseDate: formData.oldPurchaseDate,
  //         ...(formData.oldinsuranceY && {
  //           oldInsurance: {
  //             daysType:formData.daysTypeoldinsurance,
  //             daysCount:formData.daysCountoldinsurance
  //           },
  //         }),
  //         ...(formData.oldwarrantyY && {
  //           oldWarranty: {
  //             daysType:formData.daysTypeoldwarranty,
  //             daysCount:formData.daysCountoldwarranty
  //           },
  //         }),
  //         ...(formData.oldguaranteeY && {
  //           oldGuarantee:{
  //             daysType:formData.daysTypeoldguarantee,
  //             daysCount:formData.daysCountoldguarantee
  //           }
            
  //         }),
  //       }),
  //       ...(formData.oldrNew === "New" && {
  //         newPurchasePrice: formData.newpurchasePrice,
  //         newPurchaseDate: formData.newpurchaseDate,
  //         ...(formData.newinsuranceY && {
  //           newInsurance: {
  //             daysType: formData.daysTypenewinsurance,
  //             daysCount: formData.daysCountnewinsurance,
  //           },
  //           newWarranty: {
  //             daysType: formData.daysTypenewwarranty,
  //             daysCount: formData.daysCountnewwarranty,
  //           },
  //           newGuarantee: {
  //             daysType: formData.daysTypenewguarantee,
  //             daysCount: formData.daysCountnewguarantee,
  //           },
  //         }),
  //       }),
  //     }),
  //   };

  //   const response = await webApi.post("api/newPnm/", payload)
  //   console.log(response.data.data._id,"idsssss")
  //   if(response.status === 200 || response.status === 201){
  //     const id = response.data.data._id
  //   }
  //   // webApi
  //   //   .post("api/newPnm/", payload)
  //   //   .then((response) => {
  //   //     const data = response.data;
  //   //     console.log(response.data);
  //   //     console.log("Server Response:", response);
  //   //     console.log("Data:", data);
  //   //     const id = response.data.data._id;
  //   //     console.log(id,"iiiii");
  //   //     uploadDocuments(id);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.error("Error:", error);
  //   //   });
  // };
  
  const handleSubmit = async () => {
    const payload = {
      type: formData.type,
      assetCode: id,
      equipmentType:formData.equipmentType,
      fuelRnot: formData.fuelRnot,
      makeName: formData.makename,
      modelName: formData.modelname,
      engineRmotor: formData.engineRmotor,
      selectNo: formData.selectNo,
      type: formData.type,
      services: formData.services,
      ...formData.services ==="Yes" && {
      ...(formData.servicePeriodType === "hours" && { hours: formData.serviceHours }),
      ...(formData.servicePeriodType === "days" && { days: formData.serviceDays }),
      ...(formData.servicePeriodType === "months" && { months: formData.serviceMonths }),
      ...(formData.servicePeriodType === "weeks" && {weeks:formData.serviceWeeks}),
      ...(formData.servicePeriodType === "kilometers" && {
        kilometers: formData.kilometers,
      }),
      ...(formData.servicePeriodType === "Both Months&Kilometers" && {
        months: formData.months,
        kilometers: formData.kilometers,
      })},
      inspections: formData.inspections,
      ...(formData.inspections === "Yes" && {
        ...(formData.inspectionsPeriodType === "hours" && { hours: formData.inspectionHours }),
        ...(formData.inspectionsPeriodType === "days" && { days: formData.inspectionDays }),
        ...(formData.inspectionsPeriodType === "months" && { months: formData.inspectionMonths }),
      }),
      
      ...(formData.type === "hire" && {
        hireVendorName: formData.hireVendorName,
        hireVendorNumber:formData.hireVendorNumber,
        hirePreviousServiceDate: formData.hirePreviousServiceDate,
        ...(formData.hireInsurance && {
          hireInsurance:{
            daysType:formData.daysTypeHireInsurance,
            daysCount:formData.daysCountHireInsurance
          }
        }),
        ...(formData.hireGuarantee && {
          hireGuarantee:{
            daysType:formData.daysTypeHireGuarantee,
            daysCount:formData.daysCountHireGuarantee

          }
        }),
        ...(formData.hireWarranty && {
          hireWarranty:{
            daysType:formData.daysTypeHireWarranty,
            daysCount:formData.daysCountHireWarranty


          }
        }),
        daysTypeHireHowManyDays: formData.daysTypeHireHowManyDays,
        daysCountHireHowManyDays: formData.daysCountHireHowManyDays,
        hireCharges: formData.hireCharges,
        hirePrice: formData.hirePrice,
       
      }),
      ...(formData.type === "own" && {own:formData.oldrNew}),
      ...(formData.type === "own" && {
        ...(formData.oldrNew === "Old" && {
          oldPreviousServiceDate: formData.oldPreviousServiceDate,
          oldPreviousPurchasePrice: formData.oldPreviousPurchasePrice,
          oldPurchaseDate: formData.oldPurchaseDate,
          ...(formData.oldinsuranceY && {
            oldInsurance: {
              daysType:formData.daysTypeoldinsurance,
              daysCount:formData.daysCountoldinsurance
            },
          }),
          ...(formData.oldwarrantyY && {
            oldWarranty: {
              daysType:formData.daysTypeoldwarranty,
              daysCount:formData.daysCountoldwarranty
            },
          }),
          ...(formData.oldguaranteeY && {
            oldGuarantee:{
              daysType:formData.daysTypeoldguarantee,
              daysCount:formData.daysCountoldguarantee
            }
            
          }),
        }),
        ...(formData.oldrNew === "New" && {
          newPurchasePrice: formData.newpurchasePrice,
          newPurchaseDate: formData.newpurchaseDate,
          ...(formData.newinsuranceY && {
            newInsurance: {
              daysType: formData.daysTypenewinsurance,
              daysCount: formData.daysCountnewinsurance,
            },
            newWarranty: {
              daysType: formData.daysTypenewwarranty,
              daysCount: formData.daysCountnewwarranty,
            },
            newGuarantee: {
              daysType: formData.daysTypenewguarantee,
              daysCount: formData.daysCountnewguarantee,
            },
          }),
        }),
      }),
    };
    try {
      const response = await webApi.post("api/newPnm/", payload);
  
      console.log("Server Response:", response);
      const id = response.data.data._id;
      console.log(id,"idresponse");
    
      if (response.status === 201) {
        console.log(documentsData, "P documenbeforet data");
        if (documentsData) {
          const Documents = documentsData.map((doc) => ({
            [doc.documentName]: doc.file?.name,
            documentNo: doc.documentNo,
            regDate: doc.regDate,
            expDate: doc.expDate,
          }));
          const documnetData ={
            documents:[
            Documents
          ]
        
        };
        console.log(documentsData, "Processed document data");
          const uploadResponse = await webApi.put(`api/newPnm/upload/${id}`,documnetData);
          console.log("Upload response:", uploadResponse);
        }
  
        setApiProcessing({ loader: false, message: "" });
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
          console.log("Response data:", response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  const uploadDocuments = (id) => {
    const formDataPayload = new FormData();

    // Append documents
    Object.keys(formData).forEach((key) => {
      if (key.startsWith("document_") && formData[key] instanceof File) {
        formDataPayload.append("document", formData[key]); // Append as 'document'
      }
    });
    assetCodeData.documents.forEach((doc, index) => {
      formDataPayload.append(`document[${index}].documentNo`, doc.documentNo);
      formDataPayload.append(`document[${index}].regDate`, doc.regDate);
      formDataPayload.append(`document[${index}].expDate`, doc.expDate);
      if (doc.file) {
        formDataPayload.append(`document[${index}][file]`, doc.file);
      }
    });

    webApi
      .imagePut(`api/newPnm/upload/${id}`, formDataPayload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const data = response.data;
        console.log(response.data);
        console.log("Server Response:", response);
        console.log("Data:", data);
        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error appropriately
      });
  };

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      try {
        const response = await webApi.get(`api/pnms/assetCode`);
        const assetCodes = response.data.data.AssetCodes;
        setAssetCodeData(assetCodes);
      } catch (error) {
        console.error("Error fetching asset codes:", error);
        // Handle error fetching asset codes
      }
    };

    fetchAssetCodeData();
  }, []);

  return (
    <>
      <a
        className={`${
          newPnMStyle.display ? "text-[white]" : "text-[#E47331]"
        } no-underline`}
        href="#"
      >
        Add P&M
      </a>
      {newPnMStyle.display && (
        <div
          className={`mt-[50px] absolute left-[140px] right-[140px] bg-white `}
        >
          {!showForm && (
            <div
              style={{
                display: "flex",
                margin: !showForm ? "0px 45px 0px 50px" : "",
                justifyContent: "space-between",
                alignItems: "center",
                color: "black",
                backgroundColor: "white",
                position: "relative",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            >
              <div className={`flex justify-center items-center  mt-[50px]`}>
                <h3
                  style={{
                    color: showForm ? "white" : "var(--primary-color)",
                    fontSize: "20px",
                    margin: 0,
                  }}
                >
                  Add New P&M
                </h3>
              </div>

              <div
                className={`flex justify-center items-center ${
                  !showForm ? "mt-[50px]" : ""
                } `}
                style={{ position: "relative" }}
              >
                {!showForm && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "250px",
                        paddingRight: "5px",
                      }}
                    >
                      <input
                        placeholder="Search Asset Code List"
                        style={{
                          paddingLeft: "20px",
                          fontSize: "12px",
                          borderRadius: "6px",
                          border: "1px solid var(--primary-color)",
                          width: "250px",
                          height: "40px",
                        }}
                      />
                      <Icon.Search
                        style={{
                          size: "16px",
                          position: "absolute",
                          right: "6px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                    <Button
                      className="btn btn-primary"
                      style={{
                        height: "40px",
                        width: "150px",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "var(--primary-color)",
                        border: "none",
                        marginLeft: "50px",
                        marginRight: "50px",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setShowForm(true);
                        setOpen(true);
                      }}
                    >
                      <Icon.PlusCircle
                        size={16}
                        style={{ marginRight: "5px" }}
                      />{" "}
                      Add New
                    </Button>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            style={{
              padding: "0px 30px 0px 50px",
              height: "max-content",
              width: "100%",
            }}
          >
            {!showForm && (
              <table style={{ width: "100%", marginTop: "10px" }}>
                <thead
                  style={{
                    backgroundColor: "#F3F6F9",
                    textAlign: "start",
                    height: "45px",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        width: "2%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    ></th>
                    <th
                      style={{
                        width: "5%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      S.No
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Type
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      AssetCode
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Sub Code
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Name
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Make Name
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Fuel/Not
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Own/Hire
                    </th>
                    {/* <th style={{ width: '15%' }}>Documents</th> */}
                    <th
                      style={{
                        width: "15%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Next Inspection
                    </th>
                    <th
                      style={{
                        width: "10%",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>

                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      )}

      {showForm && (
        <Dialog
          open={open}
          disableEnforceFocus
          onClose={handleClose}
          maxWidth="md" // Controls max width, you can also use 'sm', 'lg', 'xl', or false for no max width
          fullWidth // Makes the dialog take up the full width specified by maxWidth
          PaperProps={{
            style: {
              height: "1553px", // Set your desired height here
              width: "1440px", // Set your desired width here
              maxWidth: "none",
            },
          }}
        >
          <div className="flex h-[50px] bg-[#4D4D4D] items-center">
            <h3 className="w-full text-white text-center"> Asset Code </h3>
            <RxCross1
              size={20}
              style={{
                marginLeft: "auto",
                cursor: "pointer",
                marginRight: "25px",
                color: "white",
              }}
              onClick={handleClose}
            />
          </div>
          {showForm && (
            <>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                connector={<StepConnector style={{ top: "20px" }} />}
                className="my-4 mx-10"
                style={{
                  borderBottom: "2px dotted gray",
                  paddingBottom: "60px",
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Step style={{ fontSize: "40px", minWidth: "80px" }}>
                  <StepLabel
                    StepIconComponent={() => (
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          border: `2px solid #4C4C4C`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "18px",
                          color: activeStep === 0 ? "#fff" : "#4C4C4C",
                          backgroundColor:
                            activeStep === 0 ? "#4C4C4C" : "transparent",
                        }}
                      >
                        1
                      </div>
                    )}
                  >
                    Equipment Type Details
                  </StepLabel>
                </Step>
                <Step style={{ fontSize: "40px", minWidth: "80px" }}>
                  <StepLabel
                    StepIconComponent={() => (
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          border: `2px solid ${
                            activeStep === 0 || activeStep === 1
                              ? "#4C4C4C"
                              : "#979797"
                          }`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "18px",
                          color: activeStep === 1 ? "#fff" : "#4C4C4C",
                          backgroundColor:
                            activeStep === 1 ? "#4C4C4C" : "transparent",
                        }}
                      >
                        2
                      </div>
                    )}
                  >
                    Equipment Make Details
                  </StepLabel>
                </Step>
                <Step style={{ fontSize: "40px", minWidth: "80px" }}>
                  <StepLabel
                    StepIconComponent={() => (
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          border: `2px solid ${
                            activeStep < 2 ? "#4C4C4C" : "#979797"
                          }`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "18px",
                          color: activeStep === 2 ? "#fff" : "#4C4C4C",
                          backgroundColor:
                            activeStep === 2 ? "#4C4C4C" : "transparent",
                        }}
                      >
                        3
                      </div>
                    )}
                  >
                    Equipment Make Confirm Details
                  </StepLabel>
                </Step>
              </Stepper>

              <form>
                {activeStep === 0 ? (
                  <>
                    <div className={styles.MainContainer}>
                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Type:"}/>
                              <div className={styles.InputContainer}>
                                <select className={styles.InputField} 
                                onChange={handleChange} 
                                value={formData.type}
                                name="type"
                                >
                                  <option value="" hidden>Select Type</option>
                                  <option value="own">Own</option>
                                  <option value="hire">Hire</option>  
                                </select>
                                <p className="text-danger absolute">{errors.type}</p>
                              </div>
                            </div>
                            <div className={styles.Content}>
                              <FormHeadings text={" Equipment Type:"}/>
                              <div className={styles.InputContainer}>
                                <select className={styles.InputField} onChange={handleChange} 
                                name="equipmentType"
                                value={formData.equipmentType}>
                                  <option value="" hidden>Select Equipment</option>
                                  <option value="Vehicle">Vehicle</option>
                                  <option value="Machinery">Machinery</option>  
                                  <option value="Power Tools">Power Tools</option>  
                                  <option value="Distribution Board">Distribution Board</option>  
                                  <option value="Batching Point">Batching Point</option>  
                                </select>
                                <p className="text-danger absolute">{errors.equipmentType}</p>
                              </div>
                            </div>
                        </div>
                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Name/Asset Code:"}/>
                              <div className={styles.InputContainer}>
                              {/* <SearchableDropdown
                                options={filteredAssets.map(
                                  (item) => item.assetCode
                                )}
                                onOptionSelect={(option) =>
                                  handleChange({
                                    target: {
                                      name: "assetCode",
                                      value: option,
                                    },
                                  })
                                }
                              /> */}
                              <select className={styles.InputField}
                              name="assetCode"
                              value={formData.assetCode}
                              onChange={handleChange}
                              >
                                <option value="" hidden>Select</option>
                                {assetCodeData.length && equipment ?
                            assetCodeData
                            .filter((item) => item.type.includes(equipment))
                              .map((item) => (
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              )):assetCodeData.map((item)=>(
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                              </select>
                              <p className="text-danger absolute">{errors.assetCode}</p>
                              </div>
                            </div>
                            <div className={styles.Content}>
                              <FormHeadings text={"Fuel/Not:"}/>
                              <div className={styles.InputContainer}>
                              <select
                            name="fuelRnot"
                            value={formData.fuelRnot}
                            onChange={handleChange}
                            className={styles.InputField}
                          >
                            <option value="">Select Type</option>
                            <option value="Petrol">Petrol</option>
                            <option value="Diesel">Diesel</option>
                            <option value="Power">Power</option>
                          </select>
                          <p className="text-danger absolute">{errors.fuelRnot}</p>
                              </div>
                            </div>
                        </div>
                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Make Name:"}/>
                              <div className={styles.InputContainer}>
                              <input
                            type="text"
                            className={styles.InputField}
                            name="makename"
                            placeholder="Enter Make Name"
                            value={formData.makename}
                            onChange={handleChange}
                            
                          />
                          <p className="text-danger">{errors.makename}</p>
                              </div>
                            </div>
                            <div className={styles.Content}>
                              <FormHeadings text={"Model:"}/>
                              <div className={styles.InputContainer}>
                              <input
                            type="text"
                            className={styles.InputField}
                            name="modelname"
                            placeholder="Enter Model Name"
                            value={formData.modelname}
                            onChange={handleChange}
                            
                          />
                          <p className="text-danger absolute">{errors.modelname}</p>
                          <p className="text-danger absolute">{errors.modelname}</p>
                              </div>
                            </div>
                        </div>
                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Inspection:"}/>
                              <div className={styles.InputContainer}>
                                <select className={styles.InputField} onChange={handleChange} 
                                value={formData.inspections} name="inspections">
                                  <option value="" hidden>Select Yes/No</option>
                                  <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Not Applicable">N/A</option>
                              </select>

                              <p className="text-danger absolute">
                                {errors.inspections}
                              </p>
                              </div>
                            </div>
                            {formData.inspections === "Yes" && <div className={styles.Content}>
                              <FormHeadings text={"Inspection Period:"}/>
                              <div className={styles.InputContainer} style={{display:"flex",position:"relative"}}>
                                <select 
                                name="inspectionsPeriodType"
                                value={formData.inspectionsPeriodType}
                                className={formData.inspectionsPeriodType?styles.SmallInputField:styles.InputField} onChange={handleChange}>
                                  <option value="" hidden>Select Inspection</option>
                                  <option value="Weeks">Weeks</option>
                                  <option value="Days">Days</option>  
                                  <option value="Hours">Hours</option>  
                                  <option value="Month">Months</option>  
                                </select>
                                {formData.inspectionsPeriodType && <select
                                    className={styles.SmallInputField}
                                      
                                      name={
                                        formData.inspectionsPeriodType === "Hours"
                                          ? "inspectionHours"
                                          : formData.inspectionsPeriodType === "Days"
                                          ? "inspectionDays"
                                          : formData.inspectionsPeriodType === "Weeks"
                                          ? "inspectionWeeks"
                                          : "inspectionMonths"
                                      }
                                      value={
                                        formData.inspectionsPeriodType === "Hours"
                                          ? formData.inspectionHours
                                          : formData.inspectionsPeriodType === "Days"
                                          ? formData.inspectionDays
                                          : formData.inspectionsPeriodType === "Weeks"
                                          ? formData.inspectionWeeks
                                          : formData.inspectionMonths
                                      }
                                      onChange={handleChange}
                                    >
                                      <option value="">
                                        {formData.inspectionsPeriodType === "Hours"
                                          ? "Select Hours"
                                          : formData.inspectionsPeriodType === "Days"
                                          ? "Select Days"
                                          : formData.inspectionsPeriodType === "Weeks"
                                          ? "Select Weeks"
                                          : "Select Months"}
                                      </option>
                                      {Array.from(
                                        {
                                          length:
                                            formData.inspectionsPeriodType === "Hours"
                                              ? 24
                                              : formData.inspectionsPeriodType === "Days"
                                              ? 7
                                              : formData.inspectionsPeriodType === "Weeks"
                                              ? 52
                                              : 12,
                                        },
                                        (_, i) => i + 1
                                      ).map((value) => (
                                        <option key={value} value={value}>
                                          {value}
                                        </option>
                                      ))}
                                </select>}

                                {formData.inspectionsPeriodType && <p className="text-danger absolute top-[100%]">
                                    {formData.inspectionsPeriodType === "Hours"
                                      ? errors.inspectionHours
                                      : formData.inspectionsPeriodType === "Days"
                                      ? errors.inspectionDays
                                      : formData.inspectionsPeriodType === "Weeks"
                                      ? errors.inspectionWeeks
                                      : errors.inspectionMonths}
                                  </p>}
                                {!formData.inspectionsPeriodType
                                 && <p className="text-danger absolute top-[100%]">{errors.inspectionsPeriodType}</p>}
                              </div>
                            </div>}
                        </div>
                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Service:"}/>
                              <div className={styles.InputContainer}>
                                <select className={styles.InputField} onChange={handleChange} 
                                value={formData.services} name="services">
                                  <option value="" hidden>Select Yes/No</option>
                                  <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Not Applicable">N/A</option>
                              </select>
                              <p className="text-danger">
                                {errors.services}
                              </p>
                              </div>
                            </div>
                            {formData.services === "Yes" && <div className={styles.Content}>
                              <FormHeadings text={"Service Period:"}/>
                              <div className={styles.InputContainer} style={{display:"flex",position:"relative"}}>
                                <select 
                                name="servicePeriodType"
                                value={formData.servicePeriodType}
                                className={formData.servicePeriodType?styles.SmallInputField:styles.InputField} onChange={handleChange}>
                                  <option value="" hidden>Select Inspection</option>
                                  <option value="Weeks">Weeks</option>
                                  <option value="Days">Days</option>  
                                  <option value="Hours">Hours</option>  
                                  <option value="Months">Months</option>
                                <option value="Kilometers">Kilometers</option>
                                <option value="Both Months & Kilometers">
                                  Both Months&Kilometers
                                </option>
                                </select>
                                

                                {formData.servicePeriodType && (
                                <>
                                  {formData.servicePeriodType === "Kilometers" ||
                                  formData.servicePeriodType ===
                                    "Both Months & Kilometers" ? (
                                    <input
                                      type="number"
                                      className={styles.SmallInputField}
                                      name="kilometers"
                                      min="0"
                                      placeholder="Enter No Of KM"
                                      value={formData.kilometers}
                                      onChange={handleChange}
                                    />
                                  ) : (
                                    <select
                                    className={styles.SmallInputField}
                                      
                                      name={
                                        formData.servicePeriodType === "Hours"
                                          ? "serviceHours"
                                          : formData.servicePeriodType === "Days"
                                          ? "serviceDays"
                                          : formData.servicePeriodType === "Weeks"
                                          ? "serviceWeeks"
                                          : "serviceMonths"
                                      }
                                      value={
                                        formData.servicePeriodType === "Hours"
                                          ? formData.serviceHours
                                          : formData.servicePeriodType === "Days"
                                          ? formData.serviceDays
                                          : formData.servicePeriodType === "Weeks"
                                          ? formData.serviceWeeks
                                          : formData.serviceMonths
                                      }
                                      onChange={handleChange}
                                    >
                                      <option value="">
                                        {formData.servicePeriodType === "Hours"
                                          ? "Select Hours"
                                          : formData.servicePeriodType === "Days"
                                          ? "Select Days"
                                          : formData.servicePeriodType === "Weeks"
                                          ? "Select Weeks"
                                          : "Select Months"}
                                      </option>
                                      {Array.from(
                                        {
                                          length:
                                            formData.servicePeriodType === "Hours"
                                              ? 24
                                              : formData.servicePeriodType === "Days"
                                              ? 7
                                              : formData.servicePeriodType === "Weeks"
                                              ? 52
                                              : 12,
                                        },
                                        (_, i) => i + 1
                                      ).map((value) => (
                                        <option key={value} value={value}>
                                          {value}
                                        </option>
                                      ))}
                                    </select>
                                  )}
                                  {formData.services === "Yes" && <p className="text-danger absolute top-[100%]">
                                    {formData.servicePeriodType === "Hours"
                                      ? errors.serviceHours
                                      : formData.servicePeriodType === "Days"
                                      ? errors.serviceDays
                                      : formData.servicePeriodType === "Weeks"
                                      ? errors.serviceWeeks
                                      : formData.servicePeriodType === "Kilometers" ||
                                        formData.servicePeriodType ===
                                          "Both Months & Kilometers"
                                      ? errors.kilometers
                                      : errors.serviceMonths}
                                  </p>}
                                </>
                              )}

                              {!formData.servicePeriodType &&  <p className="text-danger absolute top-[100%]">{errors.servicePeriodType}</p>}
                              </div>
                            </div>}
                        </div>

                        <div className={styles.ContentContainer}>
                            <div className={styles.Content}>
                              <FormHeadings text={"Engine/Motor:"}/>
                              <div className={styles.InputContainer}>
                                <select className={styles.InputField}  
                                 name="engineRmotor"
                                 value={formData.engineRmotor}
                                 onChange={handleChange}
                               >
                                 <option value="">Select Type</option>
                                 <option value="Engine">Engine</option>
                                 <option value="Motor">Motor</option>
                               </select>
                               <p className="text-danger absolute">
                                 {errors.engineRmotor}
                               </p>
                              </div>
                            </div>
                            <div className={styles.Content}>
                              <FormHeadings text={"No of Engine/Motor:"}/>
                              <div className={styles.InputContainer}>
                              <select
                                  name="selectNo"
                                  value={formData.selectNo}
                                  className={styles.InputField}
                                  onChange={handleChange}
                                >
                                  <option value="">Select No.of</option>
                                  <option value="Single">Single</option>
                                  <option value="Double">Double</option>
                                </select>
                                <p className="text-danger absolute ">
                                  {errors.selectNo}
                                </p>
                              </div>
                            </div>
                        </div>

                        {formData.type === "own" && <>
                        <h3 className={styles.Heading}>Equipment Details</h3>


                        {(!formData.oldrNew || formData.oldrNew ==="New") && <div className={styles.ContentContainer}>
                              <div className={styles.FullContent}>
                                <FormHeadings text={"New/Old:"}/>
                                <div className={styles.InputContainer}>
                                  <select className={styles.InputField} 
                                  onChange={handleChange} 
                                  value={formData.oldrNew}
                                  name="oldrNew"
                                  >
                                    <option value="" hidden>Select Type</option>
                                    <option value="">Select Old/New</option>
                                    <option value="Old">Old</option>
                                    <option value="New">New</option>
                                  </select>  
                                  <p className="text-danger absolute">{errors.oldrNew}</p>
                                </div>
                              </div>
                              
                          </div>}
                        
                        {formData.oldrNew === "New" && <>
                          

                           <div className={styles.ContentContainer}>
                              <div className={styles.Content}>
                                <FormHeadings text={"Purchase Date:"}/>
                                <div className={styles.InputContainer}>
                                <input
                                  type="date"
                                  name="newpurchaseDate"
                                  value={formData.newpurchaseDate}
                                  className={styles.InputField}
                                  onChange={handleChange}
                                />
                                <p className="text-danger absolute">
                                  {errors.newpurchaseDate}
                                </p>
                                </div>
                              </div>
                              <div className={styles.Content}>
                                <FormHeadings text={"Purchase Price:"}/>
                                <div className={styles.InputContainer}>
                                <input
                                  type="number"
                                  name="newpurchasePrice"
                                  placeholder="Enter Price"
                                  value={formData.newpurchasePrice}
                                  className={styles.InputField}
                                  onChange={handleChange}
                                />
                                <p className="text-danger absolute">
                                  {errors.newpurchasePrice}
                                </p>
                                </div>
                              </div>
                          </div>

                          <div className={styles.ContentContainer}>
                            <div className={styles.CheckBoxContent}>
                              <div className={styles.CheckBox}>
                                <input type="checkbox"
                                name="newinsuranceY"
                                checked={formData.newinsuranceY}
                                value={formData.newinsuranceY}
                                onChange={handleChange}
                                />
                                <FormHeadings text={"Insurance:"}/>
                              </div>
                              <div>
                              {formData.newinsuranceY && <>
                              <select
                                className={styles.SmallInputField}
                                name="daysTypenewinsurance"
                                value={
                                  formData.daysTypenewinsurance
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Type
                                </option>
                                <option value="Week">
                                  Week
                                </option>
                                <option value="Month">
                                  Month
                                </option>
                                <option value="Days">
                                  Days
                                </option>
                                <option value="Years">
                                  Years
                                </option>
                              </select>
                              {formData.daysTypenewinsurance && (
                                <select
                                className={styles.SmallInputField}
                                name="daysCountnewinsurance"
                                value={
                                  formData.daysCountnewinsurance
                                }
                                onChange={handleChange}
                                
                              >
                                <option value="">
                                  Select Count
                                </option>
                                {getDaysCountOptions(
                                  formData.daysTypenewinsurance
                                ).map((count) => (
                                  <option
                                    key={count}
                                    value={count}
                                  >
                                    {count}
                                  </option>
                                ))}
                              </select>
                              )}
                              {!formData.newinsuranceY?<p className="text-danger absolute">{errors.newinsuranceY}</p>:!formData.daysTypenewinsurance?<p className="text-danger absolute">{errors.daysTypenewinsurance}</p>:<p className="text-danger absolute">{errors.daysCountnewinsurance}</p>}
                              </>}
                              </div>

                              
                            </div>
                            <div className={styles.CheckBoxContent}>
                              <div className={styles.CheckBox}>
                                <input type="checkbox"
                                name="newguaranteeY"
                                checked={formData.newguaranteeY}
                                value={formData.newguaranteeY}
                                onChange={handleChange}
                                />
                                <FormHeadings text={"Guarantee:"}/>
                              </div>
                              <div>
                              {formData.newguaranteeY 
                              && <>
                              <select
                                className={styles.SmallInputField}
                                name="daysTypenewguarantee"
                                value={
                                  formData.daysTypenewguarantee
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Type
                                </option>
                                <option value="Week">
                                  Week
                                </option>
                                <option value="Month">
                                  Month
                                </option>
                                <option value="Days">
                                  Days
                                </option>
                                <option value="Years">
                                  Years
                                </option>
                              </select>
                              {formData.daysTypenewguarantee && (
                                <select
                                name="daysCountnewguarantee"
                                value={
                                  formData.daysCountnewguarantee
                                }
                                onChange={handleChange}
                              className={styles.SmallInputField}
                              >
                                <option value="">
                                  Select Count
                                </option>
                                {getDaysCountOptions(
                                  formData.daysTypenewguarantee
                                ).map((count) => (
                                  <option
                                    key={count}
                                    value={count}
                                  >
                                    {count}
                                  </option>
                                ))}
                              </select>
                              )}
                              {!formData.newguaranteeY?<p className="text-danger absolute">{errors.newguaranteeY}</p>:!formData.daysTypenewguarantee?<p className="text-danger absolute">{errors.daysTypenewguarantee}</p>:<p className="text-danger absolute">{errors.daysCountnewguarantee}</p>}
                              </>}
                              </div>
                            </div>
                            <div className={styles.CheckBoxContent}>
                              <div className={styles.CheckBox}>
                                <input 
                                type="checkbox"
                                name="newwarrantyY"
                                checked={formData.newwarrantyY}
                                value={formData.newwarrantyY}
                                onChange={handleChange}
                                />
                                <FormHeadings text={"Warranty:"}/>
                              </div>
                              <div>
                              {formData.newwarrantyY && <>
                              <select
                                className={styles.SmallInputField}
                                name="daysTypenewwarranty"
                                value={
                                  formData.daysTypenewwarranty
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Type
                                </option>
                                <option value="Week">
                                  Week
                                </option>
                                <option value="Month">
                                  Month
                                </option>
                                <option value="Days">
                                  Days
                                </option>
                                <option value="Years">
                                  Years
                                </option>
                              </select>
                              {formData.daysTypenewwarranty && (
                                <select
                                
                                className={styles.SmallInputField}
                                name="daysCountnewwarranty"
                                value={
                                  formData.daysCountnewwarranty
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Count
                                </option>
                                {getDaysCountOptions(
                                  formData.daysTypenewwarranty
                                ).map((count) => (
                                  <option
                                    key={count}
                                    value={count}
                                  >
                                    {count}
                                  </option>
                                ))}
                              </select>
                              )}
                              {!formData.newwarrantyY?<p className="text-danger absolute">{errors.newwarrantyY}</p>:!formData.daysTypenewwarranty?<p className="text-danger absolute">{errors.daysTypenewwarranty}</p>:<p className="text-danger absolute">{errors.daysCountnewwarranty}</p>}
                              </>}
                              </div>

                            </div>
                          </div>
                        </>}
                        {/* ----------------- */}
                        
                        {formData.oldrNew === "Old" && <>
                          <div className={styles.ContentContainer}>
                              <div className={styles.Content}>
                                <FormHeadings text={"New/Old:"}/>
                                <div className={styles.InputContainer}>
                                  <select className={styles.InputField} 
                                  onChange={handleChange} 
                                  value={formData.oldrNew}
                                  name="oldrNew"
                                  >
                                    <option value="" hidden>Select Type</option>
                                    <option value="">Select Old/New</option>
                                    <option value="Old">Old</option>
                                    <option value="New">New</option>
                                  </select>  
                                  <p className="text-danger absolute">{errors.oldrNew}</p>
                                </div>
                              </div>
                              <div className={styles.Content}>
                                <FormHeadings text={"Purchase Date:"}/>
                                <div className={styles.InputContainer}>
                                  <input
                                        type="date"
                                        name="oldPurchaseDate"
                                        value={formData.oldPurchaseDate}
                                        className={styles.InputField}
                                        onChange={handleChange}
                                      />
                                      <p className="text-danger absolute">
                                        {errors.oldPurchaseDate}
                                      </p>
                                </div>
                              </div>
                          </div>

                           

                          <div className={styles.ContentContainer}>
                              <div className={styles.Content}>
                                <FormHeadings text={"Previous Purchase Price:"}/>
                                <div className={styles.InputContainer}>
                                <input
                                  type="number"
                                  placeholder="Enter Price"
                                  name="oldPreviousPurchasePrice"
                                  value={formData.oldPreviousPurchasePrice}
                                  className={styles.InputField}
                                  onChange={handleChange}
                                />
                                <p className="text-danger absolute">
                                  {errors.oldPreviousPurchasePrice}
                                </p>
                                </div>
                              </div>
                              <div className={styles.Content}>
                                <FormHeadings text={"Previous Purchase Date:"}/>
                                <div className={styles.InputContainer}>
                                <input
                                  type="date"
                                  name="oldPreviousServiceDate"
                                
                                  value={formData.oldPreviousServiceDate}
                                  className={styles.InputField}
                                  onChange={handleChange}
                                />
                                <p className="text-danger absolute">
                                  {errors.oldPreviousServiceDate}
                                </p>
                                </div>
                              </div>
                          </div>

                          <div className={styles.ContentContainer}>
                            <div className={styles.CheckBoxContent}>
                              <div className={styles.CheckBox}>
                                <input type="checkbox"
                                name="oldinsuranceY"
                                checked={formData.oldinsuranceY}
                                value={formData.oldinsuranceY}
                                onChange={handleChange}
                                />
                                <FormHeadings text={"Insurance:"}/>
                              </div>
                              <div>
                              {formData.oldinsuranceY && <>
                              <select
                                className={styles.SmallInputField}
                                name="daysTypeoldinsurance"
                                value={
                                  formData.daysTypeoldinsurance
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Type
                                </option>
                                <option value="Week">
                                  Week
                                </option>
                                <option value="Month">
                                  Month
                                </option>
                                <option value="Days">
                                  Days
                                </option>
                                <option value="Years">
                                  Years
                                </option>
                              </select>
                              {formData.daysTypeoldinsurance && (
                                <select
                                className={styles.SmallInputField}
                                name="daysCountoldinsurance"
                                value={
                                  formData.daysCountoldinsurance
                                }
                                onChange={handleChange}
                                
                              >
                                <option value="">
                                  Select Count
                                </option>
                                {getDaysCountOptions(
                                  formData.daysTypeoldinsurance
                                ).map((count) => (
                                  <option
                                    key={count}
                                    value={count}
                                  >
                                    {count}
                                  </option>
                                ))}
                              </select>
                              )}
                              {!formData.oldinsuranceY?<p className="text-danger absolute">{errors.oldinsuranceY}</p>:!formData.daysTypeoldinsurance?<p className="text-danger absolute">{errors.daysTypeoldinsurance}</p>:<p className="text-danger absolute">{errors.daysCountoldinsurance}</p>}
                              </>}
                              </div>

                            </div>
                            <div className={styles.CheckBoxContent}>
                              <div className={styles.CheckBox}>
                                <input type="checkbox"
                                name="oldguaranteeY"
                                checked={formData.oldguaranteeY}
                                value={formData.oldguaranteeY}
                                onChange={handleChange}
                                />
                                <FormHeadings text={"Guarantee:"}/>
                              </div>
                              <div>
                              {formData.oldguaranteeY && <>
                              <select
                                className={styles.SmallInputField}
                                name="daysTypeoldguarantee"
                                value={
                                  formData.daysTypeoldguarantee
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Type
                                </option>
                                <option value="Week">
                                  Week
                                </option>
                                <option value="Month">
                                  Month
                                </option>
                                <option value="Days">
                                  Days
                                </option>
                                <option value="Years">
                                  Years
                                </option>
                              </select>
                              {formData.daysTypeoldguarantee && (
                                <select
                                name="daysCountoldguarantee"
                                value={
                                  formData.daysCountoldguarantee
                                }
                                onChange={handleChange}
                              className={styles.SmallInputField}
                              >
                                <option value="">
                                  Select Count
                                </option>
                                {getDaysCountOptions(
                                  formData.daysTypeoldguarantee
                                ).map((count) => (
                                  <option
                                    key={count}
                                    value={count}
                                  >
                                    {count}
                                  </option>
                                ))}
                              </select>
                              )}
                              {!formData.oldguaranteeY?<p className="text-danger absolute">{errors.oldguaranteeY}</p>:!formData.daysTypeoldguarantee?<p className="text-danger absolute">{errors.daysTypeoldguarantee}</p>:<p className="text-danger absolute">{errors.daysCountoldguarantee}</p>}
                              </>}
                              </div>

                            </div>
                            

                            <div className={styles.CheckBoxContent}>
                              <div className={styles.CheckBox}>
                                <input 
                                type="checkbox"
                                name="oldwarrantyY"
                                checked={formData.oldwarrantyY}
                                value={formData.oldwarrantyY}
                                onChange={handleChange}
                                />
                                <FormHeadings text={"Warranty:"}/>
                              </div>
                              <div>
                              {formData.oldwarrantyY && <>
                              <select
                                className={styles.SmallInputField}
                                name="daysTypeoldwarranty"
                                value={
                                  formData.daysTypeoldwarranty
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Type
                                </option>
                                <option value="Week">
                                  Week
                                </option>
                                <option value="Month">
                                  Month
                                </option>
                                <option value="Days">
                                  Days
                                </option>
                                <option value="Years">
                                  Years
                                </option>
                              </select>
                              {formData.daysTypeoldwarranty && (
                                <select
                                
                                className={styles.SmallInputField}
                                name="daysCountoldwarranty"
                                value={
                                  formData.daysCountoldwarranty
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Count
                                </option>
                                {getDaysCountOptions(
                                  formData.daysTypeoldwarranty
                                ).map((count) => (
                                  <option
                                    key={count}
                                    value={count}
                                  >
                                    {count}
                                  </option>
                                ))}
                              </select>

                                
                              )}
                              {!formData.oldwarrantyY?<p className="text-danger absolute">{errors.oldwarrantyY}</p>:!formData.daysTypeoldwarranty?<p className="text-danger absolute">{errors.daysTypeoldwarranty}</p>:<p className="text-danger absolute">{errors.daysCountoldwarranty}</p>}
                              </>}
                              </div>

                            </div>
                          </div>
                        </>}

                        </>}

                        {formData.type === "hire" && <>
                          <h3 className={styles.Heading}>Equipment Details</h3>
                          <div className={styles.ContentContainer}>
                                <div className={styles.Content}>
                                  <FormHeadings text={"Vendor Name:"}/>
                                  <div className={styles.InputContainer}>
                                  <input
                                    type="text"
                                    name="hireVendorName"
                                    value={formData.hireVendorName}
                                    className={styles.InputField}
                                    onChange={handleChange}
                                    placeholder="Enter Name"
                                  />
                                  <p className="text-danger absolute">
                                    {errors.hireVendorName}
                                  </p>
                                  </div>
                                </div>
                                <div className={styles.Content}>
                                  <FormHeadings text={"Vendor Contact No:"}/>
                                  <div className={styles.InputContainer}>
                                  <input
                                    type="number"
                                    name="hireVendorNumber"
                                    placeholder="Enter Price"
                                    value={formData.hireVendorNumber}
                                    className={styles.InputField}
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger absolute">
                                    {errors.hireVendorNumber}
                                  </p>
                                  </div>
                                </div>
                          </div>

                          <div className={styles.ContentContainer}>
                                <div className={styles.Content} style={{display:"flex"}}>
                                  <FormHeadings text={"How Many Days:"}/>
                                  <div className={styles.InputContainer}>
                                  <select
                                    type="text"
                                    name="daysTypeHireHowManyDays"
                                    value={formData.daysTypeHireHowManyDays}
                                    className={!formData.daysTypeHireHowManyDays?styles.InputField:styles.SmallInputField}
                                    onChange={handleChange}
                                    placeholder="Enter Name"
                                  >
                                    <option value="" hidden>Select Type</option>
                                    <option value="Week" >Week</option>
                                    <option value="Month" >Month</option>
                                    <option value="Days" >Days</option>
                                    <option value="Years" >Years</option>

                                  </select>

                                  { formData.daysTypeHireHowManyDays
                                  && <input
                                    placeholder="Enter"
                                    name="daysCountHireHowManyDays"
                                    value={formData.daysCountHireHowManyDays}
                                    onChange={handleChange}
                                    className={styles.SmallInputField}
                                    type="number"
                                  />}

                                  <p className="text-danger absolute">
                                    {!formData.daysTypeHireHowManyDays?errors.daysTypeHireHowManyDays:errors.daysCountHireHowManyDays}
                                  </p>
                                  </div>
                                </div>
                                
                                <div className={styles.Content}>
                                  <FormHeadings text={"Rate:"}/>
                                  <div className={styles.InputContainer} style={{display:"flex",position:"relative"}}>
                                  <select
                                    name="hireCharges"
                                    value={formData.hireCharges}
                                    onChange={handleChange}
                                    className={!formData.hireCharges ? styles.InputField : styles.SmallInputField}
                                  >
                                    <option value="" hidden>Select type</option>
                                    <option value="Daily">Daily</option>
                                    <option value="Hourly">Hourly</option>
                                  </select>
                                  
                                  {formData.hireCharges && <input
                                  className={styles.SmallInputField}
                                  name="hirePrice"
                                  value={formData.hirePrice}
                                  onChange={handleChange}
                                  placeholder="Enter rate"
                                  type="number"
                                  />}
                                  <p className="text-danger absolute top-[100%]">
                                    {!formData.hireCharges?errors.hireCharges:errors.hirePrice}
                                  </p>
                                  </div>
                                </div>
                          </div>

                          <div className={styles.ContentContainer}>
                                <div className={styles.Content}>
                                  <FormHeadings text={"Previous Serivce Date:"}/>
                                  <div className={styles.InputContainer}>
                                  <input
                                    type="date"
                                    name="hirePreviousServiceDate"
                                    value={formData.hirePreviousServiceDate}
                                    className={styles.InputField}
                                    onChange={handleChange}
                                    placeholder="Enter Name"
                                  />
                                  <p className="text-danger absolute">
                                    {errors.hirePreviousServiceDate}
                                  </p>
                                  </div>
                                </div>
                                
                                
                          </div>

{/* ------------------------------------------------- */}
                        <div className={styles.ContentContainer}>
                            <div className={styles.CheckBoxContent}>
                              <div className={styles.CheckBox}>
                                <input type="checkbox"
                                name="hireInsurance"
                                checked={formData.hireInsurance}
                                value={formData.hireInsurance}
                                onChange={handleChange}
                                />
                                <FormHeadings text={"Insurance:"}/>
                              </div>
                              <div>
                              {formData.hireInsurance && <>
                              <select
                                className={styles.SmallInputField}
                                name="daysTypeHireInsurance"
                                value={
                                  formData.daysTypeHireInsurance
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Type
                                </option>
                                <option value="Week">
                                  Week
                                </option>
                                <option value="Month">
                                  Month
                                </option>
                                <option value="Days">
                                  Days
                                </option>
                                <option value="Years">
                                  Years
                                </option>
                              </select>
                              {formData.daysTypeHireInsurance && (
                                <select
                                className={styles.SmallInputField}
                                name="daysCountHireInsurance"
                                value={
                                  formData.daysCountHireInsurance
                                }
                                onChange={handleChange}
                                
                              >
                                <option value="">
                                  Select Count
                                </option>
                                {getDaysCountOptions(
                                  formData.daysTypeHireInsurance
                                ).map((count) => (
                                  <option
                                    key={count}
                                    value={count}
                                  >
                                    {count}
                                  </option>
                                ))}
                              </select>
                              )}
                              {!formData.hireInsurance?<p className="text-danger absolute">{errors.hireInsurance}</p>:!formData.daysTypeHireInsurance?<p className="text-danger absolute">{errors.daysTypeHireInsurance}</p>:<p className="text-danger absolute">{errors.daysCountHireInsurance}</p>}
                              </>}
                              </div>

                            </div>
                            <div className={styles.CheckBoxContent}>
                              <div className={styles.CheckBox}>
                                <input type="checkbox"
                                name="hireGuarantee"
                                checked={formData.hireGuarantee}
                                value={formData.hireGuarantee}
                                onChange={handleChange}
                                />
                                <FormHeadings text={"Guarantee:"}/>
                              </div>
                              <div>
                              {formData.hireGuarantee && <>
                              <select
                                className={styles.SmallInputField}
                                name="daysTypeHireGuarantee"
                                value={
                                  formData.daysTypeHireGuarantee
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Type
                                </option>
                                <option value="Week">
                                  Week
                                </option>
                                <option value="Month">
                                  Month
                                </option>
                                <option value="Days">
                                  Days
                                </option>
                                <option value="Years">
                                  Years
                                </option>
                              </select>
                              {formData.daysTypeHireGuarantee && (
                                <select
                                name="daysCountHireGuarantee"
                                value={
                                  formData.daysCountHireGuarantee
                                }
                                onChange={handleChange}
                              className={styles.SmallInputField}
                              >
                                <option value="">
                                  Select Count
                                </option>
                                {getDaysCountOptions(
                                  formData.daysTypeHireGuarantee
                                ).map((count) => (
                                  <option
                                    key={count}
                                    value={count}
                                  >
                                    {count}
                                  </option>
                                ))}
                              </select>
                              )}
                              {!formData.hireGuarantee?<p className="text-danger absolute">{errors.hireGuarantee}</p>:!formData.daysTypeHireGuarantee?<p className="text-danger absolute">{errors.daysTypeHireGuarantee}</p>:<p className="text-danger absolute">{errors.daysCountHireGuarantee}</p>}
                              </>}
                              </div>

                            </div>
                            

                            <div className={styles.CheckBoxContent}>
                              <div className={styles.CheckBox}>
                                <input 
                                type="checkbox"
                                name="hireWarranty"
                                checked={formData.hireWarranty}
                                value={formData.hireWarranty}
                                onChange={handleChange}
                                />
                                <FormHeadings text={"Warranty:"}/>
                              </div>
                              <div>
                              {formData.hireWarranty && <>
                              <select
                                className={styles.SmallInputField}
                                name="daysTypeHireWarranty"
                                value={
                                  formData.daysTypeHireWarranty
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Type
                                </option>
                                <option value="Week">
                                  Week
                                </option>
                                <option value="Month">
                                  Month
                                </option>
                                <option value="Days">
                                  Days
                                </option>
                                <option value="Years">
                                  Years
                                </option>
                              </select>
                              {formData.daysTypeHireWarranty && (
                                <select
                                
                                className={styles.SmallInputField}
                                name="daysCountHireWarranty"
                                value={
                                  formData.daysCountHireWarranty
                                }
                                onChange={handleChange}
                              >
                                <option value="">
                                  Select Count
                                </option>
                                {getDaysCountOptions(
                                  formData.daysTypeHireWarranty
                                ).map((count) => (
                                  <option
                                    key={count}
                                    value={count}
                                  >
                                    {count}
                                  </option>
                                ))}
                              </select>

                                
                              )}
                              {!formData.hireWarranty?<p className="text-danger absolute">{errors.hireWarranty}</p>:!formData.daysTypeHireWarranty?<p className="text-danger absolute">{errors.daysTypeHireWarranty}</p>:<p className="text-danger absolute">{errors.daysCountHireWarranty}</p>}
                              </>}
                              </div>

                            </div>
                          </div>

                        </>}




                    </div>
                  </>
                ) : activeStep === 1 ? (
                  <div>
                    <div className="row mb-2 d-flex justify-content-around px-8">
                    {  filteredAssets
                        .find((asset) => asset._id === id).documents[0]!=='' && formData.assetCode &&
                      filteredAssets
                        .find((asset) => asset._id === id)
                        ?.documents?.map((doc, index) => (
                          <div key={index} className="mb-3 flex-col">
                            <label className="px-4" name={`documentName_${index}`} value={documentsData[index]?.documentName || ""}>{doc}:</label>
                   

                            <div className="flex justify-between p-4">
                              {/* Document Number */}
                              <div className={styles.AddnewPnmDoc}>
                                <input
                                  type="text"
                                  name={`documentNo_${index}`}
                                  placeholder="Enter the Document No"
                                  className={styles.AddnewPnmInputFields}
                                  value={documentsData[index]?.documentNo || ""}
                                  onChange={(e) => changeHandler(e, index)}
                                />
                                {docErrors[index]?.documentNo && (
                                  <p className="text-danger">{docErrors[index]?.documentNo}</p>
                                )}
                              </div>

                              {/* Registration Date */}
                              <div className={styles.AddnewPnmDoc}>
                                <input
                                  type="date"
                                  name={`regDate_${index}`}
                                  className={styles.AddnewPnmInputFields}
                                  value={documentsData[index]?.regDate || ""}
                                  onChange={(e) => changeHandler(e, index)}
                                />
                                {docErrors[index]?.regDate && (
                                  <p className="text-danger">{docErrors[index]?.regDate}</p>
                                )}
                              </div>

                              {/* Expiry Date */}
                              <div className={styles.AddnewPnmDoc}>
                                <input
                                  type="date"
                                  name={`expDate_${index}`}
                                  className={styles.AddnewPnmInputFields}
                                  value={documentsData[index]?.expDate || ""}
                                  onChange={(e) => changeHandler(e, index)}
                                />
                                {docErrors[index]?.expDate && (
                                  <p className="text-danger">{docErrors[index]?.expDate}</p>
                                )}
                              </div>

                              {/* File Upload */}
                              <div className={styles.AddnewPnmDoc}>
                                <input
                                  type="file"
                                  className={styles.AddnewPnmInputFields}
                                  name={`document_${doc}`}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(e) => changeHandler(e, index)}
                                />
                                {docErrors[index]?.file && (
                                  <p className="text-danger">{docErrors[index]?.file}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}

                     
                     
                    </div>
                  </div>
                
                ) : activeStep === 2 ? (
                  <>
                    
                    <section className="grid grid-cols-7 gap-x-4 gap-y-6 mb-4 md:gap-x-4 mt-4 ml-10">
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Type</strong>
                      </p>
                      :<p>{formData.type}</p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Fuel/Not</strong>
                      </p>
                      :<p>{formData.fuelRnot}</p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Name</strong>
                      </p>
                      :<p>{formData.name}</p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Make Name</strong>
                      </p>
                      :<p>{formData.makename}</p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Model</strong>{" "}
                      </p>
                      :<p>{formData.modelname}</p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Inspection</strong>
                      </p>
                      :<p>{formData.inspections}</p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>
                          select Engine / Motor
                        </strong>
                      </p>
                      :<p>{formData.engineRmotor}</p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Subcode</strong>
                      </p>
                      :<p>{formData.subCode}</p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>Insurance</strong>
                      </p>
                      :<p>{formData.Insurance}</p>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>New / Old</strong>
                      </p>
                      :<p>{formData.oldrNew}</p>
                      <p></p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>
                          Purchase Date
                        </strong>
                      </p>
                      :
                      <p>
                        {formData.oldrNew === "New"
                          ? formData.newpurchaseDate
                          : formData.oldPurchaseDate}
                      </p>
                      <p>
                        <strong style={{ color: "#4D4D4D" }}>
                          Purchase Price
                        </strong>
                      </p>
                      :
                      <p>
                        {formData.oldrNew === "New"
                          ? formData.newpurchasePrice
                          : formData.oldPreviousPurchasePrice}
                      </p>
                      <p></p>
                      <p></p>
                      <p></p>
                      <p></p>
                      

                    </section>

                    

                    {filteredAssets
                        .find((asset) => asset._id === id).documents[0]!=='' && 
                      formData.document && formData.document.length > 0
                      ? formData.document.map((doc, index) => (
                    
                                         
                        <section className="grid grid-cols-7 gap-x-4 gap-y-6 mb-4 md:gap-x-4 mt-4 ml-10">
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>RC:</strong>
                        </p>
                        :<p>{doc.documentNo || "N/A"}</p>
                        <p></p>
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>Date of Regn:</strong>
                        </p>
                        :<p>{doc.regDate || "N/A"}</p>
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>Regn Validity:</strong>
                        </p>
                        :<p>{doc.expDate || "N/A"}</p>
                        <p></p>
                        <p>
                          <strong style={{ color: "#4D4D4D" }}>Chosen File:</strong>
                        </p>
                        :<p>{doc?.file?.name || "N/A"}</p>
                        
                        
  
                      </section>)):<p className="p-4">No Document Available</p>
                    }
                    
                  </>
                ) : null}
                <div className="flex justify-between w-full mt-8 px-[60px]">
                  <button
                    type="button"
                    onClick={handleBack}
                    style={{
                      marginBottom: "20px",
                      padding: "10px",
                      borderRadius: "5px",
                      width: "175px",
                      backgroundColor: "white",
                      color: "var(--primary-color)",
                      border: "2px solid var(--primary-color)",
                    }}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    onClick={handleNext}
                    style={{
                      marginBottom: "20px",
                      padding: "10px",
                      borderRadius: "5px",
                      width: "175px",
                      backgroundColor: "var(--primary-color)",
                      color: "white",
                    }}

                  >
                    {activeStep === 2 ? "Finish" : "Next"}
                  </button>
                </div>
              </form>
            </>
          )}
        </Dialog>
      )}
    </>
  );
};

export default AddnewPnm;
