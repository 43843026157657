import React, { useState } from "react";
import { WebApimanager } from "../../../WebApiManager";
import "./ConstructionNeeds.css";

const ConstructionNeedsFiles = ({
  open,
  handleClose,
  itemId,
  onDataUpdated,
}) => {
  const [formData, setFormData] = useState({
    companyName: "",
    location: "",
    address: "",
    contactNumber: "",
    mailId: "",
    typesOfServiceProviding: "",
    description: "",
    file: null,
    constructionNeedContactName: "",
    constructionNeedContactRole: "",
  });
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState(null);

  const webApi = new WebApimanager();

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "contactNumber") {
      const numericValue = value.replace(/[^0-9]/g, "");
      if (numericValue.length <= 10) {
        if (numericValue.length === 0 || /^[6-9]/.test(numericValue)) {
          setFormData({
            ...formData,
            [name]: numericValue,
          });
        }
      }
    } else if (name === "file") {
      setFile(files[0]);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setErrorMessage("");

    let formErrors = {};
    const requiredFields = [
      "companyName",
      "location",
      "address",
      "contactNumber",
      "typesOfServiceProviding",
      "description",
      "constructionNeedContactRole",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        formErrors[field] = `${field
          .replace(/([A-Z])/g, " $1")
          .toLowerCase()} is required.`;
      }
    });

    const { constructionNeedContactName, constructionNeedContactRole, mailId } =
      formData;

    if (!constructionNeedContactName) {
      formErrors.constructionNeedContactName = "Contact Name is required.";
    }
    if (!constructionNeedContactRole) {
      formErrors.constructionNeedContactRole = "Contact Role is required.";
    }

    if (!mailId) {
      formErrors.mailId = "Please Email is not required.";
    }
    // Validate file
    if (!file) {
      formErrors.file = "File is required.";
    } else if (!file.type.startsWith("image/")) {
      formErrors.file = "Only image allowed.";
    }

    if (formData.contactNumber && !/^\d{10}$/.test(formData.contactNumber)) {
      formErrors.contactNumber = "must contain 10 digit number";
    }
    const allowedDomains = [
      "@gmail.com",
      "@yahoo.com",
      "@hotmail.com",
      "@outlook.com",
      "@live.com",
      "@example.com",
      "@aol.com",
      "@icloud.com",
      "@protonmail.com",
      "@zoho.com",
      "@mail.com",
      "@gmx.com",
      "@yandex.com",
      "@fastmail.com",
      "@inbox.com",
      "@hushmail.com",
      "@mailfence.com",
      "@me.com",
      "@posteo.net",
      "@tutanota.com",
      "@runbox.com",
      "@qq.com",
      "@163.com",
      "@sina.com",
      "@yeah.net",
      "@aliyun.com",
      "@126.com",
      "@21cn.com",
      "@china.com",
      "@tom.com",
      "@msn.com",
      "@live.co.uk",
      "@live.ca",
      "@mail.ru",
      "@bk.ru",
      "@list.ru",
      "@inbox.ru",
      "@rambler.ru",
      "@web.de",
      "@freenet.de",
      "@gmx.de",
      "@t-online.de",
      "@seznam.cz",
      "@centrum.cz",
      "@volny.cz",
      "@atlas.cz",
      "@chello.nl",
      "@home.nl",
      "@ziggo.nl",
      "@kpnmail.nl",
      "@xs4all.nl",
      "@orange.fr",
      "@wanadoo.fr",
      "@laposte.net",
      "@sfr.fr",
      "@neuf.fr",
      "@free.fr",
      "@tiscali.it",
      "@virgilio.it",
      "@alice.it",
      "@libero.it",
      "@tin.it",
      "@iol.it",
      "@tele2.it",
      "@vodafone.it",
      "@btinternet.com",
      "@talktalk.net",
      "@blueyonder.co.uk",
      "@virginmedia.com",
      "@sky.com",
      "@ntlworld.com",
      "@ntl.com",
      "@rediffmail.com",
      "@indiatimes.com",
      "@bol.com.br",
      "@uol.com.br",
      "@terra.com.br",
      "@ig.com.br",
      "@oi.com.br",
      "@globo.com",
      "@globomail.com",
      "@sapo.pt",
      "@netcabo.pt",
      "@clix.pt",
      "@vodafone.pt",
      "@telepac.pt",
      "@tmn.pt",
      "@mail.ee",
      "@mail.lv",
      "@inbox.lv",
      "@mail.lt",
      "@mail.kz",
      "@kcell.kz",
      "@knet.kz",
    ];

    if (
      formData.mailId &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.mailId)
    ) {
      formErrors.mailId = "Please enter a valid email address.";
    } else if (formData.mailId) {
      const domain = formData.mailId.substring(
        formData.mailId.lastIndexOf("@")
      );
      if (!allowedDomains.includes(domain)) {
        formErrors.mailId = "Email must be from an domain.";
      }
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const responseContactDetails = await webApi.put(
        `api/constructionNeeds/contactDetails/${itemId}`,
        formData
      );
      console.log("Contact details updated:", responseContactDetails);

      // Upload file if present
      if (file) {
        const uploadFile = new FormData();
        uploadFile.append("uploadFile", file);

        const responseFileUpload = await webApi.imagePut(
          `api/constructionNeeds/file/${itemId}`,
          uploadFile,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("File uploaded:", responseFileUpload);
      }

      setFormData({
        companyName: "",
        location: "",
        address: "",
        contactNumber: "",
        mailId: "",
        typesOfServiceProviding: "",
        description: "",
      });
      setFile(null);

      onDataUpdated();
      handleClose();
    } catch (error) {
      setErrorMessage("An error occurred while submitting the form.");
      console.error("Submission error:", error);
    }
  };

  return (
    <div
      className={`modal ${open ? "show" : ""}`}
      tabIndex="-1"
      style={{ display: open ? "block" : "none" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        style={{ width: "922px", height: "auto" }}
      >
        <div className="modal-content">
          <div
            className="modal-header "
            style={{ backgroundColor: "#303030", height: "40px" }}
          >
            <p
              className="modal-title text-white text-center"
              style={{ width: "100%", fontSize: "20px", fontWeight: "500" }}
            >
              Add Contacts
            </p>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={handleClose}
              style={{
                filter: "invert(1)",
                border: "none",
                color: "white",
              }}
            ></button>
          </div>
          <div className="modal-body p-5">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="companyName" className="form-label">
                      <p className="mb-0" style={{ marginLeft: "-210px" }}>
                        Company Name <span style={{ color: "red" }}>*</span>
                      </p>
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      value={formData.companyName}
                      className="form-control"
                      placeholder="Enter company name"
                      onChange={handleChange}
                      style={{
                        border: "1px rgba(151, 151, 151, 0.25) solid",
                        background: "#F3F3F3",
                        width: "330px",
                      }}
                    />
                    {errors.companyName && (
                      <div
                        className="text-danger"
                        style={{ fontSize: "14px", marginLeft: "-130px" }}
                      >
                        {errors.companyName}
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      <p className="mb-0" style={{ marginLeft: "-280px" }}>
                        Address <span style={{ color: "red" }}>*</span>
                      </p>
                    </label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      value={formData.address}
                      className="form-control"
                      placeholder="Enter address"
                      onChange={handleChange}
                      style={{
                        border: "1px rgba(151, 151, 151, 0.25) solid",
                        background: "#F3F3F3",
                        width: "330px",
                      }}
                    />
                    {errors.address && (
                      <div
                        className="text-danger"
                        style={{ marginLeft: "-170px" }}
                      >
                        {errors.address}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="location"
                      className="form-label"
                      style={{ marginLeft: "-250px" }}
                    >
                      <p className="mb-0">
                        Location <span style={{ color: "red" }}>*</span>
                      </p>
                    </label>
                    <input
                      type="text"
                      id="location"
                      name="location"
                      value={formData.location}
                      className="form-control"
                      placeholder="Enter location"
                      onChange={handleChange}
                      style={{
                        border: "1px rgba(151, 151, 151, 0.25) solid",
                        background: "#F3F3F3",
                        width: "330px",
                      }}
                    />
                    {errors.location && (
                      <div
                        className="text-danger"
                        style={{ marginLeft: "-170px" }}
                      >
                        {errors.location}
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="file" className="form-label">
                      <p className="mb-0" style={{ marginLeft: "-240px" }}>
                        Upload a File <span style={{ color: "red" }}>*</span>
                      </p>
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="file"
                      className="form-control"
                      onChange={handleChange}
                      style={{ height: "100%" }}
                    />
                    {errors.file && (
                      <div
                        className="text-danger"
                        style={{ marginLeft: "-200px" }}
                      >
                        {errors.file}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <div className="mb-3">
                  <label
                    htmlFor="typesOfServiceProviding"
                    className="form-label"
                  >
                    <p className="mb-0" style={{ marginLeft: "-500px" }}>
                      Types of Service Providing
                      <span style={{ color: "red" }}>*</span>
                    </p>
                  </label>
                  <input
                    type="text"
                    id="typesOfServiceProviding"
                    name="typesOfServiceProviding"
                    value={formData.typesOfServiceProviding}
                    className="form-control"
                    placeholder="Enter types of service"
                    onChange={handleChange}
                    style={{
                      border: "1px rgba(151, 151, 151, 0.25) solid",
                      background: "#F3F3F3",
                      width: "100%",
                    }}
                  />
                  {errors.typesOfServiceProviding && (
                    <div
                      className="text-danger"
                      style={{ marginLeft: "-380px" }}
                    >
                      {errors.typesOfServiceProviding}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    <p className="mb-0" style={{ marginLeft: "-620px" }}>
                      Description <span style={{ color: "red" }}>*</span>
                    </p>
                  </label>
                  <input
                    id="description"
                    name="description"
                    type="text"
                    value={formData.description}
                    className="form-control"
                    placeholder="Enter description"
                    onChange={handleChange}
                    rows="4"
                    style={{
                      border: "1px rgba(151, 151, 151, 0.25) solid",
                      background: "#F3F3F3",
                      width: "100%",
                    }}
                  />
                  {errors.description && (
                    <div
                      className="text-danger"
                      style={{ marginLeft: "-500px" }}
                    >
                      {errors.description}
                    </div>
                  )}
                </div>
              </div>

              <hr
                style={{
                  borderColor: "#CDCDCD",
                  borderWidth: "2px",
                  borderStyle: "solid",
                }}
              />

              <div>
                <div>
                  <p className="contactDetaielsFiles">
                    Contact person details:
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "48%" }}>
                      <div className="mb-3">
                        <label
                          htmlFor="constructionNeedContactName"
                          className="form-label"
                        >
                          <p className="mb-0" style={{ marginLeft: "-290px" }}>
                            Name
                            <span style={{ color: "red" }}>*</span>
                          </p>
                        </label>
                        <input
                          type="text"
                          id="constructionNeedContactName"
                          name="constructionNeedContactName"
                          value={formData.constructionNeedContactName}
                          className="form-control"
                          placeholder="Enter contact name"
                          onChange={(e) => {
                            const value = e.target.value;

                            const letters = /^[A-Za-z\s]*$/;
                            if (letters.test(value)) {
                              handleChange(e);
                            }
                          }}
                          style={{
                            border: "1px rgba(151, 151, 151, 0.25) solid",
                            background: "#F3F3F3",
                            width: "330px",
                          }}
                        />
                        {errors.constructionNeedContactName && (
                          <div
                            className="text-danger"
                            style={{ marginLeft: "-110px" }}
                          >
                            {errors.constructionNeedContactName}
                          </div>
                        )}
                      </div>

                      <div className="mb-3">
                        <label htmlFor="contactNumber" className="form-label">
                          <p className="mb-0" style={{ marginLeft: "-290px" }}>
                            Mobile
                            <span style={{ color: "red" }}>*</span>
                          </p>
                        </label>
                        <input
                          type="text"
                          id="contactNumber"
                          name="contactNumber"
                          value={formData.contactNumber}
                          className="form-control"
                          placeholder="Enter contact number"
                          onChange={handleChange}
                          style={{
                            border: "1px rgba(151, 151, 151, 0.25) solid",
                            background: "#F3F3F3",
                            width: "330px",
                          }}
                        />
                        {errors.contactNumber && (
                          <div
                            className="text-danger"
                            style={{ marginLeft: "-100px" }}
                          >
                            {errors.contactNumber}
                          </div>
                        )}
                      </div>
                    </div>

                    <div style={{ width: "48%" }}>
                      <div className="mb-3">
                        <label
                          htmlFor="constructionNeedContactRole"
                          className="form-label"
                        >
                          <p className="mb-0" style={{ marginLeft: "-310px" }}>
                            Role
                            <span style={{ color: "red" }}>*</span>
                          </p>
                        </label>
                        <input
                          type="text"
                          id="constructionNeedContactRole"
                          name="constructionNeedContactRole"
                          value={formData.constructionNeedContactRole}
                          className="form-control"
                          placeholder="Enter contact number"
                          onChange={handleChange}
                          style={{
                            border: "1px rgba(151, 151, 151, 0.25) solid",
                            background: "#F3F3F3",
                            width: "330px",
                          }}
                        />
                        {errors.constructionNeedContactRole && (
                          <div
                            className="text-danger"
                            style={{ marginLeft: "-120px" }}
                          >
                            {errors.constructionNeedContactRole}
                          </div>
                        )}
                      </div>

                      <div className="mb-3">
                        <label htmlFor="mailId" className="form-label">
                          <p className="mb-0" style={{ marginLeft: "-290px" }}>
                            Mail ID <span style={{ color: "red" }}>*</span>
                          </p>
                        </label>
                        <input
                          type="email"
                          id="mailId"
                          name="mailId"
                          value={formData.mailId}
                          className="form-control"
                          placeholder="Enter email address"
                          onChange={handleChange}
                          style={{
                            border: "1px rgba(151, 151, 151, 0.25) solid",
                            background: "#F3F3F3",
                            width: "330px",
                          }}
                        />
                        {errors.mailId && (
                          <div
                            className="text-danger"
                            style={{ marginLeft: "-62px" }}
                          >
                            {errors.mailId}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn mt-3"
                style={{
                  backgroundColor: "#E47331",
                  borderColor: "#E47331",
                  color: "white",
                  width: "175px",
                  height: "50px",
                  margin: "0 auto",
                }}
              >
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConstructionNeedsFiles;
