import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Dialog, DialogContent } from "@mui/material";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import AddDrawingDialog from "./Components/AddDrawingDialog";
import { todayDate } from "../../../Utilities/DateUtils";
import { useRecoilState } from "recoil";
import { registerAtom, siteId } from "../../../atoms";
import MultiSelectSearch from "../../../Utilities/MultiSelectSearch";
import { RxCross1 } from "react-icons/rx";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableWrapper from "../../../Utilities/TableWrapper";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import ReceivedTable from "./ReceivedTable";
import SearchDropdownIdVal from "../../../Widgets/SearchDropdownIdVal";
import { processFile, updateRegister } from "../BackendUtils";
import { SUCESS_STATUS } from "../../../Utilities/Constants";
import DrawingTableWrapper from "../Components/DrawingTableWrapper";

const ApprovedTable = () => {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const tomorrowDateString = tomorrow.toISOString().split("T")[0];

  let webApi = new WebApimanager();

  const [open, setOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [singleDrawingData, setSingleDrawingData] = useState(null);
  const [error, setError] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [drawingList, setDrawingList] = useState([]);
  const [initialDrawingList, setInitialDrawingList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [registerList, setRegisterList] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [editData, setEditData] = useState({
    drawingId: "",
    drawingNumber: "",
    expectedDate: "",
    requestedDate: todayDate(),
    remarks: "",
    drawingFileName: null,
    revision: [],
    natureofrequestedInfo: "",
  });
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [site, setSite] = useRecoilState(siteId);
  const [selectedRevision, setSelectedRevision] = useState("");

  const [registerInfo, setRegisterInfo] = useRecoilState(registerAtom);
  // const handleSiteChange = (e) => {
  //   setSiteId(e.target.value);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    fetchData();
  };
  const handleEditOpen = (drawingId) => {
    const drawingItem = drawingList.find((item) => item._id === drawingId);
    if (drawingItem) {
      const combinedRevisions = drawingItem.acceptedArchitectRevisions.flatMap(
        (rev) => rev.revision
      );
      setEditData({
        drawingId: drawingId,
        drawingNumber: drawingItem.drawingNo,
        expectedDate: "", // Consider initializing this with a valid date if needed
        requestedDate: todayDate(),
        remarks: "",
        drawingFileName: null,
        selectedRevision: "", // Make sure this is included
        revision: combinedRevisions, // Ensure this is set as an array
        natureofrequestedInfo: "", // Ensure this key is initialized if used elsewhere
      });

      setEditDialogOpen(true);
    }
  };
  const handleRevisionSelection = (event) => {
    setSelectedRevision(event.target.value);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditData({
      drawingId: "",
      drawingNumber: "",
      expectedDate: "",
      remarks: "",
      drawingFileName: null,
      revision: [],
      natureofrequestedInfo: [],
    });
  };
  const [selectedTab, setSelectedTab] = useState(0);

  // const handleChevronClick = (index) => {
  //   setExpandedRevision(expandedRevision === index ? null : index);
  // };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    
  };

  const handleViewOpen = (drawingItem) => {
    setSingleDrawingData(drawingItem);
    if (drawingItem.acceptedArchitectRevisions.length > 0) {
      setSelectedTab(drawingItem.acceptedArchitectRevisions.length - 1);
    }
    setViewDialogOpen(true);
  };

  const handleViewClose = () => {
    setApiProcessing({ loader: false });
    setSingleDrawingData(null);
    setViewDialogOpen(false);
  };

  const fetchInData = async () => {
    if (registerInfo && registerInfo.length > 0) {
      setDrawingList(registerInfo);
      setInitialDrawingList(registerInfo);
      console.log("fetched from recoil", registerInfo);
    } else {
      await updateRegister(setRegisterInfo, site).then((response) => {
        if (response.status === SUCESS_STATUS) {
          setDrawingList(response.registers);
          setInitialDrawingList(response.registers);
        }
      });
    }
  };

  useEffect(() => {
    setReloadData(true);
  }, [drawingList]);

  const fetchData = async () => {
    await updateRegister(setRegisterInfo, site).then((response) => {
      if (response.status === SUCESS_STATUS) {
        setDrawingList(response.registers);
        setInitialDrawingList(response.registers);
      }
    });
  };

  useEffect(() => {
    fetchInData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setEditData({
        ...editData,
        [name]: files[0], // Assuming you are only uploading one file
      });
    } else {
      setEditData({
        ...editData,
        [name]: value,
      });
    }
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();

    let formErrors = {};
    if (!editData.drawingNumber)
      formErrors.drawingNumber = "Drawing Number is required";
    if (!editData.expectedDate)
      formErrors.expectedDate = "Expected Date is required";
    if (!editData.remarks) formErrors.remarks = "Remarks are required";
    if (!editData.drawingFileName) {
      formErrors.drawingFileName = "Drawing File is required";
    }
    if (!editData.natureofrequestedInfo)
      formErrors.natureofrequestedInfo = "this field is requires";
    // if (!editData.revision) formErrors.revision = 'Please select a revision.';

    setError(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }

    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });
    try {
      const editFormData = {
        drawingId: editData.drawingId,
        drawingNo: editData.drawingNumber,
        siteId: site,
        requestedDate: todayDate(),
        expectedDate: editData.expectedDate,
        remarks: editData.remarks,
        revision: selectedRevision,
        natureOfRequestedInformation: editData.natureofrequestedInfo,
      };

      console.log("Submitting edit data:", editFormData);

      const response = await webApi.post(
        "api/architectureToRoRequested/postRequest",
        editFormData
      );

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.status === "error") {
          // Handle error from response data
          setErrorMessage(responseData.message);
          console.log("Response data:", responseData.message);
          setApiProcessing({ loader: false, message: "" });
          return; // Stop further processing
        }

        const id = response.data.data._id;

        if (editData.drawingFileName) {
          const drawingFileName = new FormData();
          drawingFileName.append("drawingFileName", editData.drawingFileName);
          const uploadResponse = await webApi.imagePut(
            `api/architectureToRoRequested/${id}`,
            drawingFileName
          );
          console.log("Upload response:", uploadResponse);
        }
        setApiProcessing({ loader: false, message: "" });
        handleEditClose();
        fetchData();
      } else if (response.status === 400) {
        if (response.data && response.data.message) {
          setErrorMessage(response.data.message);
          console.log("Response data:", response.data.message);
        }
        setApiProcessing({ loader: false, message: "" });
      } else {
        setErrorMessage("An unexpected error occurred.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage("Failed to submit data.");
      }
    }
  };

  const handleDownload = async (id, revision) => {
    setApiProcessing({
      loader: true,
      message: "Downloading...",
    });
    try {
      // Request the file with binary response handling
      //TODO: need to check this path correct or not
      const response = await webApi.getImage(
        `api/architectureToRoRequested/${id}?revision=${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );

      processFile(response, "download", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const handleViewFile = async (id, revision) => {
    try {
      setApiProcessing({ loader: true, message: "Viewing" });

      // Make the request without explicitly setting the responseType
      const response = await webApi.getImage(
        `api/architectureToRoRegister/${id}/revisions/${revision}`,
        {
          responseType: "arraybuffer", // Expect binary data
        }
      );
      processFile(response, "view", navigate); // Process file for viewing
    } catch (error) {
      console.error("Error viewing file:", error);
    } finally {
      setApiProcessing({ loader: false, message: "" });
    }
  };

  const listItems = [
    { id: "A - Missing Information", name: "A - Missing Information" },
    { id: "B - Clarification", name: "B - Clarification" },
    { id: "C - Additional Information", name: "C - Additional Information" },
    { id: "D - Uncoordinated Drawings", name: "D - Uncoordinated Drawings" },
  ];

  // Function to get the name from id
  const getNameFromId = (id) => {
    const item = listItems.find((item) => item.id === id);
    return item ? item.name : "";
  };

  const [currentView, setCurrentView] = useState("upload");

  const handleTabViewChange = (event, newValue) => {
    setCurrentView(newValue);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const searchResult = (e) => {
    const searchInput = e.target.value.trim().toLowerCase(); // Convert search input to lowercase and trim any extra spaces
    setSearchValue(searchInput);
    setSearchActive(true);

    if (!searchInput) {
      fetchData(); // Fetch all drawings again if the search input is empty
      setSearchActive(false);
    } else {
      const filteredDrawings = initialDrawingList.filter((drawingItem) => {
        // Convert each field to lowercase for case-insensitive comparison
        const drawingNumber = drawingItem.drawingNo?.toLowerCase() || "";
        const drawingName = drawingItem.drawingTitle?.toLowerCase() || "";
        const consultant =
          drawingItem.designDrawingConsultant?.role?.toLowerCase() || "";
        const category = drawingItem.category?.category?.toLowerCase() || "";
        // const acceptedROSubmissionDate = drawingItem.acceptedROSubmissionDate ? new Date(drawingItem.acceptedROSubmissionDate).toLocaleDateString().toLowerCase() : "";
        const submittedROSubmissionDate = drawingItem.submittedROSubmissionDate
          ? new Date(drawingItem.submittedROSubmissionDate)
              .toLocaleDateString()
              .toLowerCase()
          : "";
        // const dueDays = calculateDueDays(drawingItem.acceptedArchitectDate).toString().toLowerCase(); // Convert dueDays to lowercase for comparison
        // const dueStatus = dueDays >= 0 ? `${dueDays} days` : `${Math.abs(dueDays)} days overdue`;
        const revisions =
          drawingItem.acceptedArchitectRevisions
            ?.map((revisionItem) => revisionItem.revision.toLowerCase())
            .join(" ") || "";

        // Filter based on the selected filter criteria
        switch (selectedFilter) {
          case "Drawing No":
            return drawingNumber.includes(searchInput);
          case "Drawing Consultant":
            return consultant.includes(searchInput);
          case "Drawing Category":
            return category.includes(searchInput);
          case "Drawing Title":
            return drawingName.includes(searchInput);
          // case 'Accepted RO Submission Date':
          //   return acceptedROSubmissionDate.includes(searchInput);
          case "Final Submission Date":
            return submittedROSubmissionDate.includes(searchInput);
          // case 'Due/Overdue':
          //   return dueStatus.includes(searchInput) || dueDays.includes(searchInput);
          case "Revision":
            return revisions.includes(searchInput);
          default:
            return (
              drawingNumber.includes(searchInput) ||
              drawingName.includes(searchInput) ||
              consultant.includes(searchInput) ||
              category.includes(searchInput) ||
              // acceptedROSubmissionDate.includes(searchInput) ||
              submittedROSubmissionDate.includes(searchInput) ||
              // dueStatus.includes(searchInput) ||
              // dueDays.includes(searchInput) ||
              revisions.includes(searchInput)
            );
        }
      });

      setDrawingList(filteredDrawings);
    }
  };

  const handleDropdownClick = (filter) => {
    setSelectedFilter(filter);
    setShowDropdown(false); // Close dropdown after selection
  };
  const placeholderText = selectedFilter
    ? `Search by ${selectedFilter}`
    : "Search Drawing Lists";

  const calculateDueDays = (
    acceptedROSubmissionDate,
    softCopySubmittedDate = null
  ) => {
    if (!acceptedROSubmissionDate) return "-";

    const dateToCompare = softCopySubmittedDate
      ? new Date(softCopySubmittedDate)
      : new Date();
    const acceptedDate = new Date(acceptedROSubmissionDate);
    const differenceInTime = acceptedDate.getTime() - dateToCompare.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24)); // convert time difference to days

    return differenceInDays;
  };

  return (
    <>
      <TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <Tabs
                  value={currentView}
                  onChange={handleTabViewChange}
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "var(--primary-color)", // Custom color for active tab indicator
                    },
                    "& .MuiTab-root": {
                      textTransform: "none", // Prevents text from being transformed to uppercase
                      color: "#131313", // Text color for all tabs
                      borderBottom: "none",
                      fontWeight: "bold",
                    },
                    "& .Mui-selected": {
                      color: "#131313 !important", // Text color for selected tab
                    },
                    "& .MuiTab-root:not(.Mui-selected)": {
                      color: "#131313", // Text color for non-selected tabs
                    },
                  }}
                >
                  <Tab label="Upload" value="upload" />
                  <Tab label="Received" value="received" />
                </Tabs>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    position: "relative",
                    width: "250px",
                    paddingLeft: "20px",
                    height: "50px",
                  }}
                >
                  <input
                    placeholder={placeholderText}
                    onChange={searchResult}
                    style={{
                      paddingLeft: "20px",
                      border: "1px solid var(--primary-color)",
                      width: "250px",
                      height: "40px",
                    }}
                  />
                  <Icon.Search
                    style={{
                      position: "absolute",
                      right: "-3px",
                      top: "40%",
                      transform: "translateY(-50%)",
                      color:'#ACACAC'
                              }}
                  />
                </div>
                <Button
                  style={{
                    height: "40px",
                    marginLeft: "40px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "var(--primary-color)",
                    border: "1px solid var(--primary-color)",
                    borderRadius:'0px'
                  }}
                  onClick={handleClickOpen}
                >
                  <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} />{" "}
                  Add New
                </Button>
              </div>
            </div>
            <div>
              {showDropdown && (
                <div
                  style={{
                    position: "absolute",
                    top: "350px",
                    right: "50px", // Align with the filter icon
                    border: "1px solid var(--primary-color)",
                    borderRadius: "6px",
                    backgroundColor: "#fff",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    padding: "5px",
                    zIndex: 1000,
                    width: "150px", // Adjust width for compact design
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Drawing No")}
                    >
                      Drawing No
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick(" Consultant")}
                    >
                      Drawing Consultant
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Category")}
                    >
                      Drawing Category
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Title")}
                    >
                      Drawing Title
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick(" R0 Submission Date")}
                    >
                      Accepted R0 Submission Date
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() =>
                        handleDropdownClick("Final Submission Date")
                      }
                    >
                      Final Submission Date
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Due/Overdue")}
                    >
                      Due/Overdue
                    </li>
                    <li
                      style={{ padding: "8px", cursor: "pointer" }}
                      onClick={() => handleDropdownClick("Revision")}
                    >
                      Revision
                    </li>
                  </ul>
                </div>
              )}

              {currentView === "upload" ? (
                <DrawingTableWrapper
                  tableHead={
                    <tr>
                      <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                      <th style={{ width: "10%" }}>Drawing No</th>
                      <th style={{ width: "10%" }}>Drawing Consultant</th>
                      <th style={{ width: "10%" }}>Drawing Category</th>
                      <th style={{ width: "10%" }}>Drawing Title</th>
                      <th style={{ width: "13%", paddingLeft: "10px" }}>
                        Accepted R0 Submission Date
                      </th>
                      <th style={{ width: "13%", paddingLeft: "10px" }}>
                        Submission Date
                      </th>
                      <th style={{ width: "13%" }}>Due/Overdue</th>
                      <th style={{ width: "10%" }}>Revision</th>
                      <th style={{ width: "10%" }}>Action</th>
                    </tr>
                  }
                  tableBody={
                    <tbody style={{ textAlign: "left" }}>
                      {drawingList &&
                        [
                          ...drawingList
                            .filter(
                              (item) =>
                                item.acceptedArchitectRevisions.length > 0
                            ) // Items with revisions
                            .sort((a, b) => {
                              const consultantA = a.designDrawingConsultant
                                ?.role
                                ? a.designDrawingConsultant?.role.toLowerCase()
                                : "";
                              const consultantB = b.designDrawingConsultant
                                ?.role
                                ? b.designDrawingConsultant?.role.toLowerCase()
                                : "";
                              return consultantA.localeCompare(consultantB);
                            }),
                        ].map((drawingItem, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0
                                  ? "var(--table-oddrow-color)"
                                  : "var(--table-evenrow-color)",
                            }}
                          >
                            <td style={{ width: "5%" }}>{index + 1}</td>
                            <td style={{ width: "10%" }}>
                              {drawingItem?.drawingNo || ""}
                            </td>
                            <td style={{ width: "10%" }}>
                              {drawingItem.designDrawingConsultant?.role || ""}
                            </td>
                            <td style={{ width: "10%" }}>
                              {drawingItem.category?.category || ""}
                            </td>
                            <td style={{ width: "9%" }}>
                              {drawingItem.drawingTitle}
                            </td>
                            <td style={{ width: "12.8%" }}>
                              {formatDate(
                                drawingItem?.acceptedROSubmissionDate || ""
                              )}
                            </td>
                            <td style={{ width: "13%" }}>
                              {formatDate(
                                drawingItem.acceptedArchitectRevisions[0]
                                  ?.softCopySubmittedDate
                              ) || ""}
                            </td>
                            <td style={{ width: "12%" }}>
                              <span
                                style={{
                                  color:
                                    new Date(
                                      drawingItem.softCopySubmittedDate
                                    ) < new Date()
                                      ? "black"
                                      : calculateDueDays(
                                          drawingItem.acceptedROSubmissionDate,
                                          drawingItem
                                            .acceptedArchitectRevisions[0]
                                            ?.softCopySubmittedDate
                                        ) >= 0
                                      ? "green"
                                      : "red",
                                }}
                              >
                                {calculateDueDays(
                                  drawingItem.acceptedROSubmissionDate,
                                  drawingItem.acceptedArchitectRevisions[0]
                                    ?.softCopySubmittedDate
                                ) === "-"
                                  ? "-"
                                  : calculateDueDays(
                                      drawingItem.acceptedROSubmissionDate,
                                      drawingItem.acceptedArchitectRevisions[0]
                                        ?.softCopySubmittedDate
                                    ) >= 0
                                  ? `${calculateDueDays(
                                      drawingItem.acceptedROSubmissionDate,
                                      drawingItem.acceptedArchitectRevisions[0]
                                        ?.softCopySubmittedDate
                                    )} days due`
                                  : `${Math.abs(
                                      calculateDueDays(
                                        drawingItem.acceptedROSubmissionDate,
                                        drawingItem
                                          .acceptedArchitectRevisions[0]
                                          ?.softCopySubmittedDate
                                      )
                                    )} days overdue`}
                              </span>
                            </td>
                            <td>
                              {drawingItem.acceptedArchitectRevisions.length >
                              0 ? (
                                <div>
                                  {drawingItem.acceptedArchitectRevisions
                                    .slice(-4) // Show only the latest four revisions
                                    .map(
                                      (revisionItem, idx) =>
                                        `${revisionItem.revision}` || "NA"
                                    )
                                    .join(", ")}
                                </div>
                              ) : (
                                <div>-</div>
                              )}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                marginTop: "20px",
                                paddingLeft: "0px",
                              }}
                            >
                              <Icon.Pen
                                size={20}
                                style={{
                                  marginRight: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleEditOpen(drawingItem._id)}
                              />
                              <Icon.EyeFill
                                size={20}
                                style={{
                                  marginRight: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleViewOpen(drawingItem)}
                              />
                              <Icon.Download
                                size={20}
                                style={{ marginLeft: "5px", cursor: "pointer" }}
                                onClick={() => {
                                  const latestRevision =
                                    drawingItem.acceptedArchitectRevisions
                                      .length > 0
                                      ? drawingItem.acceptedArchitectRevisions[
                                          drawingItem.acceptedArchitectRevisions
                                            .length - 1
                                        ].revision
                                      : null;
                                  handleDownload(
                                    drawingItem._id,
                                    latestRevision
                                  );
                                }}
                              />
                              <Loader
                                open={apiProcessing.loader}
                                message={apiProcessing.message}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  }
                />
              ) : (
                <ReceivedTable
                  selectedFilter={selectedFilter}
                  searchValue={searchValue}
                  searchActive={searchActive}
                  setSearchValue={setSearchValue}
                  setSearchActive={setSearchActive}
                  reloadData={reloadData}
                  setReloadData={setReloadData}
                  filterCondition="approved"
                />
              )}
            </div>
          </>
        }
      />
      <AddDrawingDialog
        handleDialogClose={handleDialogClose}
        open={open}
        site={site}
      />
      <Dialog
        open={editDialogOpen}
        onClose={handleEditClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{ style: {borderRadius:10 ,fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
          height:'50px'
          }}
        >
          <p
            style={{ margin: "5px 250px", textAlign: "center", color: "white" }}
          >
            RFI
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleEditClose}
          />
        </div>
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <form onSubmit={handleEditSubmit}>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="drawingNumber"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Drawing Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="drawingNumber"
                    placeholder="Enter your Drawing Number"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    value={editData.drawingNumber}
                    onChange={handleInputChange}
                    readOnly
                  />
                  {error.drawingNumber && (
                    <div className="text-danger">{error.drawingNumber}</div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="requestedDate"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Requested Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    name="expectedDate"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    value={editData.requestedDate}
                    onChange={handleInputChange}
                    readOnly
                  />
                  {error.requestedDate && (
                    <div className="text-danger">{error.requestedDate}</div>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="expectedDate"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Expected Date <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="date"
                    name="expectedDate"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    value={editData.expectedDate}
                    onChange={handleInputChange}
                    min={tomorrowDateString}
                  />
                  {error.expectedDate && (
                    <div className="text-danger">{error.expectedDate}</div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="revision"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Revisions
                    {/* <span style={{ color: 'red' }}>*</span> */}
                  </label>
                  <SearchDropdownIdVal
                    placeholder={"Select Revision"}
                    backgroundColor={"var(--table-evenrow-color)"}
                    // Check if `editData.revision` is an array and get the latest revision
                    options={
                      Array.isArray(editData.revision) &&
                      editData.revision.length > 0
                        ? [
                            {
                              name: editData.revision.slice(-1)[0], // Get the latest revision
                              value: editData.revision.slice(-1)[0], // Use the latest revision for value
                            },
                          ]
                        : []
                    }
                    onOptionSelect={(option) =>
                      handleRevisionSelection({
                        target: {
                          name: "selectedRevision",
                          value: option.value,
                        },
                      })
                    }
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                  />

                  {/* <select
          id="revision"
          name="selectedRevision"
          onChange={handleRevisionSelection}
          value={selectedRevision}
          className="form-control fs-12"
          style={{ width: '100%' }}
        >
          <option value="">Select Revision</option>
          {(Array.isArray(editData.revision) ? editData.revision : []).map((rev, index) => (
                  <option key={index} value={rev}>{rev}</option>
                ))}
        </select> */}

                  {error.revision && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {error.revision}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  {/* <label htmlFor="natureofrequestedInfo" className="fs-10 fw-bold text-start py-2">
        Nature of Requested Information<span style={{ color: 'red' }}>*</span>
            </label> */}
                  <MultiSelectSearch
                    mandatory={true}
                    heading={"Nature of Requested Information"}
                    listItems={listItems}
                    selectedIds={editData.natureofrequestedInfo || []} // Ensure this is an array
                    setSelectedIds={(ids) =>
                      setEditData({ ...editData, natureofrequestedInfo: ids })
                    }
                    handleSelectedPropertyOption={(data) => {
                      if (editData.natureofrequestedInfo.includes(data.id)) {
                        setEditData({
                          ...editData,
                          natureofrequestedInfo:
                            editData.natureofrequestedInfo.filter(
                              (id) => id !== data.id
                            ),
                        });
                      } else {
                        setEditData({
                          ...editData,
                          natureofrequestedInfo: [
                            ...editData.natureofrequestedInfo,
                            data.id,
                          ],
                        });
                      }
                    }}
                    clearAll={() =>
                      setEditData({ ...editData, natureofrequestedInfo: [] })
                    }
                    addAll={() =>
                      setEditData({
                        ...editData,
                        natureofrequestedInfo: listItems.map((item) => item.id),
                      })
                    }
                  />

                  {/* Display selected fields */}
                  <div style={{ marginTop: "20px" }}>
                    <p className="fs-10 fw-bold text-start py-2">
                      Selected Fields:
                    </p>
                    <ul>
                      {editData.natureofrequestedInfo.length > 0 ? (
                        editData.natureofrequestedInfo.map((id) => (
                          <li key={id}>{getNameFromId(id)}</li>
                        ))
                      ) : (
                        <li>No fields selected</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", gap: "15px" }}>
                <div style={{ flex: 1 }}>
                  <label
                    htmlFor="remarks"
                    className="fs-10 fw-bold text-start py-2"
                  >
                    Request Reason <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    name="remarks"
                    placeholder="Enter your Reason"
                    className="form-control fs-12"
                    style={{ width: "100%" }}
                    // rows="3"
                    value={editData.remarks}
                    onChange={handleInputChange}
                  />
                  {error.remarks && (
                    <div className="text-danger">{error.remarks}</div>
                  )}
                </div>
              </div>
              <div>
                <label
                  htmlFor="drawingFileName"
                  className="fs-10 fw-bold text-start py-2"
                >
                  Upload Your File <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="file"
                  accept=".pdf, .jpg, .jpeg, .png, .dwg"
                  id="drawingFileName"
                  name="drawingFileName"
                  className="form-control fs-12"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      // Check if the file type is one of the accepted types
                      const validExtensions = [
                        ".pdf",
                        ".jpg",
                        ".jpeg",
                        ".png",
                        ".dwg",
                      ];
                      const fileExtension = file.name
                        .slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2)
                        .toLowerCase();

                      if (validExtensions.includes(`.${fileExtension}`)) {
                        handleInputChange(e);
                      } else {
                        alert("Please select a valid file type.");
                        e.target.value = null; // Reset the file input if the file is not valid
                      }
                    }
                  }}
                />

                {error.drawingFileName && (
                  <div className="text-danger">{error.drawingFileName}</div>
                )}
              </div>
              {errorMessage && (
                <div style={{ color: "red", fontSize: "15px" }}>
                  {errorMessage}
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  type="submit"
                  style={{
                    background: "blue",
                    color: "white",
                    width: "100px",
                    height: "35px",  
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={viewDialogOpen}
        onClose={handleViewClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            fontFamily: "Poppins",
            borderRadius: "10px",

            // boxShadow: "0px 0px 10px 0px #F3F3F3",
            // width: '922px',height: '1060px'
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height:'50px'
          }}
        >
          <p
            style={{ margin: "5px 400px", textAlign: "center", color: "white" }}
          >
            View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleViewClose}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0",
              border: "1px solid var(--primary-color)",
            }}
          >
            {singleDrawingData?.acceptedArchitectRevisions?.length > 0 ? (
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="transparent" // Hides the default indicator
                textColor="inherit" // Ensures text color is handled manually
                style={{ backgroundColor: "transparent" }}
              >
                {singleDrawingData.acceptedArchitectRevisions.map(
                  (revisionItem, index) => (
                    <Tab
                      key={index}
                      label={`${revisionItem.revision}`}
                      style={{
                        backgroundColor:
                          singleDrawingData.acceptedArchitectRevisions
                            .length === 1 || selectedTab === index
                            ? "var(--primary-color)"
                            : "transparent",
                        color:
                          singleDrawingData.acceptedArchitectRevisions
                            .length === 1 || selectedTab === index
                            ? "white"
                            : "var(--primary-color)",
                        width: `${
                          100 /
                          singleDrawingData.acceptedArchitectRevisions.length
                        }%`,
                        height: "30px",
                        minWidth: "20px",
                        cursor: "pointer",
                        borderRight:
                          index <
                          singleDrawingData.acceptedArchitectRevisions.length -
                            1
                            ? "1px solid var(--primary-color)"
                            : "none",
                      }}
                    />
                  )
                )}
              </Tabs>
            ) : (
              <div>No Revisions</div>
            )}
          </div>
        </div>
        {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {selectedTab ===
                  singleDrawingData.acceptedArchitectRevisions.length - 1 ? (
                    <div style={{ color: "green" }}>Good For Construction</div>
                  ) : (
                    <div style={{ color: "red" }}>Superseeded</div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
        <DialogContent
          style={{ padding: "25px", minWidth: "300px", minHeight: "300px" }}
        >
          <>
            <Loader
              open={apiProcessing.loader}
              message={apiProcessing.message}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                padding: "20px",
                color: "#4D4D4D",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "28px",

                justifyContent: "center",
                marginLeft: "100px",
              }}
            >
              <div>Drawing Number</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingNo || "NA"}
              </div>

              <div>Design Consultant</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.designDrawingConsultant?.role}{" "}
              </div>

              <div>Category</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.category?.category || "NA"}
              </div>

              <div>Drawing Title</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.drawingTitle || "NA"}
              </div>

              <div>Scheduled submission date</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                {singleDrawingData?.acceptedROSubmissionDate
                  ? new Date(singleDrawingData.acceptedROSubmissionDate)
                      .toISOString()
                      .split("T")[0]
                  : "NA"}
              </div>

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Actual Final Submission Date</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ].softCopySubmittedDate
                          ? formatDate(
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ].softCopySubmittedDate
                            )
                          : "No Submitted Date"}
                      </div>
                    </>
                  )}
                </>
              )}

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Revision</div>
                      <div>:</div>
                      <div style={{ marginLeft: "-120px" }}>
                        {singleDrawingData.acceptedArchitectRevisions[
                          selectedTab
                        ].revision || "NA"}
                      </div>
                    </>
                  )}
                </>
              )}

              <div>Due / Overdue</div>
              <div>:</div>
              <div style={{ marginLeft: "-120px" }}>
                <span
                  style={{
                    color:
                      singleDrawingData &&
                      singleDrawingData.acceptedArchitectRevisions &&
                      singleDrawingData.acceptedArchitectRevisions.length > 0
                        ? calculateDueDays(
                            singleDrawingData.acceptedROSubmissionDate,
                            singleDrawingData.acceptedArchitectRevisions[0]
                              ?.softCopySubmittedDate
                          ) >= 0
                          ? "green"
                          : "red"
                        : "black",
                  }}
                >
                  {singleDrawingData &&
                  singleDrawingData.acceptedArchitectRevisions &&
                  singleDrawingData.acceptedArchitectRevisions.length > 0
                    ? calculateDueDays(
                        singleDrawingData.acceptedROSubmissionDate,
                        singleDrawingData.acceptedArchitectRevisions[0]
                          ?.softCopySubmittedDate
                      ) === "-"
                      ? "NA"
                      : calculateDueDays(
                          singleDrawingData.acceptedROSubmissionDate,
                          singleDrawingData.acceptedArchitectRevisions[0]
                            ?.softCopySubmittedDate
                        ) >= 0
                      ? `${calculateDueDays(
                          singleDrawingData.acceptedROSubmissionDate,
                          singleDrawingData.acceptedArchitectRevisions[0]
                            ?.softCopySubmittedDate
                        )} days due`
                      : `${Math.abs(
                          calculateDueDays(
                            singleDrawingData.acceptedROSubmissionDate,
                            singleDrawingData.acceptedArchitectRevisions[0]
                              ?.softCopySubmittedDate
                          )
                        )} days overdue`
                    : "-"}
                </span>
              </div>

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
                singleDrawingData?.acceptedArchitectRevisions[selectedTab]
                  ?.changes && (
                  <>
                    <div>Changes in Revsion</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData.acceptedArchitectRevisions[selectedTab]
                        .changes || "NA"}
                    </div>
                  </>
                )}

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 &&
                singleDrawingData?.acceptedArchitectRevisions[selectedTab]
                  ?.issuesInRevision?.length > 0 && (
                  <>
                    <div>Issues In Revision</div>
                    <div>:</div>
                    <div style={{ marginLeft: "-120px" }}>
                      {singleDrawingData.acceptedArchitectRevisions[
                        selectedTab
                      ].issuesInRevision.join(", ") || "NA"}
                    </div>
                  </>
                )}

              {singleDrawingData?.acceptedArchitectRevisions?.length > 0 && (
                <>
                  {singleDrawingData.acceptedArchitectRevisions[
                    selectedTab
                  ] && (
                    <>
                      <div>Drawing File </div>
                      <div>:</div>
                      <div style={{ display: "flex", marginLeft: "-120px" }}>
                        <Icon.Image
                          size={20}
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={() =>
                            handleViewFile(
                              singleDrawingData._id,
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ].revision
                            )
                          }
                        />
                        <Icon.Download
                          size={20}
                          style={{ marginRight: "5px", cursor: "pointer" }}
                          onClick={() =>
                            handleDownload(
                              singleDrawingData._id,
                              singleDrawingData.acceptedArchitectRevisions[
                                selectedTab
                              ].revision
                            )
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              {/* <iframe src="//sharecad.org/cadframe/load?url=https://www.cadsofttools.com/dwgviewer/hostel_block.dwg" scrolling="no"> </iframe> */}
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ApprovedTable;
