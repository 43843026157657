import React from "react";
import "./ToggleSwitch.css";
import { IoIosArrowForward } from "react-icons/io";

const ToggleSubModuleSwitch = ({ label, isChecked, onToggle, onIconClick }) => {
  const noArrowLabels = ["employee", "organizationChart"];

  return (
    <div
      className="toggle-submodule-switch row"
      style={{
        boxSizing: "border-box",
        backgroundColor: "transparent",
      }}
    >
      <div className="col-sm-4" style={{ marginLeft: "-20px", width: "100px" }}>
        <span className="toggle-submodule-switch-label">{label}</span>
      </div>

      <div className="col-sm-4" style={{ marginTop: "10px" }}>
        <label
          className="switch"
          // style={{ margin: "0 -10px", marginLeft: "50px" }}
        >
          <input type="checkbox" checked={isChecked} onChange={onToggle} />
          <span className="slider round"></span>
        </label>
      </div>

      <div
        className="col-sm-4"
        style={{ marginLeft: "200px", marginTop: "-30px" }}
      >
        {!noArrowLabels.includes(label) && isChecked && (
          <div onClick={onIconClick} style={{ cursor: "pointer" }}>
            <IoIosArrowForward />
          </div>
        )}
      </div>
    </div>
  );
};

export default ToggleSubModuleSwitch;
