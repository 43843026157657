import React from "react";

const TableWrapper = (props) => {
  return (
    <div
      style={{
        boxShadow: "0 0 10px #F3F3F3",
        borderRadius: "12px",
        backgroundColor: "white",
        padding: "15px",
        paddingTop: "20px",
        fontFamily: "Poppins",
        height: "76vh",
      }}
    >
      {props.children}
    </div>
  );
};

export default TableWrapper;
