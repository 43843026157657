import React, { useState, useEffect } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import SelectSite from "../../Utilities/SelectSite";
import Approval from "./Approval/Approval";
import { useRecoilState } from "recoil";
import { architectureTab, siteId } from "../../atoms";
import { WebApimanager } from "../../WebApiManager";
import DesignForm from "./Design/DesignForm";
import Form from "./Form/Form";
import SitesDropdown from "../../Utilities/SitesDropdown";

const Checklist = () => {
  let webApi = new WebApimanager();
  const [value, setValue] = useRecoilState(architectureTab);
  const [site, setSite] = useRecoilState(siteId);
  const [sites, setDatasites] = useState([]);
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSiteChange = async (e) => {
    setSite(e.target.value);
    setApiProcessing({
      loader: true,
      message: "Loading...",
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setApiProcessing({
        loader: false,
        message: "",
      });
    } catch (error) {
      console.error("Error occurred while changing the site:", error);
      setApiProcessing({
        loader: false,
        message: "Failed to load site data",
      });
    }
  };
  if (site === "") {
    return <SelectSite />;
  }

  return (
    // <>
   
    //   <div
    //     style={{
    //       display: "flex",
    //       flexDirection: "column",
    //       alignItems: "center",
    //       padding: "30px",
    //       marginTop: "30px",
    //       backgroundColor: "#F3F3F3",
    //       height: "100%",
    //     }}
    //   >
    //     <Box
    //       sx={{
    //         border: "1px solid var(--primary-color)",
    //         width: "100%",
    //         maxWidth: "450px",
    //       }}
    //     >
    //       <Tabs
    //         id="controlled-tab-example"
    //         value={value}
    //         onChange={handleChange}
    //         sx={{
    //           "& .MuiTabs-indicator": {
    //             display: "none", // Hide default indicator
    //           },
    //           "& .MuiTab-root": {
    //             textTransform: "none",
    //             borderBottom: "none",
    //             borderLeft: "1px solid var(--primary-color)",
    //             minWidth: "150px",
    //             fontWeight: "bold",
    //             color: "var(--primary-color)",
    //             "&:first-of-type": {
    //               borderLeft: "none",
    //             },
    //             "&:last-of-type": {
    //               borderRight: "none",
    //             },
    //           },
    //           "& .Mui-selected": {
    //             backgroundColor: "var(--primary-color)", // Background color for selected tab
    //             color: "white !important",
    //             borderBottom: "1px solid var(--primary-color)", // Maintain border at the bottom of selected tab
    //           },
    //           "& .MuiTab-root:not(.Mui-selected)": {
    //             color: "var(--primary-color)", // Text color for non-selected tabs
    //           },
    //         }}
    //       >
    //         <Tab label="Design" />
    //         <Tab label="Approval" />
    //         <Tab label="Forms" />
    //       </Tabs>
    //     </Box>
        
    //   </div>
     

    //   <div className="w-full bg-[#f3f3f3] px-[40px]">
    //     <TabPanel value={value} index={0}>
    //       <DesignForm />
    //     </TabPanel>
    //     <TabPanel value={value} index={1}>
    //       <Approval />
    //     </TabPanel>
    //     <TabPanel value={value} index={2}>
    //       <Form />
    //     </TabPanel>
    //   </div>
    // </>
    <>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "30px",
        marginTop: "30px",
        backgroundColor: "#F3F3F3",
        height: "100%",
        justifyContent:'center'
      }}
    >
      <Box
        sx={{
          border: "1px solid var(--primary-color)",
          width: "100%",
          maxWidth: "450px",
        }}
      >
        {/* Flex container for Tabs and Dropdown */}
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Tabs
            id="controlled-tab-example"
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                display: "none", // Hide default indicator
              },
              "& .MuiTab-root": {
                textTransform: "none",
                borderBottom: "none",
                borderLeft: "1px solid var(--primary-color)",
                minWidth: "150px",
                fontWeight: "bold",
                color: "var(--primary-color)",
                "&:first-of-type": {
                  borderLeft: "none",
                },
                "&:last-of-type": {
                  borderRight: "none",
                },
              },
              "& .Mui-selected": {
                backgroundColor: "var(--primary-color)", // Background color for selected tab
                color: "white !important",
                borderBottom: "1px solid var(--primary-color)", // Maintain border at the bottom of selected tab
              },
              "& .MuiTab-root:not(.Mui-selected)": {
                color: "var(--primary-color)", // Text color for non-selected tabs
              },
            }}
          >
            <Tab label="Design" />
            <Tab label="Approval" />
            <Tab label="Forms" />
          </Tabs>
  
         
        </div>
            
      </Box>
      {/* <div >
            <SitesDropdown
              siteId={site}
              handleSiteChange={handleSiteChange}
              sites={sites}
              defaultValue={"Select Site"}
            />
          </div> */}
    </div>
  
    <div className="w-full bg-[#f3f3f3] px-[40px]">
      <TabPanel value={value} index={0}>
        <DesignForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Approval />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Form />
      </TabPanel>
    </div>
  </>
  
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {value === index && (
        <Box p={3} style={{ textAlign: "center", width: "100%" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default Checklist;
