import React from 'react';
import PartnerCard from './PartnerCard'; // Assuming this component is already responsive.
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const partnerData = [
  {
    imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/82afc384fba40d98cc8e06156df28686324e0b361a9101189640612195eba535?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    title: "Value Added Reseller (VAR)",
    description: "As Value Added Reseller (VAR), you gain the ability to resell, consult, support and offer training services for In4Suite®. The program provides everything you need to differentiate and add a competitive edge to your current business."
  },
  {
    imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/210897bf2e746470f2f352f9491ea71e66cf25aff1f3aad94f751ee2439e74e5?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    title: "System Integrator (SI)",
    description: "As an System Integrator you gain the ability to resell, implement and provide first line support for In4Suite®. At this level you become a one-stop shop for In4Suite® sales, implementation and support in your territory."
  },
  {
    imageSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/bded6079ba766db9e1c041fedbf1904e9ffabacb2857243006f5728f7ff3bb11?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    title: "Referral partner",
    description: "The partner program is ideal for any company that understands and works with Real Estate/Construction/Leasing & Facilities management organisations. The partners have the ability to pass opportunities."
  }
];

const PartnerProgram = () => {
  return (
    <section className="py-5">
      <div className="">
        <h2 className="text-center mb-4 text-[#E47331]">How We Partner</h2>
        <div className="row">
          {partnerData.map((partner, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 mb-4 d-flex align-items-stretch">
              <div className="card h-100 w-100 ">
                <PartnerCard {...partner} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PartnerProgram;
