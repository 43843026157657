import autoMechine from "./ourProductImages/autoMechanic.png";
import drawingBuilding from "./ourProductImages/drawingBuilding.png";
import uorProducts3 from "./ourProductImages/ourProducts3.png";

const BACKEND_URL = "https://api.chromacheer.com/";
// const BACKEND_URL = "http://localhost:8000/";
const BG_COLOUR = "linear-gradient(to right, #E7C97E 0%, #C55C9B 100%)";
const Button_COLOUR = "#7C1034";
const Field_COLOUR = "#F3E9D7";
const SUCESS_STATUS = "success";
const FAILED_STATUS = "failed";

const OUR_PRODUCTS = [
  {
    image: autoMechine,
    title: "P & M Management System",
    desc: `Proper maintenance, timely data analysis, and informed equipment selection are crucial for maximizing productivity and reducing costs in construction projects. Regular maintenance cycles, accurate usage tracking, and performance analysis are essential for optimizing equipment efficiency and preventing costly breakdowns and delays.`,
    readMorelk: "/products",
  },
  {
    image: drawingBuilding,
    title: "Drawing Management System",
    desc: "Effective drawing management demands seamless coordination between site, head office and Design consultants, with timely issue resolution to maintain project timelines.",
    readMorelk: "/products",
  },
  {
    image: uorProducts3,
    title: "Drive Management System",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium purus at blandit consequat.",
    readMorelk: "/products",
  },
];

const UPCOMING_PRODUCTS = [
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/67d75a8bb049c56d4e56e60f54b089da7dd6a1901dab588d417804a0c57e3046?apiKey=3e99c58a56f84e4cb0d84873c390b13e&",
    title: "QA / QC Management System",
    desc: "An ERP solution streamlines the integration of Quality Assurance (QA) and Quality Control (QC) processes in the construction industry by centralizing data and automating workflows, ensuring consistent adherence to quality protocols and enabling real-time monitoring. This enhances communication, minimizes errors, and optimizes resource allocation, leading to improved project efficiency, reduced delays, and cost savings throughout the project lifecycle.",
    readMorelk: "/products",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/20be375433b560f43c10b19efe336969a68b959a6ca39a3376bd7b70632bd268?apiKey=3e99c58a56f84e4cb0d84873c390b13e&",
    title: "HR / Admin Management System",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium purus at blandit consequat.",
    readMorelk: "/products",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/8791cbcb4465ec747bddbf33165ecab34074b2e099e29bd0b4357cad8074bf2e?apiKey=3e99c58a56f84e4cb0d84873c390b13e&",
    title: "EHS Management System",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium purus at blandit consequat.",
    readMorelk: "/products",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F50230da391844affbffaade64040852d",
    title: "Planning Management System",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium purus at blandit consequat.",
    readMorelk: "/products",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets%2F7fa683a22a9c42d7a697097e1847f1c9%2F942afa69d2644986a5325314e5694b20",
    title: "Store Management System",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam pretium purus at blandit consequat.",
    readMorelk: "/products",
  },
];

export {
  BACKEND_URL,
  BG_COLOUR,
  Button_COLOUR,
  Field_COLOUR,
  SUCESS_STATUS,
  FAILED_STATUS,
  OUR_PRODUCTS,
  UPCOMING_PRODUCTS,
};
