import React from "react";
import FloorDetails from "../FloorDetails";

const CommonClubHouseDetails = (props) => {
  const { formData, handleChange, errors } = props;

  return formData.clubs.map((club, index) => (
    <div key={index}>
      <h3>Club House {index + 1}</h3>
      <div
        style={{
          borderBottom: "1px dotted lightgray",
          display: "flex",
          marginTop: "4px",
          paddingBottom: "40px",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
            }}
          >
            <label>
              Number of Floors<a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="number"
              min="1"
              max="100"
              name="noOfFloors"
              placeholder="Enter Number of Floors"
              value={club.noOfFloors}
              onChange={(e) => handleChange(e, null, null, null, index)}
              style={{marginTop:'15px'}}
            />
            {errors[`noOfFloors-${index}`] && (
              <p style={{ color: "red" }}>{errors[`noOfFloors-${index}`]}</p>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" ,marginTop:'15px'}}>
            <label>
              Number of Poures for a Floor
              <a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="number"
              min="1"
              max="100"
              name="noOfPouresFloor"
              placeholder="Enter Number Of Poures for a Floor"
              value={club.noOfPouresFloor}
              onChange={(e) => handleChange(e, null, null, null, index)}
              style={{marginTop:'15px'}}
            />
            {errors[`noOfPouresFloor-${index}`] && (
              <p style={{ color: "red" }}>
                {errors[`noOfPouresFloor-${index}`]}
              </p>
            )}
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
            }}
          >
            <label>
              Number of Basements<a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="number"
              min="1"
              max="100"
              name="CHnoOfBasements"
              placeholder="Enter Number of Basements"
              value={club.CHnoOfBasement}
              onChange={(e) => handleChange(e, null, null, null, index)}
              style={{marginTop:'15px'}}
            />
            {errors[`CHnoOfBasement-${index}`] && (
              <p style={{ color: "red" }}>
                {errors[`CHnoOfBasement-${index}`]}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop:'15px'
            }}
          >
            <label>
              Number of Poures for a Basement
              <a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="number"
              min="1"
              max="100"
              name="CHnoOfPouresBasement"
              placeholder="Enter Number Of Poures for a basement"
              value={club.CHnoOfPouresBasement}
              onChange={(e) => handleChange(e, null, null, null, index)}
              style={{marginTop:'15px'}}
            />
            {errors[`CHnoOfPouresBasement-${index}`] && (
              <p style={{ color: "red" }}>
                {errors[`CHnoOfPouresBasement-${index}`]}
              </p>
            )}
          </div>
        </div>
      </div>
      {club.noOfFloors > 0 && (
        <FloorDetails
          floors={club.floors}
          errors={errors}
          handleChange={handleChange}
          ventureType={formData.ventureType}
          clubIndex={index}
        />
      )}
    </div>
  ));
};

export default CommonClubHouseDetails;
