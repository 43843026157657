import React from "react";
import FloorDetails from "../FloorDetails";

const BuildingDetails = (props) => {
  const { formData, handleChange, errors } = props;
  return (
    <>
      <h3>Enter Details for Building</h3>
      <div
        style={{
          borderBottom: "1px dotted lightgray",
          display: "flex",
          marginTop: "20px",
          paddingBottom: "40px",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
            }}
          >
            <label>
              Number of Floors<a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="number"
              min="1"
              max="100"
              name="BOnoOfFloors"
              placeholder="Enter Number of Floors"
              value={formData.BOnoOfFloors}
              onChange={(e) => handleChange(e)}
              style={{marginTop:'15px'}}
            />
            {errors.BOnoOfFloors && (
              <p style={{ color: "red" }}>{errors.BOnoOfFloors}</p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label>
              Number of Poures for a Floor
              <a style={{ color: "red" }}>*</a>
            </label>
            <input
              type="number"
              min="1"
              max="100"
              name="BOnoOfPouresFloor"
              placeholder="Enter Number of Poures for a Floor"
              value={formData.BOnoOfPouresFloor}
              onChange={(e) => handleChange(e)}
              style={{marginTop:'15px'}}
            />
            {errors.BOnoOfPouresFloor && (
              <p style={{ color: "red" }}>{errors.BOnoOfPouresFloor}</p>
            )}
          </div>
        </div>
      </div>
      {formData.BOnoOfFloors > 0 && (
        <FloorDetails
          floors={formData.floors}
          errors={errors}
          handleChange={handleChange}
          ventureType={formData.ventureType}
        />
      )}
    </>
  );
};

export default BuildingDetails;
