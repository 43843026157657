import React, { useState, useEffect, useRef } from "react";
import { Dialog, Stepper, StepLabel, Step, DialogContent } from "@mui/material";
import { WebApimanager } from "../../WebApiManager";
import { useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useRecoilState } from "recoil";
import { siteId, userInfo } from "../../atoms";
import "./Employee.module.css";
import MultiSelectSearch from "../../Utilities/MultiSelectSearch";
import ToggleSwitch from "../Sites/ToggleSwitch";
import "./users.css";
import CustomDropdown from "./CustomDropdown";
import { RxCross2 } from "react-icons/rx";

const AddUserModal = ({ show, onClose }) => {
  let webApi = new WebApimanager();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedWorkModel, setSelectedWorkModel] = useState("");
  const [roles, setRoles] = useState([]);
  const [workModels, setWorkModels] = useState([]);
  const [datasites, setDataSites] = useState([]);
  const [site, setSite] = useRecoilState(siteId);
  const [currentUserInfo, setCurrentUserInfo] = useRecoilState(userInfo);
  const [userId, setUserId] = useState(currentUserInfo._id || "");

  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    contactnumber: "",
    emailid: "",
    bloodGroup: "",
    emergencyContactnumber: "",
    reportingEmpIdName: "",
    employeeId: "",
    selectedDepartment: "",
    selectedRole: "",
    selectedWorkModel: "",
    permittedSite: [],
    reportingEmpId: "",
    reportingUserId: "",
  });
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    contactnumber: "",
    emailid: "",
    bloodGroup: "",
    emergencyContactnumber: "",

    reportingEmpIdName: "",
    employeeId: "",
    selectedDepartment: "",
    selectedRole: "",
    selectedWorkModel: "",
    permittedSite: [],
  });

  const [selectedSiteIds, setSelectedSiteIds] = useState([]);
  const [enabledModules, setEnableModules] = useState();

  const handleNext = () => {
    const isValid = validateFields();

    if (!isValid) {
      return;
    }

    if (!selectedDepartment) {
      errors.selectedDepartment = "Department is required";
    }
    if (!selectedRole) {
      errors.selectedRole = "Role is required";
    }
    console.log(activeStep, "activeStep");

    console.log("selected ID ", selectedSiteIds);
    if (activeStep === 1) {
      console.log("modules Data", ModulesData);

      let a = [];
      const sites = datasites;

      for (const site of sites) {
        const siteModules = ModulesData[site.id] || [];

        for (const module of siteModules) {
          if (!a.includes(module)) {
            a.push(module);
          }
        }
      }

      setEnableModules(a);

      console.log("Filtered enabled modules:", a);
    }

    if (Object.keys(errors).length === 0) {
      if (activeStep === 2) {
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }

    if (activeStep === 2) {
      setFormValues((prevValues) => ({
        ...prevValues,
        permittedSite: selectedSiteIds,
        selectedDepartment: selectedDepartment,
        selectedRole: selectedRole,
      }));
    }
    if (activeStep === 3) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  const departmentData = {
    Management: {
      roles: [
        "Company Managing Director",
        "Director",
        "Technical President",
        "Senior Vice President",
        "Vice President",
        "Assistant Vice President",
      ],

      workModels: [""],
    },
    "Design Consultant": {
      roles: [
        "Archiectural",
        "Structural",
        "MEP",
        "Facade",
        "Landscape and Lighting",
        "Vertical Transportation",
        "Traffic and Parking",
      ],
      workModels: [""],
    },

    Execution: {
      roles: [
        "Deputy Project Manager",
        "Assistant Project Manager",
        "Assistant Manager",
        "Sr.Project Engineer",
        "Project Engineer",
        "Jr.Project Engineer",
        "Senior Engineer",
        "Engineer",
        "Junior Engineer",
        "GTE",
      ],
      workModels: ["Structural", "Finishes"],
    },
    Planning: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: [""],
    },
    SiteManagement: {
      role: [
        "General Manager",
        "Assistant General Manager",
        "Senior Project Manager",
        "Project Manager",
      ],
      workModels: [""],
    },
    QualitySurveyorBilling: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: ["BBS", "Billing", "Contractor", "QS"],
    },
    MEP: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Supervisors",
      ],
      workModels: ["Electrical", "Plumbing", "Fire Fighting", "Ele & HVAC"],
    },
    EHS: {
      roles: [
        "Head",
        "Manager",
        "Assistant Manager",
        "Safety Incharge",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
        "Steve Ward",
        "Male Nurse",
      ],
      workModels: [""],
    },
    CRMTECH: {
      roles: [
        "Head",
        "Manager",
        "Deputy Manager",
        "Assistant Manager",
        "Sr.Engineer",
        "Engineer",
        "Jr.Engineer",
        "GTE",
      ],
      workModels: [""],
    },
    Store: {
      roles: [
        "Head",
        "Store Manager",
        "Assistant Store Manager",
        "Store Officer",
        "Store Incharge",
        "Sr.Store Keeper",
        "Store Keeper/ Executive",
        "Sr.Store Asst",
        "Store Asst",
        "Trainee Store",
      ],
      workModels: [""],
    },
    Survey: {
      roles: ["Sr.Engineer", "Engineer", "Jr.Engineer", "GTE", "Helper"],
    },
    Accounts: {
      roles: [
        "Head",
        "Manager",
        "Sr.Accountant",
        "Accountant",
        "Jr.Accountant",
      ],
      workModels: [""],
    },
    Admin: {
      roles: [
        "Head",
        "Project Admin",
        "Ass Admin",
        "Time Keeper",
        "Colony Admin",
      ],
      workModels: [""],
    },
  };

  const handleDepartmentChange = (e) => {
    const selectedDepartment = e.target.value;
    setSelectedDepartment(selectedDepartment);

    setSelectedRole("");
    setSelectedWorkModel("");

    if (selectedDepartment) {
      const departmentDetails = departmentData[selectedDepartment];
      setRoles(departmentDetails.roles || []);
      setWorkModels(departmentDetails.workModels || []);
    }
  };

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async () => {
    try {
      const permittedSites = selectedSiteIds
        .map((siteId) => {
          const siteData = allData.find((data) => data.siteId === siteId);

          if (!siteData) {
            return null;
          }

          const enableModules = {};
          siteData.enabledModules.forEach((module) => {
            if (formValues[module]) {
              enableModules[module] = true;
            }
          });

          return {
            siteId,
            enableModules,
          };
        })
        .filter((site) => site !== null);

      const reportEmpName = (
        formValues.reportingEmpIdName.includes("|")
          ? formValues.reportingEmpIdName.split("|")[1]
          : formValues.reportingEmpIdName
      ).replace(/\s+/g, "");
      const reportingEmpId = formValues.reportingEmpId.replace(/\s+/g, "");
      const reportingUserId = formValues.reportingUserId.replace(/\s+/g, "");

      const userResponse = await webApi.post("api/users", {
        firstName: formValues.firstname,
        lastName: formValues.lastname,
        email: formValues.emailid,
        workMode: selectedWorkModel,
        bloodGroup: formValues.bloodGroup,
        emergencyContact: formValues.emergencyContactnumber,
        contactNumber: formValues.contactnumber,
        department: formValues.selectedDepartment,
        role: formValues.selectedRole,
        empId: formValues.employeeId,
        permittedSites: permittedSites,
        reportingEmpId: reportingEmpId,
        reportingEmpIdName: reportEmpName,
        reportingUserId: reportingUserId,
      });

      if (userResponse.status === 201) {
        // Success case, new user created
        const userId = userResponse.data._id;
        console.log("User created successfully, User ID:", userId);
        onClose(); // Close modal on success
      } else if (userResponse.status === 200) {
        // Update success case or warnings
        const responseData = userResponse.data;
        if (responseData.status === "warning") {
          setErrorMessage(responseData.msg || "Warning occurred.");
          console.log("Warning:", responseData.msg);
        } else {
          setErrorMessage(responseData.message || "Validation error occurred.");
          console.log("Validation error:", responseData.message);
        }
        onClose();
      } else {
        setErrorMessage("An unexpected error occurred.");
        console.log("Unexpected response:", userResponse);
      }
    } catch (error) {
      if (error.response) {
        console.log("Error response:", error.response);

        const errorResponse = error.response.data;

        if (error.response.status === 400) {
          setErrorMessage(
            errorResponse.msg || "Bad request: check your input data"
          );
        } else if (errorResponse.status === "error") {
          setErrorMessage(errorResponse.msg || "Warning occurred.");
          console.log("Warning:", errorResponse.msg);
        } else {
          setErrorMessage(errorResponse.msg || "Error occurred.");
          console.log("Error:", errorResponse.msg);
        }
      } else {
        setErrorMessage("Failed to submit data.");
        console.error("Submission error:", error.message);
      }
    } finally {
      // Any final cleanup can be done here if needed
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (activeStep === 0) {
      newErrors.firstname = formValues.firstname
        ? ""
        : "Please enter First Name.";
      newErrors.lastname = formValues.lastname ? "" : "Please enter Last Name.";
      newErrors.contactnumber = formValues.contactnumber
        ? ""
        : "Please enter Contact Number.";
      newErrors.emailid = formValues.emailid ? "" : "Please enter Email ID.";
      newErrors.bloodGroup = formValues.bloodGroup
        ? ""
        : "Please select Blood Group.";
      newErrors.emergencyContactnumber = formValues.emergencyContactnumber
        ? ""
        : "Please enter Emergency Contact Number.";
    } else if (activeStep === 1) {
      newErrors.reportingEmpIdName = formValues.reportingEmpIdName
        ? ""
        : "Please enter Reporting Employee ID / Name.";
      newErrors.selectedDepartment = selectedDepartment
        ? ""
        : "Please enter Department.";
      newErrors.employeeId = formValues.employeeId
        ? ""
        : "Please enter employeeId.";
      newErrors.selectedRole = selectedRole ? "" : "Please enter Role.";
    } else if (activeStep === 2) {
      newErrors.permittedSite = formValues.permittedSite
        ? ""
        : "Please enter permittedSite.";
    }

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "reportingEmpIdName") {
      const [reportingUserId, reportingEmpIdName, reportingEmpId] =
        value.split("|");

      setFormValues((prevValues) => ({
        ...prevValues,
        reportingEmpIdName: reportingEmpIdName.trim(),
        reportingUserId: reportingUserId,
        reportingEmpId: reportingEmpId,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));

      console.log(`Selected value for ${name}: ${value}`);
    }

    let errorMessage = "";

    switch (name) {
      case "emailid":
      case "bloodGroup":
      case "permittedSite":
        errorMessage = value.trim()
          ? ""
          : `Please enter ${name.charAt(0).toUpperCase() + name.slice(1)}.`;
        break;
      case "contactnumber":
        const isValidContactNumber = /^\d{10}$/.test(value);
        errorMessage = value.trim()
          ? isValidContactNumber
            ? ""
            : "Please enter a valid Contact Number."
          : "Please enter Contact Number.";
        break;
      case "emergencyContactnumber":
        const isValidEmergencyContactNumber = /^\d{10}$/.test(value);
        errorMessage = value.trim()
          ? isValidEmergencyContactNumber
            ? ""
            : "Please enter a valid Emergency Contact Number."
          : "Please enter Emergency Contact Number.";
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    webApi
      .get("api/sites/AllSites")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          const sitesList = response.data.sites.map((permittedSite) => ({
            id: permittedSite._id,
            name: permittedSite.siteName,
          }));
          setDataSites(sitesList);

          console.log("All sites data:", response.data.sites);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
        setError("There was an error fetching the data");
      });
  }, []);

  const [ModulesData, SetModulesData] = useState([]);
  const [allData, setAllData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await webApi.get("api/sites/getAll");
        const fetchedData = response.data;

        setAllData(fetchedData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (selectedSiteIds.length > 0) {
      fetchData();
    }
  }, [selectedSiteIds]);

  useEffect(() => {
    webApi
      .get("api/users")
      .then((response) => {
        if (response.data && Array.isArray(response.data.data.allUsers)) {
          setData(response.data.data.allUsers);
          const allUsers = response.data.data.allUsers;

          setData(allUsers);

          console.log("ALL USERS", setData);
        } else {
          setError("Unexpected data format");
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error.meg);
        setError(
          error.response?.data?.msg || "There was an error fetching the data"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleToggle = (moduleName) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [moduleName]: !prevValues[moduleName],
    }));
  };

  const options = data.map((user) => ({
    value: `${user._id} | ${user.firstName} ${user.lastName} | ${user.empId}`,
    label: (
      <div className="containerSearch">
        <div className="iconContainer">
          <Icon.PersonCircle size={23} />
        </div>
        <div className="infoContainer">
          <p className="name">
            {user.firstName} {user.lastName}
          </p>
          <p className="empId">{user.empId}</p>
        </div>
      </div>
    ),
  }));

  const stapNames = [
    "Personal Details",
    "Employee Working Details",
    "Enable Modules",
    "Confirm Details",
  ];

  return (
    <div
      className={`modal fade ${show ? "show" : ""}`}
      style={{ display: show ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="addUserModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "50px",
              backgroundColor: "#303030",
              color: "var(--neturals-background-neturals-base-white, #FFF)",
            }}
          >
            <h5
              className="modal-title"
              id="addUserModalLabel"
              style={{ textAlign: "center", flex: 1 }}
            >
              {stapNames[activeStep]}
            </h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              aria-label="Close"
              style={{ background: "none", border: "none" }}
            >
              <span
                aria-hidden="true"
                style={{
                  color: "var(--neturals-background-neturals-base-white, #FFF)",
                }}
              >
                <RxCross2 size={20} />
              </span>
            </button>
          </div>

          <div className="modal-body">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className="my-4 "
              style={{
                borderBottom: "1px dashed lightgray",
                paddingBottom: "10px",
                width: "90%",
                margin: "auto",
              }}
            >
              <Step>
                <StepLabel
                  StepIconComponent={() => (
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        border: `2px solid #4C4C4C`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        color: activeStep === 0 ? "#fff" : "#4C4C4C",
                        backgroundColor: activeStep === 0 ? "#4C4C4C" : "#fff",
                      }}
                    >
                      1
                    </div>
                  )}
                >
                  Personal Details
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  StepIconComponent={() => (
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        border: `2px solid #4C4C4C`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        color: activeStep === 1 ? "#fff" : "#4C4C4C",
                        backgroundColor:
                          activeStep === 1 ? "#4C4C4C" : "transparent",
                      }}
                    >
                      2
                    </div>
                  )}
                >
                  Employee Working Details
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  StepIconComponent={() => (
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        border: `2px solid #4C4C4C`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        color: activeStep === 2 ? "#fff" : "#4C4C4C",
                        backgroundColor:
                          activeStep === 2 ? "#4C4C4C" : "transparent",
                      }}
                    >
                      3
                    </div>
                  )}
                >
                  Enable Modules
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  StepIconComponent={() => (
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        border: `2px solid #4C4C4C`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        color: activeStep === 3 ? "#fff" : "#4C4C4C",
                        backgroundColor:
                          activeStep === 3 ? "#4C4C4C" : "transparent",
                      }}
                    >
                      4
                    </div>
                  )}
                >
                  Confirm Details
                </StepLabel>
              </Step>
            </Stepper>

            <DialogContent>
              <form>
                {activeStep === 0 ? (
                  <>
                    <div>
                      <section className="grid grid-cols-2 gap-x-16 gap-y-6  mb-4  mt-4">
                        <div>
                          <label
                            htmlFor="firstname"
                            className="fs-10 fw-bold text-start py-2 userlableName"
                            style={{ marginLeft: "-380px" }}
                          >
                            First Name
                            <a style={{ color: "red" }}>*</a>
                          </label>
                          <br />
                          <input
                            type="text"
                            id="firstname"
                            name="firstname"
                            value={formValues.firstname}
                            placeholder="Enter your firstname"
                            className="form-control   fs-12"
                            style={{
                              width: "480px",
                              height: "50px",
                              boxSizing: "border-box",
                              fontSize: "14px",
                            }}
                            onChange={handleInputChange}
                          />
                          <span style={{ color: "red", marginLeft: "-310px" }}>
                            {errors.firstname}
                          </span>
                        </div>
                        <div>
                          <label
                            htmlFor="lastname"
                            className="fs-10 fw-bold text-start py-2 userlableName"
                            style={{ marginLeft: "-400px" }}
                          >
                            Last Name
                            <a style={{ color: "red" }}>*</a>
                          </label>
                          <br />
                          <input
                            type="text"
                            id="lastname"
                            name="lastname"
                            placeholder="Enter your lastname"
                            className="form-control   fs-12"
                            value={formValues.lastname}
                            style={{
                              width: "480px",
                              height: "50px",
                              boxSizing: "border-box",
                              fontSize: "14px",
                            }}
                            onChange={handleInputChange}
                          />
                          <span style={{ color: "red", marginLeft: "-310px" }}>
                            {errors.lastname}
                          </span>
                        </div>

                        <div>
                          <label
                            htmlFor="contactnumber"
                            className="fs-10 fw-bold text-start py-2 userlableName"
                            style={{ marginLeft: "-340px" }}
                          >
                            Contact Number
                            <a style={{ color: "red" }}>*</a>
                          </label>
                          <br />
                          <input
                            type="tel"
                            id="phone"
                            name="contactnumber"
                            pattern="\d{10}"
                            maxLength="10"
                            placeholder="Enter your Contact Number"
                            value={formValues.contactnumber}
                            className={`form-control ${
                              errors.contactnumber ? "is-invalid" : ""
                            }`}
                            style={{
                              width: "480px",
                              height: "50px",
                              boxSizing: "border-box",
                              fontSize: "14px",
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/\D/, "");
                              handleInputChange(e);
                            }}
                          />
                          <p
                            className="invalid-feedback"
                            style={{ marginLeft: "-150px" }}
                          >
                            {errors.contactnumber}
                          </p>
                        </div>

                        <div>
                          <label
                            htmlFor="emailid"
                            className="fs-10 fw-bold text-start py-2 userlableName"
                            style={{ marginLeft: "-410px" }}
                          >
                            Email ID
                            <a style={{ color: "red" }}>*</a>
                          </label>
                          <br />
                          <input
                            type="text"
                            id="emailid"
                            name="emailid"
                            placeholder="Enter your Email"
                            className="form-control fs-12"
                            value={formValues.emailid}
                            style={{
                              width: "480px",
                              height: "50px",
                              boxSizing: "border-box",
                              fontSize: "14px",
                            }}
                            onChange={handleInputChange}
                          />
                          <span style={{ color: "red", marginLeft: "-310px" }}>
                            {errors.emailid}
                          </span>
                        </div>

                        <div>
                          <label
                            htmlFor="bloodGroup"
                            className="fs-10 fw-bold text-start py-2 userlableName"
                            style={{ marginLeft: "-380px" }}
                          >
                            Blood Group
                            <a style={{ color: "red" }}>*</a>
                          </label>
                          <br />
                          <select
                            id="bloodGroup"
                            name="bloodGroup"
                            className="form-control fs-12"
                            style={{
                              width: "480px",
                              height: "50px",
                              padding: "10px",
                              boxSizing: "border-box",
                              fontSize: "14px",
                            }}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Blood Group</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                            <option value="AB-">AB-</option>
                            <option value="AB+">AB+</option>
                          </select>
                          <span style={{ color: "red", marginLeft: "-300px" }}>
                            {errors.bloodGroup}
                          </span>
                        </div>

                        <div>
                          <label
                            htmlFor="emergencyContactnumber"
                            className="fs-10 fw-bold text-start py-2 userlableName"
                            style={{ width: "500px", fontSize: "14px" }}
                          >
                            Emergency Contact Number
                            <a style={{ color: "red" }}>*</a>
                          </label>
                          <br />
                          <input
                            type="tel"
                            id="emergencyContactnumber"
                            name="emergencyContactnumber"
                            placeholder="Enter your Emergency Contact Number"
                            className={`form-control ${
                              errors.emergencyContactnumber ? "is-invalid" : ""
                            }`}
                            pattern="\d{10}"
                            maxLength="10"
                            style={{
                              width: "430px",
                              height: "50px",
                              boxSizing: "border-box",
                              fontSize: "14px",
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(/\D/, "");
                              handleInputChange(e);
                            }}
                          />
                          <p
                            className="invalid-feedback"
                            style={{ marginLeft: "-110px" }}
                          >
                            {errors.emergencyContactnumber}
                          </p>
                        </div>
                      </section>
                    </div>
                  </>
                ) : activeStep === 1 ? (
                  <>
                    <div className="container">
                      <div>
                        <CustomDropdown
                          options={options}
                          handleInputChange={handleInputChange}
                          errors={errors}
                        />

                        <div style={{ marginTop: "100px" }}>
                          <label
                            htmlFor="employeeId"
                            className="fs-10 fw-bold  py-2 userlableName"
                            style={{ marginLeft: "-910px" }}
                          >
                            Employee Id
                          </label>
                          <br />
                          <input
                            type="text"
                            id="employeeId"
                            name="employeeId"
                            value={formValues.employeeId}
                            placeholder="Enter Employee Id"
                            className="form-control fs-12"
                            style={{
                              boxSizing: "border-box",
                              fontSize: "14px",
                              height: "50px",
                              width: "100%",
                            }}
                            onChange={handleInputChange}
                          />
                          <span style={{ color: "red", marginLeft: "-850px" }}>
                            {errors.employeeId}
                          </span>
                        </div>

                        <section className="grid grid-cols-2 gap-x-16 gap-y-6 w-5/6 mb-4 md:gap-x-8 md:w-11/12 mt-4">
                          <div>
                            <MultiSelectSearch
                              mandatory={true}
                              heading={"Site"}
                              listItems={datasites}
                              selectedIds={selectedSiteIds}
                              setSelectedIds={setSelectedSiteIds}
                              handleSelectedPropertyOption={(data) => {
                                if (selectedSiteIds.includes(data.id)) {
                                  setSelectedSiteIds(
                                    selectedSiteIds.filter(
                                      (id) => id !== data.id
                                    )
                                  );
                                } else {
                                  setSelectedSiteIds([
                                    ...selectedSiteIds,
                                    data.id,
                                  ]);
                                }
                              }}
                              clearAll={() => {
                                setSelectedSiteIds([]);
                              }}
                              addAll={() => {
                                const allIds = datasites.map(
                                  (permittedSite) => permittedSite.id
                                );
                                setSelectedSiteIds(allIds);
                              }}
                            />
                            {selectedSiteIds.length === 0 && (
                              <p style={{ color: "red", marginLeft: "-180px" }}>
                                Please select at least one site
                              </p>
                            )}
                          </div>

                          <div>
                            <label
                              htmlFor="department"
                              className="fs-10 fw-bold text-start py-2 userlableName"
                            >
                              Department
                            </label>
                            <br />
                            <select
                              value={selectedDepartment}
                              className="form-control fs-12"
                              style={{
                                width: "400px",
                                height: "50px",
                              }}
                              onChange={handleDepartmentChange}
                            >
                              <option value="">Select</option>
                              {Object.keys(departmentData).map((department) => (
                                <option key={department} value={department}>
                                  {department}
                                </option>
                              ))}
                            </select>
                            <span
                              style={{ color: "red", marginLeft: "-200px" }}
                            >
                              {errors.selectedDepartment}
                            </span>
                          </div>

                          {selectedDepartment && (
                            <div>
                              <label
                                htmlFor="selectRole"
                                className="fs-10 fw-bold text-start py-2 userlableName"
                              >
                                Select Role
                              </label>
                              <br />
                              <select
                                id="selectRole"
                                name="selectRole"
                                className="form-select fs-12"
                                style={{
                                  width: "400px",
                                }}
                                value={selectedRole}
                                onChange={(e) => {
                                  setSelectedRole(e.target.value);
                                }}
                              >
                                <option value="">Select Role</option>
                                {roles.map((role) => (
                                  <option key={role} value={role}>
                                    {role}
                                  </option>
                                ))}
                              </select>
                              <span
                                style={{ color: "red", marginLeft: "-250px" }}
                              >
                                {errors.selectedRole}
                              </span>
                            </div>
                          )}

                          {selectedRole && workModels.length > 0 && (
                            <div>
                              <label
                                htmlFor="workModel"
                                className="fs-10 fw-bold text-start py-2 userlableName"
                              >
                                Select Work Model
                              </label>
                              <br />
                              <select
                                id="workModel"
                                name="workModel"
                                className="form-select fs-12"
                                style={{
                                  width: "400px",
                                }}
                                value={selectedWorkModel}
                                onChange={(e) =>
                                  setSelectedWorkModel(e.target.value)
                                }
                              >
                                <option value="">Select Work Model</option>
                                {workModels.map((workModel) => (
                                  <option key={workModel} value={workModel}>
                                    {workModel}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        </section>
                      </div>
                    </div>
                  </>
                ) : activeStep === 2 ? (
                  <>
                    <div className="container">
                      <div>
                        <h3>Enable Modules</h3>
                        {selectedSiteIds.map((siteId) => {
                          const siteData = allData.find(
                            (data) => data.siteId === siteId
                          );

                          if (!siteData) {
                            return null;
                          }

                          const { siteName, enabledModules } = siteData;

                          const moduleLabels = {
                            drawing: "Drawing",
                            pAndM: "P & M",
                            qaAndQc: "QA/QC",
                            ehs: "EHS",
                            qs: "QS",
                            hr: "HR",
                            user: "USER",
                            store: "STORE",
                            planner: "PlANNER",
                            admin: "ADMIN",
                          };

                          return (
                            <div
                              key={siteId}
                              className="flex flex-col mt-5 pb-10"
                            >
                              <h4>{siteName}</h4>
                              <div
                                className="formData-grid"
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "repeat(4, 1fr)",
                                  gap: "30px",
                                  alignItems: "center",
                                  justifyItems: "center",
                                }}
                              >
                                {enabledModules.map((module) => (
                                  <ToggleSwitch
                                    key={module}
                                    label={moduleLabels[module] || module}
                                    isChecked={formValues[module] || false}
                                    onToggle={() => handleToggle(module)}
                                  />
                                ))}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                ) : activeStep === 3 ? (
                  <>
                    <section className="grid grid-cols-2 gap-x-16 gap-y-6  mb-4 md:gap-x-8 md:w-11/12 mt-4 w-100">
                      <div className="py-3">
                        <div className="row">
                          <div className="col-5">Full Name</div>
                          <div className="col-2">:</div>
                          <div className="col-5 text-start">
                            {formValues.firstname} {formValues.lastname}
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-5">Contact Number</div>
                          <div className="col-2">:</div>
                          <div className="col-5 text-start">
                            {formValues.contactnumber}
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-5">Email ID</div>
                          <div className="col-2">:</div>
                          <div className="col-5 text-start">
                            {formValues.emailid}
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-5">Blood Group</div>
                          <div className="col-2">:</div>
                          <div className="col-5 text-start">
                            {formValues.bloodGroup}
                          </div>
                        </div>

                        <div className="row mt-3">
                          <div className="col-5">Enable Modules</div>
                          <div className="col-2">:</div>
                          <div className="col-5 text-start">
                            {Object.entries(formValues)
                              .filter(
                                ([key, value]) =>
                                  value &&
                                  [
                                    "drawing",
                                    "pAndM",
                                    "qaqc",
                                    "ehs",
                                    "qs",
                                    "hr",
                                    "user",
                                    "store",
                                    "planner",
                                    "admin",
                                  ].includes(key)
                              )
                              .map(
                                ([key]) =>
                                  key.charAt(0).toUpperCase() + key.slice(1)
                              )
                              .join(", ") || "None"}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="row ">
                          <div className="col-5">
                            Reporting Employee ID / Name
                          </div>
                          <div className="col-2">:</div>
                          <div className="col-5 text-start">
                            {formValues.reportingEmpIdName
                              ? formValues.reportingEmpIdName.split("|")[1] ||
                                "N/A"
                              : "N/A"}{" "}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-5">Employee ID</div>
                          <div className="col-2">:</div>
                          <div className="col-5 text-start">
                            {formValues.employeeId}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-5">Site</div>
                          <div className="col-2">:</div>
                          <div className="col-5 text-start">
                            {formValues.permittedSite.length} Sites Selected
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-5">Department</div>
                          <div className="col-2">:</div>
                          <div className="col-5 text-start">
                            {selectedDepartment}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-5">Role</div>
                          <div className="col-2">:</div>
                          <div className="col-5 text-start">{selectedRole}</div>
                        </div>

                        {selectedWorkModel && (
                          <div className="row mt-3">
                            <div className="col-5">Work Module</div>
                            <div className="col-2">:</div>
                            <div className="col-5 text-start">
                              {selectedWorkModel}
                            </div>
                          </div>
                        )}
                      </div>
                    </section>
                  </>
                ) : null}
                {errorMessage && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "15px",
                      textAlign: "center",
                    }}
                  >
                    {errorMessage}
                  </div>
                )}
                <div className="flex justify-between w-full mt-8">
                  <button
                    type="button"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className="  py-2 px-4"
                    style={{
                      border: "1px solid #E47331",
                      color: "#E47331",
                      fontSize: "20px",
                    }}
                  >
                    BACK
                  </button>
                  <button
                    type="button"
                    onClick={handleNext}
                    className=" text-white py-2 px-4"
                    style={{
                      backgroundColor: "#E47331",
                      color: "#fff",
                    }}
                  >
                    {activeStep === 3 ? "Finish" : "Next"}
                  </button>
                </div>
              </form>
            </DialogContent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
