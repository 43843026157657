import React, { useEffect, useState } from "react";
import { FaSearch, FaEdit } from "react-icons/fa";
import "./users.css";
import { WebApimanager } from "../../WebApiManager";
import ToggleSwitch from "../Sites/ToggleSwitch";
import ProfileModal from "./ProfileUpdate/ProfileModal";
import ToggleSwitchWithModules from "../Sites/ToggleSwitchWithModules";
import { Icon } from "@mui/material";
import { LuPlusCircle } from "react-icons/lu";
import AddUserModal from "./AddUserModal";

const Profile = () => {
  const webApi = new WebApimanager();
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const moduleLabelsTop = {
    pAndM: "P & M",
    qaAndQc: "QA & QC",
    ehs: "EHS",
    qs: "QS",
    planner: "PLANNER",
    hr: "HR",
    store: "STORE",
    admin: "ADMIN",
  };
  const moduleLabelsBottom = {
    drawing: "Drawing",
    user: "USER",
  };

  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [formData, setFormData] = useState({
    drawings: false,
    pAndM: false,
    qaAndQc: false,
    ehs: false,
    qs: false,
    planner: false,
    hr: false,
    user: false,
    store: false,
    admin: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [sites, setSites] = useState([]);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const responce = await webApi.get("api/users");

      if (responce.data && responce.data.data && responce.data.data.allUsers) {
        setUsers(responce.data.data.allUsers);
      } else {
        console.log("No users found in response");
      }
    } catch (error) {
      console.error("Error fetching users data", error);
      setError("fail to fetch data for users");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const handleAddUser = () => {
    setShowAddUserModal(true);
    console.log("sdfv sdhfgvsjdcbhjsbch");
  };

  const handleCloseUserModal = () => {
    setShowAddUserModal(false);
  };

  const fetchUserDetails = async (userId) => {
    try {
      const response = await webApi.get(`api/users/enableModules/${userId}`);
      console.log("Selected response:", response);

      if (response.data && response.data.data) {
        console.log("Full response data:", response.data.data);

        const { newUser, enabledModules } = response.data.data;

        const user = {
          _id: newUser._id,
        };
        const siteData = Object.entries(enabledModules).map(
          ([siteId, site]) => ({
            siteId: siteId,
            siteName: site.siteName,
            modules: site.modules,
          })
        );
        setSites(siteData);

        if (siteData.length > 0) {
          setSelectedSiteId(siteData[0].siteId);
          setSelectedUser({
            ...user,
            siteName: siteData[0].siteName,
            modules: siteData[0].modules,
          });
        }
      } else {
        console.log("No data available in the response");
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const handleSiteIdChange = (event) => {
    const siteId = event.target.value;
    const selectedSite = sites.find((site) => site.siteId === siteId);
    setSelectedSiteId(siteId);
    setSelectedUser({
      siteName: selectedSite ? selectedSite.siteName : "N/A",
      modules: selectedSite ? selectedSite.modules : {},
    });
  };

  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (searchQuery.trim().length > 0) {
      const results = users.filter(
        (user) =>
          user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.empId.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSearchSuggestions(results);
      setShowDropdown(true);
      console.log("dropdown show", showDropdown);
      console.log("Search Suggestions:", results);
    } else {
      setSearchSuggestions([]);
      setShowDropdown(false);
    }
  }, [searchQuery, users]);

  const handleDropdownItemClick = (user) => {
    console.log("Selected User ID:", user._id);
    setSelectedUser("");
    setUserInfo(user);
    setShowDropdown(false);
    fetchUserDetails(user._id);
    console.log("User Info:", user);
  };

  const handleToggle = (key) => {
    setSelectedUser((prevData) => {
      return {
        ...prevData,
        modules: {
          ...prevData.modules,
          [key]: !prevData.modules[key],
        },
      };
    });
  };

  useEffect(()=>{
    //trigger api
  },[selectedUser])

  const handleEdit = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container profileContainer">
      <div className="d-flex justify-content-between">
        <div>
          <p className="profileName">Profile</p>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search users"
            className="searchProfile"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onFocus={() => setShowDropdown(true)}
            style={{ width: "300px", border: "1px solid #e47331" }}
          />
          <FaSearch className="search-icon" />
          {showDropdown && searchSuggestions.length > 0 && (
            <div className="dropdown-menu dropdownMenu">
              {searchSuggestions.map((user) => (
                <div
                  key={user._id}
                  className="dropdown-item dropdownItem"
                  onClick={() => handleDropdownItemClick(user)}
                >
                  {user.firstName} {user.lastName} {user.empId}
                </div>
              ))}
            </div>
          )}
          <button
            className="add-button"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              border: "none",
              backgroundColor: "#e47331",
              color: "#fff",
              padding: "8px 16px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={handleAddUser}
          >
            <LuPlusCircle size={20} style={{ marginRight: "5px" }} />
            Add New
          </button>
        </div>
      </div>

      {userInfo ? (
        <div>
          {userInfo && (
            <>
              <div className="user-details mt-4">
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="userDetails">User Details</p>
                  </div>
                  <div>
                    <button className="profileBtn" onClick={handleEdit}>
                      <FaEdit className="IconBtn" size={20} />
                    </button>
                  </div>
                </div>
                <div className="user-item">
                  <section className="grid grid-cols-2 gap-x-16 gap-y-6 mb-4 md:gap-x-8 md:w-11/12 mt-4 w-100">
                    <div>
                      <div className="row">
                        <div className="col-5 userLable">Full Name</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{`${userInfo.firstName} ${userInfo.lastName}`}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Contact Number</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.contactNumber}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Email ID</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.email}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Emergency Contact</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.emergencyContact}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Blood Group</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.bloodGroup}</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-5 userLable">
                          Reporting Employee ID / Name
                        </div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          {userInfo.reportingEmpIdName}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-5 userLable">Employee ID</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.empId}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Site</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          {userInfo.permittedSites ? (
                            <p>
                              {userInfo.permittedSites.length} site selected
                            </p>
                          ) : (
                            "No sites permitted"
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Department</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.department}</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5 userLable">Role</div>
                        <div className="col-2">:</div>
                        <div className="col-5 userData">
                          <p>{userInfo.role}</p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>

              <div>
                <div className="profilePermissions">
                  <p>Permissions</p>
                  <button className="dropdown-toggle-btn PermissionsBtnToggleUser">
                    <select
                      name="siteid"
                      value={selectedSiteId}
                      onChange={handleSiteIdChange}
                      placeholder="Select siteId"
                      onFocus={(e) =>
                        (e.target.style.border = "1px solid black")
                      }
                      onBlur={(e) =>
                        (e.target.style.border =
                          "1px solid rgba(151, 151, 151, 0.25)")
                      }
                      style={{
                        backgroundColor: "#F3F3F3",
                        width: "425px",
                        height: "40px",
                        float: "right",
                      }}
                    >
                      {sites.map((site) => (
                        <option key={site.siteId} value={site.siteId}>
                          {site.siteName}
                        </option>
                      ))}
                    </select>
                  </button>
                </div>

                <div
                  className="formData-grid mt-3"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "30px",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <ToggleSwitch
                    label="Locations :"
                    isChecked={formData.location}
                    onToggle={() => handleToggle("location")}
                  />
                  <ToggleSwitch
                    label="Authentication :"
                    isChecked={formData.authentication}
                    onToggle={() => handleToggle("authentication")}
                  />
                </div>
              </div>

              <div>
                <div className="enable-modules-container">
                  <div>
                    <p className="enableModuleTitleProfile">Enable Modules</p>
                  </div>
                </div>

                {selectedUser && (
                  <div
                    className="formData-grid mt-3"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gap: "30px",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    {Object.entries(moduleLabelsTop).map(
                      ([title, value], index) => {
                        if (
                          selectedUser &&
                          selectedUser.modules &&
                          selectedUser.modules.hasOwnProperty(title)
                        ) {
                          const backgroundColor =
                            index % 2 === 1 ? "#FDF8F4" : "#F3F6F8";
                          return (
                            <div
                              key={title}
                              style={{
                                backgroundColor,
                                borderRadius: "10px",
                                padding: "10px",
                                width: "100%",
                                boxSizing: "border-box",
                              }}
                            >
                              <ToggleSwitchWithModules
                                key={title}
                                selectedSiteId={selectedSiteId}
                                id={title}
                                label={value}
                                isChecked={selectedUser.modules[title]}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                onToggle={() => handleToggle(title)}
                                style={{ backgroundColor: "transparent" }}
                              />
                            </div>
                          );
                        }
                        return null;
                      }
                    )}
                  </div>
                )}
                {selectedUser && (
                  <div
                    className="formData-grid mt-3"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gap: "30px",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    {Object.entries(moduleLabelsBottom).map(
                      ([title, value], index) => {
                        if (
                          selectedUser &&
                          selectedUser.modules &&
                          selectedUser.modules.hasOwnProperty(title) &&
                          selectedUser.modules[title] === true
                        ) {
                          const backgroundColor =
                            index % 2 === 1 ? "#FDF8F4" : "#F3F6F8";
                          return (
                            <div
                              key={title}
                              style={{
                                backgroundColor,
                                borderRadius: "10px",
                                padding: "10px",
                                width: "100%",
                                boxSizing: "border-box",
                              }}
                            >
                              <ToggleSwitchWithModules
                                key={title}
                                id={title}
                                label={value}
                                isChecked={selectedUser.modules[title]}
                                selectedSiteId={selectedSiteId}
                                user={userInfo}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                onToggle={() => handleToggle(title)}
                                style={{ backgroundColor: "transparent" }}
                              />
                            </div>
                          );
                        }
                        return null;
                      }
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div style={{ marginTop: "10px" }}>
            <p style={{ margin: "0 auto" }}>No user found</p>
            <img
              src="https://img.freepik.com/premium-vector/default-avatar-profile-icon-social-media-user-image-gray-avatar-icon-blank-profile-silhouette-vector-illustration_561158-3383.jpg"
              alt="User Not Found"
              style={{
                width: "200px",
                height: "200px",
                objectFit: "cover",
                margin: "0 auto",
              }}
            />
          </div>
        </>
      )}

      <ProfileModal
        show={showModal}
        onHide={handleCloseModal}
        user={userInfo}
        refetchUserData={fetchUserData}
      />

      {showAddUserModal && (
        <AddUserModal show={showAddUserModal} onClose={handleCloseUserModal} />
      )}
    </div>
  );
};

export default Profile;
