import React, { useState } from "react";
import { Snackbar, Alert } from "@mui/material";

//Loader Component Imports
import { Backdrop, CircularProgress } from "@mui/material";
import { Typography, styled } from "@mui/material";

//Custom SnackBar Alerts
export const SnackAlert = ({ handleSnackbar, handleClose }) => {
  return (
    <Snackbar
      open={handleSnackbar.open}
      onClose={handleClose}
      autoHideDuration={5000}
    >
      <Alert
        onClose={handleClose}
        variant="filled"
        severity={handleSnackbar.type}
        sx={{ width: "100%" }}
      >
        {handleSnackbar.message}
      </Alert>
    </Snackbar>
  );
};

//Custom Loader Component
const LoaderContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const Logo = styled("img")({
  width: 100,
  height: 60,
  background: "white",
  marginBottom: 16,
});

export const Loader = (props) => {
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={props.open}
      >
        <LoaderContainer>
          {/* <Logo src="logo/DASHLOGO.png" alt="Logo" /> */}
          <Typography variant="h6">{props.message || "Loading..."}</Typography>
          <CircularProgress
            size={70}
            sx={{
              color: "#025fa1",
              // borderWidth: 2,
              // borderColor: "#fff",
            }}
          />
        </LoaderContainer>
      </Backdrop>
    </div>
  );
};
