import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";


const Chats = () => {
  
  return (
    <div className="container d-flex justify-content-center align-items-center mt-4">
        <p style={{color:'red'}}>In progress</p>

    </div>
  );
};

export default Chats;
