import React, { useState } from "react";
import PagesHeroSection from "../Components/constants-comp/pagesHerosection";
import PartnershipBenefits from "./PartnershipBenefits";
import styles from "./partners.module.css";
import PartnersWithUs from "../Components/Partners/Partners";
import PartnerProgram from "./PartnersProgram/PartnerProgram";
import Header from "../Components/Navbar/Header";
import ApplyPartnership from "./ApplyPartner/ApplyPartnership";
import CompanyImg from "./Images/CompanyImg.png"
import VestraImg from "./Images/VestraImg.png"
import ScienceImg from "./Images/ScienceImg.png"
import InntelImg from "./Images/InntelImg.png"
import PublicChat from "./Images/PublicChat.png"

function Partners({ imageSrc, altText, text }) {
  const [showApplyPartnership, setShowApplyPartnership] = useState(false);

  const handleApplyPartnershipClick = () => {
    setShowApplyPartnership(true);
  };

  return (
    <section>
      <Header />
      <PagesHeroSection
        imageUrl={
          "https://cdn.builder.io/api/v1/image/assets/TEMP/0f21d9bf9d042d84875537fcdc5857d0d73384ee498cd14036c9509de89dd4d3?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20"
        }
        title={"Partners"}
      />
      {!showApplyPartnership && (
        <>
          <div className={styles.banefitssec}>
            <PartnershipBenefits />
          </div>
          <div className={styles.partnersprogram}>
            <PartnerProgram />
          </div>
          {/* <PartnersWithUs backgroundColor="#fff" marginTop="0" /> */}
          <div className={styles.PartnerLogoContainer}>
            <h3>Partner</h3>
            <div className={styles.PartnerImageContainer}>
              <div className={styles.PartnerCompanyLogo}>
                <img src={CompanyImg} alt="companyImg" />
              </div>
              <div className={styles.PartnerCompanyLogo}>
                <img src={VestraImg} alt="vestraImg" />
              </div>
              <div className={styles.PartnerCompanyLogo}>
                <img src={ScienceImg} alt="scienceImg" />
              </div>
              <div className={styles.PartnerCompanyLogo}>
                <img src={InntelImg} alt="inntelImg" />
              </div>
              <div className={styles.PartnerCompanyLogo}>
                <img src={PublicChat} alt="PubicChatImg" />
              </div>
            </div>
          </div>
          <div style={{ background: "#F2F4F6" }}>
            <div className={styles.callToAction}>
              <div>
                <h3 className={styles.ctaTitle}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </h3>
              </div>
              <div className={styles.ctaButtons}>
                <button className={styles.chatButton} onClick={handleApplyPartnershipClick}>
                  Apply for Partnership
                </button>
                {/* <button className={styles.loginButton}>
                  Download Partner Guide
                </button> */}
              </div>
            </div>
          </div>
        </>
      )}
      {showApplyPartnership && <ApplyPartnership />}
    </section>
  );
}

export default Partners;
