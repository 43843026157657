

import React, { useState, useEffect } from "react";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { Dialog, DialogContent } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import styles from "./Design.module.css";
import Add from "../../../Images/Add.svg";
import Sub from "../../../Images/subtration.svg";

const ViewDialog = (props) => {
  const { open, handleDialogClose, site, Filter, existingData } = props;
  let webApi = new WebApimanager();
  
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });




  const handleClose = () => {
    handleDialogClose();

  };

 
  



  

 

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{ style: { borderRadius: 10, fontFamily: "Poppins" } }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: " #4D4D4D",
            margin: "0px",
            height: '50px',
          }}
        >
          <p
            style={{ margin: "5px 550px", textAlign: "center", color: "white" }}
          >
          View
          </p>
          <RxCross1
            size={20}
            style={{
              marginLeft: "auto",
              cursor: "pointer",
              marginRight: "20px",
              color: "white",
            }}
            onClick={handleClose}
          />
        </div>

        <DialogContent
          style={{ padding: "25px", minWidth: "400px", minHeight: "500px" }}
        >
   <div className={styles.overall}>
    <div>
        <p className={styles.heading}>Ranjith Buildcon Pvt. Ltd.</p>
    </div>
    <div className={styles.second}>
       
        <div className={styles.grid}>
<p>ActivityName</p>
<p>:</p>
<p>ActivityName</p>

<p>ActivityName</p>
<p>:</p>
<p>ActivityName</p>


<p>ActivityName</p>
<p>:</p>
<p>ActivityName</p>

<p>ActivityName</p>
<p>:</p>
<p>ActivityName</p>
</div>
    </div>

<div>
    <table>
        <tr className={styles.tableHead}>
            <th>S.No</th>
            <th>Description</th>
        </tr>
        <tbody>
            <tr className={styles.tableBody}>
                <td>01</td>
                <td>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae feugiat quam, eget congue nibh.</td>
            </tr>
        </tbody>
    </table>
    </div>

    <div className={styles.last}>
        <p>Signature Of</p>
        <div className={styles.assignednames}>
            <p>Site Engineer</p>
            <p>QC Engineer</p>
            <p>Project Manager</p>
        </div>
    </div>

   </div>
         
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewDialog;
