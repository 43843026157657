import React, { useEffect, useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import * as Icon from "react-bootstrap-icons";
import AddCompanyDialog from "./AddCompanyDialog";
import AddSiteDialog from "./AddSiteDialog";
import { WebApimanager } from "../../WebApiManager";
import EmptyLogo from "../../Images/emptylogo.png";
import UploadLogoDialog from "./UploadLogoDialog";
import AddSiteDetailsDialog from "./AddSiteDetailsDialog";
import { BACKEND_URL } from "../../Utilities/Constants";
import { BsThreeDotsVertical } from "react-icons/bs";

const Sites = () => {
  let webApi = new WebApimanager();
  const [companies, setCompanies] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [SelectedSite, setSelectedSite] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [showAddSiteModal, setShowAddSiteModal] = useState(false);
  const [showAddSiteDetailsModal, setShowAddSiteDetailsModal] = useState(false);
  const [showUploadLogoDialog, setShowUploadLogoDialog] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [showSiteModal, setShowSiteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dialogType, setDialogType] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    webApi
      .get("api/company")
      .then((response) => {
        setCompanies(response.data.data.companies);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  useEffect(() => {
    webApi
      .get("api/sites/AllSites")
      .then((response) => {
        setSites(response.data.sites);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, []);

  useEffect(() => {
    if (sites.length > 0) {
      console.log(sites, "Updated sites data");
    }
  }, [sites]);

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
    setSelectedCompanyId(company._id);
    console.log(selectedCompanyId);
  };

  const handleSiteClick = (site) => {
    setSelectedSite(site);
    setSelectedSiteId(site._id);
    console.log(selectedSiteId);
    setSelectedCompany(selectedCompany);
    setSelectedCompanyId(selectedCompany?._id);
  };

  const handleBackToCompanies = () => {
    setSelectedCompany(null);
    setSelectedCompanyId("");
  };
  const handleBackToSites = () => {
    setSelectedSite(null);
    setSelectedSiteId("");
    setSelectedCompany(selectedCompany);
    setSelectedCompanyId(selectedCompany?._id);
  };

  const handleMenuClick = (event, id, type) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
    setDialogType(type);
  };

  const handleUploadLogoClick = () => {
    if (dialogType === "company") {
      setSelectedCompanyId(selectedId);
    } else if (dialogType === "site") {
      setSelectedSiteId(selectedId);
    }

    setShowUploadLogoDialog(true);
    handleMenuClose();
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    handleMenuClose();
    if (option === "edit") {
      console.log("Edit option selected for", dialogType, selectedId);
    } else if (option === "upload") {
      setShowUploadLogoDialog(true);
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedCompany(selectedCompany);
    setSelectedCompanyId(selectedCompany?._id);
  };

  const handleAddSiteClick = (companyId, companyName) => {
    setSelectedCompanyId(companyId);
    setSelectedCompanyName(companyName);
    setShowAddSiteModal(true);
  };

  const handleAddSiteDetailsClick = () => {
    setShowAddSiteDetailsModal(true);
    handleCloseMenu();
  };

  const handleAddCompany = () => setShowAddCompanyModal(true);

  const handleAddCompanyClose = () => setShowAddCompanyModal(false);

  const handleSiteUpload = () => setShowSiteModal(true);

  const handleAddSiteClose = () => setShowAddSiteModal(false);

  const handleAddSiteDetailsClose = () => setShowAddSiteDetailsModal(false);
  const handleUploadLogoClose = () => {
    setShowUploadLogoDialog(false);
    setDialogType("");
    setSelectedCompanyId("");
    setSelectedSiteId("");
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filteredCompanies = companies.filter((company) =>
    (company.companyDetails.companyName || "")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  const filteredSites = sites
    .filter((site) => site.companyId === selectedCompanyId)
    .filter((site) =>
      site.siteName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });

  return (
    <div
      style={{
        backgroundColor: "#f3f3f3",
        height: "100%",
        width: "100%",
        paddingTop: "25px",
      }}
    >
      <div
        style={{
          margin: "50px auto",
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
          borderRadius: "8px",
          backgroundColor: "#ffffff",
          fontFamily: "Poppins, sans-serif",
          fontWeight: "400",
          fontSize: "16px",
          padding: "20px",
          maxWidth: "1200px",
          position: "relative",
        }}
      >
        {SelectedSite ? (
          <>
            <div
              style={{
                display: "flex",

                alignItems: "center",
                paddingBottom: "20px",
              }}
            >
              <Button
                onClick={handleBackToSites}
                style={{
                  marginTop: "-10px",
                  color: "black",
                  cursor: "pointer",
                  border: "none",
                }}
              >
                <Icon.ArrowLeft
                  size={30}
                  style={{ color: "var(--primary-color)" }}
                />
              </Button>
              <h4 style={{ color: "#E47331" }}>
                ADD SITE DETAILS OF {SelectedSite.siteName}
              </h4>
            </div>
            <AddSiteDetailsDialog id={selectedSiteId} />
          </>
        ) : selectedCompany ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "20px",
              }}
            >
              <Button
                onClick={handleBackToCompanies}
                style={{
                  marginTop: "-10px",
                  color: "black",
                  cursor: "pointer",
                  border: "none",
                }}
              >
                <Icon.ArrowLeft
                  size={30}
                  style={{ color: "var(--primary-color)" }}
                />
              </Button>
              <h4 style={{ color: "#E47331" }}>
                {selectedCompany.companyDetails.companyName}
              </h4>
              <div
                style={{
                  position: "relative",
                  width: "250px",
                  marginLeft: "auto",
                  marginRight: "20px",
                  height: "40px",
                }}
              >
                <input
                  placeholder="Search Sites"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  style={{
                    paddingLeft: "20px",
                    border: "1px solid #E47331",
                    width: "100%",
                    height: "100%",
                  }}
                />
                <Icon.Search
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#ACACAC",
                  }}
                />
              </div>
              <Button
                style={{
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#E47331",
                  borderColor: "#E47331",
                  color: "white",
                  borderRadius: "0px",
                }}
                onClick={() =>
                  handleAddSiteClick(
                    selectedCompany._id,
                    selectedCompany.companyDetails.companyName
                  )
                }
              >
                <Icon.PlusCircle size={20} style={{ marginRight: "5px" }} /> Add
                Site
              </Button>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "20px",
              }}
            >
              {filteredSites.map((site) => (
                <div
                  key={site._id}
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: "#f9f9f9",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                  }}
                >
                  <div>
                    <img
                      src={
                        site.siteImage
                          ? `${BACKEND_URL}${site.siteImage}`
                          : EmptyLogo
                      }
                      alt="Not Found"
                      style={{
                        width: "100%",
                        height: "200px",
                        cursor:
                          site.enableModules.qaAndQc === true ||
                          site.enableModules.qs === true
                            ? "pointer"
                            : "default",
                        transition: "transform 0.3s ease-in-out",
                      }}
                      onMouseEnter={(e) => {
                        if (
                          site.enableModules.qaAndQc === true ||
                          site.enableModules.qs === true
                        ) {
                          e.currentTarget.style.transform = "scale(1.1)"; // Zoom in on hover
                        }
                      }}
                      onMouseLeave={(e) => {
                        if (
                          site.enableModules.qaAndQc === true ||
                          site.enableModules.qs === true
                        ) {
                          e.currentTarget.style.transform = "scale(1)"; // Reset zoom on mouse leave
                        }
                      }}
                      onClick={
                        site.enableModules.qaAndQc === true ||
                        site.enableModules.qs === true
                          ? () => handleSiteClick(site)
                          : null
                      }
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "#F3F4F6",
                        padding: "10px 10px 10px",
                      }}
                    >
                      <h5>{site.siteName || "Unnamed Site"}</h5>
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(event) =>
                          handleMenuClick(event, site._id, "site")
                        }
                      >
                        <BsThreeDotsVertical size={25} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "20px",
              }}
            >
              <h3 style={{ color: "var(--primary-color)", fontSize: "20px" }}>
                Companies
              </h3>
              <div
                style={{
                  position: "relative",
                  width: "250px",
                  marginLeft: "auto",
                  marginRight: "20px",
                  height: "40px",
                }}
              >
                <input
                  placeholder="Search Companies"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  style={{
                    paddingLeft: "20px",

                    border: "1px solid #E47331",
                    width: "100%",
                    height: "100%",
                  }}
                />
                <Icon.Search
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#ACACAC",
                  }}
                />
              </div>
              <Button
                style={{
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#E47331",
                  borderColor: "#E47331",
                  color: "white",
                  borderRadius: "0px",
                }}
                onClick={handleAddCompany}
              >
                <Icon.Plus
                  size={20}
                  style={{ marginRight: "5px", color: "white" }}
                />{" "}
                Add Company
              </Button>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "20px",
              }}
            >
              {filteredCompanies.map((company) => (
                <div
                  key={company._id}
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: "#f9f9f9",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                  }}
                >
                  <div>
                    <img
                      src={
                        company.uploadLogo
                          ? `${BACKEND_URL}${company.uploadLogo}`
                          : EmptyLogo
                      }
                      alt="Not Found"
                      style={{
                        width: "100%",
                        height: "200px",
                        cursor: "pointer",
                        transition: "transform 0.3s ease-in-out",
                      }}
                      onClick={() => handleCompanyClick(company)}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "0px",

                        background: "#F3F4F6",

                        padding: "10px 10px 10px",
                      }}
                    >
                      <h5>
                        {company.companyDetails.companyName ||
                          "Unnamed Company"}
                      </h5>
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(event) =>
                          handleMenuClick(event, company._id, "company")
                        }
                      >
                        <BsThreeDotsVertical size={25} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              width: "200px",
            },
          }}
        >
          <MenuItem onClick={() => handleMenuItemClick("edit")}>
            Edit {dialogType === "company" ? "Company" : "Site"}
          </MenuItem>

          <MenuItem
            onClick={(e) => handleUploadLogoClick(e, selectedId, dialogType)}
          >
            Upload {dialogType === "company" ? "Company" : "Site"} Logo
          </MenuItem>
        </Menu>

        {/* Upload Company Dialog */}
        <AddCompanyDialog
          close={handleAddCompanyClose}
          open={showAddCompanyModal}
        />

        <AddSiteDialog
          close={handleAddSiteClose}
          open={showAddSiteModal}
          id={selectedCompanyId}
          companyName={selectedCompanyName}
        />
        {/* Upload Sites Dialog */}
        <UploadLogoDialog
          open={showUploadLogoDialog}
          close={handleUploadLogoClose}
          type={dialogType}
          id={dialogType === "company" ? selectedCompanyId : selectedSiteId}
        />
      </div>
    </div>
  );
};

export default Sites;
