import React,{useState} from 'react'
import Styles from "./VerifyCode.module.css"
import MobileLogo from "../Images/MobileLogo.png"
import VerifyCodeImg from "../Images/VerifyCodeImg.png"
import { Link,useLocation,useNavigate } from 'react-router-dom'
import { WebApimanager } from '../../../WebApiManager'
const VerifyCode = () => {
  let webApi = new WebApimanager();
  const location = useLocation()
  const email = location.state

  const navigate = useNavigate()


  const [code,setCode]= useState('')
  const [error,setError] = useState('')
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  })
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = (e) =>{
    e.preventDefault();
    if(code){
      navigate("/reset-password",{state:[email,code]})
    } else {
      setError("Please enter the correct Code")
      return
    }

  }

  const handleResend = async () => {
    setApiProcessing({ loader: true, message: '' }); // Optionally set loader state
    try {
      const mail = { email: email };
      const response = await webApi.post('api/users/requestForPassword', mail);
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.status === 'error') {
          setErrorMessage(responseData.message);
          console.log('Response data:', responseData.message);
          return;
        }
        setErrorMessage('A new code has been sent to your email.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('Failed to resend code. Please try again.');
    } finally {
      setApiProcessing({ loader: false, message: '' }); // Reset loading state
    }
  };
  return (
    <>
      <div className={Styles.LoginContainer}>
    <div className={Styles.LeftContainer}>
     
      <form  onSubmit={handleSubmit} className={Styles.FormContainer}>
      <div className={Styles.ImageContainer}>
              <img className={Styles.Logo} src="logo/logo3.png" />
            </div>
             <h5 className={Styles.LoginText}>Verify Code</h5>
             <p className={Styles.FormDescription}>
           An authentication code has been sent to your email
           </p>
             <div className={Styles.InputContainer}>
              <div className={Styles.EmailContent}>
                <label className={Styles.InputLabel}>Enter Code</label>
                <input
               type="text"
               name="code"
               value={code}
               className={Styles.InputFeild}
               placeholder="XXXXXXXX"
               aria-label="Enter your email or phone number"
               onChange={(e)=>setCode(e.target.value)}
             />
                {error && (
            <p  style={{color:"red",fontSize:"14px",position:"absolute",top:"100%"}}>
              {error}
            </p>
          )}
                <p style={{marginTop:"10px",fontSize:"14px",textAlign:"center"}}>Didn't recieve a code ?
                    <span style={{color:"#E47331", }}  onClick={handleResend}> Resend</span>
                </p>
              </div>
              

            </div>
            <div className={Styles.SubmitBtnContainer}>
                
            <button type="submit" className={Styles.SubmitBtn}>
              Verify
            </button>
            {errorMessage && (
                <p style={{ color: "black", fontSize: "15px" }}>
                  {errorMessage}
                </p>
              )}
            </div>
            <div className={Styles.HelpContainer}>
                    <h6 className={Styles.HelpHeading} style={{color:"#E47331", fontSize:"14px"}}>Need Help ?</h6>
                    <p style={{fontSize:"14px"}}>If you didnot recieve the code or If you changed your email or phone no. <span style={{color:"#E47331", }}>Try Different Way</span></p>
            </div>
            
      </form>
    </div>
    <div className={Styles.RightContainer} >
    <img src={VerifyCodeImg} alt="" className={Styles.RightContainerImage} />
    </div>
    <div className={Styles.MobileContainer}>
    <div className={Styles.MobileView}>
        <div className={Styles.MobileViewLogo}>
          <img src={MobileLogo} alt="" />
          <p className={Styles.HoldOn}>Hold On!</p>
        </div> 
    </div>
    <div className={Styles.MobileMsgContainer}>
          <p className={Styles.MobileMsg}>We are currently not supporting Mobile Devices please click the below button to Download mobile application</p>
          
    </div>
    <div className={Styles.BtnContainer}>
    <button className={Styles.DownloadBtn}>
        <a href="www.google.com" style={{textDecoration:"none",color:"white"}}>Download</a>
  </button>

    </div>
    </div>
  </div>
    </>
  )
}

export default VerifyCode