import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { WebApimanager } from "../../../WebApiManager";
import { siteId } from "../../../atoms";
import { useRecoilState } from "recoil";
import Style from "./VehicleAndMachinery.module.css"
import FormHeadings from "../PNMAssets/FormHeadings";

const PowerTools = () => {
  let webApi = new WebApimanager();
  //equipmentType
  const [formData, setFormData] = useState({
    type: "",
    siteName: "",
    assetCode: "",
    equipmentType: "Power Tools",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    differenceTime: "",
    differenceDate: "",
    oil: "",
    oilIn: "",
    transitionDate: "",
    scopeOfWorkDetails: "",
    location: "",
    remarks: "",
    subCode: "",

    fuelType: "",
    debitNote: "" || "No",
    employeeType: "",
    employeeId: "",
    debitAmount: "",
    debitReason: "",
    issuedEmployeeType:"",
    issuedEmployeeId:""
  });

  const [errors, setErrors] = useState({});
  const [assetCodeData, setAssetCodeData] = useState([]);
  const [nameData, setNameData] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [showdebitInputs, setShowDebitInputs] = useState(false);
  const [site, setSite] = useRecoilState(siteId);
  const [sites, setDatasites] = useState([]);
  const [id, setId] = useState();

  function calculateDateDifference(startDate, endDate) {
    const [startYear, startMonth, startDay] = startDate.split("-").map(Number);
    const [endYear, endMonth, endDay] = endDate.split("-").map(Number);
    console.log(startYear, endYear);
    console.log(startMonth, endMonth);
    console.log(startDay, endDay);

    const start = new Date(startYear, startMonth - 1, startDay);
    const end = new Date(endYear, endMonth - 1, endDay);

    if (end >= start) {
      const diffTime = end.getTime() - start.getTime();
      const diffDays = diffTime / (1000 * 60 * 60 * 24);
      return Math.round(diffDays);
    } else {
      return -1;
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = {
      ...formData,
      [name]: value,
    };

    if (name === "assetCode" && value) {
      const assCode = assetCodeData.find(
        (doc, ind) => doc.assetCode === value.split("/")[0]
      );
      if (assCode) {
        setId(assCode._id);
        console.log(assCode.name);
        newFormData = {
          ...newFormData,
          ["subCode"]: assCode.subCode,
          // ["equipmentType"]: assCode.type,
        };
        setFormData(newFormData);
      }
    }

    setFormData(newFormData);

    // Calculate meter difference

    if (name === "startDate" || name === "endDate") {
      if (newFormData.startDate !== "" && newFormData.endDate !== "") {
        const diff = calculateDateDifference(
          newFormData.startDate,
          newFormData.endDate
        );
        setFormData({ ...newFormData, differenceDate: diff });
        if (diff < 0) {
          errors.differenceDate = "start date should be less than end Date";
          setFormData({
            ...newFormData,
            differenceDate: "",
          });
        } else {
          setFormData({ ...newFormData, differenceDate: diff });
          errors.differenceDate = "";
        }
      } else {
        setFormData({ ...newFormData, differenceDate: "" });
      }
    }

    // Calculate Engine2 readings difference

    // Calculate time difference
    if (name === "startTime" || name === "endTime") {
      if (newFormData.startTime && newFormData.endTime) {
        const start = new Date(`1970-01-01T${newFormData.startTime}`);
        const end = new Date(`1970-01-01T${newFormData.endTime}`);
        let difference = (end.getTime() - start.getTime()) / 1000; // Difference in seconds

        const hours = Math.floor(difference / 3600);
        difference = difference % 3600;
        const minutes = Math.floor(difference / 60);

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedDifference = `${formattedHours}:${formattedMinutes}`;
        if (start >= end) {
          errors.differenceTime = "Start Time should be less than End Date";
          setFormData({
            ...newFormData,
            differenceTime: "",
          });
        } else {
          errors.differenceTime = "";
        }
        setFormData({
          ...newFormData,
          differenceTime: formattedDifference,
        });
      } else {
        setFormData({
          ...newFormData,
          differenceTime: "",
        });
      }
    }
  };
  const filteredNames = assetCodeData.filter(
    (item) => item._id === formData.assetCode
  );
  const filteredSubcodes = assetCodeData.filter(
    (item) => item._id === formData.assetCode && formData.name
  );

  const handleSiteChange = (e) => {
    const { name, value } = e.target;
    setSite(value);
    // setSite(e.target.value);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  useEffect(() => {
 
    webApi
      .get("api/sites/getAllSitesInfo")
      .then((response) => {
        if (response.data && Array.isArray(response.data.sites)) {
          setDatasites(response.data.sites);
      
          if (!site) {
            const defaultSiteId = response.data.sites[0]._id;
            setSite(defaultSiteId); 
            setFormData((prev) => ({ ...prev, siteName: defaultSiteId })); 
          } else {
            setFormData((prev) => ({ ...prev, siteName: site })); 
          }
        } else {
          console.error("Unexpected data format:", response.data);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [site]);
  const matchedSite = sites.find((siteItem) => siteItem._id === site);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = {};
    const requiredFields = [
      "type",
      "equipmentType",
      "siteName",
      "assetCode",
      "startDate",
      "endDate",
      "differenceDate",
      "startTime",
      "endTime",
      "differenceTime",
      "oil",
      "oilIn",
      "transitionDate",
      "scopeOfWorkDetails",
      "location",
      "remarks",
      "subCode",
      "fuelType",
      "debitNote",
      "employeeType",
      "employeeId"
    ];

    requiredFields.forEach((key) => {
      if (!formData[key]) {
        formErrors[key] = `${key} is required`;
      }
    });

    if (formData.debitNote === "Yes") {
      const conditionalDebitNoteFields = [
        "issuedEmployeeType",
        "issuedEmployeeId",
        "debitAmount",
        "debitReason",
      ];
      conditionalDebitNoteFields.forEach((key) => {
        if (!formData[key]) {
          formErrors[key] = `${key} is required`;
        }
      });
    }

    // if (formData.workareadetails === "yes") {
    //   const conditionalWorkAreaFields = ["floor", "flat", "room"];
    //   conditionalWorkAreaFields.forEach((key) => {
    //     if (!formData[key]) {
    //       formErrors[key] = `${key} is required`;
    //     }
    //   });
    // }

    if (Object.keys(formErrors).length > 0) {
      console.log("Form errors:", formErrors); // Log form errors for debugging
      setErrors(formErrors);
    } else {
      console.log(formData);
      const tempFormData = {
        siteName: site,
        type: formData.type,
        assetCode: id,
        equipmentType: formData.equipmentType,
        employeeType: formData.employeeType,
        employeeId: formData.employeeId,
        startDate: formData.startDate,
        endDate: formData.endDate,
        differenceDate: formData.differenceDate,
        startTime: formData.startTime,
        endTime: formData.endTime,
        differenceTime: formData.differenceTime,
        oil: Number(formData.oil),
        oilIn: formData.oilIn,
        transitionDate: formData.transitionDate,
        scopeOfWorkDetails: formData.scopeOfWorkDetails,
        location: formData.location,
        remarks: formData.remarks,
        subCode: formData.subCode,
        fuelType: formData.fuelType,
        debitAmount: Number(formData.debitAmount),
        debitReason: formData.debitReason,
        debitNote: formData.debitNote || "No",
        issuedEmployeeType: formData.issuedEmployeeType,
        issuedEmployeeId: formData.issuedEmployeeId,
      };
      console.log(tempFormData);
      const response = await webApi.post("api/dailyLogReport/pt", tempFormData);
      console.log(response, "ressssssss");
      // window.location.reload();
    }
  };

  useEffect(() => {
    const fetchAssetCodeData = async () => {
      let response = await webApi.get(`api/pnms/assetCode`);
      setAssetCodeData(response.data.data.AssetCodes);
    };

    // const fetchSiteData = async () => {
    //   let response = await webApi.get(`api/sites/AllSites`)
    //   console.log("dd", response.data.sites)
    //   setNameData(response.data.sites)
    // }

    fetchAssetCodeData();
    // fetchSiteData()
  }, []);

  return (
    <>
      <div className={Style.VMMainContainer}>
          <h3 className={Style.VMHeading}>
            Daily Log Report For Power Tools
          </h3>
          <form onSubmit={handleSubmit} className={Style.VMFormContainer}>
            <div className={Style.VMSiteContainer}>
              <div className={Style.VMSiteHeading}>
                <FormHeadings text={"Site Name:"} />
              </div>
              <div className={Style.VMSite}>
                <select
                  className={Style.VMSiteInputField}
                  name="siteName"
                  value={formData.siteName}
                  onChange={handleSiteChange}
                  
                >
                  <option value="" hidden>
                    Select a site
                  </option>
                  {sites &&
                    Array.isArray(sites) &&
                    sites.map((item) => (
                      <option value={item._id} key={item._id}>
                        {item.siteName}
                      </option>
                    ))}
                </select>
                <p className="text-danger absolute">{errors.siteName}</p>
              </div>
            </div>
            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition ID:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                      <input type="text" readOnly className={Style.VMTopInputField} />
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Transition Date:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                            type="date"
                            className={Style.VMTopInputField}
                            name="transitionDate"
                            value={formData.transitionDate}
                            onChange={handleChange}
                            placeholder="---------"
                          />
                          <p className="text-danger absolute">
                            {errors.transitionDate}
                          </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Equipment Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          name="equipmentType"
                          value={formData.equipmentType}
                          readOnly
                        />
                        <p className="text-danger absolute left-[5%]">
                          {errors.equipmentType}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Asset code/Name:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="assetCode"
                          value={formData.assetCode}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            Select name
                          </option>
                          {assetCodeData.length &&
                            assetCodeData
                              .filter(
                                (item) =>
                                  item.type === "Power Tools"
                              )
                              .map((item) => (
                                <option
                                  value={item.assetCode + "/" + item.name}
                                  key={item._id}
                                >
                                  {item.assetCode + "/" + item.name}
                                </option>
                              ))}
                        </select>
                        <p className="text-danger absolute">
                          {errors.assetCode}
                        </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Type:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                          className={Style.VMTopInputField}
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                          
                        >
                          <option value="" hidden>
                            select Type
                          </option>
                          <option value="Own">Own</option>
                          <option value="Hire">Hire</option>
                        </select>
                        <p className="text-danger absolute ">
                          {errors.type}
                        </p>
                    </div>
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"SubCode:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <input
                          className={Style.VMTopInputField}
                          type="text"
                          placeholder="Enter SubCode"
                          name="subCode"
                          onChange={handleChange}
                          value={formData.subCode}
                        />
                        <p className="text-danger absolute">{errors.subCode}</p>
                    </div>
                  </div>
                </div>
              </div>
              
                
            </div>
            <div className={Style.VMFormSeperator}></div>

            <h3 className={Style.VMHeading} >
            Issued To
          </h3>

          <div className={Style.VMTopFormContainer} style={{marginBottom:"30px"}}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee Type:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  name="employeeType"
                                  value={formData.employeeType}
                                  placeholder="Enter employee Type"
                                  onChange={handleChange}
                                />

                                <p className="text-danger absolute">
                                  {errors.employeeType}
                                </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee ID:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  name="employeeId"
                                  placeholder="Enter Employee Id"
                                  value={formData.employeeId}
                                  onChange={handleChange}
                                />

                                <p className="text-danger absolute">
                                  {errors.employeeId}
                                </p>
                      </div>
                    </div>
                  </div>
                </div>
           
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>Date:</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Start:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="date"
                              className={Style.VMMidInput}
                              name="startDate"
                              value={formData.startDate}
                              onChange={handleChange}
                            />
                            <p className="text-danger absolute ">
                              {errors.startDate}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"End:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="date"
                            className={Style.VMMidInput}
                            name="endDate"
                            value={formData.endDate}
                            onChange={handleChange}
                            
                          />
                          <p className="text-danger absolute text-wrap  w-[11%] ">
                            {errors.endDate}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="number"
                            className={Style.VMMidInput}
                            name="differenceDate"
                            value={formData.differenceDate}
                            onChange={handleChange}
                            
                          />
                          <p className="text-danger absolute text-wrap  w-[11%] ">
                            {errors.differenceDate}
                          </p>
                      </div>
                </div>
            </div>
            <div className={Style.VMMidFormCont}>
                <p className={Style.VMMidFormHeading}>Clock:</p>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"In:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                        <input
                              placeholder="Enter Reading"
                              type="time"
                              className={Style.VMMidInput}
                              name="startTime"
                              value={formData.startTime}
                              onChange={handleChange}
                            />
                            <p className="text-danger absolute text-wrap  w-[11%] ">
                              {errors.startTime}
                            </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Out:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            placeholder="Enter Reading"
                            type="time"
                            className={Style.VMMidInput}
                            name="endTime"
                            value={formData.endTime}
                            onChange={handleChange}
                            
                          />
                          <p className="text-danger absolute text-wrap  w-[11%] ">
                            {errors.endTime}
                          </p>
                      </div>
                </div>
                <div className={Style.VMMidFormMainInputContainer}>
                      <div className={Style.VMMidFormInputHeading}>
                        <FormHeadings text={"Difference:"} />
                      </div>
                      <div className={Style.VMMidFormInputContainer}>
                      <input
                            type="text"
                            className={Style.VMMidInput}
                            name="differenceTime"
                            value={formData.differenceTime}
                            onChange={handleChange}
                            
                          />
                          <p className="text-danger absolute text-wrap  w-[11%] ">
                            {errors.differenceTime}
                          </p>
                      </div>
                </div>
            </div>

            <div className={Style.VMFormSeperator}></div>
            <div className={Style.VMTopFormMainContainer}>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Fuel Type:"} />
                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                            className={Style.VMTopInputField}
                            name="fuelType"
                            value={formData.fuelType}
                            onChange={handleChange}
                          >
                            <option value="">Select Fuel Type</option>
                            <option value="Diesel">Diesel</option>
                            <option value="Petrol">Petrol</option>
                          </select>
                          <p className="text-danger absolute ">
                            {errors.fuelType}
                          </p>
                    </div>  
                  </div>
                </div>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Issued Oil:"} />
                    </div>
                    <div className={Style.VMTopInputCont} style={{display:"flex",position:"relative"}}>
                    <input
                            type="number"
                            className={formData.oil ? Style.VMIssuedOil:
                              Style.VMTopInputField
                            }
                            name="oil"
                            placeholder="Enter Number"
                            value={formData.oil}
                            onChange={handleChange}
                          />
                          {formData.oil && <>
                          <select
                              className={Style.VMOilIn}
                              name="oilIn"
                              value={formData.oilIn}
                              onChange={handleChange}
                            >
                              <option value="" hidden>
                                Ltr/Gal
                              </option>
                              <option value="Litre">Ltr</option>
                              <option value="Gallon">Gal</option>
                            </select>
                          
                          </>}
                          {formData.oil === ""  ?<p className="text-danger absolute top-[100%]">{errors.oil}</p>:

<p className="text-danger absolute top-[100%]">
  {errors.oilIn}
</p>}
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.VMTopFormContainer}>
                <div className={Style.VMFormLeftInputContainer}>
                  <div className={Style.VMTopInputFieldContainer}>
                    <div className={Style.VMTopInputHeading}>
                    <FormHeadings text={"Debit Note:"} />

                    </div>
                    <div className={Style.VMTopInputCont}>
                    <select
                            className={Style.VMTopInputField}
                            name="debitNote"
                            value={formData.debitNote || "No"}
                            onChange={handleChange}
                          >
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                          
                    </div>
                  </div>
                </div>
                
              </div>
              
              {formData.debitNote === "Yes" && <>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  name="issuedEmployeeType"
                                  value={formData.issuedEmployeeType}
                                  placeholder="Enter employee Type"
                                  onChange={handleChange}
                                />

                                <p className="text-danger absolute">
                                  {errors.issuedEmployeeType}
                                </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Employee ID:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  name="issuedEmployeeId"
                                  placeholder="Enter Employee Id"
                                  value={formData.issuedEmployeeId}
                                  onChange={handleChange}
                                />

                                <p className="text-danger absolute">
                                  {errors.issuedEmployeeId}
                                </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Debit Reason:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                  className={Style.VMTopInputField}
                                  placeholder="Enter Reason"
                                  name="debitReason"
                                  value={formData.debitReason}
                                  onChange={handleChange}
                                />
                                <p className="text-danger absolute">
                                  {errors.debitReason}
                                </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Debit Amount:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                                    type="number"
                                    className={Style.VMTopInputField}
                                    name="debitAmount"
                                    placeholder="Enter amount"
                                    value={formData.debitAmount}
                                    onChange={handleChange}
                                  />
                                  <p className="text-danger absolute">
                                    {errors.debitAmount}
                                  </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>}
                
            </div>
            <div className={Style.VMFormSeperator}></div>

            <div className={Style.VMWorkDetails}>
              <div className={Style.VMWorkDetailsHeading}>
                <FormHeadings text={"Scope of work Details:"} />
              </div>
              <div className={Style.VMWorkDetailsInputCont}>
                <input
                  type="text"
                  placeholder="Enter Scope of work"
                  className={Style.VMWorkDetailsInputField}
                  name="scopeOfWorkDetails"
                  value={formData.scopeOfWorkDetails}
                  onChange={handleChange}
                />
                <p className="text-danger absolute">
                  {errors.scopeOfWorkDetails}
                </p>
              </div>

            </div>
            <div className={Style.VMTopFormContainer}>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Location:"} />

                      </div>
                      <div className={Style.VMTopInputCont}>
                      <input
                          type="text"
                          className={Style.VMTopInputField}
                          name="location"
                          placeholder="Enter Work Location"
                          value={formData.location}
                          onChange={handleChange}
                        />
                        <p className="text-danger absolute ">
                          {errors.location}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={Style.VMFormLeftInputContainer}>
                    <div className={Style.VMTopInputFieldContainer}>
                      <div className={Style.VMTopInputHeading}>
                      <FormHeadings text={"Remarks:"} />
                      </div>
                      <div className={Style.VMTopInputCont}>
                        <input
                            type="text"
                            className={Style.VMTopInputField}
                            name="remarks"
                            placeholder="Enter Remarks"
                            value={formData.remarks}
                            onChange={handleChange}
                          />
                          <p className="text-danger absolute">{errors.remarks}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={Style.VMBtnContainer}>
                  <button >SUBMIT</button>
                </div>

          </form>
    </div>

    </>
    
  );
};

export default PowerTools;
