import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { WebApimanager } from "../../../WebApiManager";
import { Loader } from "../../../Widgets/notificationFeedbacks";
import { siteId } from "../../../atoms";
import { useRecoilState } from "recoil";
import { Button } from "react-bootstrap";

const AddActivity = (props) => {
  let webApi = new WebApimanager();
  const [open, setOpen] = useState(false);
  const [existingActivities, setExistingActivities] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [formData, setFormData] = useState({
    activities: [],
  });

  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [site, setSite] = useRecoilState(siteId);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData({
        activities: [],
    });
    setErrors({});
    setOpen(false);
  };

//   const handleChange = (e, index, type) => {
//     const { name, value } = e.target;
//     const newValue = value.toUpperCase();
//     console.log(`name: ${name}, value: ${value}`);
//     if (index !== undefined) {
//       const updatedFields = [...formData[type]];
//       updatedFields[index].name = value;
//       setFormData((prevData) => ({
//         ...prevData,
//         [type]: updatedFields,
//       }));
//     } else {
//       setFormData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }
//   };
const handleChange = (e, index) => {
    const { value } = e.target;
  
    setFormData((prevData) => {
      const updatedActivities = [...prevData.activities]; // Make a shallow copy of activities
      updatedActivities[index] = { name: value }; // Update the specific index with the new value
  
      return {
        ...prevData,
        activities: updatedActivities, // Set the new activities array
      };
    });
  };
  
const handleSubmit = async () => {
    let valid = true;
    const newErrors = {};
    if (!formData.activities.every((activity) => activity.name.trim() !== "")) {
      newErrors.activities = "All activity fields must be filled";
      valid = false;
    }
    if (!valid) {
      setErrors(newErrors);
      return;
    }
  
    setApiProcessing({
      loader: true,
      message: "Submitting...",
    });
    const submissionData = formData.activities.map((item) => ({
      activity: item.name, 
    }));
  
    console.log("Form submitted:", submissionData);
  
    try {
      const response = await webApi.post("api/activity/create", submissionData);
      console.log(response);
      if (response.status === 200 && response.data.status === "success") {
        handleClose();
        setApiProcessing({ loader: false, message: "" });
        getAll(); 
      } else {
        setErrorMessage("Unexpected response format.");
        setApiProcessing({ loader: false, message: "" });
      }
    } catch (error) {
      setApiProcessing({ loader: false, message: "" });
      if (error.response) {
        if (error.response.data && error.response.data.message) {
          setErrorMessage(error.response.data.message.error || "An error occurred.");
        } else {
          setErrorMessage("An error occurred while submitting the form.");
        }
      } else if (error.request) {
        setErrorMessage("Network error or server is not reachable.");
      } else {
        setErrorMessage("An error occurred: " + error.message);
      }
    }
  };
  
  
const handleAddField = () => {
  setFormData((prevData) => ({
    ...prevData,
    activities: [...prevData.activities, { name: '' }], 
  }));
};
const handleRemoveField = (index) => {
    setFormData((prevData) => {
      const updatedActivities = prevData.activities.filter((_, i) => i !== index); // Remove the activity at the specified index
  
      return {
        ...prevData,
        activities: updatedActivities,
      };
    });
  };
const onDelete = async (id) => {
   
    if (!id || typeof id !== "string" || id.trim().endsWith(';')) {
      console.error("Invalid ID for deletion:", id);
      return;
    }
    const apiEndpoint = `api/activity/delete/${id.trim()}`; 
    try {
      const response = await webApi.delete(apiEndpoint);
      console.log(`Deleted successfully:`, response.data);
      getAll(); // Refresh the activities list
    } catch (error) {
      console.error(`Error deleting activity:`, error);
    }
  };
  
const getAll = () => {
    webApi.get("api/activity/getAll").then((data) => {
      if (data) {
        setExistingActivities(data.data.data.activities);
        setFilteredActivities(data.data.data.activities);
      }
    });
  };

  useEffect(() => {
    getAll();
  }, []);

  // Search function
  const searchResult = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchInput(searchTerm);
    const filtered = existingActivities.filter((activity) =>
      activity.activity.toLowerCase().includes(searchTerm)
    );
    setFilteredActivities(filtered);
  };

  return (
    <>
      <Loader open={apiProcessing.loader} message={apiProcessing.message} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            margin: "15px",
          }}
        >
          <div
            style={{
              margin: "10px",
              width: "50%",
              borderRadius: 5,
            }}
          >
            <div
              style={{
                border: "1px solid #97979740",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  backgroundColor: " #4D4D4D",
                  color: "white",
                }}
              >
                <p style={{ padding: "5px" }}>View Activity </p>
              </div>
              <div
                style={{
                  maxHeight: "270px",
                  overflowY: "auto",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "15px",
                  }}
                >
                  <p></p>
                  <input
                    placeholder="Search"
                    onChange={searchResult}
                    style={{
                      borderRadius: "6px",
                      width: "201px",
                      height: "40px",
                      padding: "10px",
                      borderRadius: "5px ",
                      border: "1px solid var(--primary-color)",
                    }}
                  />
                </div>
                {filteredActivities && filteredActivities.length > 0 ? (
                  filteredActivities.map((activity, index) => (
                    <li
                      key={activity._id}
                      style={{
                        display: "flex",
                        borderTop: index > 0 ? "1px dotted #000" : "none",
                        padding: "15px 5px",
                      }}
                    >
                      <p
                        style={{
                          width: "500px",
                          margin: 0,
                          textAlign: "start",
                          marginLeft: "5px",
                          fontSize: "16px",
                        }}
                      >
                        {activity.activity}
                      </p>
                      <Icon.TrashFill
                        size={20}
                        style={{ cursor: "pointer", color: "#FF0808" }}
                        onClick={() => onDelete(activity._id)}
                      />
                    </li>
                  ))
                ) : (
                  <p>No activities found</p>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              margin: "10px",
              width: "50%",
              borderRadius: 5,
            }}
          >
            <div
              style={{
                border: "1px solid #97979740",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  backgroundColor: " #4D4D4D",
                  color: "white",
                }}
              >
                <p style={{ padding: "5px" }}>Add Activity</p>
              </div>
              <div
                style={{
                  maxHeight: "270px",
                  overflowY: "auto",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                  padding: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "15px",
                  }}
                >
                  <p></p>
                  <Button
                    style={{
                      height: "40px",
                      marginLeft: "40px",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "var(--primary-color)",
                      border: "1px solid var(--primary-color)",
                    }}
                    onClick={handleAddField}
                  >
                    <Icon.PlusCircle
                      size={20}
                      style={{
                        marginRight: "5px",
                        color: "var(--primary-color)",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        padding: "1px",
                      }}
                    />{" "}
                    Add
                  </Button>
                </div>
                {formData.activities.map((item, index) => (
                  <>
                    <div
                      key={index}
                      style={{
                        alignItems: "center",
                        padding: "8px 15px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <label
                        style={{
                          marginRight: "5px",
                          width: "50px",
                          textAlign: "center",
                          display: "block",
                          padding: "0.375rem 0.75rem",
                          fontSize: "1rem",
                          lineHeight: "2",
                          color: "#495057",
                          backgroundColor: "#fff",
                          border: "1px solid #979797",
                          borderRadius: "0.25rem",
                        }}
                      >
                        {index + 1}.
                      </label>
                      <input
                        type="text"
                        name={`Activity${index}`}
                        value={item.name || ''}
                        placeholder={`Enter the Activity Name ${index + 1}`}
                        onChange={(e) => handleChange(e, index)}
                        style={{
                          textTransform: "uppercase",
                          width: "400px",
                          padding: "15px",
                          display: "block",
                          fontSize: "1rem",
                          lineHeight: "1.5",
                          color: "#495057",
                          backgroundColor: "#fff",
                          backgroundClip: "padding-box",
                          border: "1px solid #979797",
                          borderRadius: "0.25rem",
                          transition:
                            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                          boxShadow: "none",
                          outline: "none",
                        }}
                      />

                      {errors.activities && (
                        <span style={{ color: "red" }}>
                          {errors.activities}
                        </span>
                      )}

                      <Icon.DashCircleFill
                        size={20}
                        onClick={() => handleRemoveField(index,"activities")}
                        style={{
                          cursor: "pointer",
                          color: "red",
                          marginLeft: "8px",
                        }}
                      />
                    </div>
                  </>
                ))}
                {formData.activities.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      style={{
                        marginLeft: "50px",
                        marginTop: "8px",
                        padding: "5px",
                        border: "1px solid var(--primary-color)",
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                        width: "100px",
                      }}
                      onClick={handleSubmit}
                    >
                      SUBMIT
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {errorMessage && (
          <div style={{ textAlign: "center", color: "red" }}>
            {errorMessage}
          </div>
        )}
      </div>
    </>
  );
};

export default AddActivity;
