import React from "react";
import styles from "./Hero.module.css";
import overviewlogo from "../../../Images/overview.jpg";

const Hero = () => {
  return (
    <section>
      <div className={styles.imageContainer}>
          <img
            src={overviewlogo}
            alt="Overview"
            className={`img-fluid ${styles.overviewIMG}`}
          />

          <div className={styles.overlayText}>
            <div className={styles.RCONsince}>
              <p className={styles.RCONTitle}>RCON SPACE</p>
              <p className={styles.Since}> SINCE 2023</p>
            </div>
            <p className={styles.description}>
                 We, RCONSPACE excel in Construction & Real Estate ERP Software: We are a All-in-One Cloud Solution powered with AI Analytics, Real-Time Project Tracking, streamlining Workflows for Residential, Commercial & Infrastructure Development.
            </p>
            <div className={styles.scrolSection}>
              <p>Scroll to Discover</p>
            </div>
          </div>
          <div className={styles.verticalLine}></div>
          <div className={styles.scrollIcon}>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4d3659488f245b0e6e13829c833f995d30185ef270684cad274f4cde5d7fb06a?apiKey=3e99c58a56f84e4cb0d84873c390b13e&"
              alt=""
              className={styles.bottomrightimage}
            />
          </div>
      </div>
    </section>
  );
};

export default Hero;
