import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { WebApimanager } from "../../../WebApiManager";
import { useRecoilState } from "recoil";
import { Button } from "react-bootstrap";
import Folder from "../../../Images/Folder.svg"
import {
  userInfo,
  siteId,
  registerAtom,
  atororegisterTab,
} from "../../../atoms";
import Filter from '../../../Images/Filter.svg'
import TableWrapper from "../../../Utilities/TableWrapper";
import DrawingTableWrapper from "../../Drawing/Components/DrawingTableWrapper";
import Eye from "../../../Images/Eye.svg";
import SelectSite from "../../../Utilities/SelectSite";
import ViewDialog from "./ViewDialog";
import FolderFile from "./Folder"
const Form = () => {
  let webApi = new WebApimanager();
  const [site, setSite] = useRecoilState(siteId);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [viewopen, setviewOpen] = useState(false);
  const [formData, setFormData] = useState(null); 
  const [viewData,setViewData] = useState(null);
  const [SelectedFolder, setSelectedFolder] = useState(null);
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });



  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleFolder = (data) => {
    setFormData(data); 
    setSelectedFolder(true);
   
  };
  
  const  handleView = (data) =>{
    setViewData(data);
    setviewOpen(true);
  };

  const handleViewClose = () => {
    setviewOpen(false);
    setViewData("");
  };

  const handleBackToForms = () => {
    setSelectedFolder(null);
    setSelectedFolderId("");
  };
  // useEffect(() => {
  //   if (searchValue === "") {
  //     // fetchData(); 
  //     setSearchActive(false);
  //   } else {
    
  //   }
  // }, [searchValue]);

  // const searchResult = (e) => {
  //   const searchInput = e.target.value.trim().toLowerCase();
  //   setSearchValue(searchInput);
  //   setSearchActive(true);
  // };

  if (site === "") {
    return <SelectSite/>;
  }


  return (
    <>
      {SelectedFolder ? 
      (
        <>
        <div style={{    boxShadow: 'rgb(243, 243, 243) 0px 0px 10px',
    borderRadius: '12px',
    backgroundColor: 'white',
    padding: '20px 15px 15px',
    fontFamily: 'Poppins',
    height: '76vh'
}}>
    <div style={{
                display: "flex",
                flexDirection:'row',
                alignItems: "center",
                paddingBottom: "20px",
                gap:'10px'
              }}>
    <button
                onClick={handleBackToForms}
                style={{
                  marginTop: "-10px",
                  color: "black",
                  cursor: "pointer",
                  border: "none",
                }}
              >
                <Icon.ArrowLeft
                  size={30}
                  style={{ color: "var(--primary-color)" }}
                />
              </button>
              <p style={{marginTop:'5px'}}>Activity of folder </p>
              </div>
<FolderFile
        site={site}
        formData={formData} 
      />
      </div>
    
      </>
      ):(
<TableWrapper
        children={
          <>
            <div
              style={{
                display: "flex",
                marginLeft: "5px",
                marginRight: "5px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
              <p
                style={{
                  color: "#131313",
                  fontWeight: "600",
                  fontSize: "16px",
                }}
              >
                Form
              </p>
              </div>
              <div>
                <>
               
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          position: "relative",
                          width: "250px",
                          paddingLeft: "5px",
                          height: "50px",
                        }}
                      >
                        <input
                          placeholder="Search Forms"
                          // onChange={searchResult}
                          value={searchValue}
                          style={{
                            paddingLeft: "20px",                    
                            border: "1px solid var(--primary-color)",
                            width: "250px",
                            height: "40px",
                          }}
                        />
                        <Icon.Search
                          style={{
                            position: "absolute",
                            right: "15px",
                            top: "40%",
                            transform: "translateY(-50%)",
                            color:'#ACACAC'
                          }}
                        />
                      </div>
                     
                  <img src={Filter} style={{
                      width: "40px",
                      height: "40px",
                      marginLeft: "10px",
                      padding: "5px",
                      border: "1px solid var(--primary-color)",
                      cursor: "pointer",
                    }} />
                    </div>
                    <div>
                    </div>
                 
                </>
</div>
            </div>
              <DrawingTableWrapper
                tableHead={
                  <tr>
                    <th style={{ width: "5%", paddingLeft: "10px" }}>S.No</th>
                    <th style={{ width: "10%" }}>Department</th>
                    <th style={{ width: "15%" }}>Activity</th>
                    <th style={{ width: "15%" }}>No of Checklist</th>
                    <th style={{ width: "15%" }}>Types Of Checklist</th>
                    <th style={{ width: "15%" }}> Action </th>
                  </tr>
                }
                tableBody={
                  <tbody>
        
                        <tr
          
                        >
                          <td>checking</td>
                          <td>checking</td>
                          <td>checking</td>
                          <td>checking</td>
                          <td>checking</td>
                         
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "10px",
                            }}
                          >
                         
                                <img
                                src={Folder}
                                 loading="lazy"
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleFolder()}
                                />
                                 <img
                                src={Eye}
                                 loading="lazy"
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleView()}
                                />   
                          </td>
                        </tr>
          
                  </tbody>
                }
              />
          </>
        }
      />
      )
      }

<ViewDialog
        handleDialogClose={handleViewClose}
        open={viewopen}
        site={site}
        formData={viewData} 
      />
    </>
  );
};

export default Form;
